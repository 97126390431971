import React, { memo, useState, useEffect, useRef, Fragment } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { Highlighted } from "utils/helpers/hightlightText";

const VektorSearchAutoComplete = ({
  value,
  onChange,
  readOnly,
  endpoint,
  label,
  dataKey,
  displayKey,
}) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [dataListOptions, setDataListOptions] = useState([]);
  const { projectId } = useParams();
  const timer = useRef(null);
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState(0);
  const listElem = useRef();
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) mounted.current = true;
    else if (position && listElem.current)
      listElem.current.scrollTop = position - listElem.current.offsetHeight;
  });
  const getDataList = async (skip = false, type = "", scrollTop = () => {}) => {
    setLoading(true);
    await endpoint(projectId, !skip ? 0 : dataList.length, 10, searchText)
      .then((resp) => {
        if (type === "reload") {
          setPosition(0);
        }
        setDataList(
          type === "reload" ? resp.data : [...dataList, ...resp.data]
        );
        scrollTop();
        setLoadMore(resp?.pagination?.count > dataList.length ? true : false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>> ", err);
      });
  };

  const getDataListOptions = () => {
    return dataList.map((dataItem) => {
      return {
        label: dataItem[displayKey],
        value: dataItem._id,
      };
    });
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await endpoint(projectId, 0, null, searchText)
        .then((resp) => {
          setLoading(false);
          setDataListOptions(resp.data);
          setDataList(resp.data.slice(0, 10));
          setLoadMore(resp?.pagination?.count > resp.data.slice(0, 10)?.length ? true : false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error >>>>> ", err);
        });
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchText !== "") {
      clearTimeout(timer.current);
      timer.current = setTimeout(
        () => {
          getDataList(false, "reload");
        },
        searchText === "" ? 0 : 1000
      );
    }
    if (searchText === "") {
      setDataList(dataListOptions.slice(0, 10));
      setLoadMore(true);
    }

    // eslint-disable-next-line
  }, [searchText]);
  const getValue = () => {
    if (value && dataListOptions?.length) {
      const option = dataListOptions.find((dataItem) => dataItem._id === value);
      if (option) {
        return {
          value: value,
          label: option[displayKey],
        };
      }
    }
    return null;
  };
  return (
    <Autocomplete
      readOnly={readOnly}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setSearchText("");
        setOpen(false);
      }}
      value={getValue()}
      options={getDataListOptions()}
      onChange={(e, value) => {
        onChange({
          target: {
            name: dataKey,
            value: value?.value || null,
            type: dataKey,
          },
        });
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <span>
              <Highlighted
                text={option?.label}
                highlight={searchText}
              ></Highlighted>
            </span>
          </li>
        );
      }}
      loading={loading}
      ListboxProps={{
        ref: listElem,
        onScroll: ({ currentTarget }) => {
          const scrollPosition =
            currentTarget.scrollTop + currentTarget.clientHeight;

          if (currentTarget.scrollHeight - scrollPosition <= 1) {
            setPosition(scrollPosition);
            if (loadMore) getDataList(true, "add");
          }
        },
        style: {
          maxHeight: "150px",
        },
      }}
      renderInput={(params) => (
        <TextField
          onChange={({ target }) => {
            setSearchText(value ? "" : target.value);
            onChange({
              target: {
                name: dataKey,
                value: null,
                type: dataKey,
              },
            });
          }}
          ref={params.InputProps.ref}
          variant="outlined"
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    ></Autocomplete>
  );
};

export default memo(VektorSearchAutoComplete);
