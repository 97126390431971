import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PROJECT_MODES } from "../constants";
import { PERMISSION_TYPES } from "utils/constants";
import { readMetaSystem, getSystemHistory } from "redux/actions/metaSystem";
import { getProjects } from "redux/actions/projects";
import LINKS from "utils/constants/links";
import { ENVIRONMENT } from "config";

export const useEditProjectLogic = (id, fetchHistory = false) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    projects,
    permissions,
    metaSystems = [],
  } = useSelector(({ projects, auth }) => {
    const {
      currentUser: { permissions },
    } = auth;
    const { results, metaSystems } = projects;

    return {
      projects: results,
      permissions,
      metaSystems: metaSystems.filter((m) => m.project === id),
    };
  });

  useEffect(() => {
    const getProjectData = async () => {
      setLoading(true);
      await dispatch(getProjects({ filter: { _id: id } }));
      await dispatch(readMetaSystem({ project: id }, true, setLoading));
      if (fetchHistory) {
        await dispatch(getSystemHistory(id, true));
      }
    };
    getProjectData();

    // eslint-disable-next-line
  }, []);
  const phases = useSelector((state) => state?.projects?.results)?.find(
    (data) => data?._id === id
  )?.phases;
  const project = useMemo(
    () => projects.find((item) => item._id === id),
    [id, projects]
  );
  const projectType = project?.type === "QMS" ? "QMS" : "DELIVERY";
  const NAV_LINKS = [
    LINKS.PROJECT_MANAGEMENT,
    ENVIRONMENT?.toLowerCase() !== "qms" ? LINKS.PROJECTS : LINKS[projectType],
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMode = useMemo(() => {
    switch (permissions) {
      case PERMISSION_TYPES.supervisor:
      case PERMISSION_TYPES.admin:
      case PERMISSION_TYPES.superAdmin:
        return PROJECT_MODES.EDITING;
      default:
        return PROJECT_MODES.VIEWING;
    }
  }, [permissions]);
  const isPhasesVisible = project && project?.phases.length > 0;

  const onClickButton = (href) => () => {
    history.push(href.replace(":projectId", id));
  };

  return {
    projectId: id,
    project,
    isPhasesVisible,
    getMode,
    onClickButton,
    metaSystems,
    loading,
    phases,
    NAV_LINKS,
  };
};
