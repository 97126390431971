import React, { memo } from "react";
import styled, { withTheme } from "styled-components/macro";
import { Grid, AppBar as MuiAppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import UserDropdown from "./UserDropdown";
import UserInfo from "./UserInfo";
import Logo from "components/Logo";
import Notification from "./Notification";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const useStyles = makeStyles(() => ({
  userInfo: {
    display: "flex",
    alignItems: "center",
  },
}));

const Header = ({ onDrawerToggle }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Logo />

            <Grid item xs />
            <Grid item>
              <div className={classes.userInfo}>
                <UserInfo />
                <Notification />
                <UserDropdown />
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default memo(withTheme(Header));
