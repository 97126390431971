import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./phaseSnapshot.css";
import { CustomTableCell } from "../../DeliverableSnapshot/components/deliverableSnapshot";
import { ToolTipStatusText, colorRA, getExeceutionColor } from "./helper";
import useFeatureFlag from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import LINKS from "utils/constants/links";
import { useSelector } from "react-redux";

export const PhaseSnapshotTable = ({
  filteredDeliverableObject,
  selectedPhases,
  projectId,
}) => {
  const systemFeatureFlag = useFeatureFlag(FEATURE_FLAGS.Systems);
  const metaSystems = useSelector((state) => state.projects?.metaSystems || []);
  const handleSystemRedirect = (name) => {
    const filteredMetasystem = metaSystems.find((val) => val.name === name);
    const link = LINKS.EDIT_META_SYSTEM.HREF.replace(":projectId", projectId)
      .replace(":systemId", filteredMetasystem?._id)
      .replace(":mainSystemId", filteredMetasystem?.mainSystem?._id);
    window.open(link, "_blank");
  };

  return (
    <>
      <TableContainer style={{ display: "table", tableLayout: "fixed" }}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell
                align="center"
                className="stickyTop snapshot-header-border-initial"
              >
                Master System
              </CustomTableCell>
              <CustomTableCell
                align="center"
                className="stickyTop snapshot-header-border-initial"
              >
                Deliverables
              </CustomTableCell>
              {selectedPhases?.map((phase) => (
                <CustomTableCell
                  align="center"
                  className="stickyTop snapshot-header-border-initial"
                >
                  {phase?.label}
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDeliverableObject?.map((row) => {
              return (
                <>
                  {row.masterSystemDeliverables?.map((del, delIndex) => {
                    return (
                      <TableRow>
                        {delIndex === 0 && (
                          <CustomTableCell
                            align="center"
                            rowSpan={row.masterSystemDeliverables.length}
                            className="snapshot-header-border-initial"
                          >
                            {row.name}
                          </CustomTableCell>
                        )}
                        <CustomTableCell className="snapshot-header-border-initial">
                          {del.name}
                        </CustomTableCell>
                        {del.deliverables.map((deliverable) => {
                          const approverList = [];
                          deliverable?.approver?.forEach((m) =>
                            approverList.push(...m.users)
                          );
                          const reviewerList = [];
                          deliverable?.reviewer?.forEach((m) =>
                            reviewerList.push(...m.users)
                          );

                          return deliverable ? (
                            <CustomTableCell
                              align="center"
                              className="snapshot-header-border-initial"
                            >
                              <Tooltip
                                title={ToolTipStatusText(
                                  deliverable,
                                  approverList,
                                  reviewerList
                                )}
                              >
                                {deliverable?.status === 100 ? (
                                  <CheckBoxIcon
                                    onClick={() => {
                                      systemFeatureFlag &&
                                        handleSystemRedirect(
                                          deliverable.systemName
                                        );
                                    }}
                                    style={{
                                      color: colorRA(
                                        deliverable?.completion,
                                        deliverable?.end,
                                        deliverable?.status
                                      ),
                                      zIndex: 0,
                                    }}
                                  />
                                ) : (
                                  <div
                                    onClick={() => {
                                      systemFeatureFlag &&
                                        handleSystemRedirect(
                                          deliverable.systemName
                                        );
                                    }}
                                    style={{
                                      color: getExeceutionColor(
                                        deliverable.completion,
                                        deliverable.end,
                                        deliverable.status
                                      ),
                                    }}
                                  >
                                    {Math.round(deliverable?.status || 0) + "%"}
                                  </div>
                                )}
                              </Tooltip>
                            </CustomTableCell>
                          ) : (
                            <CustomTableCell
                              className="snapshot-header-border-initial"
                              style={{
                                backgroundColor: "#d6d2d2",
                              }}
                            />
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
