import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Fragment } from "react";
import { colorRA } from "../snapshotHelper";
import moment from "moment";
import { getColoredIconComponent } from "pages/Projects/ProjectInsight/constant";
import { reviewerApproverList } from "../../PhaseSnapshot/components/helper";

export const DepartmentSnapshot = ({
  classes,
  departments,
  filteredDeliverableObject,
  getDepartmentUserList,
  inputLoading,
  updateComment,
  deliverableList,
  setDeliverableList,
  setInputLoading,
  dispatch,
}) => {
  return (
    <TableContainer style={{ display: "table", tableLayout: "fixed" }}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={`${classes.customTableHeaderCellStyle} snapshotheader-left-border-radius`}
              style={{ zIndex: 2 }}
              key={"systemcell"}
            >
              Systems
            </TableCell>
            <TableCell
              align="center"
              className={[classes.customTableHeaderCellStyle]}
            >
              Deliverables
            </TableCell>
            {departments?.map((department, index) => {
              return (
                <TableCell
                  style={{ whiteSpace: "nowrap" }}
                  key={department?.label}
                  className={[
                    classes.customTableHeaderCellStyle,
                    classes.cellWidth,
                  ]}
                  align="center"
                >
                  {department?.label}
                </TableCell>
              );
            })}
            <TableCell
              style={{ whiteSpace: "nowrap" }}
              align="center"
              className={`${classes.customTableHeaderCellStyle} snapshotheader-right-border-radius`}
            >
              Comments
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(filteredDeliverableObject).map((key) => (
            <Fragment key={key}>
              <TableRow>
                <TableCell
                  align="center"
                  rowSpan={filteredDeliverableObject[key].length + 1}
                  className={[
                    classes.sticky,
                    classes.systemNameCell,
                    classes.customTableSystemInitialCellStyle,
                    classes.snapshotSubheaderRight,
                  ]}
                >
                  {key}
                </TableCell>
              </TableRow>
              {filteredDeliverableObject[key].map((deliverable) => (
                <TableRow key={"deliverable" + deliverable.name}>
                  <TableCell
                    className={[classes.customTableSubHeaderCellStyle]}
                  >
                    {deliverable.name}
                  </TableCell>
                  {departments.map((department) => {
                    const { filteredApprover, filteredReviewer } =
                      getDepartmentUserList(
                        department._id,
                        deliverable.reviewer,
                        deliverable.approver
                      );

                    const appDates =
                      filteredApprover
                        ?.filter((f) => f.date)
                        ?.map((m) => new Date(m.date)) || [];
                    const revDates =
                      filteredReviewer
                        ?.filter((f) => f.date)
                        ?.map((m) => new Date(m.date)) || [];
                    return (
                      <TableCell
                        className={[
                          classes.customTableDelIconCellStyle,
                          classes.snapshotSubheaderRight,
                          classes.snapshotSubheaderLeft,
                        ]}
                        key={"department" + department._id}
                        align="center"
                        style={{
                          backgroundColor:
                            filteredApprover !== undefined ||
                            filteredReviewer !== undefined ||
                            (deliverable.approver.length === 0 &&
                              deliverable.reviewer.length === 0 &&
                              (deliverable.draft.status ||
                                deliverable.ifr.issued ||
                                deliverable.review.status ||
                                deliverable.ifa.issued ||
                                deliverable.approval.status)) ||
                            deliverable.type === "EXECUTION"
                              ? "white"
                              : "#F0F0F0",
                        }}
                      >
                        {filteredApprover !== undefined &&
                        filteredApprover?.filter(
                          (user) => user?.approved === true
                        )?.length === filteredApprover?.length ? (
                          <Tooltip title={"Approved"}>
                            {getColoredIconComponent(
                              "check_circle_filled",
                              colorRA(
                                deliverable.documentId
                                  ? appDates.length !== 0
                                    ? moment(
                                        Math.max.apply(null, appDates)
                                      ).format("YYYY-MM-DD[T00:00:00.000Z]")
                                    : undefined
                                  : deliverable.approval.completion,
                                deliverable.approval.end,
                                parseInt(
                                  (filteredApprover?.filter(
                                    (user) => user?.approved === true
                                  )?.length /
                                    filteredApprover?.length) *
                                    100
                                ) || 0
                              )
                            )}
                            {/* <VerifiedIcon
                              style={{
                                color: colorRA(
                                  deliverable.documentId
                                    ? appDates.length !== 0
                                      ? moment(
                                          Math.max.apply(null, appDates)
                                        ).format("YYYY-MM-DD[T00:00:00.000Z]")
                                      : undefined
                                    : deliverable.approval.completion,
                                  deliverable.approval.end,
                                  deliverable.approval.status
                                ),
                              }}
                            /> */}
                          </Tooltip>
                        ) : deliverable.ifa.issued &&
                          filteredApprover?.filter(
                            (user) => user?.approved === true
                          )?.length !== filteredApprover?.length &&
                          filteredApprover !== undefined ? (
                          <Tooltip
                            title={
                              filteredApprover
                                ?.filter((user) => user?.approved !== true)
                                ?.map((val, index) => {
                                  return index === 0
                                    ? reviewerApproverList(
                                        "approvalpending" + val.userid,
                                        true,
                                        val?.name,
                                        parseInt(
                                          (filteredApprover?.filter(
                                            (user) => user?.approved === true
                                          )?.length /
                                            filteredApprover?.length) *
                                            100
                                        ) || 0,
                                        "Approval Pending"
                                      )
                                    : reviewerApproverList(
                                        "approvalpending" + val.userid,
                                        false,
                                        val?.name,
                                        parseInt(
                                          (filteredApprover?.filter(
                                            (user) => user?.approved === true
                                          )?.length /
                                            filteredApprover?.length) *
                                            100
                                        ) || 0
                                      );
                                }) || ""
                            }
                          >
                            {getColoredIconComponent(
                              "check_circle_outline",
                              colorRA(
                                deliverable.documentId
                                  ? appDates.length !== 0
                                    ? moment(
                                        Math.max.apply(null, appDates)
                                      ).format("YYYY-MM-DD[T00:00:00.000Z]")
                                    : undefined
                                  : deliverable.approval.completion,
                                deliverable.approval.end,
                                parseInt(
                                  (filteredApprover?.filter(
                                    (user) => user?.approved === true
                                  )?.length /
                                    filteredApprover?.length) *
                                    100
                                ) || 0
                              )
                            )}
                            {/* {customSnapshotDiv(
                              "approval",
                              deliverable?.approval?.end
                            )} */}
                          </Tooltip>
                        ) : filteredReviewer?.filter(
                            (user) => user?.approved === true
                          )?.length === filteredReviewer?.length &&
                          filteredReviewer !== undefined ? (
                          <Tooltip title={"Reviewed"}>
                            {getColoredIconComponent(
                              "review_filled",
                              colorRA(
                                deliverable.documentId
                                  ? revDates.length !== 0
                                    ? moment(
                                        Math.max.apply(null, revDates)
                                      ).format("YYYY-MM-DD[T00:00:00.000Z]")
                                    : undefined
                                  : deliverable.review.completion,
                                deliverable.review.end,
                                parseInt(
                                  (filteredReviewer?.filter(
                                    (user) => user?.approved === true
                                  )?.length /
                                    filteredReviewer?.length) *
                                    100
                                ) || 0
                              )
                            )}
                          </Tooltip>
                        ) : deliverable.ifr.issued &&
                          filteredReviewer?.filter(
                            (user) => user?.approved === true
                          )?.length !== filteredReviewer?.length &&
                          filteredReviewer !== undefined ? (
                          <Tooltip
                            title={
                              filteredReviewer
                                ?.filter((user) => user?.approved !== true)
                                ?.map((val, index) => {
                                  return index === 0
                                    ? reviewerApproverList(
                                        "reviewpending" + val.userid,
                                        true,
                                        val?.name,
                                        parseInt(
                                          (filteredReviewer?.filter(
                                            (user) => user?.approved === true
                                          )?.length /
                                            filteredReviewer?.length) *
                                            100
                                        ) || 0,
                                        "Review Pending"
                                      )
                                    : reviewerApproverList(
                                        "reviewpending" + val.userid,
                                        false,
                                        val?.name,
                                        parseInt(
                                          (filteredReviewer?.filter(
                                            (user) => user?.approved === true
                                          )?.length /
                                            filteredReviewer?.length) *
                                            100
                                        ) || 0
                                      );
                                }) || ""
                            }
                          >
                            {getColoredIconComponent(
                              "review_outline",
                              colorRA(
                                deliverable.documentId
                                  ? appDates.length !== 0
                                    ? moment(
                                        Math.max.apply(null, appDates)
                                      ).format("YYYY-MM-DD[T00:00:00.000Z]")
                                    : undefined
                                  : deliverable.review.completion,
                                deliverable.review.end,
                                parseInt(
                                  (filteredReviewer?.filter(
                                    (user) => user?.approved === true
                                  )?.length /
                                    filteredReviewer?.length) *
                                    100
                                ) || 0
                              )
                            )}
                            {/* {customSnapshotDiv(
                              "review",
                              deliverable?.review?.end
                            )} */}
                          </Tooltip>
                        ) : Number(deliverable.draft.status || 0) <= 100 &&
                          !deliverable.ifr.issued &&
                          (filteredApprover !== undefined ||
                            filteredReviewer !== undefined) ? (
                          <Tooltip
                            title={`Draft-${deliverable.draft.status || 0}%`}
                          >
                            {getColoredIconComponent(
                              "draft",
                              colorRA(
                                deliverable.documentId
                                  ? appDates.length !== 0
                                    ? moment(
                                        Math.max.apply(null, appDates)
                                      ).format("YYYY-MM-DD[T00:00:00.000Z]")
                                    : undefined
                                  : deliverable.draft.completion,
                                deliverable.draft.end,
                                deliverable?.draft?.status || 0
                              )
                            )}
                            {/* {customSnapshotDiv("ifr", deliverable?.ifr?.end)} */}
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    className={[
                      classes.customTableDelIconCellStyle,
                      classes.snapshotSubheaderRight,
                      classes.snapshotSubheaderLeft,
                    ]}
                    key={deliverable.systemId + deliverable._id + "comment"}
                    align={
                      inputLoading._id === deliverable._id ? "center" : "left"
                    }
                  >
                    <TextField
                      disabled={
                        inputLoading._id === deliverable._id &&
                        inputLoading.systemName === deliverable.systemName
                      }
                      InputProps={{
                        disableUnderline: true,
                        endAdornment:
                          inputLoading._id === deliverable._id &&
                          inputLoading.systemName === deliverable.systemName ? (
                            <CircularProgress size={20} color="secondary" />
                          ) : (
                            <></>
                          ),
                      }}
                      size="small"
                      defaultValue={deliverable?.comment}
                      onBlur={(e) => {
                        if (e.target.value !== deliverable.comment) {
                          if (
                            deliverable.comment !== undefined ||
                            e.target.value !== ""
                          ) {
                            updateComment(
                              {
                                ...deliverable,
                                comment: e.target.value,
                              },
                              deliverable.systemId,
                              deliverableList,
                              setDeliverableList,
                              setInputLoading,
                              dispatch
                            );
                          }
                        }
                      }}
                      fullWidth
                    ></TextField>
                  </TableCell>
                </TableRow>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
