import LINKS from "utils/constants/links";
import { CategoryRounded, DescriptionOutlined } from "@material-ui/icons";
import {
  InsightsOutlined,
  PrecisionManufacturingOutlined,
  TableChartOutlined,
  CalendarMonth,
  InfoOutlined,
} from "@mui/icons-material";

export const handleNavigation = (history, notification) => {
  switch (notification.module) {
    case "DMS":
      history.push(
        LINKS.EDIT_DOCUMENTS.HREF.replace(
          ":projectId",
          notification.projectId
        ).replace(":id", notification.moduleId)
      );
      break;
    case "Systems":
      if (notification.subModuleId) {
        history.push(
          LINKS.EDIT_DELIVERABLE.HREF.replace(
            ":projectId",
            notification.projectId
          )
            .replace(":systemId/:mainSystemId", notification.moduleId)
            .replace(":deliverableId", notification.subModuleId)
        );
      } else {
        history.push(
          LINKS.EDIT_META_SYSTEM.HREF.replace(
            ":projectId",
            notification.projectId
          ).replace(":systemId/:mainSystemId", notification.moduleId)
        );
      }
      break;
    case "Phases":
      history.push(
        LINKS.PROJECT_PHASES.HREF.replace(":projectId", notification.projectId)
      );
      break;
    case "Dashboard":
      history.push(
        LINKS.PROJECT_INSIGHT.HREF.replace(":projectId", notification.projectId)
      );
      break;
    case "Deliverable Snapshot":
      history.push(
        LINKS.DELIVERABLE_SNAPSHOT.HREF.replace(
          ":projectId",
          notification.projectId
        )
      );
      break;
    case "Calendar View":
      history.push(
        LINKS.PROJECT_CALENDAR.HREF.replace(
          ":projectId",
          notification.projectId
        )
      );
      break;
    case "AIM":
      history.push(
        LINKS.EDIT_DEVIATIONS.HREF.replace(
          ":projectId",
          notification.projectId
        ).replace(":id", notification.moduleId)
      );
      break;
    default:
      break;
  }
};

export const getNotificationIcon = (notification, fullScreen) => {
  const style = { marginTop: fullScreen ? "15px" : "20px" };
  const fontSize = fullScreen ? "large" : "default";
  switch (notification.module) {
    case "DMS":
      return <DescriptionOutlined style={style} fontSize={fontSize} />;
    case "Systems":
      return (
        <PrecisionManufacturingOutlined style={style} fontSize={fontSize} />
      );
    case "Phases":
      return <CategoryRounded style={style} fontSize={fontSize} />;
    case "Dashboard":
      return <InsightsOutlined style={style} fontSize={fontSize} />;
    case "Deliverable Snapshot":
      return <TableChartOutlined style={style} fontSize={fontSize} />;
    case "Calendar View":
      return <CalendarMonth style={style} fontSize={fontSize} />;
    default:
      return <InfoOutlined style={style} fontSize={fontSize} />;
  }
};
