import { memo } from "react";
import { ReactComponent as SearchIcon } from "icons/search.svg";

import VektorTextField from "components/UI/TextFields/VektorTextField";

const VektorSearchInput = ({ ...rest }) => {
  return (
    <VektorTextField
      InputProps={{
        startAdornment: (
          <SearchIcon className="light-grey__icon icon_space_right" />
        ),
      }}
      {...rest}
    />
  );
};

export default memo(VektorSearchInput);
