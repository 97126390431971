import React from "react";
import LINKS from "utils/constants/links";
import EditProject from "pages/Projects/EditProject";
import ProjectCalendar from "pages/Projects/ProjectCalendar";
import ProjectPhases from "pages/Projects/ProjectPhases";
import Documents from "pages/Projects/Documents";
import ProjectScheduler from "pages/Projects/ProjectScheduler";
import ProjectInsight from "pages/Projects/ProjectInsight";
import DeliverableSnapshot from "pages/Projects/ProjectInsight/components/DeliverableSnapshot";
import DocumentSnapshot from "pages/Projects/ProjectInsight/components/DocumentSnapshot";
import ProjectSystems from "pages/Projects/ProjectSystems";
import CustomNotificationList from "pages/Projects/ProjectCustomNotification";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import { PERMISSION_TYPES } from "utils/constants";
import Deviations from "pages/Projects/Deviations";
import SystemSnapshot from "pages/Projects/SystemSnapshot";
import Finance from "pages/Projects/Finance";
import ChangeRequests from "pages/Projects/ChangeRequests";
import Library from "pages/Projects/Library";
import DeliverableMatrix from "pages/Projects/ProjectInsight/components/DeliverableMatrix";
import PatientData from "pages/Projects/PatientData";
import PhaseSnapshot from "pages/Projects/ProjectInsight/components/PhaseSnapshot";
import { ReactComponent as SnapshotIcon } from "icons/snapshot.svg";
import { ReactComponent as OverviewHomeIcon } from "icons/home.svg";
import { ReactComponent as PhasesIcon } from "icons/phases.svg";
import { ReactComponent as SystemsIcon } from "icons/systems.svg";
import { ReactComponent as CalendarViewIcon } from "icons/calendar_view.svg";
import { ReactComponent as LibraryIcon } from "icons/library_1.svg";
import { ReactComponent as DocumentIcon } from "icons/document.svg";
import { ReactComponent as SchedularIcon } from "icons/schedular.svg";
import { ReactComponent as DeviationIcon } from "icons/deviations.svg";
import { ReactComponent as ChangeRequestIcon } from "icons/change requests.svg";
import { ReactComponent as CustomNotificationsIcon } from "icons/custom notifications.svg";
import { ReactComponent as InsightsIcon } from "icons/insights.svg";
import { ReactComponent as FinanceIcon } from "icons/finance.svg";
import { ReactComponent as PatientDataIcon } from "icons/patient data.svg";

export const getProjectRoutes = (
  id,
  isAdmin,
  systemSnapshotFeatureFlag,
  deliverableSnapshotFeatureFlag,
  deliverableMatrixFeatureFlag,
  accessedFeatures,
  permissions
) => {
  const SnapshotArray = [
    {
      id: "System Snapshot",
      path: LINKS.SYSTEM_SNAPSHOT.HREF.replace(":projectId", id),
      icon: <SnapshotIcon />,
      containsHome: true,
      feature: FEATURE_FLAGS.SystemSnapshot,
      closedIcon: <SnapshotIcon />,
      component: SystemSnapshot,
      children: null,
    },
    {
      id: "Phase Snapshot",
      path: LINKS.PHASE_SNAPSHOT.HREF.replace(":projectId", id),
      icon: <SnapshotIcon />,
      containsHome: true,
      feature: FEATURE_FLAGS.PhaseSnapshot,
      closedIcon: <SnapshotIcon />,
      component: PhaseSnapshot,
      children: null,
    },
    {
      id: "Deliverable Snapshot",
      path: LINKS.DELIVERABLE_SNAPSHOT.HREF.replace(":projectId", id),
      icon: <SnapshotIcon />,
      containsHome: true,
      feature: FEATURE_FLAGS.DeliverableSnapshot,
      closedIcon: <SnapshotIcon />,
      component: DeliverableSnapshot,
      children: null,
    },
    {
      id: "Document Snapshot",
      path: LINKS.DOCUMENT_SNAPSHOT.HREF.replace(":projectId", id),
      icon: <SnapshotIcon />,
      containsHome: true,
      feature: FEATURE_FLAGS.DocumentSnapshot,
      closedIcon: <SnapshotIcon />,
      component: DocumentSnapshot,
      children: null,
    },
    {
      id: "Deliverable Matrix",
      path: LINKS.DELIVERABLE_MATRIX.HREF.replace(":projectId", id),
      icon: <SnapshotIcon />,
      containsHome: true,
      feature: FEATURE_FLAGS.DeliverableMatrix,
      closedIcon: <SnapshotIcon />,
      component: DeliverableMatrix,
      children: null,
    },
  ];

  const allowedSubroute = [PERMISSION_TYPES.superAdmin].includes(permissions)
    ? SnapshotArray
    : SnapshotArray.filter((value) =>
        accessedFeatures?.includes(value.feature)
      );

  let routes = [
    {
      id: "Overview",
      path: LINKS.EDIT_PROJECT.HREF.replace(":id", id),
      icon: <OverviewHomeIcon />,
      containsHome: true,
      closedIcon: <OverviewHomeIcon />,
      component: EditProject,
      children: null,
    },
    {
      id: "Phases",
      path: LINKS.PROJECT_PHASES.HREF.replace(":projectId", id),
      icon: <PhasesIcon />,
      feature: FEATURE_FLAGS.Phases,
      containsHome: true,
      closedIcon: <PhasesIcon />,
      component: ProjectPhases,
      children: null,
    },
    {
      id: "Systems",
      pathId: "meta-system",
      path: LINKS.PROJECT_SYSTEMS.HREF.replace(":projectId", id),
      icon: <SystemsIcon />,
      feature: FEATURE_FLAGS.Systems,
      containsHome: true,
      closedIcon: <SystemsIcon />,
      component: ProjectSystems,
      children: null,
    },
    {
      id: "Calendar View",
      pathId: "calendar",
      path: LINKS.PROJECT_CALENDAR.HREF.replace(":projectId", id),
      icon: <CalendarViewIcon />,
      feature: FEATURE_FLAGS.CalendarView,
      closedIcon: <CalendarViewIcon />,
      containsHome: true,
      component: ProjectCalendar,
      children: null,
    },
    {
      id: "Library",
      path: LINKS.LIBRARY.HREF.replace(":projectId", id),
      icon: <LibraryIcon />,
      feature: FEATURE_FLAGS.Library,
      closedIcon: <LibraryIcon />,
      containsHome: true,
      component: Library,
      children: null,
    },
    {
      id: "Documents",
      path: LINKS.PROJECT_DOCUMENTS.HREF.replace(":projectId", id),
      icon: <DocumentIcon />,
      feature: FEATURE_FLAGS.DocumentManagement,
      closedIcon: <DocumentIcon />,
      containsHome: true,
      component: Documents,
      children: null,
    },
    {
      id: "Scheduler",
      path: LINKS.PROJECT_SCHEDULER.HREF.replace(":projectId", id),
      icon: <SchedularIcon />,
      feature: FEATURE_FLAGS.Scheduler,
      containsHome: true,
      closedIcon: <SchedularIcon />,
      component: ProjectScheduler,
      children: null,
    },
    {
      id: "Deviations",
      pathId: "deviation",
      path: LINKS.PROJECT_DEVIATIONS.HREF.replace(":projectId", id),
      icon: <DeviationIcon />,
      feature: FEATURE_FLAGS.DeviationManagement,
      closedIcon: <DeviationIcon />,
      containsHome: true,
      component: Deviations,
      children: null,
    },
    {
      id: "Change Requests",
      pathId: "ecr",
      path: LINKS.PROJECT_CHANGE_REQUEST.HREF.replace(":projectId", id),
      icon: <ChangeRequestIcon />,
      feature: FEATURE_FLAGS.ChangeRequestManagement,
      closedIcon: <ChangeRequestIcon />,
      containsHome: true,
      component: ChangeRequests,
      children: null,
    },
    {
      id: "Insights",
      path: LINKS.PROJECT_INSIGHT.HREF.replace(":projectId", id),
      icon: <InsightsIcon />,
      containsHome: true,
      feature: FEATURE_FLAGS.Insights,
      closedIcon: <InsightsIcon />,
      component: ProjectInsight,
      children: null,
    },

    {
      id: "Finance",
      path: LINKS.PROJECT_FINANCE.HREF.replace(":projectId", id),
      icon: <FinanceIcon />,
      containsHome: true,
      feature: FEATURE_FLAGS.Finance,
      closedIcon: <FinanceIcon />,
      component: Finance,
      children: null,
    },
    {
      id: "Patient Data",
      pathId: "patient",
      path: LINKS.PATIENT_LIST.HREF.replace(":projectId", id),
      icon: <PatientDataIcon />,
      containsHome: true,
      feature: FEATURE_FLAGS.CellGlass,
      closedIcon: <PatientDataIcon />,
      component: PatientData,
      children: null,
    },

    // {
    //   id: "History",
    //   path: LINKS.PROJECT_HISTORY.HREF.replace(":projectId", id),
    //   icon: <HistoryOutlined />,
    //   closedIcon: (
    //     <Tooltip title="History">
    //       <HistoryOutlined />
    //     </Tooltip>
    //   ),
    //   containsHome: true,
    //   component: ProjectHistory,
    //   children: null,
    // },
  ];
  if (isAdmin) {
    routes.splice(9, 0, {
      id: "Custom Notifications",
      pathId: "custom-notification",
      path: LINKS.NOTIFICATION_TEMPLATE.HREF.replace(":projectId", id),
      icon: <CustomNotificationsIcon />,
      closedIcon: <CustomNotificationsIcon />,
      containsHome: true,
      component: CustomNotificationList,
      children: null,
    });
  }

  // if (
  //   systemSnapshotFeatureFlag &&
  //   !deliverableSnapshotFeatureFlag &&
  //   !deliverableMatrixFeatureFlag
  // ) {
  //   routes.splice(4, 0, {
  //     id: "System Snapshot",
  //     path: LINKS.SYSTEM_SNAPSHOT.HREF.replace(":projectId", id),
  //     icon: <TableChartOutlined />,
  //     containsHome: true,
  //     feature: FEATURE_FLAGS.SystemSnapshot,
  //     closedIcon: (
  //       <Tooltip title="System Snapshot">
  //         <TableChartOutlined />
  //       </Tooltip>
  //     ),
  //     component: SystemSnapshot,
  //     children: null,
  //   });
  // }
  // if (
  //   !systemSnapshotFeatureFlag &&
  //   deliverableSnapshotFeatureFlag &&
  //   !deliverableMatrixFeatureFlag
  // ) {
  //   routes.splice(4, 0, {
  //     id: "Deliverable Snapshot",
  //     path: LINKS.DELIVERABLE_SNAPSHOT.HREF.replace(":projectId", id),
  //     icon: <TableChartOutlined />,
  //     containsHome: true,
  //     feature: FEATURE_FLAGS.DeliverableSnapshot,
  //     closedIcon: (
  //       <Tooltip title="Deliverable Snapshot">
  //         <TableChartOutlined />
  //       </Tooltip>
  //     ),
  //     component: DeliverableSnapshot,
  //     children: null,
  //   });
  // }
  // if (
  //   !systemSnapshotFeatureFlag &&
  //   !deliverableSnapshotFeatureFlag &&
  //   deliverableMatrixFeatureFlag
  // ) {
  //   routes.splice(4, 0, {
  //     id: "Deliverable Matrix",
  //     path: LINKS.DELIVERABLE_MATRIX.HREF.replace(":projectId", id),
  //     icon: <TableChartOutlined />,
  //     containsHome: true,
  //     feature: FEATURE_FLAGS.DeliverableMatrix,
  //     closedIcon: (
  //       <Tooltip title="System Snapshot">
  //         <TableChartOutlined />
  //       </Tooltip>
  //     ),
  //     component: DeliverableSnapshot,
  //     children: null,
  //   });
  // }

  // if (
  //   systemSnapshotFeatureFlag &&
  //   deliverableSnapshotFeatureFlag &&
  //   deliverableMatrixFeatureFlag
  // ) {
  //   routes.splice(4, 0, {
  //     id: "Snapshots",
  //     icon: <TableChartOutlined />,
  //     containsHome: true,
  //     closedIcon: (
  //       <Tooltip title="Snapshots">
  //         <TableChartOutlined />
  //       </Tooltip>
  //     ),
  //     children: [
  //       {
  //         id: "System Snapshot",
  //         path: LINKS.SYSTEM_SNAPSHOT.HREF.replace(":projectId", id),
  //         icon: <TableChartOutlined />,
  //         containsHome: true,
  //         feature: FEATURE_FLAGS.SystemSnapshot,
  //         closedIcon: (
  //           <Tooltip title="System Snapshot">
  //             <TableChartOutlined />
  //           </Tooltip>
  //         ),
  //         component: SystemSnapshot,
  //         children: null,
  //       },
  //       {
  //         id: "Deliverable Snapshot",
  //         path: LINKS.DELIVERABLE_SNAPSHOT.HREF.replace(":projectId", id),
  //         icon: <TableChartOutlined />,
  //         containsHome: true,
  //         feature: FEATURE_FLAGS.DeliverableSnapshot,
  //         closedIcon: (
  //           <Tooltip title="Deliverable Snapshot">
  //             <TableChartOutlined />
  //           </Tooltip>
  //         ),
  //         component: DeliverableSnapshot,
  //         children: null,
  //       },
  //       {
  //         id: "Deliverable Matrix",
  //         path: LINKS.DELIVERABLE_MATRIX.HREF.replace(":projectId", id),
  //         icon: <TableChartOutlined />,
  //         containsHome: true,
  //         feature: FEATURE_FLAGS.DeliverableMatrix,
  //         closedIcon: (
  //           <Tooltip title="Deliverable Snapshot">
  //             <TableChartOutlined />
  //           </Tooltip>
  //         ),
  //         component: DeliverableSnapshot,
  //         children: null,
  //       },
  //     ],
  //   });
  // }

  // if (
  //   !systemSnapshotFeatureFlag &&
  //   deliverableSnapshotFeatureFlag &&
  //   deliverableMatrixFeatureFlag
  // ) {
  //   routes.splice(4, 0, {
  //     id: "Snapshots",
  //     icon: <TableChartOutlined />,
  //     containsHome: true,
  //     closedIcon: (
  //       <Tooltip title="Snapshots">
  //         <TableChartOutlined />
  //       </Tooltip>
  //     ),
  //     children: [
  //       {
  //         id: "Deliverable Snapshot",
  //         path: LINKS.DELIVERABLE_SNAPSHOT.HREF.replace(":projectId", id),
  //         icon: <TableChartOutlined />,
  //         containsHome: true,
  //         feature: FEATURE_FLAGS.DeliverableSnapshot,
  //         closedIcon: (
  //           <Tooltip title="Deliverable Snapshot">
  //             <TableChartOutlined />
  //           </Tooltip>
  //         ),
  //         component: DeliverableSnapshot,
  //         children: null,
  //       },
  //       {
  //         id: "Deliverable Matrix",
  //         path: LINKS.DELIVERABLE_MATRIX.HREF.replace(":projectId", id),
  //         icon: <TableChartOutlined />,
  //         containsHome: true,
  //         feature: FEATURE_FLAGS.DeliverableMatrix,
  //         closedIcon: (
  //           <Tooltip title="Deliverable Snapshot">
  //             <TableChartOutlined />
  //           </Tooltip>
  //         ),
  //         component: DeliverableSnapshot,
  //         children: null,
  //       },
  //     ],
  //   });
  // }

  // if (
  //   systemSnapshotFeatureFlag &&
  //   !deliverableSnapshotFeatureFlag &&
  //   deliverableMatrixFeatureFlag
  // ) {
  //   routes.splice(4, 0, {
  //     id: "Snapshots",
  //     icon: <TableChartOutlined />,
  //     containsHome: true,
  //     closedIcon: (
  //       <Tooltip title="Snapshots">
  //         <TableChartOutlined />
  //       </Tooltip>
  //     ),
  //     children: [
  //       {
  //         id: "System Snapshot",
  //         path: LINKS.SYSTEM_SNAPSHOT.HREF.replace(":projectId", id),
  //         icon: <TableChartOutlined />,
  //         containsHome: true,
  //         feature: FEATURE_FLAGS.SystemSnapshot,
  //         closedIcon: (
  //           <Tooltip title="System Snapshot">
  //             <TableChartOutlined />
  //           </Tooltip>
  //         ),
  //         component: SystemSnapshot,
  //         children: null,
  //       },
  //       {
  //         id: "Deliverable Matrix",
  //         path: LINKS.DELIVERABLE_MATRIX.HREF.replace(":projectId", id),
  //         icon: <TableChartOutlined />,
  //         containsHome: true,
  //         feature: FEATURE_FLAGS.DeliverableMatrix,
  //         closedIcon: (
  //           <Tooltip title="Deliverable Snapshot">
  //             <TableChartOutlined />
  //           </Tooltip>
  //         ),
  //         component: DeliverableSnapshot,
  //         children: null,
  //       },
  //     ],
  //   });
  // }

  // if (
  //   systemSnapshotFeatureFlag &&
  //   deliverableSnapshotFeatureFlag &&
  //   !deliverableMatrixFeatureFlag
  // ) {
  //   routes.splice(4, 0, {
  //     id: "Snapshots",
  //     icon: <TableChartOutlined />,
  //     containsHome: true,
  //     closedIcon: (
  //       <Tooltip title="Snapshots">
  //         <TableChartOutlined />
  //       </Tooltip>
  //     ),
  //     children: [
  //       {
  //         id: "System Snapshot",
  //         path: LINKS.SYSTEM_SNAPSHOT.HREF.replace(":projectId", id),
  //         icon: <TableChartOutlined />,
  //         containsHome: true,
  //         feature: FEATURE_FLAGS.SystemSnapshot,
  //         closedIcon: (
  //           <Tooltip title="System Snapshot">
  //             <TableChartOutlined />
  //           </Tooltip>
  //         ),
  //         component: SystemSnapshot,
  //         children: null,
  //       },
  //       {
  //         id: "Deliverable Snapshot",
  //         path: LINKS.DELIVERABLE_SNAPSHOT.HREF.replace(":projectId", id),
  //         icon: <TableChartOutlined />,
  //         containsHome: true,
  //         feature: FEATURE_FLAGS.DeliverableSnapshot,
  //         closedIcon: (
  //           <Tooltip title="Deliverable Snapshot">
  //             <TableChartOutlined />
  //           </Tooltip>
  //         ),
  //         component: DeliverableSnapshot,
  //         children: null,
  //       },
  //     ],
  //   });
  // }

  if (allowedSubroute.length > 1) {
    routes.splice(4, 0, {
      id: "Snapshots",
      icon: <SnapshotIcon />,
      containsHome: true,
      closedIcon: <SnapshotIcon />,
      children: [...SnapshotArray],
    });
  } else if (allowedSubroute.length > 0 || allowedSubroute.length === 1) {
    routes.splice(4, 0, allowedSubroute[0]);
  }

  return routes;
};
