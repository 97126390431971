import PageHeader from "parts/PageHeader";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import LINKS from "utils/constants/links";
import { getNavLinks } from "../ProjectPhases/helpers";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import {
  Chip,
  Grid,
  IconButton,
  Tab,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Budget from "./components/Budget";
import PurchaseOrder from "./components/PurchaseOrder";
import { PERMISSION_TYPES } from "utils/constants";
import { getProjects } from "redux/actions/projects";
import Invoices from "./components/Invoices";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import FeatureFlag from "utils/hocs/FeatureFlag";
// import { FilterAltRounded } from "@mui/icons-material";
import { FINANCE_ADD_BUTTON } from "./helpers";
import { ColorButton } from "components/UI/Buttons";
import { exportFinance } from "services/api-finance";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import { saveAs } from "file-saver";
// import SearchIcon from "@material-ui/icons/Search";
// import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { ReactComponent as FilterIcon } from "icons/filter.svg";
import { ReactComponent as SearchIcon } from "icons/search.svg";
import { ReactComponent as CloseIcon } from "icons/cross_circle.svg";

const Finance = () => {
  const [activeTab, setActiveTab] = useState("budget");
  const [openAdd, setOpenAdd] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchText, setSearchText] = useState("");
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.auth.currentUser);
  const accessedFeatures = useSelector(
    (state) => state?.modulePreference?.accessibleFeature
  );
  const project = useSelector((state) =>
    state.projects.results.find((p) => p._id === projectId)
  );
  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, projectId]);

  const handleTab = (event, newValue) => {
    setActiveTab(newValue);
    handleClose();
  };

  const handleClose = () => {
    setOpenAdd(null);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  const enableFilterClear = () => {
    setShowClear(true);
  };

  const clearFilter = () => {
    setShowClear(false);
  };

  const tabs = [
    {
      label: "Budget",
      id: "budget",
      component: (
        <Budget
          projectId={projectId}
          project={project}
          openAdd={openAdd}
          handleClose={handleClose}
        />
      ),
    },
    {
      label: "Purchase Order",
      id: "purchaseOrder",
      component: (
        <PurchaseOrder
          projectId={projectId}
          project={project}
          openAdd={openAdd}
          handleClose={handleClose}
          openFilter={openFilter}
          handleFilterClose={handleFilterClose}
          enableFilterClear={enableFilterClear}
          clearFilter={clearFilter}
          showClear={showClear}
          initialLoad={initialLoad}
          searchText={searchText}
        />
      ),
    },
    {
      label: "Invoice",
      id: "invoice",
      component: (
        <Invoices
          projectId={projectId}
          project={project}
          openAdd={openAdd}
          handleClose={handleClose}
          openFilter={openFilter}
          handleFilterClose={handleFilterClose}
          enableFilterClear={enableFilterClear}
          clearFilter={clearFilter}
          showClear={showClear}
        />
      ),
    },
  ];

  const getTabList = () => {
    const allTabs = tabs.filter(
      (tab) =>
        accessedFeatures.includes(tab.id) ||
        !Object.values(FEATURE_FLAGS).includes(tab.id) ||
        [PERMISSION_TYPES.superAdmin].includes(permissions)
    );
    if (!activeTab) {
      setActiveTab(allTabs[0].id);
    }
    return (
      <TabList onChange={handleTab} className="mb-10">
        {allTabs.map((tab) => (
          <Tab label={tab.label} key={tab.id} value={tab.id} />
        ))}
      </TabList>
    );
  };

  const getTabPanel = () => {
    return tabs.map((tab) => {
      return (
        <TabPanel key={tab.id} className="pd-0" value={tab.id}>
          <FeatureFlag feature={tab.id}>{tab.component}</FeatureFlag>
        </TabPanel>
      );
    });
  };

  const downloadFinance = () => {
    const tab = activeTab === "invoice" ? "orderInvoice" : activeTab;
    exportFinance(projectId, tab)
      .then(async (resp) => {
        const blob = await new Blob([resp], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        await saveAs(
          blob,
          `${project?.name}_${activeTab}_${moment().format("MMM DD@HH:mm")}`
        );
      })
      .catch((err) => {
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: "Failed to export file.",
          })
        );
      });
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <>
      <PageHeader
        title={LINKS.PROJECT_FINANCE.TITLE}
        links={getNavLinks(project?.name, projectId)}
      />
      <TabContext value={activeTab}>
        {/* <<<<<<<<<<<<<<<<< header start >>>>>>>>>>>>>>>>>>>> */}
        <div className="d-jsb-c">
          {getTabList()}
          <div className="d-jsb-cen">
            {["purchaseOrder", "invoice"].includes(activeTab) && (
              <>
                {showClear && (
                  <Chip
                    size="small"
                    label="Clear Filter"
                    className="chip-clear"
                    onClick={clearFilter}
                  />
                )}
                <IconButton
                  className="pd-0 mr-10"
                  onClick={() => setOpenFilter(true)}
                >
                  {/* <FilterAltRounded /> */}
                  <FilterIcon />
                </IconButton>
              </>
            )}
            {["purchaseOrder"].includes(activeTab) && (
              <Grid item className="ml-10 txt-end">
                <TextField
                  value={searchText}
                  onChange={(e) => {
                    setInitialLoad(false);
                    setSearchText(e.target.value);
                  }}
                  variant="outlined"
                  size="small"
                  label="Search"
                  style={{
                    marginRight: "10px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton className="pd-0">
                          <SearchIcon />
                        </IconButton>
                        {searchText && (
                          <IconButton
                            className="pd-10"
                            onClick={handleClearSearch}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            <ColorButton
              colour="lightGreen"
              onClick={() => setOpenAdd(activeTab)}
            >
              {FINANCE_ADD_BUTTON[activeTab]}
            </ColorButton>
            <ColorButton
              colour="lightGreen"
              className="ml-10"
              onClick={downloadFinance}
            >
              Export
            </ColorButton>
          </div>
        </div>
        {/* <<<<<<<<<<<<<<<<< header end >>>>>>>>>>>>>>>>>>>> */}
        {/* <<<<<<<<<<<<<<<<< table data start >>>>>>>>>>>>>>>>>>>> */}
        <div>{getTabPanel()}</div>
        {/* <<<<<<<<<<<<<<<<< table data end >>>>>>>>>>>>>>>>>>>> */}
      </TabContext>
    </>
  );
};

export default memo(Finance);
