import React, { memo } from "react";
import styled from "styled-components/macro";
import { ListItem, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@mui/icons-material";
import { ReactComponent as MenuIcon } from "icons/menu.svg";

const Brand = styled(ListItem)`
  min-height: 56px;
  background: #f7f9fc;
  display: flex;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const SidebarHeader = ({ open, onDrawerToggle }) => {
  return (
    <Brand button>
      {open && <div className="ml-40">Navigation</div>}
      <IconButton onClick={onDrawerToggle} size="small">
        {!open && <MenuIcon />}
        {open && <ChevronLeft />}
      </IconButton>
    </Brand>
  );
};

export default memo(SidebarHeader);
