import React, { memo, useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { CssBaseline, Paper as MuiPaper, withWidth } from "@material-ui/core";
import { isWidthUp } from "@material-ui/core/withWidth";

import Header from "./Header";
// import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Popup from "components/UI/Popup";
import CustomPopup from "components/UI/Popup/CustomPopup";
import { useIdleTimer } from "react-idle-timer";
import { setPopup, setPopupText } from "redux/actions/popupActions";
import { useDispatch } from "react-redux";
import { POPUP_TYPE } from "utils/constants";
import { logoutUser } from "redux/actions/authActions";
import { INACTIVE_TIMEOUT } from "config";
import { PROMPT_BEFORE_INACTIVE_TIMEOUT } from "config";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  width: 96%;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;
const Dashboard = ({ children, routes, width }) => {
  const dispatch = useDispatch();
  const [remaining, setRemaining] = useState(INACTIVE_TIMEOUT);

  const timeTillPrompt = Math.max(
    remaining - PROMPT_BEFORE_INACTIVE_TIMEOUT / 1000,
    0
  );
  const seconds = `${remaining} ${timeTillPrompt > 1 ? "seconds" : "second"}`;

  const onIdle = () => {
    dispatch(logoutUser());
    dispatch(setPopup({ type: "" }));
  };

  const onActive = () => {
    console.log("USER IS NOW ACTIVE");
  };

  useEffect(() => {
    if (remaining * 1000 <= PROMPT_BEFORE_INACTIVE_TIMEOUT) {
      dispatch(
        setPopupText(`You will be automatically logged out on ${seconds}`)
      );
    }
  }, [seconds, remaining, dispatch]);

  const onPrompt = () => {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.confirm,
        popupText: `You will be automatically logged out on ${seconds}.`,
        confirmText: "Yes",
        cancelText: "No",
        onConfirm: () => activate(),
        onCancel: () => dispatch(logoutUser()),
        popupHeader: "Are you still here?",
        showCloseIcon: false,
      })
    );
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: INACTIVE_TIMEOUT,
    promptBeforeIdle: PROMPT_BEFORE_INACTIVE_TIMEOUT,
    throttle: 1000,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Root>
      <Popup />
      <CustomPopup />
      <CssBaseline />
      <GlobalStyle />
      <Sidebar routes={routes} variant="temporary" />
      <AppContent>
        <Header />
        <MainContent p={isWidthUp("lg", width) ? 6 : 4}>{children}</MainContent>
        {/* <Footer /> */}
      </AppContent>
    </Root>
  );
};

export default memo(withWidth()(Dashboard));
