import TYPES from "utils/constants/action-types";
import { POPUP_TYPE } from "utils/constants";

const setCustomPopup = (popup) => {
  return {
    type: TYPES.SET_CUSTOM_POPUP,
    payload: popup,
  };
};
const setCustomPopupText = (popup) => {
  return {
    type: TYPES.SET_CUSTOM_POPUP_TEXT,
    payload: popup,
  };
};

const showCustomError = (msg) => ({
  type: TYPES.SET_CUSTOM_POPUP,
  payload: {
    popupType: POPUP_TYPE.error,
    popupText: msg,
  },
});

export { setCustomPopup, showCustomError, setCustomPopupText };
