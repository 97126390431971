import React from "react";
import { Dialog, DialogContent } from "components/UI/VektorDialog";
import { Box, Button, TextField } from "@material-ui/core";

const TaskNoteDialog = ({
  title,
  open,
  onClose,
  task,
  users,
  onCreate,
  onUpdate,
  onCellChange,
  departments,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogContent>
        <Box width={"300px"}>
          <h2>Edit Notes</h2>
          <div className="mb-20">
            <TextField
              multiline
              fullWidth
              label="Note"
              type="string"
              name="note"
              InputProps={{
                inputProps: { min: "0", max: "100", step: "1" },
              }}
              onChange={onCellChange}
              value={task.note}
            />
          </div>
          <div className="d-jsb-r mb-20">
            <Button variant="contained" color="disabled" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-10"
              onClick={onUpdate}
            >
              Update
            </Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TaskNoteDialog;