import React, { useState, useEffect } from "react";
import { getDmsTemplates } from "services/api-document";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { DocumentHelper } from "pages/Projects/Documents/helpers";
import LINKS from "utils/constants/links";
import { useHistory } from "react-router-dom";

export default function Templates({ projectId }) {
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const history = useHistory();


  const { TABLE_COLUMN_TEMPLATE } = DocumentHelper();

  const getTemplatesList = async () => {
    setLoading(true);
    await getDmsTemplates(projectId)
      .then((resp) => {
        setTemplates(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>> ", err);
      });
  };

  useEffect(() => {
    getTemplatesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  if (!templates.length)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>No Templates available.</p>
      </div>
    );
  return (
    <Card>
      <TableContainer style={{ height: "70vh", maxHeight: "70vh" }}>
        <Table stickyHeader className="w-100 h-100">
          <TableHead>
            {TABLE_COLUMN_TEMPLATE.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {templates.map((doc, index) => (
              <TableRow key={index}>
                {TABLE_COLUMN_TEMPLATE.map((column) => {
                  let value = doc[column.id];
                  if (column.id === "fileName") {
                    value = doc?.histories[0]?.fileName
                  }
                  if (column.id === "version") {
                    value = doc?.histories[0]?.version
                  }
                  if (column.id === "name") {
                    value = <Typography
                    className="pointer"
                    color="primary"
                    onClick={() =>
                      history.push(
                        LINKS.EDIT_DMS_TEMPLATE.HREF.replace(
                          ":projectId",
                          projectId
                        ).replace(":id", doc._id)
                      )
                    }
                  >
                    {value}
                  </Typography>
                    
                  }

                  return (
                    <TableCell key={column.id} align={column.align}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
