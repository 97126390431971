import React, { memo, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";

import ContainedButton from "components/UI/Buttons/ContainedButton";
import PageHeader from "parts/PageHeader";
import DetailLinkCard from "parts/DetailLinkCard";
import ProjectForm from "../Shared/ProjectForm";
import StopDailyData from "../Shared/StopDailyData";
import MetaSystemTable from "pages/MetaSystems/Shared/MetaSystemTable";
import PhasesListView from "../Shared/PhasesListView";
import LINKS from "utils/constants/links";
import { useHistory, useParams } from "react-router-dom";
// import { NAV_LINKS } from "./constants";
import { useEditProjectLogic } from "./helpers";
import TrendChart from "pages/MetaSystems/Shared/TrendChart";
import Loader from "components/Loader";
import { useUserPermission } from "utils/hooks";
import FeatureFlag from "utils/hocs/FeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import { useDispatch, useSelector } from "react-redux";
import { getModulePreferences } from "redux/actions/modulePreferenceActions";
// import { getSelectedOrganization } from "redux/actions/organizations";

const EditProject = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    projectId,
    project,
    isPhasesVisible,
    getMode,
    onClickButton,
    metaSystems,
    phases,
    loading,
    NAV_LINKS,
  } = useEditProjectLogic(id, true);
  const history = useHistory();

  const { activeProject } = useSelector(({ projects }) => projects);

  const { isAdmin } = useUserPermission("ADMIN");

  const { currentUser } = useSelector(({ auth }) => {
    const { currentUser } = auth;
    return { currentUser };
  });

  useEffect(() => {
    if (currentUser._id && projectId && projectId !== activeProject) {
      dispatch(getModulePreferences(currentUser._id, projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser._id, projectId]);

  const isPhaseOrSystemAvailable = Boolean(
    project?.phases?.length || project?.metaSystems?.length
  );

  // useEffect(() => {
  //   if (project) {
  //     dispatch(getSelectedOrganization(project?.organization));
  //   }
  // }, [dispatch, project]);

  return (
    <>
      <PageHeader
        title={project?.name}
        // links={NAV_LINKS}
        links={NAV_LINKS}
        leftElement={
          <Box>
            <FeatureFlag feature={FEATURE_FLAGS.Insights}>
              <ContainedButton
                style={{ marginRight: "10px" }}
                onClick={onClickButton(LINKS.PROJECT_INSIGHT.HREF)}
              >
                Insights
              </ContainedButton>
            </FeatureFlag>
            {/* <ContainedButton
              onClick={onClickButton(LINKS.PROJECT_HISTORY.HREF)}
            >
              History
            </ContainedButton> */}
          </Box>
        }
      />
      <Grid container spacing={6}>
        <FeatureFlag feature={FEATURE_FLAGS.ProjectDetails}>
          <Grid item xs={12}>
            <ProjectForm mode={getMode} project={project} />
          </Grid>
        </FeatureFlag>
        {isAdmin && (
          <Grid item xs={12}>
            <DetailLinkCard
              title="Module Preferences"
              onDetail={onClickButton(LINKS.PROJECT_MODULE_PREFERENCE.HREF)}
            />
          </Grid>
        )}
        {!isPhaseOrSystemAvailable && (
          <FeatureFlag feature={FEATURE_FLAGS.Phases}>
            <Grid item xs={12}>
              <DetailLinkCard
                title="Phases"
                onDetail={onClickButton(LINKS.PROJECT_PHASES.HREF)}
              />
            </Grid>
          </FeatureFlag>
        )}
        {!isPhaseOrSystemAvailable && (
          <FeatureFlag feature={FEATURE_FLAGS.Systems}>
            <Grid item xs={12}>
              <MetaSystemTable
                records={metaSystems}
                projectId={projectId}
                projectName={project?.name}
                phases={phases}
                organization={project?.organization}
              />
            </Grid>
          </FeatureFlag>
        )}
        {isPhaseOrSystemAvailable && (
          <>
            <FeatureFlag feature={FEATURE_FLAGS.CalendarView}>
              <Grid item xs={12}>
                <DetailLinkCard
                  title="Calendar View"
                  records={metaSystems}
                  project={project}
                  onDetail={onClickButton(LINKS.PROJECT_CALENDAR.HREF)}
                />
              </Grid>
            </FeatureFlag>
            <FeatureFlag feature={FEATURE_FLAGS.SystemSnapshot}>
              <Grid item xs={12}>
                <DetailLinkCard
                  title="System Snapshot"
                  onDetail={() => {
                    history.push(
                      LINKS.SYSTEM_SNAPSHOT.HREF.replace(
                        ":projectId",
                        projectId
                      )
                    );
                  }}
                />
              </Grid>
            </FeatureFlag>
            <FeatureFlag feature={FEATURE_FLAGS.PhaseSnapshot}>
              <Grid item xs={12}>
                <DetailLinkCard
                  title="Phase Snapshot"
                  onDetail={() => {
                    history.push(
                      LINKS.PHASE_SNAPSHOT.HREF.replace(":projectId", projectId)
                    );
                  }}
                />
              </Grid>
            </FeatureFlag>
            <FeatureFlag feature={FEATURE_FLAGS.DeliverableSnapshot}>
              <Grid item xs={12}>
                <DetailLinkCard
                  title="Deliverable Snapshot"
                  onDetail={() => {
                    history.push(
                      LINKS.DELIVERABLE_SNAPSHOT.HREF.replace(
                        ":projectId",
                        projectId
                      )
                    );
                  }}
                />
              </Grid>
            </FeatureFlag>
            <FeatureFlag feature={FEATURE_FLAGS.DocumentSnapshot}>
              <Grid item xs={12}>
                <DetailLinkCard
                  title="Document Snapshot"
                  onDetail={() => {
                    history.push(
                      LINKS.DOCUMENT_SNAPSHOT.HREF.replace(
                        ":projectId",
                        projectId
                      )
                    );
                  }}
                />
              </Grid>
            </FeatureFlag>
            <FeatureFlag feature={FEATURE_FLAGS.DeliverableMatrix}>
              <Grid item xs={12}>
                <DetailLinkCard
                  title="Deliverable Matrix"
                  onDetail={() => {
                    history.push(
                      LINKS.DELIVERABLE_MATRIX.HREF.replace(
                        ":projectId",
                        projectId
                      )
                    );
                  }}
                />
              </Grid>
            </FeatureFlag>
            <FeatureFlag feature={FEATURE_FLAGS.Scheduler}>
              <Grid item xs={12}>
                <DetailLinkCard
                  title="Scheduler"
                  onDetail={() => {
                    history.push(
                      LINKS.PROJECT_SCHEDULER.HREF.replace(
                        ":projectId",
                        projectId
                      )
                    );
                  }}
                />
              </Grid>
            </FeatureFlag>
          </>
        )}
        <FeatureFlag feature={FEATURE_FLAGS.Library}>
          <Grid item xs={12}>
            <DetailLinkCard
              title="Library"
              onDetail={onClickButton(LINKS.LIBRARY.HREF)}
            />
          </Grid>
        </FeatureFlag>
        <FeatureFlag feature={FEATURE_FLAGS.DocumentManagement}>
          <Grid item xs={12}>
            <DetailLinkCard
              title="Documents"
              onDetail={onClickButton(LINKS.PROJECT_DOCUMENTS.HREF)}
            />
          </Grid>
        </FeatureFlag>
        <FeatureFlag feature={FEATURE_FLAGS.DeviationManagement}>
          <Grid item xs={12}>
            <DetailLinkCard
              title="Deviations"
              onDetail={onClickButton(LINKS.PROJECT_DEVIATIONS.HREF)}
            />
          </Grid>
        </FeatureFlag>
        <FeatureFlag feature={FEATURE_FLAGS.ChangeRequestManagement}>
          <Grid item xs={12}>
            <DetailLinkCard
              title="Change Requests"
              onDetail={() => {
                history.push(
                  LINKS.PROJECT_CHANGE_REQUEST.HREF.replace(
                    ":projectId",
                    projectId
                  )
                );
              }}
            />
          </Grid>
        </FeatureFlag>
        <FeatureFlag feature={FEATURE_FLAGS.Finance}>
          <Grid item xs={12}>
            <DetailLinkCard
              title="Finance"
              onDetail={() => {
                history.push(
                  LINKS.PROJECT_FINANCE.HREF.replace(":projectId", projectId)
                );
              }}
            />
          </Grid>
        </FeatureFlag>
        {isAdmin && (
          <Grid item xs={12}>
            <StopDailyData project={project} />
          </Grid>
        )}
        {isPhaseOrSystemAvailable && (
          <FeatureFlag feature={FEATURE_FLAGS.Phases}>
            <Grid item xs={12}>
              <DetailLinkCard
                title="Phases"
                onDetail={onClickButton(LINKS.PROJECT_PHASES.HREF)}
              />
            </Grid>
          </FeatureFlag>
        )}
        {isPhasesVisible && (
          <Grid item xs={12}>
            <PhasesListView project={project} metaSystems={metaSystems} />
          </Grid>
        )}

        <FeatureFlag feature={FEATURE_FLAGS.SystemTrendChart}>
          <Grid item xs={12}>
            <TrendChart title="System Trend Chart" projectId={projectId} />
          </Grid>
        </FeatureFlag>

        {isPhaseOrSystemAvailable && (
          <FeatureFlag feature={FEATURE_FLAGS.Systems}>
            <Grid item xs={12}>
              <MetaSystemTable
                records={metaSystems}
                projectId={projectId}
                projectName={project?.name}
                phases={phases}
                organization={project?.organization}
              />
            </Grid>
          </FeatureFlag>
        )}
      </Grid>
      {loading && <Loader />}
    </>
  );
};

export default memo(EditProject);
