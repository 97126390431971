import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useDrop } from "react-dnd";
import { useCardToggler } from "utils/hooks";
import PhaseItem from "../PhaseItem";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}));

const BufferBox = ({ systems, setLoading, phases }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [toggleButton, , toggled] = useCardToggler({ defaultToggled: false });
  const [, dropRef] = useDrop({
    accept: "ITEM",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div className={classes.root} ref={dropRef}>
      <Card>
        <CardHeader
          title="Unassigned systems"
          action={
            <div className="d-flex">
              {toggled && systems.length > 0 && (
                <TextField
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  variant="outlined"
                  size="small"
                  label="Search"
                  style={{
                    marginRight: "10px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon />
                          {searchText && (
                            <IconButton onClick={() => setSearchText("")}>
                              <CloseIcon />
                            </IconButton>
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {toggleButton}
            </div>
          }
        />
        {toggled && (
          <CardContent className="max-ht-620 scroll-y">
            <Grid container spacing={2}>
              {systems
                .filter(
                  ({ projectPhase, autoPhaseId }) =>
                    !projectPhase && !autoPhaseId
                )
                .filter((sys) => {
                  return (
                    (sys?.parentSystem ||
                      systems.filter(
                        (system) => system.parentSystem === sys._id
                      ).length === 0) &&
                    (sys.name
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                      sys.equipmentNumber
                        .toLowerCase()
                        .includes(searchText.toLowerCase()))
                  );
                })
                .map((sys) => (
                  <Grid key={sys._id} item xs={3}>
                    <PhaseItem
                      item={sys}
                      searchText={searchText}
                      setLoading={setLoading}
                      phases={phases}
                    />
                  </Grid>
                ))}
            </Grid>
          </CardContent>
        )}
      </Card>
    </div>
  );
};

export default BufferBox;
