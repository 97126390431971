import React, { memo, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { saveAs } from "file-saver";
import {
  Card,
  CardHeader,
  Table,
  TableCell,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Chip,
  Typography,
  TableBody,
  TableHead,
  TableContainer,
  TableSortLabel,
  Modal,
} from "@material-ui/core";
import LinkButton from "components/UI/Buttons/LinkButton";
import InfiniteScroll from "react-infinite-scroll-component";
import LINKS from "utils/constants/links";
import { useHistory } from "react-router-dom";
import { useTableSort } from "utils/hooks";
import { ColorButton } from "components/UI/Buttons";
import { ReactComponent as CloseIcon } from "icons/cross_circle.svg";
import { ReactComponent as SearchIcon } from "icons/search.svg";
import { ReactComponent as DownloadIcon } from "icons/download.svg";
import { ReactComponent as FilterIcon } from "icons/filter.svg";
import { ReactComponent as AddCircle } from "icons/add_circle.svg";
import { Highlighted } from "utils/helpers/hightlightText";
import {
  EQUIPMENTS,
  EQUIPMENT_CATEGORIES,
  POPUP_TYPE,
  SYSTEM_TYPES,
} from "utils/constants";
import { exportProjectSystems } from "services/api-project";
import useUserPermissions from "utils/hooks/useUserPermission";
import { ALLOWED_ROLES } from "pages/Projects/ProjectPhases/constants";
import { getMetaSystems } from "services/api-meta-system";
import { CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setPopup } from "redux/actions/popupActions";
import moment from "moment";
import { getAreas } from "services/api-area";
import { SORT_DIRS } from "utils/constants";
import { CalendarFilter } from "pages/Projects/Calendar/CalendarFilter";

const columns = [
  { id: "name", label: "Name", minWidth: 120, sortable: true },
  {
    id: "assignedTemplate",
    label: "Assigned Template",
    minWidth: 120,
    sortable: true,
  },
  {
    id: "equipmentNumber",
    label: "Equipment Number",
    minWidth: 120,
    sortable: true,
  },
  {
    id: "mainSystem.calculated.totalPlannedHours",
    label: "Total Planned Hours",
    minWidth: 120,
    sortable: true,
  },
  {
    id: "mainSystem.calculated.PV",
    label: "PV",
    minWidth: 120,
    sortable: true,
  },
  {
    id: "mainSystem.calculated.overallStatus",
    label: "Status",
    minWidth: 120,
    sortable: true,
  },
  {
    id: "mainSystem.calculated.totalWorkedHours",
    label: "Effort",
    minWidth: 120,
    sortable: true,
  },
  {
    id: "mainSystem.calculated.EV",
    label: "EV",
    minWidth: 120,
    sortable: true,
  },
];

const MetaSystemTable = ({
  projectId = 0,
  records = [],
  phases,
  organization,
  projectName,
}) => {
  const [searchText, setSearchText] = useState("");
  const [metaSystems, setMetaSystems] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const history = useHistory();
  const { sortedRows, handleSort } = useTableSort(metaSystems);
  const [isDownloading, setIsDownloading] = useState(false);
  const [phaseList, setPhaseList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [systemTypeList, setSystemTypeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(SORT_DIRS.asc);
  const [orderBy, setOrderBy] = useState(null);
  const { included } = useUserPermissions(ALLOWED_ROLES);
  const [openFilter, setOpenFilter] = useState(false);
  const dispatch = useDispatch();
  const timer = useRef(null);
  const workflows = useSelector((state) => state.projects.workflows);
  useEffect(() => {
    if (organization) {
      getArea();
    }
    // eslint-disable-next-line
  }, [organization]);

  useEffect(() => {
    setMetaSystems(records.slice(0, 20));
  }, [records]);

  useEffect(() => {
    if (!initialLoad) {
      clearTimeout(timer.current);
      timer.current = setTimeout(
        () => {
          setMetaSystems([]);
          getMetaSystemsList(0, true);
        },
        searchText === "" ? 0 : 1000
      );
    }
    // eslint-disable-next-line
  }, [projectId, searchText, initialLoad]);

  const getMetaSystemsList = async (skip = 0, isClear, filter = null) => {
    if (isClear) {
      setLoading(true);
    }
    await getMetaSystems({
      sort: "name",
      filter: {
        project: projectId,
        name: searchText === "" ? null : searchText,
        equipmentCategory: filter
          ? filter.category
          : categoryList
              .filter((category) => category.selected)
              ?.map((category) => category.VALUE),
        equipmentType: filter
          ? filter.type
          : typeList.filter((type) => type.selected)?.map((type) => type.VALUE),
        area: filter
          ? filter.area
          : areaList.filter((area) => area.selected)?.map((area) => area._id),
        projectPhase: filter
          ? filter.phase
          : phaseList
              .filter((phase) => phase.selected)
              ?.map((phase) => phase._id),
        systemType: filter
          ? filter.systemType
          : systemTypeList
              .filter((system) => system.selected)
              ?.map((system) => system.VALUE),
      },
      limit: isClear ? 20 : 5,
      skip,
    })
      .then((resp) => {
        setMetaSystems(isClear ? resp?.data : [...metaSystems, ...resp.data]);
        setLoadMore(
          resp?.pagination?.count > metaSystems.length ? true : false
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log("error >>>>> ", err);
      });
  };

  const handleLoadMore = async () => {
    if (metaSystems.length !== 0) {
      getMetaSystemsList(metaSystems.length, false);
    }
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const getArea = async () => {
    await getAreas(organization)
      .then((resp) => {
        const areas = resp;
        areas.unshift({ label: "Default", _id: null });
        setAreaList(
          areas.map((area) => {
            return { ...area, selected: false };
          })
        );
      })
      .catch((err) => {
        console.log("error >>>>> ", err);
      });
  };

  useEffect(() => {
    let phaseList = phases?.map((phase) => ({
      ...phase,
      selected: false,
    }));
    let categoryList = EQUIPMENT_CATEGORIES?.map((equipment) => ({
      ...equipment,
      selected: false,
    }));
    let typeList = EQUIPMENTS?.map((equipment) => ({
      ...equipment,
      selected: false,
    }));
    let systemTypeList = SYSTEM_TYPES.map((system) => ({
      ...system,
      selected: false,
    }));
    setCategoryList(categoryList);
    setTypeList(typeList);
    setSystemTypeList(systemTypeList);
    setPhaseList(phaseList);
  }, [phases]);

  const handleFilterClear = () => {
    typeList?.map((type) => {
      return (type.selected = false);
    });
    phaseList.map((phase) => {
      return (phase.selected = false);
    });
    categoryList.map((category) => {
      return (category.selected = false);
    });
    areaList?.map((area) => {
      return (area.selected = false);
    });
    systemTypeList?.map((system) => {
      return (system.selected = false);
    });
    setTypeList(typeList);
    setPhaseList(phaseList);
    setCategoryList(categoryList);
    setAreaList(areaList);
    setSystemTypeList(systemTypeList);
    getMetaSystemsList(0, true);
  };

  const checkFilter = () => {
    let filter = false;
    if (phaseList?.filter((phase) => phase.selected).length > 0) {
      filter = true;
    }
    if (categoryList?.filter((category) => category.selected).length > 0) {
      filter = true;
    }
    if (typeList?.filter((type) => type.selected).length > 0) {
      filter = true;
    }
    if (areaList?.filter((area) => area.selected).length > 0) {
      filter = true;
    }
    if (systemTypeList?.filter((system) => system.selected).length > 0) {
      filter = true;
    }
    return filter;
  };

  const downloadExcel = async () => {
    try {
      setIsDownloading(true);
      const response = await exportProjectSystems(projectId, {
        sort: "name",
        filter: {
          project: projectId,
          name: searchText === "" ? null : searchText,
          equipmentCategory: categoryList
            .filter((category) => category.selected)
            ?.map((category) => category.VALUE),
          equipmentType: typeList
            .filter((type) => type.selected)
            ?.map((type) => type.VALUE),
          area: areaList
            .filter((area) => area.selected)
            ?.map((area) => area._id),
          projectPhase: phaseList
            .filter((phase) => phase.selected)
            ?.map((phase) => phase._id),
          systemType: systemTypeList
            .filter((system) => system.selected)
            ?.map((system) => system.VALUE),
        },
        limit: 0,
        skip: 0,
      });
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${projectName}_systems_${moment().format("MMM DD@HH:mm")}`);
    } catch (error) {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: "Failed to export file.",
        })
      );
    } finally {
      setIsDownloading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === SORT_DIRS.asc;
    const nextOrder = isAsc ? SORT_DIRS.desc : SORT_DIRS.asc;
    setOrder(nextOrder);
    setOrderBy(property);
    handleSort(property, nextOrder);
  };

  const createSortHandler = (columnId) => (event) => {
    handleRequestSort(event, columnId);
  };

  const handleOpen = () => setOpenFilter(true);
  const handleClose = () => setOpenFilter(false);

  const getWorkflow = (workflowId) => {
    if(workflows?.length > 0) {
      for (const workflow of workflows) {
        if (workflowId === workflow?._id) {
          return workflow?.name;
        }
      }
    }
  };

  return (
    <div>
      <Modal
        open={openFilter}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CalendarFilter
          phaseList={phaseList}
          equipmentCategoryList={categoryList}
          equipmentTypeList={typeList}
          areaList={areaList}
          systemTypeList={systemTypeList}
          setPhaseList={setPhaseList}
          setEquipmentCategoryList={setCategoryList}
          setEquipmentTypeList={setTypeList}
          setAreaList={setAreaList}
          setSystemTypeList={setSystemTypeList}
          handleClose={handleClose}
          handleFilterSystem={getMetaSystemsList}
          type="system"
        />
      </Modal>{" "}
      <Card>
        <CardHeader
          title={
            <Grid container justify="space-between">
              <Grid item className="mt-5">
                Systems
              </Grid>
              <Grid item className="mt-10-neg">
                <div className="d-flex jsb-l">
                  <IconButton onClick={handleOpen}>
                    {/* <GridFilterAltIcon /> */}
                    <FilterIcon />
                  </IconButton>
                  <Typography className="mr-10">Filter By</Typography>
                  {checkFilter() && (
                    <div>
                      <Chip
                        onClick={() => handleFilterClear()}
                        label="Clear"
                        className="border-r-20 chip mr-10"
                      />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          }
          action={
            <>
              <TextField
                value={searchText}
                onChange={(e) => {
                  setInitialLoad(false);
                  setSearchText(e.target.value);
                }}
                variant="outlined"
                size="small"
                label="Search"
                style={{
                  marginRight: "10px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                        {searchText && (
                          <IconButton onClick={handleClearSearch}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {included && (
                <>
                  <ColorButton
                    colour="lightGreen"
                    onClick={() =>
                      history.push(
                        LINKS.ADD_META_SYSTEM.HREF.replace(
                          ":projectId",
                          projectId
                        )
                      )
                    }
                    startIcon={
                      <AddCircle className="white__icon icon_space_left" />
                    }
                  >
                    Add Systems
                  </ColorButton>

                  <IconButton
                    className="ml-10"
                    onClick={downloadExcel}
                  >
                    {isDownloading ? (
                      <CircularProgress size={22} color="secondary" />
                    ) : (
                      // <Download />
                      <DownloadIcon />
                    )}
                  </IconButton>
                </>
              )}
            </>
          }
        />
        <div>
          <TableContainer>
            {!loading && (
              <InfiniteScroll
                dataLength={sortedRows?.length}
                hasMore={loadMore}
                next={() => {
                  handleLoadMore();
                }}
                loader={
                  sortedRows?.length >= 20 &&
                  loadMore && (
                    <div className=" d-jsb-cen">
                      <CircularProgress size={20} className="mb-10" />
                    </div>
                  )
                }
                scrollThreshold={0.9}
                height={600}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth,
                          }}
                          sortDirection={orderBy === column.id ? order : false}
                        >
                          {column.sortable ? (
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={
                                orderBy === column.id ? order : SORT_DIRS.asc
                              }
                              onClick={createSortHandler(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          ) : (
                            column.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedRows?.map((row) => (
                      <TableRow key={row._id} id={row._id}>
                        <TableCell component="th" scope="row">
                          <LinkButton
                            to={LINKS.EDIT_META_SYSTEM.HREF.replace(
                              ":systemId",
                              row._id
                            )
                              .replace(":mainSystemId", row.mainSystem._id)
                              .replace(":projectId", projectId)}
                          >
                            <Highlighted
                              text={row?.name}
                              highlight={searchText}
                            />
                          </LinkButton>
                        </TableCell>
                        <TableCell>
                          {getWorkflow(row?.mainSystem?.templateId)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Highlighted
                            text={row?.equipmentNumber || ""}
                            highlight={searchText}
                          />
                        </TableCell>
                        <TableCell>
                          {row?.mainSystem?.calculated?.totalPlannedHours || 0}
                        </TableCell>
                        <TableCell>
                          {Math.round(row?.mainSystem?.calculated?.PV || 0)}%
                        </TableCell>
                        <TableCell>
                          {Math.round(
                            row.mainSystem?.calculated?.overallStatus || 0
                          )}
                          %
                        </TableCell>
                        <TableCell>
                          {row?.mainSystem?.calculated?.totalWorkedHours || 0}
                        </TableCell>
                        <TableCell>
                          {Math.round(row?.mainSystem?.calculated?.EV || 0)}%
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            )}
          </TableContainer>
          {loading && (
            <div style={{ height: "300px" }} className="d-jsb-cen">
              <CircularProgress />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default memo(MetaSystemTable);
