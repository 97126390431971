import { exportDocumentSnapshot } from "services/api-project";
import { saveAs } from "file-saver";
import moment from "moment";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";

export const downloadDocumentSnapshotExcel = async (
  setIsDownloading,
  projectId,
  project,
  dispatch,
  filters
) => {
  try {
    setIsDownloading(true);
    const response = await exportDocumentSnapshot(projectId, filters);
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${project.name}_document_${moment().format("MMM DD@HH:mm")}`);
  } catch (error) {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.error,
        popupText: "Failed to export file.",
      })
    );
  } finally {
    setIsDownloading(false);
  }
};

export const getDefaultSubject = (project) => {
  let date = moment().format("MM/DD/YYYY");
  return (
    "Vektor " + project?.name + " - Document Snapshot " + date.split("T")[0]
  );
};

export const DATE_SORT_DIRS = {
  ASC: "ASC",
  DESC: "DESC",
};
