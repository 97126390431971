import React, { memo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "icons/cross_circle.svg";
import { makeStyles } from "@material-ui/core/styles";
import { setPopup } from "redux/actions/popupActions";
import { useDispatch, useSelector } from "react-redux";
import { POPUP_TYPE } from "utils/constants";
import { ColorButton } from "../Buttons";

const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.custom.palette.black,
  },
  actionButtons: {
    justifyContent: "center",
    marginBottom: theme.spacing(5),
  },
  dialogTitle: (props) => ({
    marginBottom: !props.popupHeader && theme.spacing(5),
  }),
  dialogContentText: {
    color: theme.custom.palette.black,
    whiteSpace: "pre-line",
  },
}));

const Popup = () => {
  const dispatch = useDispatch();
  const {
    popupType = POPUP_TYPE.inactive,
    popupText = "",
    popupHeader = "",
    onConfirm = async () => {},
    onCancel = async (type = "") => {},
    confirmText = "Confirm",
    cancelText = "Cancel",
    cancelColor = "",
    confirmColor = "",
    showCloseIcon = true,
  } = useSelector((state) => state.popup);
  const classes = useStyles({ popupHeader });

  const handleClose = async (type) => {
    await dispatch(setPopup({ popupType: POPUP_TYPE.inactive }));
    await onCancel(type);
  };

  const handlePopupCloseBG = async () => {
    await dispatch(setPopup({ popupType: POPUP_TYPE.inactive }));
  };

  const handleConfirm = async () => {
    await dispatch(setPopup({ popupType: POPUP_TYPE.inactive }));
    await onConfirm();
  };

  if (popupType === POPUP_TYPE.inactive) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={popupType !== POPUP_TYPE.inactive}
        onClose={() => handlePopupCloseBG()}
        maxWidth="xs"
        fullWidth={false}
        aria-labelledby="max-width-dialog-title"
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <DialogTitle
          id="max-width-dialog-title"
          className={classes.dialogTitle}
        >
          {popupHeader && popupHeader}
          {showCloseIcon && (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => handleClose("close")}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentText}>
            {popupText}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionButtons}>
          {popupType === POPUP_TYPE.confirm ? (
            <>
              {cancelColor === "" && (
                <Button
                  color="default"
                  variant="contained"
                  onClick={handleClose}
                >
                  {cancelText}
                </Button>
              )}
              {cancelColor !== "" && (
                <ColorButton colour="red" onClick={handleClose}>
                  {cancelText}
                </ColorButton>
              )}
              {confirmColor === "" && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleConfirm}
                >
                  {confirmText}
                </Button>
              )}

              {confirmColor !== "" && (
                <ColorButton
                  colour="lightGreen"
                  className="mr-5"
                  onClick={handleConfirm}
                >
                  {confirmText}
                </ColorButton>
              )}
            </>
          ) : (
            <Button color="success" variant="contained" onClick={handleClose}>
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default memo(Popup);
