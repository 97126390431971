import React, { memo, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { readMetaSystem } from "redux/actions/metaSystem";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import { getPhases } from "redux/actions/projects";
// import { AntSwitch } from "../../ProjectInsight/components/DeliverableSnapshot";
import {
  Card,
  Grid,
  IconButton,
  // Typography,
  InputLabel,
} from "@material-ui/core";
import Select, { components } from "react-select";
import LINKS from "utils/constants/links";
import moment from "moment";
import { SideNavContext } from "routes";
import { Gantt } from "gantt-task-react";
import { TaskListDefault } from "./TaskListDefault";
import { SystemGanttTooltip } from "./SystemGanttTooltip";
import { Add, Remove } from "@material-ui/icons";
import useFeatureFlag from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import { TaskHeaderDefault } from "./TaskHeaderDefault";

const ganttOptions = {
  0: "Hour",
  1: "Quarter Day",
  2: "Half Day",
  3: "Day",
  4: "Week",
  5: "Month",
  6: "Year",
};

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

const SystemGantt = () => {
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  // const [viewType, setViewType] = useState(null);
  const [systemData, setSystemData] = useState([]);
  const [selectedMainSystem, setSelectedMainSystem] = useState(null);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [ganttZoom, setGanttZoom] = useState(5);
  const [expandedSystems, setExpandedSystems] = useState([]);
  const [expandedDeliverables, setExpandedDeliverables] = useState([]);
  const dispatch = useDispatch();
  const { open } = useContext(SideNavContext);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    setLoading(true);
    dispatch(getPhases(projectId));
    dispatch(readMetaSystem({ project: projectId }, true, setLoading));
    // eslint-disable-next-line
  }, []);

  const {
    metaSystems,
    phases: {
      phaseDetail: { phaseData },
    },
  } = useSelector(({ projects }) => projects);

  const systemFeatureFlag = useFeatureFlag(FEATURE_FLAGS.Systems);
  const deliverableFeatureFlag = useFeatureFlag(FEATURE_FLAGS.Deliverables);
  const handleSystemDeliverableRedirect = (
    type,
    systemId,
    mainSystemId,
    deliverableId = ""
  ) => {
    let link = "";
    if (type === "system") {
      link = LINKS.EDIT_META_SYSTEM.HREF.replace(":projectId", projectId)
        .replace(":systemId", systemId)
        .replace(":mainSystemId", mainSystemId);
    } else {
      link = LINKS.EDIT_DELIVERABLE.HREF.replace(":projectId", projectId)
        .replace(":systemId", systemId)
        .replace(":mainSystemId", mainSystemId)
        .replace(":deliverableId", deliverableId);
    }
    window.open(link, "_blank");
  };

  const getPhasesData = () => {
    return phaseData.map((phase) => {
      return {
        label: phase.phaseName,
        value: phase._id,
      };
    });
  };

  const getMainSystems = () => {
    const mainSystems = metaSystems.filter(
      (metaSystem) =>
        (selectedPhase?.find((ele) => ele.value === metaSystem.projectPhase) &&
          metaSystem.projectPhase !== undefined) ||
        (selectedPhase?.find((ele) => ele.value === metaSystem.autoPhaseId) &&
          metaSystem.autoPhaseId !== undefined)
    );
    return mainSystems.map((mainSystem) => {
      return {
        label: mainSystem.name,
        value: mainSystem._id,
      };
    });
  };

  useEffect(() => {
    if (metaSystems.length && phaseData.length) {
      setSelectedPhase([getPhasesData()[0]]);
    }
    // eslint-disable-next-line
  }, [metaSystems, phaseData]);

  useEffect(() => {
    setSelectedMainSystem(getMainSystems());
    setExpandedDeliverables([]);
    setExpandedSystems([]);
    // eslint-disable-next-line
  }, [selectedPhase]);

  const handleDeliverableDropdown = (id) => {
    if (!expandedDeliverables.includes(id)) {
      setExpandedDeliverables([...expandedDeliverables, id]);
    } else {
      let deliverables = [...expandedDeliverables];
      let index = deliverables.indexOf(id);
      deliverables.splice(index, 1);
      setExpandedDeliverables(deliverables);
    }
  };

  const handleSystemDropdown = (id) => {
    if (!expandedSystems.includes(id)) {
      setExpandedSystems([...expandedSystems, id]);
    } else {
      let index = expandedSystems.indexOf(id);
      let systems = [...expandedSystems];
      systems.splice(index, 1);
      setExpandedSystems(systems);
      let selectedMetasystem = metaSystems.find(
        (metaSystem) => metaSystem._id === id
      );
      let removeDeliverables = selectedMetasystem.mainSystem.deliverables.map(
        (deliverable) => deliverable._id
      );
      let deliverables = [...expandedDeliverables];
      removeDeliverables.forEach((value) => {
        if (deliverables.indexOf(id + "-" + value) >= 0) {
          let index = deliverables.indexOf(id + "-" + value);
          deliverables.splice(index, 1);
        }
      });
      setExpandedDeliverables(deliverables);
    }
  };

  const getDependency = (type, id) => {
    switch (type) {
      case "draft":
        return [];
      case "ifr":
        return ["draft" + id];
      case "review":
        return ["ifr" + id];
      case "ifa":
        return ["review" + id];
      case "approval":
        return ["ifa" + id];
      default:
        return [];
    }
  };

  const getSubNames = (value) => {
    switch (value) {
      case "draft":
        return "Draft";
      case "ifr":
        return "IFR";
      case "review":
        return "Review";
      case "ifa":
        return "IFA";
      case "approval":
        return "Approval";
      default:
        return "";
    }
  };

  const getSubDeliverableData = (deliverable, type, system) => {
    return {
      start: new Date(
        moment(
          deliverable[type]?.start
            ? deliverable[type]?.start?.split("T")[0]
            : deliverable?.start?.split("T")[0]
        )
      ),
      end: new Date(
        moment(
          deliverable[type]?.end
            ? deliverable[type]?.end?.split("T")[0]
            : type !== "approval"
            ? deliverable?.start?.split("T")[0]
            : deliverable?.end?.split("T")[0]
        )
      ),
      name: getSubNames(type),
      progress:
        type === "ifr" || type === "ifa"
          ? deliverable[type]?.issued
            ? 100
            : 0
          : deliverable[type]?.status || 0,
      id: type + deliverable._id + "-" + system._id,
      type: "task",
      dependencies: getDependency(type, deliverable._id + "-" + system._id),
      subDeliverable: true,
    };
  };

  const getGanttHeight = () => {
    let height = 0;
    let metaSystemsLength =
      metaSystems?.filter(
        (metaSystem) =>
          selectedMainSystem
            ?.map((system) => system?.value)
            ?.includes(metaSystem?._id) &&
          metaSystem?.mainSystem?.deliverables?.length
      )?.length || 0;
    height += metaSystemsLength;
    if (expandedSystems?.length) {
      expandedSystems?.forEach((system) => {
        height +=
          metaSystems?.find((metaSystem) => metaSystem?._id === system)
            ?.mainSystem?.deliverables?.length || 0;
      });
    }
    if (expandedDeliverables?.length) {
      expandedDeliverables?.forEach(() => {
        height += 5;
      });
    }
    return height * 20;
  };

  useEffect(() => {
    setSystemData([]);
    if (selectedMainSystem) {
      const systemValues = metaSystems.filter((metaSystem) => {
        return selectedMainSystem
          .map((system) => system.value)
          .includes(metaSystem._id);
      });

      const values = [];
      systemValues.forEach((system) => {
        const dateValues = [];
        system.mainSystem.deliverables.forEach((deliverable) => {
          dateValues.push(moment(deliverable.start.split("T")[0]));
          dateValues.push(moment(deliverable.end.split("T")[0]));
        });
        if (system.mainSystem.deliverables.length) {
          values.push({
            start: new Date(moment.min(dateValues)),
            end: new Date(moment.max(dateValues)),
            name: system.name,
            progress: system.mainSystem.calculated?.overallStatus,
            id: system._id,
            type: "project",
            hideChildren: expandedSystems.includes(system._id) ? false : true,
            method: systemFeatureFlag ? handleSystemDeliverableRedirect : null,
            systemId: system._id,
            mainSystemId: system.mainSystem._id,
            handleSystemDropdown: handleSystemDropdown,
          });
        }

        system.mainSystem.deliverables.forEach((deliverable) => {
          values.push({
            start: new Date(moment(deliverable.start.split("T")[0])),
            end: new Date(moment(deliverable.end.split("T")[0])),
            name: deliverable.name + (deliverable.milestone ? "  ✦" : " "),
            displayName: deliverable.name,
            progress: deliverable.status,
            id: system._id + "-" + deliverable._id,
            type: "task",
            dependencies: deliverable.predecessors.length
              ? deliverable.predecessors.map(
                  (predecessor) => system._id + "-" + predecessor
                )
              : null,
            project: system._id,
            method: deliverableFeatureFlag
              ? handleSystemDeliverableRedirect
              : null,
            systemId: system._id,
            mainSystemId: system.mainSystem._id,
            subChildren: deliverable.type === "EXECUTION" ? false : true,
            expandedDeliverables: expandedDeliverables,
            handleDeliverableDropdown: handleDeliverableDropdown,
            deliverableType: deliverable.type,
            subDeliverable: false,
            styles: {
              progressColor: deliverable.milestone ? "#ffbb54" : "#A3A3FF",
              progressSelectedColor: "#ff9e0d",
            },
          });
          if (
            expandedDeliverables.includes(system._id + "-" + deliverable._id)
          ) {
            values.push(getSubDeliverableData(deliverable, "draft", system));
            values.push(getSubDeliverableData(deliverable, "ifr", system));
            values.push(getSubDeliverableData(deliverable, "review", system));
            values.push(getSubDeliverableData(deliverable, "ifa", system));
            values.push(getSubDeliverableData(deliverable, "approval", system));
          }
        });
      });
      setSystemData(values);
    }
    // eslint-disable-next-line
  }, [selectedMainSystem, expandedDeliverables, expandedSystems]);
  if (loading) {
    return <Loader></Loader>;
  }

  const handleExpanderClick = (task) => {
    setSystemData(systemData.map((t) => (t.id === task.id ? task : t)));
  };

  const handleChangePhase = (value) => {
    setSelectedPhase(value);
  };

  const handleChangeSystem = (value) => {
    let expandedSys = [...expandedSystems];
    let expandedDel = [...expandedDeliverables];
    setSelectedMainSystem(value);
    expandedSystems?.forEach((system) => {
      if (value?.map((data) => data?.value)?.indexOf(system) < 0) {
        expandedSys?.splice(expandedSys?.indexOf(system), 1);
      }
    });
    expandedDeliverables?.forEach((deliverable) => {
      if (
        value?.map((data) => data?.value)?.indexOf(deliverable?.split("-")[0]) <
        0
      ) {
        expandedDel?.splice(expandedDel?.indexOf(deliverable), 1);
      }
    });
    setExpandedSystems(expandedSys);
    setExpandedDeliverables(expandedDel);
  };

  const handleZoomChange = (type) => {
    setGanttZoom(
      type === "add"
        ? ganttZoom === 6
          ? ganttZoom
          : ganttZoom + 1
        : ganttZoom === 0
        ? ganttZoom
        : ganttZoom - 1
    );
  };

  const getWidth = () => {
    if (!open) {
      return "";
    } else {
      if (screenSize.dynamicWidth > 2600) {
        return "88vw";
      } else if (screenSize.dynamicWidth > 2300) {
        return "86vw";
      } else if (screenSize.dynamicWidth > 2000) {
        return "84vw";
      } else if (screenSize.dynamicWidth > 1700) {
        return "81.5vw";
      } else if (screenSize.dynamicWidth > 1400) {
        return "78.5vw";
      }
    }
  };

  return (
    <div style={{ width: getWidth() }}>
      <Grid container>
        <Grid item xs={12}>
          {/* <Grid className="mt-10" container justify="flex-end">
            <Typography>Main Systems</Typography>
            <AntSwitch
              checked={!viewType}
              onChange={() => setViewType(!viewType)}
            />
            <Typography className="mr-30">System Instances</Typography>
          </Grid> */}
          <Grid container justify="flex-end">
            <Grid item xs={2} className="mt-5 mr-5">
              <InputLabel>Phases ({selectedPhase?.length || 0})</InputLabel>
              <Select
                isMulti
                className="mt-5"
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={selectedPhase}
                options={getPhasesData()}
                onChange={handleChangePhase}
                components={{
                  Option: InputOption,
                }}
                placeholder="Select Phase"
              />
            </Grid>
            <Grid item xs={2} className="mt-5">
              <InputLabel>
                Main Systems ({selectedMainSystem?.length || 0})
              </InputLabel>
              <Select
                isMulti
                className="mt-5"
                value={selectedMainSystem}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                options={getMainSystems()}
                hideSelectedOptions={false}
                onChange={handleChangeSystem}
                components={{
                  Option: InputOption,
                }}
              />
            </Grid>
            {systemData.length !== 0 && (
              <Grid item className="ml-10" style={{ marginTop: "27px" }}>
                <Grid container justify="flex-end">
                  <Card>
                    <IconButton
                      size="small"
                      onClick={() => handleZoomChange("add")}
                    >
                      <Remove></Remove>
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleZoomChange("reduce")}
                    >
                      <Add></Add>
                    </IconButton>
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {systemData.length !== 0 && (
          <Grid item xs={12} className="mt-5">
            <Grid container>
              <Grid item xs={12} className="mt-5">
                <div id="system">
                  <Gantt
                    tasks={systemData}
                    rowHeight={20}
                    headerHeight={30}
                    ganttHeight={
                      getGanttHeight() < screenSize.dynamicHeight - 150
                        ? getGanttHeight()
                        : screenSize.dynamicHeight - 150
                    }
                    columnWidth={
                      ganttZoom < 4
                        ? 100
                        : ganttZoom === 4
                        ? 200
                        : ganttZoom === 5
                        ? 250
                        : 500
                    }
                    onExpanderClick={handleExpanderClick}
                    TaskListTable={TaskListDefault}
                    TaskListHeader={TaskHeaderDefault}
                    TooltipContent={SystemGanttTooltip}
                    viewMode={ganttOptions[ganttZoom]}
                  ></Gantt>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
        {systemData.length === 0 && (
          <div className="no-data-available-milestone">No Data Available</div>
        )}
      </Grid>
    </div>
  );
};

export default memo(SystemGantt);
