import { lazy } from "react";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";
import { ReactComponent as AdminIcon } from "icons/admin_management.svg";

const AddProjectData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AddProjectDataForm' */ "pages/ProjectData/AddProjectDataForm"
    )
  )
);

const ProjectData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectData' */ "pages/ProjectData/ProjectDataListing"
    )
  )
);

const EditProjectData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditProjectDataForm' */ "pages/ProjectData/EditProjectDataForm"
    )
  )
);

const ProjectDataClientConfiguration = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditProjectDataForm' */ "pages/ProjectData/ClientConfiguration"
    )
  )
);

const BudgetTracking = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'BudgetTracking' */ "pages/ProjectData/BudgetTracking"
    )
  )
);

const PurchaseOrders = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'BudgetTracking' */ "pages/ProjectData/ProjectDataPurchaseOrderListing"
    )
  )
);

const TimeEntryReports = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'TimeEntryReports' */ "pages/Timesheet/TimeEntryReports"
    )
  )
);

const AdministrationSetting = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'TimeEntryReports' */ "pages/AdministrationSetting"
    )
  )
);

const administrationRoutes = Object.freeze({
  id: LINKS.ADMINISTRATION.TITLE,
  path: LINKS.ADMINISTRATION.HREF,
  icon: <AdminIcon />,
  component: null,
  roles: LINKS.ADMINISTRATION.ROLES,
  children: [
    {
      path: LINKS.PROJECT_DATA.HREF,
      name: LINKS.PROJECT_DATA.TITLE,
      component: ProjectData,
      roles: LINKS.PROJECT_DATA.ROLES,
    },
    {
      path: LINKS.ADD_PROJECT_DATA.HREF,
      name: LINKS.ADD_PROJECT_DATA.TITLE,
      component: AddProjectData,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_PROJECT_DATA.HREF,
      name: LINKS.EDIT_PROJECT_DATA.TITLE,
      component: EditProjectData,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_PROJECT_DATA_CLIENTS.HREF,
      name: LINKS.EDIT_PROJECT_DATA_CLIENTS.TITLE,
      component: ProjectDataClientConfiguration,
    },
    {
      path: LINKS.BUDGET_TRACKING.HREF,
      name: LINKS.BUDGET_TRACKING.TITLE,
      component: BudgetTracking,
    },
    {
      path: LINKS.PURCHASE_ORDERS.HREF,
      name: LINKS.PURCHASE_ORDERS.TITLE,
      component: PurchaseOrders,
    },
    {
      path: LINKS.TIME_ENTRY_REPORTS.HREF,
      name: LINKS.TIME_ENTRY_REPORTS.TITLE,
      component: TimeEntryReports,
    },
    {
      path: LINKS.ADMINISTRATION_SETTING.HREF,
      name: LINKS.ADMINISTRATION_SETTING.TITLE,
      component: AdministrationSetting,
    },
  ],
});

export default administrationRoutes;
