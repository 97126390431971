export const DEFAULT_PATIENT_FORM = {
  name: true,
  dob: true,
};

export const TABLE_COLUMN_PATIENT = [
  { id: "patientId", label: "Patient ID", minWidth: 100 },
  { id: "name", label: "Patient Name", minWidth: 100 },
  { id: "dob", label: "Date Of Birth", minWidth: 100 },
];

export const SAMPLE_RECORD_TABLE_COLUMN = [
  { id: "sampleId", label: "Sample ID", minWidth: 100 },
  { id: "category", label: "Category", minWidth: 100 },
  { id: "currentStatus", label: "Current Status", minWidth: 100 },
  { id: "label", label: "Sample Label", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];
