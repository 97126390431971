import React from "react";
import "./TaskHeaderDefault.css";

export const TaskHeaderDefault = ({
  headerHeight,
  fontFamily,
  fontSize,
  rowWidth,
}) => {
  return (
    <div
      className={"ganttTable"}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      <div
        className={"ganttTable_Header"}
        style={{
          height: headerHeight - 2,
        }}
      >
        <div
          className={"ganttTable_HeaderItem"}
          style={{
            minWidth: rowWidth,
          }}
        >
          &nbsp;Name
        </div>
        <div
          className={"ganttTable_HeaderSeparator"}
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.2,
          }}
        />
        <div
          className={"ganttTable_HeaderItem"}
          style={{
            minWidth: "80px",
          }}
        >
          &nbsp;From
        </div>
        <div
          className={"ganttTable_HeaderSeparator"}
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.25,
          }}
        />
        <div
          className={"ganttTable_HeaderItem"}
          style={{
            minWidth: "80px",
          }}
        >
          &nbsp;To
        </div>
      </div>
    </div>
  );
};
