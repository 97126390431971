import { Button, Divider, IconButton } from "@material-ui/core";

import Loader from "components/Loader";
import { memo, useEffect, useState } from "react";
import { getFolders } from "services/api-library";
import "./index.css";
import { NavigateNextRounded } from "@material-ui/icons";
import BreadcrumbsFolder from "pages/Projects/Library/components/BreadcrumbsFolder";
import { FileIcon, defaultStyles } from "react-file-icon";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const LibSelect = ({
  open,
  handleClose,
  projectId,
  isLibOption,
  getSelectedFile,
  handleUploadClose,
  multipleFiles,
}) => {
  const [loading, setLoading] = useState(false);
  const [folderList, setFolderList] = useState("");
  const [fileList, setFileList] = useState("");
  const [moveId, setMoveId] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [type, SetType] = useState(null);
  // eslint-disable-next-line
  const [isLibSelectCall, setIsLibSelectCall] = useState(true);
  useEffect(() => {
    if (folderList.length === 0 && open) {
      getFolderList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getFolderList = async (parent) => {
    const category =
      parent === breadcrumbs[0]?._id
        ? null
        : breadcrumbs[0]?.name === "DMS"
        ? "DMS_FILE"
        : null;

    setLoading(true);
    await getFolders(projectId, parent, category)
      .then((resp) => {
        setLoading(false);

        if (resp?.folders.length > 0) {
          setFolderList(resp?.folders);
          setBreadcrumbs(resp?.breadcrumbs);
        } else {
          if (!(resp?.folders.length === 0 && resp?.files.length === 0)) {
            setFolderList("");
          }
        }
        if (resp?.files.length > 0) {
          setFileList(resp.files);
        } else {
          if (!(resp?.folders.length === 0 && resp?.files.length === 0)) {
            setFileList("");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fileType = (fileName) => {
    return fileName.split(".").pop();
  };

  const handleFileSelection = (file) => {
    const isFileSelected = selectedFiles.some(
      (selectedFile) => selectedFile._id === file._id
    );

    if (isFileSelected) {
      setSelectedFiles(
        selectedFiles.filter((selectedFile) => selectedFile._id !== file._id)
      );
      setMoveId(moveId.filter((selectedFileId) => selectedFileId !== file._id));
    } else {
      const newSelectedFiles = multipleFiles
        ? [...selectedFiles, file]
        : [file];
      setSelectedFiles(newSelectedFiles);
    }
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={open}
      maxWidth="xs"
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Select File</DialogTitle>
      <div className="pd-2 ml-20 mb-5 d-jsb">
        <BreadcrumbsFolder
          breadcrumbs={breadcrumbs}
          getFolderList={getFolderList}
          isLibSelectCall={isLibSelectCall}
        />
        {multipleFiles && selectedFiles.length > 0 && (
          <div className="mr-5 fs-14">{selectedFiles.length} file selected</div>
        )}
      </div>
      <DialogContent dividers={"paper"}>
        <div>
          {isLibOption &&
            fileList.length > 0 &&
            fileList?.map((fileData, index) => (
              <div
                key={index}
                onClick={() => {
                  handleFileSelection(fileData);
                }}
              >
                <div
                  className={`pointer d-flex pd-5 file-div ${
                    moveId.includes(fileData._id)
                      ? "move-selected"
                      : "move-name"
                  }`}
                  onClick={() => {
                    if (!moveId.includes(fileData._id)) {
                      let data = [...moveId];
                      if (type === "FOLDER") {
                        SetType("FILE");
                        data = [];
                      }

                      const newData = multipleFiles
                        ? [...data, fileData._id]
                        : [fileData._id];
                      setMoveId(newData);
                    }
                  }}
                >
                  <div className="fileIcon-div">
                    <FileIcon
                      fold={false}
                      extension={fileType(fileData?.file?.fileName)}
                      {...defaultStyles[fileType(fileData?.file?.fileName)]}
                    />
                  </div>
                  {fileData?.name}

                  {moveId === fileData?._id && (
                    <IconButton
                      className="pd-0"
                      onClick={() => getFolderList(moveId)}
                    ></IconButton>
                  )}
                </div>
                <Divider fullWidth />
              </div>
            ))}

          {folderList.length > 0 &&
            folderList?.map((fileData, index) => (
              <div key={index}>
                <div
                  className={`pointer d-flex  pd-5 file-div ${
                    moveId === fileData._id ? "move-selected" : "move-name"
                  }`}
                  onClick={() => {
                    SetType("FOLDER");
                    setSelectedFiles([]);
                    setMoveId(fileData?._id);
                  }}
                >
                  <FolderOutlinedIcon
                    className={`${
                      moveId === fileData._id ? "selected" : "folderIcon"
                    }`}
                  />
                  <div>{fileData?.name}</div>

                  {moveId === fileData?._id && (
                    <IconButton
                      style={{ marginLeft: "auto" }}
                      className="pd-0"
                      onClick={() => getFolderList(moveId)}
                    >
                      <NavigateNextRounded
                        className={`${
                          moveId === fileData._id
                            ? "move-selected"
                            : "move-name"
                        }`}
                      />
                    </IconButton>
                  )}
                </div>
                <Divider fullWidth />
              </div>
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="d-jsb-r ">
          <Button variant="contained" onClick={handleClose} className="mr-10">
            Cancel
          </Button>
          <Button
            className="mr-10"
            variant="contained"
            color="primary"
            type="submit"
            disabled={!(selectedFiles.length > 0)}
            onClick={() => {
              getSelectedFile(selectedFiles);
              handleClose();
              handleUploadClose();
            }}
          >
            Select
          </Button>
        </div>
      </DialogActions>

      {loading && <Loader />}
    </Dialog>
  );
};
export default memo(LibSelect);
