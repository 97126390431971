/* eslint-disable array-callback-return */
import React, { memo } from "react";
import { useMemo, useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Person } from "@material-ui/icons";
import {
  Button,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Menu,
} from "@material-ui/core";
import styled from "styled-components/macro";
import PageHeader from "parts/PageHeader";
import LINKS from "utils/constants/links";
import VektorCheckbox from "components/UI/VektorCheckbox";
import { getNavLinks, useProjectPhasesEditing } from "../ProjectPhases/helpers";
import { startOfWeek, endOfWeek } from "date-fns";
import { Divider as MuiDivider } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useTheme } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { FileText } from "react-feather";
import "./index.css";
import {
  createDeliverableNote,
  createSubTask,
  deleteDeliverableNote,
  updateDeliverableNote,
  updateMainTasks,
  updateSubTasks,
} from "redux/actions/metaSystem";
import { NoteDialog } from "pages/MetaSystems/Shared/DeliverableTable/components";
import moment from "moment";
import TaskDialog from "./TaskDialog";
import TaskNoteDialog from "./TaskNoteDialog";
import SubTaskNoteDialog from "./SubTaskNoteDialog";
import SubTaskDialog from "./SubTaskDialog";
import "react-datepicker/dist/react-datepicker.css";

const Divider = styled(MuiDivider)(spacing);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
}));

const daysList = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const status = [
  { name: "Complete", checked: false },
  { name: "Incomplete", checked: false },
];

const useStyles = makeStyles(() => ({
  sticky: (props) => ({
    position: "fixed",
    top: props.stickyTop,
    backgroundColor: "white",
    width: props.stickyWidth,
    overflowX: "hidden",
    zIndex: 9999,
  }),
}));

const CalendarView = () => {
  const theme = useTheme();
  const [dateList, setDateList] = useState([]);
  const [systemId, setSystemId] = useState("");
  const [deliverableList, setDeliverableList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [editData, setEditData] = useState({});
  const [currentNo, setWeekNo] = useState("currentweek");
  const [viewNo, setViewNo] = useState(7);
  const [anchor, setAnchor] = useState(null);
  const [openDropDown, setOpen] = useState(false);
  const [systemList, setSystemList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [resourceList, setResourceList] = useState([]);
  const [phaseList, setPhaseList] = useState([]);
  const [statusList, setStatusList] = useState(status);
  const [checkSystem, setCheck] = useState(false);
  const [editTask, setEditTask] = useState({});
  const [editSubTask, setEditSubTask] = useState({});
  const [toggledNoteDialog, setToggledNoteDialog] = useState(false);
  const [toggledTaskDialog, setToggledTaskDialog] = useState(false);
  const [toggledSubTaskDialog, setToggledSubTaskDialog] = useState(false);
  const [dragTask, setDragTask] = useState(null);
  const [scrollX, setScrollX] = useState(0);
  const [stickyWidth, setStickyWidth] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const [stickyTop, setStickyTop] = useState(0);
  const [toggledSubTaskNoteDialog, setToggledSubTaskNoteDialog] =
    useState(false);
  const [toggledTaskNoteDialog, setToggledTaskNoteDialog] = useState(false);
  const scrollRef = useRef(null);
  const tableRef = useRef(null);
  const headerRef = useRef(null);
  const classes = useStyles({
    stickyWidth,
    stickyTop,
  });
  const { projectId } = useParams();
  const { results: projects, metaSystems } = useSelector(
    ({ projects }) => projects
  );
  const users = useSelector((state) => state.users.results);
  const organizations = useSelector((state) => state.organizations.results);
  const dispatch = useDispatch();
  const project = useMemo(
    () => projects.find((item) => item._id === projectId),
    [projectId, projects]
  );
  const { phases } = useProjectPhasesEditing();
  useEffect(() => {
    // var date = getDaysInMonth(0, 2022);
    getPhaseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // var date = getDaysInMonth(0, 2022);
    getPhaseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaSystems]);

  useEffect(() => {
    window.addEventListener("scroll", stickyHeader);
    return () => {
      window.removeEventListener("scroll", stickyHeader);
    };
  });

  const stickyHeader = (e) => {
    const header = document.getElementById("header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 250 && scrollTop !== null
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const handleTableScroll = (e) => {
    setScrollX(e.target.scrollLeft);
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(scrollX, 43);
    }
  }, [scrollX]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleTableResize);

    setStickyTop(
      window.document.getElementsByTagName("header")[0].clientHeight
    );
    setStickyWidth(scrollRef.current.parentElement.clientWidth);
  });

  useEffect(() => {
    if (isSticky && headerRef.current) {
      headerRef.current.scrollTo(scrollX, 0);
    }
  });

  const handleTableResize = (e) => {
    if (scrollRef.current) {
      setStickyWidth(scrollRef.current.parentElement.clientWidth);
    }
  };

  const handleScroll = (e) => {
    if (tableRef.current) {
      const rect = tableRef.current.getBoundingClientRect();
      const theaderHeight = scrollRef.current.children[0].clientHeight;

      setIsSticky(rect.y <= stickyTop + theaderHeight);
    }
  };

  const getPhaseList = () => {
    const phaseListDup = phaseList;
    phases.forEach((phase) => {
      const obj = {
        name: phase.name,
        color: phase.color === "#fff" ? randomHSL() : phase.color,
        checked: false,
      };
      if (!phaseListDup.find((data) => data.name === phase.name)) {
        phaseListDup.push(obj);
      }
    });
    setPhaseList(phaseListDup);
    getDeliverableList(phaseListDup);
  };

  const getDeliverableList = (phaseList) => {
    var list = [];
    var tList = [];
    var systemListDup = systemList;
    var productCodeList = productList;
    var resourceListDup = resourceList;
    metaSystems
      .filter((item) => item.project === projectId)
      .forEach((system) => {
        if (system.mainSystem.deliverables.length > 0) {
          system.mainSystem.deliverables.forEach((data) => {
            if (toggledNoteDialog && data._id === editData._id) {
              setEditData(data);
            }
            const task = data;
            var splitStart = moment(data.start.split("T")[0]).format(
              "MM/DD/YY"
            );
            var splitEnd = moment(data.end.split("T")[0]).format("MM/DD/YY");
            task.start = moment(splitStart).format();
            task.end = moment(splitEnd).format();
            task.systemName = system.name;
            task.productCode = system.productCode;
            task.systemId = system.mainSystem._id;
            task.systemResource = system.resource;
            var colorIndex = phaseList.findIndex((phase) =>
              system.name.includes(phase.name)
            );
            if (colorIndex !== -1) {
              task.colorCode = phaseList[colorIndex].color;
            }
            const dateRange = [new Date(data.start)];
            for (
              var i = 0;
              getDateFormat(dateRange[i]) !== getDateFormat(data.end);
              i++
            ) {
              var dateUTC = new Date(dateRange[i]);
              dateRange.push(new Date(dateUTC.setDate(dateUTC.getDate() + 1)));
            }
            task.dateRange = dateRange;
            if (!systemListDup.find((data) => data.name === system.name)) {
              const obj = {
                name: system.name,
                checked: false,
              };
              systemListDup.push(obj);
            }
            if (
              !productCodeList.find((data) => data.name === system.productCode)
            ) {
              const obj = {
                name: system.productCode,
                checked: false,
              };
              productCodeList.push(obj);
            }
            list.push(task);
          });
        }
        if (system.mainSystem.tasks.length > 0) {
          system.mainSystem.tasks.forEach((data) => {
            if (toggledNoteDialog && data._id === editData._id) {
              setEditData(data);
            }
            const task = data;
            var splitStart = moment(data.start.split("T")[0]).format(
              "MM/DD/YY"
            );
            var splitEnd = moment(data.end.split("T")[0]).format("MM/DD/YY");
            task.start = moment(splitStart).format();
            task.actualStartDate = task.start;
            task.end = moment(splitEnd).format();
            data.subTasks.forEach((subTask, index) => {
              var splitSubDate = moment(subTask.start.split("T")[0]).format(
                "MM/DD/YY"
              );
              task.subTasks[index].start = moment(splitSubDate).format();
            });
            task.organization = system.organization;
            task.systemName = system.name;
            task.systemId = system.mainSystem._id;
            task.productCode = system.productCode;
            task.systemResource = system.resource;
            // var colorIndex = phaseList.findIndex((phase) =>
            //   system.name.includes(phase.name)
            // );
            // if (colorIndex !== -1) {
            //   task.colorCode = phaseList[colorIndex].color;
            // }
            const dateRange = [new Date(data.start)];
            for (
              var i = 0;
              getDateFormat(dateRange[i]) !== getDateFormat(data.end);
              i++
            ) {
              var dateUTC = new Date(dateRange[i]);
              dateRange.push(new Date(dateUTC.setDate(dateUTC.getDate() + 1)));
            }
            task.dateRange = dateRange;
            if (!systemListDup.find((data) => data.name === system.name)) {
              const obj = {
                name: system.name,
                checked: false,
              };
              systemListDup.push(obj);
            }
            if (
              !productCodeList.find((data) => data.name === system.productCode)
            ) {
              const obj = {
                name: system.productCode,
                checked: false,
              };
              productCodeList.push(obj);
            }
            if (
              !resourceListDup.find((data) => data.name === system.resource)
            ) {
              const obj = {
                name: system.resource,
                checked: false,
              };
              resourceListDup.push(obj);
            }
            tList.push(task);
          });
        }
      });
    setSystemList(systemListDup);
    setProductList(productCodeList);
    setResourceList(resourceListDup);
    setDeliverableList(list);
    setTaskList(tList);
    if (toggledNoteDialog) {
      selectWeek(currentNo);
    } else {
      // var startDate = startOfWeek(new Date());
      getDaysInWeek(getStartDate(currentNo), list, tList, currentNo);
    }
  };

  const randomHSL = () => {
    // eslint-disable-next-line no-useless-concat
    return "hsla(" + ~~(360 * Math.random()) + "," + "60%," + "80%,1)";
  };

  const getStartDate = (weekLoop) => {
    var startDate = startOfWeek(new Date());
    if (weekLoop === "next2weeks") {
      startDate = new Date(endOfWeek(new Date()));
      startDate.setDate(startDate.getDate() + 1);
    }
    if (weekLoop === "prevweek") {
      startDate.setDate(startDate.getDate() - 7);
    }
    if (weekLoop === "prev2weeks") {
      startDate.setDate(startDate.getDate() - 14);
    }
    if (weekLoop === "currentMonth") {
      const todayDate = new Date();
      startDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
      startDate.setDate(startDate.getDate());
    }
    if (weekLoop === "prevMonth") {
      const todayDate = new Date();
      startDate = new Date(
        todayDate.getFullYear(),
        todayDate.getMonth() - 1,
        1
      );
      startDate.setDate(startDate.getDate());
    }
    return startDate;
  };

  const selectWeek = (weekLoop) => {
    // setCustomDate(false)
    // setCustomStartDate(null)
    // setCustomEndDate(null);
    var startDate = startOfWeek(new Date());
    if (weekLoop === "prev2weeks") {
      startDate.setDate(startDate.getDate() - 14);
    }
    if (weekLoop === "next2weeks") {
      startDate = new Date(endOfWeek(new Date()));
      startDate.setDate(startDate.getDate() + 1);
    }
    if (weekLoop === "prevweek") {
      startDate.setDate(startDate.getDate() - 7);
    }
    if (weekLoop === "currentMonth") {
      const todayDate = new Date();
      startDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
      startDate.setDate(startDate.getDate());
    }
    if (weekLoop === "prevMonth") {
      const todayDate = new Date();
      startDate = new Date(
        todayDate.getFullYear(),
        todayDate.getMonth() - 1,
        1
      );
      startDate.setDate(startDate.getDate());
    }
    getDaysInWeek(startDate, deliverableList, taskList, weekLoop);
  };

  const getDaysInWeek = (startDate, deliverablesList, taskList, weekLoop) => {
    var list =
      deliverablesList.length > 0
        ? JSON.parse(JSON.stringify(deliverablesList))
        : [];
    var tList = taskList.length > 0 ? JSON.parse(JSON.stringify(taskList)) : [];
    setWeekNo(weekLoop);
    if (weekLoop === "prevweek" || weekLoop === "currentweek") {
      weekLoop = 7;
    }
    if (weekLoop === "prev2weeks" || weekLoop === "next2weeks") {
      weekLoop = 14;
    }
    if (weekLoop === "currentMonth" || weekLoop === "prevMonth") {
      weekLoop = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        0
      ).getDate();
    }
    setViewNo(weekLoop);
    var dateUTC = new Date(startDate);
    var days = [];
    for (var i = 0; i < weekLoop; i++) {
      const ogDate = moment(dateUTC).format();
      const obj = {
        day: daysList[dateUTC.getDay()],
        date: getDateFormat(dateUTC),
        originalDate: moment(ogDate.split("T")[0]).format(
          "YYYY-MM-DD[T00:00:00.000Z]"
        ),
      };
      obj.deliverableList = list.filter(
        (data) => getDateFormat(data.start) === obj.date
      );
      obj.taskList = tList.filter(
        (data) => getDateFormat(data.start) === obj.date
      );
      const dList = list.filter(
        (data) =>
          getDateFormat(data.start) !== getDateFormat(startDate) &&
          getDateFormat(data.start) !== obj.date
      );
      dList.forEach((data) => {
        const present = data.dateRange.filter(
          (date) => getDateFormat(date) === obj.date
        );
        if (present.length > 0) {
          data.start = new Date(startDate);
          obj.deliverableList.push(data);
        }
      });
      list = dList;
      const tsList = tList.filter(
        (data) =>
          getDateFormat(data.start) !== getDateFormat(startDate) &&
          getDateFormat(data.start) !== obj.date
      );
      tsList.forEach((data) => {
        const present = data.dateRange.filter(
          (date) => getDateFormat(date) === obj.date
        );
        if (present.length > 0) {
          data.start = new Date(startDate);
          obj.taskList.push(data);
        }
      });
      tList = tsList;
      obj.deliverableList.map((task) => {
        task.dayCount = moment(task.end).diff(moment(task.start), "days");
        task.dayCount = task.dayCount + 1;
      });
      obj.taskList.map((task) => {
        task.dayCount = moment(task.end).diff(moment(task.start), "days");
        task.dayCount = task.dayCount + 1;
      });
      const phaseNames = phaseList.filter((phase) => phase.checked);
      if (phaseNames.length > 0) {
        const delArr = [];
        const taskArr = [];
        phaseNames.forEach((phase) => {
          delArr.push(
            ...obj.deliverableList.filter((data) =>
              data.systemName.includes(phase.name)
            )
          );
        });
        phaseNames.forEach((phase) => {
          taskArr.push(
            ...obj.taskList.filter((data) =>
              data.systemName.includes(phase.name)
            )
          );
        });
        obj.deliverableList = delArr;
        obj.taskList = taskArr;
      }
      const systemNames = systemList.filter((system) => system.checked);
      if (systemNames.length > 0) {
        const delArr = [];
        const taskArr = [];
        systemNames.forEach((system) => {
          delArr.push(
            ...obj.deliverableList.filter(
              (data) => data.systemName === system.name
            )
          );
        });
        systemNames.forEach((system) => {
          taskArr.push(
            ...obj.taskList.filter((data) => data.systemName === system.name)
          );
        });
        obj.deliverableList = delArr;
        obj.taskList = taskArr;
      }
      const productNames = productList.filter((system) => system.checked);
      if (productNames.length > 0) {
        const delArr = [];
        const taskArr = [];
        productNames.forEach((system) => {
          delArr.push(
            ...obj.deliverableList.filter(
              (data) => data.productCode === system.name
            )
          );
        });
        productNames.forEach((system) => {
          taskArr.push(
            ...obj.taskList.filter((data) => data.productCode === system.name)
          );
        });
        obj.deliverableList = delArr;
        obj.taskList = taskArr;
      }
      const resource = resourceList.filter((system) => system.checked);
      if (resource.length > 0) {
        const delArr = [];
        const taskArr = [];
        resource.forEach((system) => {
          delArr.push(
            ...obj.deliverableList.filter(
              (data) => data.systemResource === system.name
            )
          );
        });
        resource.forEach((system) => {
          taskArr.push(
            ...obj.taskList.filter(
              (data) => data.systemResource === system.name
            )
          );
        });
        obj.deliverableList = delArr;
        obj.taskList = taskArr;
      }
      const statusName = statusList.filter((status) => status.checked);
      if (statusName.length > 0) {
        const delArr = [];
        const taskArr = [];
        statusName.forEach((status) => {
          delArr.push(
            ...obj.deliverableList.filter((data) => {
              if (status.name === "Incomplete") {
                if (data.status < 100) {
                  return data;
                }
              }
              if (status.name === "Complete") {
                if (data.status === 100) {
                  return data;
                }
              }
            })
          );
        });
        statusName.forEach((status) => {
          taskArr.push(
            ...obj.taskList.filter((data) => {
              if (status.name === "Incomplete") {
                if (data.status < 100) {
                  return data;
                }
              }
              if (status.name === "Complete") {
                if (data.status === 100) {
                  return data;
                }
              }
            })
          );
        });
        obj.deliverableList = delArr;
        obj.taskList = taskArr;
      }
      days.push(obj);
      dateUTC.setDate(dateUTC.getDate() + 1);
    }
    setDateList(days);
  };

  const getDateFormat = (date) => {
    const formattedDate =
      new Date(date).getMonth() +
      1 +
      "/" +
      new Date(date).getDate() +
      "/" +
      new Date(date).getFullYear().toString().substr(-2);
    return formattedDate;
  };

  const handleFilter = (value) => {
    setAnchor(null);
    if (value === "All") {
      systemList.map((system) => {
        system.checked = true;
      });
      setSystemList(systemList);
      phaseList.map((phase) => {
        phase.checked = true;
      });
      setPhaseList(phaseList);
      productList.map((product) => {
        product.checked = true;
      });
      setProductList(productList);
      resourceList.map((resource) => {
        resource.checked = true;
      });
      setResourceList(resourceList);
      statusList.map((status) => {
        status.checked = true;
      });
      setStatusList(statusList);
    }
    selectWeek(currentNo);
  };

  const handleCheckbox = (index, filterKey) => {
    setCheck(!checkSystem);
    if (filterKey === "System") {
      systemList[index].checked = !systemList[index].checked;
      setSystemList(systemList);
    }
    if (filterKey === "Phase") {
      phaseList[index].checked = !phaseList[index].checked;
      setPhaseList(phaseList);
    }
    if (filterKey === "Product") {
      productList[index].checked = !productList[index].checked;
      setProductList(productList);
    }
    if (filterKey === "Resource") {
      resourceList[index].checked = !resourceList[index].checked;
      setResourceList(resourceList);
    }
    if (filterKey === "Status") {
      statusList[index].checked = !statusList[index].checked;
      setStatusList(statusList);
    }
    // setFilterSystem(systemList);
    // setPhaseList(phaseList)
    selectWeek(currentNo);
  };

  const toggleNoteDialog = (id, task) => {
    setSystemId(id);
    setEditData(task);
    setToggledNoteDialog(true);
  };

  const handleCellChange = ({ target: { type, value, name } }) => {
    if (type === "date") {
      var date = new Date(value);
      value = moment(date).format("YYYY-MM-DD[T00:00:00.000Z]");
    }

    const updatedData = { ...editData, [name]: value };

    if (name === "department") {
      updatedData.resource = [];
    }

    setEditData(updatedData);
  };

  const handleNoteUpdate = (note) => {
    dispatch(
      updateDeliverableNote({ ...note, mainId: systemId, _id: editData._id })
    );
  };

  const handleNoteCreate = (note) => {
    dispatch(
      createDeliverableNote({ ...note, mainId: systemId, _id: editData._id })
    );
  };

  const handleNoteDelete = (noteId) => {
    dispatch(
      deleteDeliverableNote({ noteId, mainId: systemId, _id: editData._id })
    );
  };

  const handleNoteClose = () => {
    getPhaseList();
    setToggledNoteDialog(false);
  };

  const handleSubTaskStatus = (systemId, taskId, subTask) => {
    subTask.status = !subTask.status;
    dispatch(updateSubTasks(systemId, taskId, subTask));
    selectWeek(currentNo);
  };

  const getDragOver = (systemId, taskId, dropDate) => {
    const subTask = dragTask;
    subTask.start = moment(dropDate.date.split("T")[0]).format(
      "YYYY-MM-DD[T00:00:00.000Z]"
    );
    dispatch(updateSubTasks(systemId, taskId, subTask));
    selectWeek(currentNo);
  };

  const handleTaskClose = () => {
    setToggledTaskDialog(false);
  };

  const handleTaskNoteClose = () => {
    setToggledTaskNoteDialog(false);
  };

  const handleTaskChange = ({ target: { type, value, name } }) => {
    if (type === "date") {
      var date = new Date(value);
      value = moment(date).format("YYYY-MM-DD[T00:00:00.000Z]");
    }

    const updatedData = { ...editTask, [name]: value };

    if (name === "department") {
      updatedData.resource = [];
    }

    if (name === "start") {
      updatedData.actualStartDate = value;
    }
    setEditTask(updatedData);
  };

  const handleSubTaskChange = ({ target: { type, value, name } }) => {
    if (type === "date") {
      var date = new Date(value);
      value = moment(date).format("YYYY-MM-DD[T00:00:00.000Z]");
    }

    const updatedData = { ...editSubTask, [name]: value };

    if (name === "department") {
      updatedData.resource = [];
    }

    setEditSubTask(updatedData);
  };

  const handleTaskUpdate = () => {
    dispatch(
      updateMainTasks({
        mainId: editTask.systemId,
        _id: editTask._id,
        ...editTask,
      })
    );
    handleTaskClose();
    handleTaskNoteClose();
  };

  const handleSubTaskUpdate = () => {
    dispatch(updateSubTasks(editTask.systemId, editTask._id, editSubTask));
    handleSubTaskNoteClose(false);
    handleSubTaskClose(false);
  };

  const toggleSubTaskNote = (task, subTask) => {
    setEditTask(task);
    setEditSubTask(subTask);
    setToggledSubTaskNoteDialog(true);
  };

  const toggleTaskNote = (task) => {
    setEditTask(task);
    setToggledTaskNoteDialog(true);
  };

  const handleSubTaskNoteClose = () => {
    setToggledSubTaskNoteDialog(false);
  };

  const handleCreateSubTask = (task) => {
    var date = new Date(task.start);
    date = moment(task.actualStartDate.split("T")[0]).format(
      "YYYY-MM-DD[T00:00:00.000Z]"
    );
    const subtask = {
      taskType: "ACTION",
      name: "",
      start: date,
      department: null,
      resource: null,
      status: false,
    };
    dispatch(createSubTask(editTask.systemId, editTask._id, subtask));
    handleTaskClose();
  };

  const openSubTaskDialog = (subTask, task) => {
    setEditSubTask(subTask);
    setEditTask(task);
    setToggledSubTaskDialog(true);
  };

  const handleSubTaskClose = () => {
    setToggledSubTaskDialog(false);
  };

  const getInitials = (string) => {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <>
      <PageHeader
        title={LINKS.PROJECT_CALENDAR.TITLE}
        links={getNavLinks(project?.name, projectId)}
      />
      <Grid container className="d-jsb-c">
        <Grid className="d-flex">
          <Button onClick={(e) => setAnchor(e.currentTarget)}>Filter By</Button>
          <Menu
            anchorEl={anchor}
            open={Boolean(anchor)}
            onClose={() => setAnchor(null)}
          >
            <List>
              <ListItem className="pointer" onClick={() => handleFilter("All")}>
                All
              </ListItem>
              <ListItem
                className="pointer d-jsb-c"
                onClick={(e) => {
                  openDropDown === "System" ? setOpen(null) : setOpen("System");
                }}
              >
                System
                {openDropDown === "System" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openDropDown === "System" ? true : false}
                unmountOnExit
              >
                {systemList.map((system, index) => {
                  return (
                    <ListItem className="pointer" key={index}>
                      <div>
                        <Checkbox
                          className="pd-0 mr-5"
                          color="primary"
                          checked={system.checked}
                          onChange={(e) => handleCheckbox(index, "System")}
                        />
                        {system.name}
                      </div>
                    </ListItem>
                  );
                })}
              </Collapse>
              <ListItem
                className="pointer d-jsb-c"
                onClick={(e) => {
                  openDropDown === "Phase" ? setOpen(null) : setOpen("Phase");
                }}
              >
                Phase
                {openDropDown === "Phase" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openDropDown === "Phase" ? true : false}
                unmountOnExit
              >
                {phaseList.map((phase, index) => {
                  return (
                    <ListItem className="pointer" key={index}>
                      <div>
                        <Checkbox
                          className="pd-0 mr-5"
                          checked={phase.checked}
                          onChange={() => handleCheckbox(index, "Phase")}
                        />
                        {phase.name}
                      </div>
                    </ListItem>
                  );
                })}
              </Collapse>
              <ListItem
                className="pointer d-jsb-c"
                onClick={(e) => {
                  openDropDown === "Product"
                    ? setOpen(null)
                    : setOpen("Product");
                }}
              >
                Product Code
                {openDropDown === "Product" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openDropDown === "Product" ? true : false}
                unmountOnExit
              >
                {productList.map((product, index) => {
                  return (
                    <ListItem className="pointer" key={index}>
                      <div>
                        <Checkbox
                          className="pd-0 mr-5"
                          checked={product.checked}
                          onChange={() => handleCheckbox(index, "Product")}
                        />
                        {product.name}
                      </div>
                    </ListItem>
                  );
                })}
              </Collapse>
              <ListItem
                className="pointer d-jsb-c"
                onClick={(e) => {
                  openDropDown === "Resource"
                    ? setOpen(null)
                    : setOpen("Resource");
                }}
              >
                Resource
                {openDropDown === "Resource" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openDropDown === "Resource" ? true : false}
                unmountOnExit
              >
                {resourceList.map((resource, index) => {
                  return (
                    <ListItem className="pointer" key={index}>
                      <div>
                        <Checkbox
                          className="pd-0 mr-5"
                          checked={resource.checked}
                          onChange={() => handleCheckbox(index, "Resource")}
                        />
                        {users.map((user) => {
                          if (user._id === resource.name) {
                            return user.name;
                          }
                        })}
                      </div>
                    </ListItem>
                  );
                })}
              </Collapse>
              <ListItem
                className="pointer d-jsb-c"
                onClick={(e) => {
                  openDropDown === "Status" ? setOpen(null) : setOpen("Status");
                }}
              >
                Status
                {openDropDown === "Status" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openDropDown === "Status" ? true : false}
                unmountOnExit
              >
                {statusList.map((status, index) => {
                  return (
                    <ListItem className="pointer" key={index}>
                      <div>
                        <Checkbox
                          className="pd-0 mr-5"
                          checked={status.checked}
                          onChange={() => handleCheckbox(index, "Status")}
                        />
                        {status.name}
                      </div>
                    </ListItem>
                  );
                })}
              </Collapse>
            </List>
          </Menu>
        </Grid>
        {dateList.length > 0 && (
          <div>
            {dateList[0].date} - {dateList[dateList.length - 1].date}
          </div>
        )}
        <Grid className="d-flex">
          <Button
            className="btn-sdw pd-5"
            onClick={() => selectWeek("prevMonth")}
            style={
              currentNo === "prevMonth"
                ? { backgroundColor: "#5A83BB", color: "white" }
                : { backgroundColor: "white", color: "black" }
            }
          >
            Prev Month
          </Button>
          <Button
            className="btn-sdw pd-5 ml-10"
            onClick={() => selectWeek("prev2weeks")}
            style={
              currentNo === "prev2weeks"
                ? { backgroundColor: "#5A83BB", color: "white" }
                : { backgroundColor: "white", color: "black" }
            }
          >
            Prev 2 weeks
          </Button>
          <Button
            className="btn-sdw pd-5 ml-10"
            onClick={() => selectWeek("prevweek")}
            style={
              currentNo === "prevweek"
                ? { backgroundColor: "#5A83BB", color: "white" }
                : { backgroundColor: "white", color: "black" }
            }
          >
            Prev week
          </Button>
          <Button
            className="btn-sdw pd-5 mr-10 ml-10"
            onClick={() => selectWeek("currentweek")}
            style={
              currentNo === "currentweek"
                ? { backgroundColor: "#5A83BB", color: "white" }
                : { backgroundColor: "white", color: "black" }
            }
          >
            Current week
          </Button>
          <Button
            className="btn-sdw pd-5 mr-10"
            onClick={() => selectWeek("next2weeks")}
            style={
              currentNo === "next2weeks"
                ? { backgroundColor: "#5A83BB", color: "white" }
                : { backgroundColor: "white", color: "black" }
            }
          >
            Next 2 weeks
          </Button>
          {/* <Button
            className="btn-sdw pd-5 mr-10 date-picker"
            style={
              customDate
                ? { backgroundColor: "#5A83BB", color: "white" }
                : { backgroundColor: "white", color: "black" }
            }
            onClick={() => {setOpenCustomDate(true); setCustomDate(true)}}
          >
            Custom Dates
            <DatePicker
              showPopperArrow={false}
              onBlur={() => setOpenCustomDate(false)}
              onClickOutside={() => setOpenCustomDate(false)}
              open={openCustomDate}
              showTimeInput={false}
              selectsRange={true}
              onChange={handleCustomDates}
              startDate={customStartDate}
              endDate={customEndDate}
              shouldCloseOnSelect={true}
              onCalendarClose={() => handleCalendarClose()}
            ></DatePicker>
          </Button> */}
          <Button
            className="btn-sdw pd-5"
            onClick={() => selectWeek("currentMonth")}
            style={
              currentNo === "currentMonth"
                ? { backgroundColor: "#5A83BB", color: "white" }
                : { backgroundColor: "white", color: "black" }
            }
          >
            Current Month
          </Button>
        </Grid>
      </Grid>
      <div
        className="scroll-x wd-100 mwd-800"
        ref={scrollRef}
        onScroll={handleTableScroll}
      >
        <div
          id="header-section"
          ref={headerRef}
          className={isSticky ? classes.sticky : ""}
        >
          <Divider my={6} />
          <div className="d-flex">
            {dateList.map((dayData, index) => {
              return (
                <div key={index} className="d-flex">
                  <div className="p-lr-10" style={{ width: "160px" }}>
                    <div>{dayData.day}</div>
                    <div>{dayData.date}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <Divider my={6} />
        </div>
        <div ref={tableRef}>
          {dateList.map((dayData, index) => {
            return dayData.deliverableList.map((task, taskIndex) => {
              return (
                <>
                  <div className="d-flex mb-10" key={taskIndex}>
                    <div style={{ minWidth: `${160 * index}px` }}></div>
                    <div
                      className="btn-sdw pd-5"
                      style={{
                        width: `${
                          task.dayCount <= Math.abs(viewNo) &&
                          task.dayCount !== 0
                            ? task.dayCount * 160
                            : task.dayCount === 0
                            ? 160
                            : 160 * Math.abs(viewNo)
                        }px`,
                        backgroundColor: task.colorCode,
                        color: task.colorCode
                          ? theme.palette.getContrastText(task.colorCode)
                          : "black",
                      }}
                    >
                      <div>{task.systemName}</div>
                      <div className="mb-5 d-jsb-c">
                        <div>
                          {task.name}({Math.round(task.status)}%)
                        </div>
                        <IconButton
                          onClick={() => toggleNoteDialog(task.systemId, task)}
                          className="pd-5"
                        >
                          <FileText
                            color={task.notes.length ? "black" : "lightgrey"}
                          />
                        </IconButton>
                      </div>
                      <BorderLinearProgress
                        variant="determinate"
                        value={task.status}
                      ></BorderLinearProgress>
                      <NoteDialog
                        open={toggledNoteDialog}
                        title={task.name}
                        users={users.filter(
                          (u) => u.department === editData.department
                        )}
                        onChange={handleCellChange}
                        onUpdate={handleNoteUpdate}
                        onCreate={handleNoteCreate}
                        onDelete={handleNoteDelete}
                        onClose={handleNoteClose}
                        notes={editData.notes}
                      ></NoteDialog>
                    </div>
                  </div>
                </>
              );
            });
          })}
          {dateList.map((dayData, index) => {
            return dayData.taskList.map((task, taskIndex) => {
              return (
                <>
                  <div
                    className="d-flex mb-10"
                    key={taskIndex}
                    style={{ display: "inline-flex" }}
                  >
                    <div style={{ minWidth: `${160 * index}px` }}></div>
                    <div
                      className="btn-sdw pd-5 mr-10"
                      style={{
                        width: `${
                          task.dayCount <= Math.abs(viewNo) &&
                          task.dayCount !== 0
                            ? task.dayCount * 160
                            : task.dayCount === 0
                            ? 160
                            : 160 * Math.abs(viewNo)
                        }px`,
                        backgroundColor: task.colorCode,
                        color: task.colorCode
                          ? theme.palette.getContrastText(task.colorCode)
                          : "black",
                        minWidth: "160px",
                      }}
                    >
                      <div
                        onClick={() => {
                          setEditTask(task);
                          setToggledTaskDialog(true);
                        }}
                      >
                        <u>{task.systemName}</u>
                      </div>
                      <div className="d-jsb-c">
                        <div>
                          {task.name}({Math.round(task.status)}%)
                        </div>
                        <IconButton
                          onClick={() => toggleTaskNote(task)}
                          className="pd-5"
                        >
                          <FileText color={task.note ? "black" : "lightgrey"} />
                        </IconButton>
                      </div>
                      {users.map((user) => {
                        if (user._id === task.systemResource) {
                          return (
                            <div className="d-flex tooltip">
                              <IconButton className="pd-0">
                                <Person color="primary" />
                              </IconButton>
                              <div className="d-jsb-c">
                                {getInitials(user.name)}
                              </div>
                              <div className="tooltiptext">{user.name}</div>
                            </div>
                          );
                        }
                      })}
                      <BorderLinearProgress
                        variant="determinate"
                        value={task.status}
                      ></BorderLinearProgress>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ minWidth: `${160 * index}px` }}></div>
                    {dateList
                      // eslint-disable-next-line no-self-compare
                      .filter((dateObj, index) => index >= index)
                      .map((dateObj, dateIndex) => {
                        return (
                          <div
                            key={dateIndex}
                            className="mb-10"
                            onDrop={() =>
                              getDragOver(task.systemId, task._id, dateObj)
                            }
                            onDragOver={(e) => e.preventDefault()}
                          >
                            {task.subTasks.map((subTask, subTaskIndex) => {
                              const taskDates = task.dateRange.filter(
                                (dates) => getDateFormat(dates) === dateObj.date
                              );
                              if (
                                getDateFormat(subTask.start) === dateObj.date
                              ) {
                                return (
                                  <div
                                    key={subTaskIndex}
                                    className="btn-sdw pd-5 mb-5 mr-5"
                                    style={{ width: "160px" }}
                                    draggable
                                    onDragStart={() => {
                                      setEditData(task);
                                      setDragTask(subTask);
                                    }}
                                  >
                                    <div className="d-jsb-c">
                                      <div
                                        className="wd-55 min-ht-35"
                                        onClick={() =>
                                          openSubTaskDialog(subTask, task)
                                        }
                                      >
                                        <div className="d-flex">
                                          {subTask.name}
                                        </div>
                                        {users.map((user) => {
                                          if (user._id === subTask.resource) {
                                            return (
                                              <div className="d-flex tooltip">
                                                <IconButton className="pd-0">
                                                  <Person color="primary" />
                                                </IconButton>
                                                <div className="d-jsb-c">
                                                  {getInitials(user.name)}
                                                </div>
                                                <div className="tooltiptext">
                                                  {user.name}
                                                </div>
                                              </div>
                                            );
                                          }
                                        })}
                                      </div>
                                      <div>
                                        <VektorCheckbox
                                          className={"pd-0 m-0"}
                                          checked={subTask.status}
                                          onChange={(e) =>
                                            handleSubTaskStatus(
                                              task.systemId,
                                              task._id,
                                              subTask
                                            )
                                          }
                                        />
                                        <IconButton
                                          onClick={() =>
                                            toggleSubTaskNote(task, subTask)
                                          }
                                          className="pd-0"
                                        >
                                          <FileText
                                            color={
                                              subTask.note
                                                ? "black"
                                                : "lightgrey"
                                            }
                                          />
                                        </IconButton>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else if (taskDates.length > 0) {
                                return (
                                  <div
                                    className=""
                                    style={{ width: "160px" }}
                                  ></div>
                                );
                              }
                            })}
                          </div>
                        );
                      })}
                  </div>
                </>
              );
            });
          })}
        </div>
      </div>
      <TaskDialog
        open={toggledTaskDialog}
        departments={organizations.filter(
          (org) => org._id === editTask.organization
        )}
        users={users}
        task={editTask}
        onCellChange={handleTaskChange}
        onClose={handleTaskClose}
        onUpdate={handleTaskUpdate}
        onAddTask={handleCreateSubTask}
      ></TaskDialog>
      <TaskNoteDialog
        open={toggledTaskNoteDialog}
        task={editTask}
        onCellChange={handleTaskChange}
        onClose={handleTaskNoteClose}
        onUpdate={handleTaskUpdate}
      ></TaskNoteDialog>
      <SubTaskNoteDialog
        open={toggledSubTaskNoteDialog}
        task={editSubTask}
        onCellChange={handleSubTaskChange}
        onClose={handleSubTaskNoteClose}
        onUpdate={handleSubTaskUpdate}
      ></SubTaskNoteDialog>
      <SubTaskDialog
        open={toggledSubTaskDialog}
        departments={organizations.filter(
          (org) => org._id === editTask.organization
        )}
        users={users}
        subTask={editSubTask}
        onCellChange={handleSubTaskChange}
        onClose={handleSubTaskClose}
        onUpdate={handleSubTaskUpdate}
        mainTask={editTask}
      ></SubTaskDialog>
    </>
  );
};

// show week range from monday to sunday
// mm/dd/yy
// equipement name and system name is name ?
// from where resource details come
// add note to each card
// calendar popup give default october
// change date format everywhere
export default memo(CalendarView);
