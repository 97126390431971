import moment from "moment";
import { saveAs } from "file-saver";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import { exportPhaseSnapshot } from "services/api-project";

import "./phaseSnapshot.css";

let today = new Date();
today = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

export const getExeceutionColor = (completion, end, status) => {
  let today = new Date();
  today = completion || today.toISOString();
  return getFormattedDate(today) > getFormattedDate(end) &&
    Math.round(status) < 100
    ? "red"
    : getFormattedDate(end) >= getFormattedDate(today) &&
      Math.round(status) === 100
    ? "#27AE60"
    : getFormattedDate(end) < getFormattedDate(today) &&
      Math.round(status) === 100
    ? "#F39C12"
    : "#5a83bb";
};

export const getFormattedDate = (date) => {
  return moment(date?.split("T")[0])?.format("YYYY-MM-DD[T00:00:00.000Z]");
};

export const colorRA = (completion, end, status, type = "") => {
  if (!completion) {
    if (parseInt(status || 0) === 0) {
      if (getFormattedDate(end) >= getFormattedDate(today)) {
        return type !== "" ? "#bdbdbd" : "#5E5E5E";
      } else {
        return "red";
      }
    } else if (parseInt(status || 0) > 0 && parseInt(status || 0) < 100) {
      if (getFormattedDate(end) >= getFormattedDate(today)) {
        return "#5A83BB";
      } else {
        return "red";
      }
    } else {
      if (getFormattedDate(end) >= getFormattedDate(today)) {
        return "#27AE60";
      } else {
        return "#F39C12";
      }
    }
  } else {
    if (parseInt(status || 0) === 0) {
      if (getFormattedDate(end) >= getFormattedDate(completion)) {
        return type !== "" ? "#bdbdbd" : "#5E5E5E";
      } else {
        return "red";
      }
    } else if (parseInt(status || 0) > 0 && parseInt(status || 0) < 100) {
      if (getFormattedDate(end) >= getFormattedDate(completion)) {
        return "#5A83BB";
      } else {
        return "red";
      }
    } else {
      if (getFormattedDate(end) >= getFormattedDate(completion)) {
        return "#27AE60";
      } else {
        return "#F39C12";
      }
    }
  }
};

export const SYSTEM_STATUS_TYPES = [
  {
    LABEL: "Not Started",
    VALUE: "Not Started",
  },
  {
    LABEL: "In Progress",
    VALUE: "In Progress",
  },

  {
    LABEL: "Completed",
    VALUE: "Completed",
  },
];

export const reviewerApproverList = (
  key,
  isFirst = false,
  value,
  status,
  type
) => {
  return (
    <p key={key} style={{ textAlign: "center", fontSize: "0.625rem" }}>
      {isFirst ? (
        <span
        // style={{ fontWeight: "600", fontSize: "0.875rem" }}
        >
          {type} - {status}%
          <br />
        </span>
      ) : (
        <></>
      )}
      <br />
      {value}
      <br />
    </p>
  );
};

export const ToolTipStatusText = (deliverable, approverList, reviewerList) => {
  return deliverable.type !== "EXECUTION"
    ? (approverList?.filter((f) => f.approved).length === approverList.length &&
        approverList.length) ||
      (!approverList.length && Number(deliverable.approval.status) === 100)
      ? "Approved"
      : (deliverable.ifa.issued &&
          approverList.length &&
          approverList?.filter((f) => f.approved).length !==
            approverList.length) ||
        (!approverList.length &&
          Number(deliverable.approval.status) < 100 &&
          Number(deliverable.approval.status) >= 0 &&
          deliverable.ifa.issued)
      ? approverList?.filter((f) => !f.approved).length === 0
        ? "Approval Pending"
        : approverList
            ?.filter((f) => !f.approved)
            ?.map((m, index) =>
              reviewerApproverList(
                "approvalpending" + m.userid,
                index === 0,
                m?.name,
                Number(deliverable.approval.status) || 0,
                "Approval Pending"
              )
            )
      : (reviewerList?.filter((f) => f.approved).length ===
          reviewerList.length &&
          reviewerList.length) ||
        (!reviewerList.length && Number(deliverable.review.status) === 100)
      ? "Reviewed"
      : (deliverable.ifr.issued &&
          reviewerList.length &&
          reviewerList?.filter((f) => f.approved).length !==
            reviewerList.length) ||
        (!reviewerList.length &&
          Number(deliverable.review.status) < 100 &&
          Number(deliverable.review.status) >= 0 &&
          deliverable.ifr.issued)
      ? reviewerList?.filter((f) => !f.approved).length === 0
        ? "Review Pending"
        : reviewerList
            ?.filter((f) => !f.approved)
            ?.map((m, index) =>
              reviewerApproverList(
                "reviewpending" + m.userid,
                index === 0,
                m?.name,
                Number(deliverable.review.status) || 0,
                "Review Pending"
              )
            )
      : !deliverable.ifr.issued && Number(deliverable.draft.status) <= 100
      ? `Draft - ${deliverable.draft.status || 0}%`
      : Number(deliverable.draft.status || 0) !== 100 &&
        `Draft - ${deliverable.draft.status || 0}%`
    : deliverable.status === 100
    ? `Executed`
    : `Execution Pending - ${deliverable.status || 0}%`;
};

export const getFileName = (project) => {
  let date = new Date();
  return (
    project?.name +
    "_snapshot_" +
    date.toLocaleString("default", { month: "short" }) +
    " " +
    date.getFullYear().toString().slice(2) +
    "@" +
    date.getHours() +
    "_" +
    date.getMinutes()
  );
};

export const downloadPhaseSnapshotExcel = async (
  setIsDownloading,
  selectedPhases,
  projectId,
  systemFilter,
  deliverableFilter,
  statusFilter,
  project,
  dispatch
) => {
  try {
    setIsDownloading(true);
    const phaseIds = [...selectedPhases.map((s) => s.value)];
    const response = await exportPhaseSnapshot(projectId, {
      phase: {
        phaseIds: phaseIds,
      },
      filters: {
        deliverable: deliverableFilter
          .filter((deliverable) => deliverable.selected)
          ?.map((deliverable) => deliverable.name),
        system: systemFilter
          .filter((system) => system.selected)
          ?.map((system) => system.id),
        status: statusFilter
          .filter((sta) => sta.selected)
          ?.map((sta) => sta.name),
      },
    });
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${project.name}_snapshot_${moment().format("MMM DD@HH:mm")}`);
  } catch (error) {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.error,
        popupText: "Failed to export file.",
      })
    );
  } finally {
    setIsDownloading(false);
  }
};

export const getDefaultSubject = (project) => {
  let date = moment().format("MM/DD/YYYY");
  return "Vektor " + project?.name + " - Phase Snapshot " + date.split("T")[0];
};
