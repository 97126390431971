import { ReactComponent as ClockIcon } from "icons/clock.svg";
import { ReactComponent as CheckCircleFilledIcon } from "icons/check_circle_filled.svg";
import { ReactComponent as CheckCircleOutlineIcon } from "icons/check_circle_outline.svg";
import { ReactComponent as DraftIcon } from "icons/draft_icon.svg";
import { ReactComponent as ReviewOutlineIcon } from "icons/outline_review.svg";
import { ReactComponent as ExecutionOutlineIcon } from "icons/outline_execution.svg";
import { ReactComponent as ReviewFilledIcon } from "icons/filled_review.svg";
import { ReactComponent as ExecutionFilledIcon } from "icons/filled_execution.svg";

export const chartColors = [
  "#1F78B4",
  "#A6CEE3",
  "#AFE256",
  "#E256AE",
  "#E2CF57",
  "#E28956",
  "#68E256",
  "#8A56E2",
  "#B2DF8A",
  "#34A02C",
  "#68499E",
  "#28AAE1",
  "#F15A2B",
  "#7B5231",
  "#F453AD",
];
export const textColor = "#6281B6";
export const progressColor = "#1F78B4";
export const systemColor = ["#34A02C", "#E86767"];

export const getColoredIconComponent = (icon, iconColor = "") => {
  let tempColor = "black__icon";
  const newIconColor = iconColor.toLowerCase();

  if (newIconColor === "#D32F2F".toLowerCase()) {
    tempColor = "red__icon";
  } else if (
    newIconColor === "#ffb950".toLowerCase() ||
    newIconColor === "#F39C12".toLowerCase()
  ) {
    tempColor = "yellow__icon";
  } else if (
    newIconColor === "#5ba300".toLowerCase() ||
    newIconColor === "#27AE60".toLowerCase()
  ) {
    tempColor = "green__icon";
  } else if (
    newIconColor === "#0953b9".toLowerCase() ||
    newIconColor === "#5A83BB".toLowerCase()
  ) {
    tempColor = "blue__icon";
  } else if (
    newIconColor === "#676869".toLowerCase() ||
    newIconColor === "#bdbdbd".toLowerCase()
  ) {
    tempColor = "grey__icon";
  } else {
    tempColor = "black__icon";
  }
  switch (icon) {
    case "clock":
      return <ClockIcon className={tempColor} />;
    case "check_circle_filled":
      return <CheckCircleFilledIcon className={tempColor} />;
    case "check_circle_outline":
      return <CheckCircleOutlineIcon className={tempColor} />;
    case "draft":
      return <DraftIcon className={tempColor} />;
    case "review_outline":
      return <ReviewOutlineIcon className={tempColor} />;
    case "execution_outline":
      return <ExecutionOutlineIcon className={tempColor} />;
    case "review_filled":
      return <ReviewFilledIcon className={tempColor} />;
    case "execution_filled":
      return <ExecutionFilledIcon className={tempColor} />;
    default:
      return null;
  }
};

export const ICON_KEY_OBJ = {
  clock: <ClockIcon className="black__icon" />,
  check_circle_filled: <CheckCircleFilledIcon className="black__icon" />,
  draft: <DraftIcon className="black__icon" />,
  review_outline: <ReviewOutlineIcon className="black__icon" />,
  execution_outline: <ExecutionOutlineIcon className="black__icon" />,
  review_filled: <ReviewFilledIcon className="black__icon" />,
  execution_filled: <ExecutionFilledIcon className="black__icon" />,
};

export const DELIVERABLE_SNAPSHOT_LEGENDS = [
  {
    color: "#FFB950",
    label: "Completed but late",
  },
  {
    color: "#5BA300",
    label: "Completed on time",
  },
  {
    color: "#D32F2F",
    label: "Pending late",
  },
  {
    color: "#0953B9",
    label: "Pending on time",
  },
  {
    color: "#000000",
    label: "Not started",
  },
  {
    color: "#D9D9D9",
    label: "Not applicable",
  },
  {
    color: "#000000",
    icon: "clock",
    label: "Not started",
  },
  {
    color: "#000000",
    icon: "check_circle_filled",
    label: "Complete",
  },
  {
    color: "#000000",
    icon: "draft",
    label: "Draft",
  },
];

export const DELIVERABLE_MATRIX_LEGENDS = [
  ...DELIVERABLE_SNAPSHOT_LEGENDS,
  {
    color: "#000000",
    icon: "review_outline",
    label: "Review",
  },
  {
    color: "#000000",
    icon: "execution_outline",
    label: "Execution",
  },
];

export const DOCUMENT_SNAPSHOT_LEGENDS = [
  {
    color: "#FFB950",
    label: "Completed but late",
  },
  {
    color: "#5BA300",
    label: "Completed on time",
  },
  {
    color: "#D32F2F",
    label: "Pending late",
  },
  {
    color: "#0953B9",
    label: "Pending on time",
  },
  {
    color: "#000000",
    label: "Not started",
  },
  {
    color: "#D9D9D9",
    label: "Not applicable",
  },
  {
    color: "#000000",
    icon: "check_circle_filled",
    label: "Complete",
  },
  {
    color: "#000000",
    icon: "draft",
    label: "Draft",
  },
  {
    color: "#000000",
    icon: "review_outline",
    label: "Review",
  },
];
