import React, { memo, useState } from "react";
import { Dialog, DialogActions } from "components/UI/VektorDialog";
import { useDispatch } from "react-redux";
import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  Typography,
} from "@material-ui/core";
import Select from "react-select";
import Loader from "components/Loader";
import { updateMetaSystem } from "redux/actions/metaSystem";

const phasePrework = [
  {
    label: "Phase",
    value: "phase",
  },
  {
    label: "Pre-Work",
    value: "prework",
  },
];

const customStyles = {
  menuList: (base) => ({
    ...base,
    padding: 0,
    maxHeight: "100px",
  }),
};

const MovePhaseDialog = ({ open, onClose, phases, phase }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const getPhaseOptions = () => {
    return phases.map((phase) => {
      return {
        label: phase.name,
        value: phase._id,
      };
    });
  };
  const [formValues, setFormValues] = useState({
    phase: getPhaseOptions()[0],
    preWorkOrPhase: { value: "phase", label: "Phase" },
  });
  const handleSave = () => {
    setLoading(true);
    const params = {
      _id: phase._id,
      projectPhase:
        formValues.preWorkOrPhase.value === "phase"
          ? formValues.phase.value
          : null,
      autoPhaseId:
        formValues.preWorkOrPhase.value === "phase"
          ? null
          : formValues.phase.value,
    };
    dispatch(updateMetaSystem(params, setLoading));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Typography className="pd-10" variant="h6">
        Select a Phase to drop
      </Typography>

      <Grid container justify="space-between" className="pd-10">
        <Grid item xs={12} style={{ width: "150px" }}>
          <InputLabel>Phase</InputLabel>
          <FormControl fullWidth className="mb-10 mt-5">
            <Select
              value={formValues.phase}
              options={getPhaseOptions()}
              onChange={(selected) => {
                setFormValues({
                  ...formValues,
                  phase: selected,
                });
              }}
              styles={customStyles}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ width: "150px" }}>
          {" "}
          <InputLabel>Phase/Pre-Work </InputLabel>
          <FormControl fullWidth className="mb-10 mt-5">
            <Select
              value={formValues.preWorkOrPhase}
              options={phasePrework}
              onChange={(selected) => {
                setFormValues({
                  ...formValues,
                  preWorkOrPhase: selected,
                });
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Drop
        </Button>
      </DialogActions>
      {loading && <Loader />}
    </Dialog>
  );
};

export default memo(MovePhaseDialog);
