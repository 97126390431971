import React, { memo, useState, useEffect } from "react";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import { cloneDeep } from "lodash";
import {
  Card,
  CardContent,
  Table,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  TextField,
  CardHeader,
  Button,
  TableBody,
  TableHead,
  Popover,
} from "@material-ui/core";
// import { SwapVert } from "@material-ui/icons";
import { DateRange } from "react-date-range";
import { ColorButton } from "components/UI/Buttons";
import { ACTIONS } from "pages/Projects/constants";
import {
  // Delete,
  DragIndicator,
} from "@material-ui/icons";
import { ReactComponent as SwapVert } from "icons/swap_vert.svg";
import { ReactComponent as DeleteIcon } from "icons/delete.svg";

import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { useDispatch } from "react-redux";
import { updatePhaseOrder } from "redux/actions/projects";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const DragHandle = SortableHandle(({ style }) => (
  <DragIndicator style={{ ...style, ...{ cursor: "move" } }} />
));

const TableBodySortable = SortableContainer(({ children }) => (
  <TableBody>{children}</TableBody>
));
const Row = SortableElement(
  ({
    phase,
    isEditing,
    isEditingField,
    idx,
    onChangePhase,
    editingPhase,
    phases,
    onActionClick,
    included,
    handleRemoveNewPhase,
    handleWeightage,
    anchorEl,
    setAnchorEl,
    dateRange,
    setDateRange,
    onDeletePhase,
    reorder,
    dateChangeIdx,
    setDateChangeIdx,
    isEdit,
    checkSubmit,
    ...other
  }) => {
    const disabled = !isEdit;

    return (
      <>
        <TableRow {...other}>
          {included && reorder && (
            <TableCell>{!isEdit && <DragHandle />}</TableCell>
          )}
          {included && !reorder && (
            <TableCell style={{ width: "4.5%" }}></TableCell>
          )}
          <TableCell style={{ width: "35%" }}>
            <TextField
              error={phases[idx].name === "" && checkSubmit}
              fullWidth
              disabled={disabled}
              onChange={({ target }) => {
                onChangePhase(idx, "name", target.value);
              }}
              value={
                isEditingField(phase.orderIndex)
                  ? editingPhase.name
                  : phases[idx].name
              }
            ></TextField>
            {phases[idx].name === "" && checkSubmit && (
              <span className="text-error">Required</span>
            )}
          </TableCell>
          <TableCell style={{ width: "15%" }}>
            {Math.round(phase.status)}%
          </TableCell>
          <TableCell style={{ width: "15%" }}>
            <TextField
              disabled={disabled}
              required
              aria-describedby={
                Boolean(anchorEl) ? "simple-popover" : undefined
              }
              onClick={(event) => {
                setDateChangeIdx(idx);
                setAnchorEl(event.currentTarget);
                setDateRange([
                  {
                    startDate: new Date(moment(phase.start)),
                    endDate: new Date(moment(phase.end)),
                    key: "selection",
                  },
                ]);
              }}
              value={
                isEditingField(phase.orderIndex)
                  ? moment(editingPhase?.start?.split("T")[0])?.format(
                      "MM/DD/YY"
                    )
                  : moment(phases[idx]?.start?.split("T")[0])?.format(
                      "MM/DD/YY"
                    )
              }
            ></TextField>
          </TableCell>
          <TableCell style={{ width: "15%" }}>
            <TextField
              disabled={disabled}
              required
              aria-describedby={
                Boolean(anchorEl) ? "simple-popover" : undefined
              }
              onClick={(event) => {
                setDateChangeIdx(idx);
                setAnchorEl(event.currentTarget);
                setDateRange([
                  {
                    startDate: new Date(moment(phase.start)),
                    endDate: new Date(moment(phase.end)),
                    key: "selection",
                  },
                ]);
              }}
              value={
                isEditingField(phase.orderIndex)
                  ? moment(editingPhase.end.split("T")[0]).format("MM/DD/YY")
                  : moment(phases[idx].end.split("T")[0]).format("MM/DD/YY")
              }
            ></TextField>
          </TableCell>

          <TableCell>
            <TextField
              error={
                (phase.weightage === undefined || phase.weightage === "") &&
                checkSubmit
              }
              required
              disabled={disabled}
              fullWidth
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              onChange={(e) => handleWeightage(e, idx)}
              value={phase.weightage ? Math.round(phase.weightage) : ""}
            ></TextField>
            {(phase.weightage === undefined || phase.weightage === "") &&
              checkSubmit && <span className="text-error">Required</span>}
          </TableCell>
          {included && (
            <TableCell style={{ width: "20%" }}>
              {!reorder && (
                <IconButton
                  aria-label="delete"
                  onClick={() => onDeletePhase(phase.orderIndex)}
                >
                  {<DeleteIcon />}
                </IconButton>
              )}
            </TableCell>
          )}
        </TableRow>
      </>
    );
  }
);

const ProjectPhasesTable = ({
  activeAction,
  editingPhase,
  phases,
  onActionClick,
  included,
  loadingStatus,
  systemLength,
}) => {
  const isEditing = (currentOrderIndex) =>
    editingPhase?.orderIndex === currentOrderIndex;
  const isEditingField = (currentOrderIndex) =>
    activeAction === ACTIONS.EDIT && isEditing(currentOrderIndex);

  const onEditClick = (orderIndex) => {
    setCheckSubmit(true);
    if (
      phaseData.filter((phase) => phase.name === "")?.length ||
      phaseData.filter(
        (phase) => phase.weightage === undefined || phase.weightage === ""
      )?.length
    ) {
      return;
    }

    if (weightageCheck()) {
      const action = isEditing(orderIndex) ? ACTIONS.COMPLETE : ACTIONS.EDIT;
      // if (action === ACTIONS.COMPLETE) {
      //   setIsEdit(false);
      // }
      if (
        systemLength &&
        phaseData?.some((s) => !s._id) &&
        !phases?.some(
          (s) => !(phaseData?.find((f) => f._id === s._id)?.name === s.name)
        )
      ) {
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.confirm,
            popupText: `Do you want to add system instance for new phase ?`,
            confirmText: "Yes",
            cancelText: "No",
            onConfirm: () => {
              onActionClick(action, orderIndex, phaseData, true);
              setIsEdit(false);
            },
            onCancel: () => {
              onActionClick(action, orderIndex, phaseData);
              setIsEdit(false);
            },
            showCloseIcon: false,
          })
        );
      } else if (
        systemLength &&
        !phaseData?.some((s) => !s._id) &&
        phases?.some(
          (s) => !(phaseData?.find((f) => f._id === s._id)?.name === s.name)
        )
      ) {
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.confirm,
            popupText: `Do you want to rename system instances as per new Phase name ?`,
            confirmText: "Yes",
            cancelText: "No",
            onConfirm: () => {
              onActionClick(action, orderIndex, phaseData, true);
              setIsEdit(false);
            },
            onCancel: () => {
              onActionClick(action, orderIndex, phaseData);
              setIsEdit(false);
            },
            showCloseIcon: false,
          })
        );
      } else if (
        systemLength &&
        phaseData?.some((s) => !s._id) &&
        phases?.some(
          (s) => !(phaseData?.find((f) => f._id === s._id)?.name === s.name)
        )
      ) {
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.confirm,
            popupText: `Do you want to update system instances as per phase changes?`,
            confirmText: "Yes",
            cancelText: "No",
            onConfirm: () => {
              onActionClick(action, orderIndex, phaseData, true);
              setIsEdit(false);
            },
            onCancel: () => {
              onActionClick(action, orderIndex, phaseData);
              setIsEdit(false);
            },
            showCloseIcon: false,
          })
        );
      } else {
        onActionClick(action, orderIndex, phaseData);
        setIsEdit(false);
      }
    }
    setCheckSubmit(false);
  };
  const [phaseData, setPhaseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [reorder, setReorder] = useState(false);
  const [dateChangeIdx, setDateChangeIdx] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [checkSubmit, setCheckSubmit] = useState(false);

  const dispatch = useDispatch();
  const { projectId } = useParams();
  useEffect(() => {
    setPhaseData(cloneDeep(phases));
  }, [phases]);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const movedArray = arrayMove(phaseData, oldIndex, newIndex).map(
        (phase, index) => {
          return {
            ...phase,
            orderIndex: index + 1,
          };
        }
      );
      setPhaseData(movedArray);
    }
  };

  const saveReorderedPhase = async () => {
    if (phaseData.length > 0) {
      setLoading(true);
      await dispatch(updatePhaseOrder(projectId, phaseData));
      setIsEdit(false);
      setLoading(false);
    }
  };

  const handleAddNewPhase = (phaseCount) => {
    setCheckSubmit(false);
    if (phaseCount.length === 0) {
      setIsEdit(true);
    }
    let newPhase = {
      name: "",
      start: moment().format("YYYY-MM-DDT00:00:00.000Z"),
      end: moment().format("YYYY-MM-DDT00:00:00.000Z"),
      orderIndex:
        phaseData.length > 0
          ? phaseData[phaseData.length - 1]?.orderIndex + 1
          : 1,
      status: 0,
      newPhase: true,
    };
    let phases = phaseData.map((phase) => {
      return { ...phase };
    });

    setPhaseData([...phases, newPhase]);
  };

  const handleRemoveNewPhase = () => {
    setPhaseData(cloneDeep(phases));
  };

  const deletePhase = (phaseidx) => {
    let delPhasedata = phaseData.filter(
      (phase) => phase.orderIndex !== phaseidx
    );
    setPhaseData(delPhasedata);
    setIsEdit(true);
  };

  const onDeletePhase = (phaseidx) => {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.confirm,
        popupText: "Are you sure you want to delete this phase?",
        onConfirm: () => {
          deletePhase(phaseidx);
        },
      })
    );
  };

  const calculateWeightage = (newWeightage) => {
    let total = newWeightage?.reduce(
      (acc, val) => acc + Math.round(val["weightage"] || 0),
      0
    );
    return total;
  };

  const handleWeightage = (e, index) => {
    let data = [...phaseData];
    data[index].weightage = e.target.value;
    setPhaseData(data);
  };

  const weightageCheck = () => {
    if (calculateWeightage(phaseData) === 100 || phaseData.length === 0) {
      return true;
    } else {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: "Total weightage must be 100",
        })
      );
    }
  };

  const onChangePhase = (index, type, value, value1) => {
    let updatedData = [...phaseData];
    if (type === "dateRange") {
      updatedData[index].start = moment(value).format(
        "YYYY-MM-DD[T00:00:00.000Z]"
      );
      updatedData[index].end = moment(value1).format(
        "YYYY-MM-DD[T00:00:00.000Z]"
      );
    }
    if (type === "name") {
      updatedData[index].name = value;
    }

    if (type === "weightage") {
      updatedData[index]["weightage"] = value;
    }

    setPhaseData(updatedData);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <CardHeader
            title={
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Project Phases
              </Typography>
            }
            action={
              included && (
                <>
                  {!isEdit && !reorder && phases.length !== 0 && (
                    <Button
                      className="ml-10"
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => setIsEdit(true)}
                    >
                      Edit
                    </Button>
                  )}
                  {isEdit && (
                    <Button
                      size="small"
                      colour="default"
                      variant="contained"
                      onClick={() => {
                        setIsEdit(false);
                        setPhaseData(cloneDeep(phases));
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {!reorder && isEdit && (
                    <Button
                      className="ml-10"
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={() => onEditClick()}
                    >
                      Save
                    </Button>
                  )}
                  {((!reorder && isEdit) || phases.length === 0) && (
                    <Button
                      className="ml-10"
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => handleAddNewPhase(phases)}
                    >
                      Add Phase
                    </Button>
                  )}

                  {!reorder && !isEdit && phases.length !== 0 && (
                    <ColorButton
                      className="ml-10"
                      endIcon={<SwapVert className="white__icon" />}
                      size="small"
                      colour="lightGreen"
                      onClick={() => {
                        setReorder(true);
                      }}
                    >
                      Reorder
                    </ColorButton>
                  )}
                  {reorder && phases.length !== 0 && (
                    <div>
                      <Button
                        size="small"
                        colour="default"
                        variant="contained"
                        onClick={() => {
                          setReorder(false);
                          setPhaseData(cloneDeep(phases));
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="ml-10"
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setReorder(false);
                          saveReorderedPhase();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                  {
                    <div className="d-jsb-r text-error mt-5">
                      {calculateWeightage(phaseData) !== 100 &&
                      (phaseData[0]?.weightage !== undefined ||
                        phaseData[0]?.weightage === "") &&
                      isEdit &&
                      phaseData.length > 0
                        ? "Total weightage must be 100"
                        : ""}
                    </div>
                  }
                </>
              )
            }
          ></CardHeader>

          <Table>
            <TableHead displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                {included && <TableCell></TableCell>}
                <TableCell>NAME</TableCell>
                <TableCell>PROJECT % COMPLETE</TableCell>
                <TableCell>START</TableCell>
                <TableCell>END</TableCell>
                <TableCell>WEIGHTAGE</TableCell>

                {included && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBodySortable
              onSortEnd={onSortEnd}
              useDragHandle
              displayRowCheckbox={false}
              helperClass="sortableHelper"
            >
              {phaseData?.map((phase, idx) => {
                return (
                  <Row
                    idx={idx}
                    index={idx}
                    key={phase._id}
                    phase={phase}
                    isEditing={isEditing}
                    isEditingField={isEditingField}
                    onChangePhase={onChangePhase}
                    editingPhase={editingPhase}
                    phases={phaseData}
                    onActionClick={onActionClick}
                    included={included}
                    handleRemoveNewPhase={handleRemoveNewPhase}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    handleWeightage={handleWeightage}
                    onDeletePhase={onDeletePhase}
                    reorder={reorder}
                    dateChangeIdx={dateChangeIdx}
                    setDateChangeIdx={setDateChangeIdx}
                    isEdit={isEdit}
                    checkSubmit={checkSubmit}
                  />
                );
              })}
            </TableBodySortable>
            {phaseData.length > 0 && (
              <TableRow>
                {included && <TableCell style={{ width: "4.5%" }}></TableCell>}
                <TableCell colSpan={4}>Total</TableCell>
                <TableCell colSpan={6}>
                  {`${calculateWeightage(phaseData)}`}
                </TableCell>
              </TableRow>
            )}
          </Table>
          <Popover
            id={Boolean(anchorEl) ? "simple-popover" : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div>
              <DateRange
                editableDateInputs={true}
                onChange={({ selection }) => {
                  setDateRange([
                    {
                      startDate: new Date(moment(selection.startDate)),
                      endDate: new Date(moment(selection.endDate)),
                      key: "selection",
                    },
                  ]);
                }}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
              />
            </div>
            <div className="d-jsb-r mb-5">
              <Button variant="contained" onClick={() => setAnchorEl(null)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="ml-5 mr-5"
                onClick={() => {
                  onChangePhase(
                    dateChangeIdx,
                    "dateRange",
                    dateRange[0].startDate,
                    dateRange[0].endDate
                  );
                  setAnchorEl(null);
                }}
              >
                Save
              </Button>
            </div>
          </Popover>
        </CardContent>
      </Card>
      {(loading || loadingStatus) && <Loader />}
    </div>
  );
};

export default memo(ProjectPhasesTable);
