import React, { memo } from "react";
import { useEditProjectLogic } from "../EditProject/helpers";
import LINKS from "utils/constants/links";
import PageHeader from "parts/PageHeader";
import { useParams } from "react-router-dom";
import MetaSystemTable from "pages/MetaSystems/Shared/MetaSystemTable";
import Loader from "components/Loader";
const ProjectSystems = () => {
  const { projectId } = useParams();

  const { project, metaSystems, loading, phases } =
    useEditProjectLogic(projectId);

  const NAV_LINKS = [
    LINKS.PROJECT_MANAGEMENT,
    LINKS.PROJECTS,
    {
      HREF: LINKS.EDIT_PROJECT.HREF.replace(":id", projectId),
      TITLE: project?.name || "Not Found",
    },
  ];
  return (
    <div>
      <PageHeader title={`Systems`} links={NAV_LINKS} />
      <MetaSystemTable
        records={metaSystems}
        projectId={projectId}
        projectName={project?.name}
        organization={project?.organization}
        phases={phases}
      />
      {loading && <Loader />}
    </div>
  );
};

export default memo(ProjectSystems);
