import { del, get, getJQ, post, put } from "services/axios";

const getBudget = async (projectId) =>
  await get(`/v2/finance/project/${projectId}/budget`);

const putBudget = async (projectId, payload) =>
  await put(`/v2/finance/project/${projectId}/budget`, payload);

const getPO = async (projectId, skip = 0, limit = null, searchQuery = "") =>
  await getJQ(`/v2/finance/project/${projectId}/purchaseOrders`, {
    skip,
    limit,
    searchQuery,
  });

const putPO = async (projectId, purchaseOrderId, payload) =>
  await put(
    `/v2/finance/project/${projectId}/purchaseOrder/${purchaseOrderId}`,
    payload
  );

const postPO = async (projectId, payload) =>
  await post(`/v2/finance/project/${projectId}/purchaseOrder`, payload);

const delPO = async (projectId, purchaseOrderId) =>
  await del(
    `/v2/finance/project/${projectId}/purchaseOrder/${purchaseOrderId}`
  );

const getInvoices = async (projectId, skip = 0, limit = null) =>
  await getJQ(`/v2/finance/project/${projectId}/orderInvoices`, {
    skip,
    limit,
  });

const putInvoice = async (projectId, orderInvoiceId, payload) =>
  await put(
    `/v2/finance/project/${projectId}/orderInvoice/${orderInvoiceId}`,
    payload
  );

const postInvoice = async (projectId, payload) =>
  await post(`/v2/finance/project/${projectId}/orderInvoice`, payload);

const delInvoice = async (projectId, orderInvoiceId) =>
  await del(`/v2/finance/project/${projectId}/orderInvoice/${orderInvoiceId}`);

const filterPO = async (projectId, filterType, id, skip = 0, limit = null) =>
  await getJQ(
    `/v2/finance/project/${projectId}/purchaseOrders/${filterType}/${id}`,
    {
      skip,
      limit,
    }
  );

const filterInvoice = async (
  projectId,
  filterType,
  id,
  skip = 0,
  limit = null
) =>
  await getJQ(
    `/v2/finance/project/${projectId}/orderInvoices/${filterType}/${id}`,
    {
      skip,
      limit,
    }
  );
const exportFinance = async (projectId, type) => {
  return get(`/v2/finance/project/${projectId}/export?type=${type}`, {
    responseType: "blob",
  });
};
export {
  getBudget,
  putBudget,
  getPO,
  putPO,
  postPO,
  delPO,
  getInvoices,
  filterPO,
  putInvoice,
  postInvoice,
  delInvoice,
  filterInvoice,
  exportFinance,
};
