import { del, post, get, patch } from "services/axios";

const getAreas = async (organizationId) =>
  await get(`/v2/organization/${organizationId}/area/`);

const createArea = async (organizationId, req) =>
  await post(`/v2/organization/${organizationId}/area/`, req);

const updateArea = async (organizationId, req) =>
  await patch(`/v2/organization/${organizationId}/area`, req);

const deleteArea = async (organizationId, id) =>
  await del(`/v2/organization/${organizationId}/area/${id}`);

export { getAreas, createArea, updateArea, deleteArea };
