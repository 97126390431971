import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
// import SearchIcon from "@material-ui/icons/Search";
// import CloseIcon from "@material-ui/icons/Close";
import LINKS from "utils/constants/links";
import { getNavLinks } from "../ProjectPhases/helpers";
import PageHeader from "parts/PageHeader";
import { getProjects } from "redux/actions/projects";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import {
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  TextField,
} from "@material-ui/core";
import { PERMISSION_TYPES } from "utils/constants";
import { ColorButton } from "components/UI/Buttons";
import AllRequests from "./Lists/AllRequests";
import SearchResults from "./Lists/SearchResults";
import { debounce } from "lodash";
import MyTasks from "./Lists/MyTasks";
import CompletedTasks from "./Lists/CompletedTasks";
import Closed from "./Lists/Closed";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import FeatureFlag from "utils/hocs/FeatureFlag";
import { ReactComponent as CloseIcon } from "icons/cross_circle.svg";
import { ReactComponent as SearchIcon } from "icons/search.svg";

const ChangeRequests = () => {
  const [activeTab, setActiveTab] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();
  const { projectId } = useParams();
  const searchRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
  }, [projectId, dispatch]);

  const accessedFeatures = useSelector(
    (state) => state?.modulePreference?.accessibleFeature
  );

  const tabs = [
    {
      label: "My Tasks",
      id: "myChangeRequests",
      component: <MyTasks projectId={projectId} />,
    },
    {
      label: "Completed Tasks",
      id: "completedChangeRequests",
      component: <CompletedTasks projectId={projectId} />,
    },
    {
      label: "Closed Requests",
      id: "closedChangeRequests",
      component: <Closed projectId={projectId} />,
    },
    {
      label: "All Requests",
      id: "allChangeRequests",
      component: <AllRequests projectId={projectId} />,
    },
    {
      label: "Search Results",
      id: "searchResults",
      component: (
        <SearchResults projectId={projectId} searchTerm={searchTerm} />
      ),
    },
  ];

  const project = useSelector((state) =>
    state.projects.results.find((p) => p._id === projectId)
  );

  const { permissions } = useSelector(({ auth }) => auth.currentUser);

  const handleTabChange = (event, newValue) => {
    if (newValue === "searchResults") {
      searchRef.current.focus();
    } else {
      searchRef.current.blur();
    }
    setActiveTab(newValue);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    handleSearchDebounced(e.target.value);
  };

  const handleSearchDebounced = useMemo(
    () =>
      debounce((value) => {
        if (activeTab !== "searchResults") {
          setActiveTab("searchResults");
        }
        setSearchTerm(value);
      }, 500),
    [activeTab]
  );

  const getTabList = () => {
    const allTabs = tabs.filter(
      (tab) =>
        accessedFeatures.includes(tab.id) ||
        !Object.values(FEATURE_FLAGS).includes(tab.id) ||
        [PERMISSION_TYPES.superAdmin].includes(permissions)
    );

    if (!activeTab) {
      setActiveTab(allTabs[0].id);
    }
    return (
      <TabList onChange={handleTabChange}>
        {allTabs.map((tab) => (
          <Tab label={tab.label} key={tab.id} value={tab.id} />
        ))}
      </TabList>
    );
  };

  const getTabPanel = () => {
    return tabs.map((tab) => {
      return (
        <TabPanel key={tab.id} className="pd-0" value={tab.id}>
          <FeatureFlag feature={tab.id}>{tab.component}</FeatureFlag>
        </TabPanel>
      );
    });
  };

  return (
    <>
      <PageHeader
        title={LINKS.PROJECT_CHANGE_REQUEST.TITLE}
        links={getNavLinks(project?.name, projectId)}
      />
      <TabContext value={activeTab}>
        <Grid
          container
          columns={12}
          justify="space-between"
          alignItems="center"
          className="mb-10"
        >
          <Grid item xs={8}>
            {getTabList()}
          </Grid>
          <Grid item xs={4} className="txt-end">
            <TextField
              value={searchText}
              onChange={handleSearchChange}
              variant="outlined"
              inputRef={searchRef}
              size="small"
              label="Search"
              style={{
                marginRight: "10px",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton className="pd-0">
                      <SearchIcon />
                    </IconButton>
                    {searchText && (
                      <IconButton
                        className="pd-0"
                        onClick={() => {
                          setSearchText("");
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {permissions !== PERMISSION_TYPES.viewer && (
              <>
                <ColorButton
                  colour="lightGreen"
                  onClick={() =>
                    history.push(
                      LINKS.ADD_CHANGE_REQUEST.HREF.replace(
                        ":projectId",
                        projectId
                      )
                    )
                  }
                >
                  Add Change Request
                </ColorButton>
              </>
            )}
          </Grid>
        </Grid>
        {getTabPanel()}
      </TabContext>
    </>
  );
};

export default memo(ChangeRequests);
