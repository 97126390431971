import { useSelector } from "react-redux";
import { useMemo } from "react";
import { PERMISSION_TYPES } from "utils/constants";

const useAllowedRoutes = (routes) => {
  const { currentUser } = useSelector((state) => state.auth);
  const { accessibleFeature: accessedFeatures } = useSelector(
    (state) => state.modulePreference
  );

  const accessibleRoutes = useMemo(() => {
    if (
      [PERMISSION_TYPES.superAdmin].includes(
        currentUser.permissions
      )
    ) {
      return routes;
    }

    return routes
      .filter(
        (route) =>
          (!route.feature || accessedFeatures?.includes(route.feature)) &&
          (!route.roles || route.roles.includes(currentUser.permissions))
      )
      .map((route) => {
        if (route.children) {
          const children = route.children.filter(
            (child) =>
              (!child.feature || accessedFeatures?.includes(child.feature)) &&
              (!child.roles || child.roles.includes(currentUser.permissions))
          );
          return { ...route, children };
        }
        return route;
      });
  }, [routes, currentUser.permissions, accessedFeatures]);

  return accessibleRoutes;
};

export default useAllowedRoutes;
