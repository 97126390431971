import React, { useState, useEffect } from "react";
import { getAllDeviations } from "services/api-deviation";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Card,
  CircularProgress,
} from "@material-ui/core";
import LINKS from "utils/constants/links";
import { useHistory } from "react-router-dom";
import { Highlighted } from "utils/helpers/hightlightText";
import InfiniteScroll from "react-infinite-scroll-component";
import { DeviationHelper } from "../helpers";

export default function SearchResults({ projectId, searchTerm }) {
  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [searchResults, setsearchResults] = useState([]);
  const history = useHistory();
  const { DEVIATIONS_LIST_TABLE_COLUMN, DEVIATION_STATUS } = DeviationHelper();
  const [initialLoad, setInitialLoad] = useState(true);

  const searchLists = async (skip, limit, type = "") => {
    if (type === "" || type === "reset") {
      setLoading(true);
    }
    if (type === "loadMore") {
      setPaginationLoading(true);
    }

    await getAllDeviations(projectId, searchTerm, skip, limit)
      .then((resp) => {
        setsearchResults(
          type === "reset" ? resp.data : [...searchResults, ...resp.data]
        );
        if (
          type !== "reset" &&
          [...searchResults, ...resp.data].length === resp.pagination.count
        ) {
          setLoadMore(false);
        }
        if (type === "reset") {
          setLoadMore(true);
        }
        setLoading(false);
        setPaginationLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setPaginationLoading(false);
        console.log("error >>>>> ", err);
      });
  };

  useEffect(() => {
    setInitialLoad(false);
  }, []);

  const handleLoadMore = () => {
    searchLists(searchResults.length, 5, "loadMore");
  };

  useEffect(() => {
    if (!initialLoad || searchTerm !== "") {
      setsearchResults([]);
      searchLists(0, 20, "reset");
    } else {
      setsearchResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);
  if (loading)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  if (!searchTerm)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>Please type something to initiate search</p>
      </div>
    );

  if (!searchResults.length)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>No search results found.</p>
      </div>
    );

  return (
    <Card>
      <InfiniteScroll
        dataLength={searchResults?.length}
        hasMore={loadMore}
        next={() => {
          if (!paginationLoading) {
            handleLoadMore();
          }
        }}
        loader={
          searchResults?.length >= 20 &&
          loadMore && (
            <div className=" d-jsb-cen">
              <CircularProgress size={20} className="mb-10" />
            </div>
          )
        }
        scrollThreshold={0.9}
        height={600}
      >
        <Table stickyHeader>
          <TableHead>
            {DEVIATIONS_LIST_TABLE_COLUMN.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {searchResults.map((doc, index) => (
              <TableRow key={index}>
                {DEVIATIONS_LIST_TABLE_COLUMN.map((column) => {
                  let value = doc[column.id];

                  if (column.id === "status") {
                    value = DEVIATION_STATUS.find(
                      (status) => status.value === doc[column.id]
                    )?.label;
                  }

                  if (column.id === "deviationNumber") {
                    value = (
                      <Typography
                        className="pointer"
                        color="primary"
                        onClick={() =>
                          history.push(
                            LINKS.EDIT_DEVIATIONS.HREF.replace(
                              ":projectId",
                              projectId
                            ).replace(":id", doc._id)
                          )
                        }
                      >
                        <Highlighted text={value} highlight={searchTerm} />
                      </Typography>
                    );
                  }
                  if (column.id === "title") {
                    value = (
                      <Typography>
                        <Highlighted text={value} highlight={searchTerm} />
                      </Typography>
                    );
                  }
                  if (column.id === "actualDeviationNumber") {
                    value = (
                      <Typography>
                        <Highlighted text={value} highlight={searchTerm} />
                      </Typography>
                    );
                  }
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </Card>
  );
}
