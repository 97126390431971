import {
  Breadcrumbs,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { HomeRounded } from "@material-ui/icons";
import { memo } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import LINKS from "utils/constants/links";

const BreadcrumbsFolder = ({ breadcrumbs, getFolderList, isLibSelectCall }) => {
  const isNotHome = breadcrumbs.length;
  const { projectId } = useParams();
  const history = useHistory();

  return (
    <div className="d-flex jsb-l">
      <Breadcrumbs maxItems={5} aria-label="breadcrumb">
        <IconButton
          color={isNotHome ? "primary" : "inherit"}
          className="pd-0 pointer"
          onClick={() => {
            isLibSelectCall
              ? getFolderList()
              : history.push(
                  LINKS.LIBRARY.HREF.replace(":projectId", projectId)
                );
          }}
        >
          <Tooltip title="Click here to go back to main folder screen">
            <HomeRounded
              color={isNotHome ? "primary" : "inherit"}
              style={{ marginTop: -5 }}
            />
          </Tooltip>
          <Typography color="text.primary">Home</Typography>
        </IconButton>
        {breadcrumbs.map((breadcrumb, index) => {
          if (index !== breadcrumbs.length - 1) {
            return (
              <Link
                key={breadcrumb._id}
                underline="hover"
                className="pointer"
                onClick={() => {
                  isLibSelectCall
                    ? getFolderList(breadcrumb._id)
                    : breadcrumbs[0]?.name === "DMS" &&
                      !(breadcrumb._id === breadcrumbs[0]?._id)
                    ? history.push(
                        LINKS.LIBRARY_CATEGORY_NESTED.HREF.replace(
                          ":projectId",
                          projectId
                        )
                          .replace(":category", "DMS_FILE")
                          .replace(":libraryId", breadcrumb._id)
                      )
                    : history.push(
                        LINKS.LIBRARY_NESTED.HREF.replace(
                          ":projectId",
                          projectId
                        ).replace(":libraryId", breadcrumb._id)
                      );
                }}
              >
                {breadcrumb.name}
              </Link>
            );
          } else {
            return (
              <Typography key={breadcrumb._id} color="text.primary">
                {breadcrumb.name}
              </Typography>
            );
          }
        })}
      </Breadcrumbs>
    </div>
  );
};
export default memo(BreadcrumbsFolder);
