import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@material-ui/pickers";
import { ReactComponent as CalendarIcon } from "icons/calendar-2.svg";
import { memo, useState } from "react";
import moment from "moment";
import {
  checkHourMinuteRegex,
  checkTimeformat,
  returnCorrectTimeFormat,
} from "pages/Timesheet/helpers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxHeight: 700,
  background: "white",
  borderRadius: "15px",
  boxShadow: 24,
  padding: "40px 40px 20px 40px",
};

const UserTimeEntryModal = ({
  isOpen,
  close,
  userList,
  savingError,
  handleUserHourChange,
  handleSaveUserTimeData,
}) => {
  const [datePickerIndex, setDatePickerIndex] = useState(undefined);

  const handleOpenCalendar = (index) => {
    setDatePickerIndex(index);
  };

  return (
    <Modal open={isOpen} onClose={close}>
      <Box
        style={{
          ...style,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              className="mb-20"
              style={{
                textAlign: "left",
                fontWeight: "bold",
                marginBottom: 20,
              }}
            >
              Resources management
            </Typography>
          </Grid>

          <Grid container>
            <Grid item xs={12} style={{ overflow: "scroll", maxHeight: 500 }}>
              {userList?.map((user, index) => (
                <div
                  key={user.id}
                  style={{
                    flex: 1,
                    marginTop: 20,
                    marginBottom: 20,
                    marginRight: 40,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  <Grid
                    style={{
                      flex: 1,
                      alignSelf: "flex-end",
                      marginBottom: user?.totalWorkedMinutes ? 20 : 0,
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                      {user?.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography style={{ textAlign: "center", fontSize: 12 }}>
                      Planned hours
                    </Typography>
                    <TextField
                      inputProps={{
                        style: {
                          textAlign: "center",
                        },
                      }}
                      className="mt-10 mb-10"
                      error={
                        savingError &&
                        !user.plannedMinutes &&
                        user.plannedMinutesText.trim()
                      }
                      helperText={
                        savingError &&
                        !user.plannedMinutes &&
                        user.plannedMinutesText.trim()
                          ? "incorrect format"
                          : ""
                      }
                      onBlur={(e) => {
                        handleUserHourChange({
                          name: e.target.name,
                          value:
                            !checkHourMinuteRegex(e.target.value) &&
                            checkTimeformat(e.target.value)
                              ? returnCorrectTimeFormat(e.target.value)
                              : e.target.value,
                          index,
                        });
                      }}
                      variant="standard"
                      value={user.plannedMinutesText}
                      placeholder="0h 0m"
                      name="plannedMinutesText plannedMinutes"
                      size="small"
                      onChange={(e) =>
                        handleUserHourChange({
                          name: e.target.name,
                          value: e.target.value,
                          index,
                        })
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <div>
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        Worked hours
                      </Typography>

                      <TextField
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                        className="mt-10 mb-10"
                        variant="standard"
                        value={user.workedMinutesText}
                        error={
                          savingError &&
                          !user.workedMinutes &&
                          user.workedMinutesText.trim()
                        }
                        helperText={
                          savingError &&
                          !user.workedMinutes &&
                          user.workedMinutesText.trim()
                            ? "incorrect format"
                            : ""
                        }
                        onBlur={(e) => {
                          handleUserHourChange({
                            name: e.target.name,
                            value:
                              !checkHourMinuteRegex(e.target.value) &&
                              checkTimeformat(e.target.value)
                                ? returnCorrectTimeFormat(e.target.value)
                                : e.target.value,
                            index,
                          });
                        }}
                        placeholder="0h 0m"
                        name="workedMinutesText workedMinutes"
                        size="small"
                        onChange={(e) =>
                          handleUserHourChange({
                            name: e.target.name,
                            value: e.target.value,
                            index,
                          })
                        }
                      />
                      {user?.totalWorkedMinutes && (
                        <Typography
                          style={{
                            fontSize: 10,
                            color: "gray",
                            marginTop: 10,
                            marginLeft: 5,
                            fontStyle: "italic",
                          }}
                        >
                          {user?.totalWorkedMinutesText} logged
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid
                    item
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                      }}
                    >
                      Date
                    </Typography>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      <FormControl variant="outlined" style={{ marginTop: 10 }}>
                        <DatePicker
                          inputVariant="standard"
                          format="MM/dd/yyyy"
                          value={
                            user.date ? moment(user.date?.split("T")[0]) : ""
                          }
                          open={datePickerIndex === index}
                          // onClick={() => {
                          //   setDatePickerIndex(index);
                          // }}
                          onClose={() => setDatePickerIndex(undefined)}
                          onChange={(date) => {
                            handleUserHourChange({
                              name: "date",
                              value: moment(date).format(
                                "YYYY-MM-DD[T00:00:00.000Z]"
                              ),
                              index,
                            });
                            setDatePickerIndex(undefined);
                          }}
                        />
                      </FormControl>
                      <IconButton onClick={() => handleOpenCalendar(index)}>
                        <CalendarIcon />
                      </IconButton>
                    </div>
                  </Grid>
                </div>
              ))}
            </Grid>
          </Grid>

          <Grid container style={{ justifyContent: "flex-end", marginTop: 40 }}>
            <Button
              variant="outlined"
              style={{
                borderRadius: 20,
                textTransform: "none",
                fontSize: 12,
                borderColor: "#676869",
                color: "#676869",
              }}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              disabled={
                !userList?.filter((f) => f.plannedMinutes || f.workedMinutes)
                  ?.length
              }
              style={{
                borderRadius: 20,
                marginLeft: 20,
                textTransform: "none",
                fontSize: 12,
              }}
              variant="contained"
              color="primary"
              onClick={handleSaveUserTimeData}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default memo(UserTimeEntryModal);
