import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { PERMISSION_TYPES } from "utils/constants";
// import { ENVIRONMENT } from "config";

import {
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardHeader,
  Switch,
} from "@material-ui/core";

import { ColorButton } from "components/UI/Buttons";
import VektorTextField from "components/UI/TextFields/VektorTextField";
import FilterSelect from "components/UI/Selects/FilterSelect";
import UserTransfer from "parts/UserTransfer";
import { PROJECT_MODES } from "pages/Projects/constants";

import { useProjectFrom, useVisibilityBooleans } from "./helpers";
import { PROJECT_DEFAULT_VALUES } from "./constants";
import useStyles from "./styles";
// import { ExpandMore } from "@material-ui/icons";
import { ReactComponent as ExpandMore } from "icons/arrow_down.svg";
import Loader from "components/Loader";

const ProjectForm = ({ project = PROJECT_DEFAULT_VALUES, mode }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    errors,
    control,
    PMs,
    supervisors,
    // type,
    filteresUsers,
    assignedUserList,
    organization,
    handleAssignedUsers,
    onSubmit,
    onDeleteProject,
    setCurrentOrganization,
    // setProjType,
  } = useProjectFrom(project, mode, setLoading, setOpen);
  const {
    isOrganizationVisible,
    isSupervisorVisible,
    isViewingMode,
    isButtonEnabled,
  } = useVisibilityBooleans(organization, mode);
  const [autoSchedule, setAutoSchedule] = useState(false);
  const [notifications, setNotifications] = useState(true);
  const [emails, setEmails] = useState(true);
  const { results: organizations } = useSelector(
    ({ organizations }) => organizations
  );
  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    setAutoSchedule(project.autoSchedulerEnabled);
  }, [project.autoSchedulerEnabled]);

  useEffect(() => {
    setNotifications(project.receiveNotifications);
  }, [project.receiveNotifications]);

  useEffect(() => {
    setEmails(project.receiveEmails);
  }, [project.receiveEmails]);

  useEffect(() => {
    if (mode === PROJECT_MODES.CREATION) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [mode]);

  const renderBottomButtons = () => {
    if (isButtonEnabled) {
      let rightButtonComponent;

      switch (mode) {
        case PROJECT_MODES.CREATION:
          rightButtonComponent = (
            <Button
              color="primary"
              className={classes.addAnother}
              onClick={onSubmit(true, autoSchedule, notifications, emails)}
            >
              Save and add another
            </Button>
          );
          break;
        case PROJECT_MODES.EDITING:
          rightButtonComponent = (
            <ColorButton
              className={classes.deleteButton}
              variant="contained"
              colour="red"
              onClick={onDeleteProject}
            >
              Delete Project
            </ColorButton>
          );
          break;
        default:
          break;
      }

      return (
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit(
              false,
              autoSchedule,
              notifications,
              emails,
              organizations.find(
                (org) => org._id === project.organization || organization
              )
            )}
          >
            Save
          </Button>
          {rightButtonComponent}
        </div>
      );
    }
  };

  return (
    <Accordion expanded={open} onChange={(e) => setOpen(!open)}>
      <AccordionSummary expandIcon={<ExpandMore className="icons_small" />}>
        <CardHeader title={"Project Details"} className="pd-0 ml-10" />
      </AccordionSummary>
      <AccordionDetails>
        <Card>
          <CardContent>
            <form noValidate className={classes.form}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    id="name"
                    as={<VektorTextField />}
                    fullWidth
                    name="name"
                    label="Name"
                    placeholder="Name"
                    error={errors.name?.message}
                    control={control}
                    defaultValue={project.name}
                    disabled={isViewingMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    id="number"
                    as={<VektorTextField />}
                    fullWidth
                    name="number"
                    label="Number"
                    placeholder="Number"
                    error={errors.number?.message}
                    control={control}
                    defaultValue={project.number}
                    disabled={isViewingMode}
                  />
                </Grid>

                {isOrganizationVisible && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      id="organization"
                      as={
                        <FilterSelect keys={{ label: "name", value: "_id" }} />
                      }
                      fullWidth
                      name="organization"
                      label="Organization"
                      placeholder="Select organization"
                      items={organizations}
                      error={errors.organization?.message}
                      control={control}
                      onClick={({ target }) =>
                        setCurrentOrganization(target?.value)
                      }
                      defaultValue={project.organization}
                      disabled={isViewingMode}
                    />
                  </Grid>
                )}

                {/* {ENVIRONMENT?.toLowerCase() === "qms" && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={isOrganizationVisible ? 3 : false}
                  >
                    <Controller
                      id="type"
                      as={
                        <FilterSelect keys={{ label: "label", value: "_id" }} />
                      }
                      fullWidth
                      name="type"
                      label="Type"
                      placeholder="Select Type"
                      error={errors.type?.message}
                      control={control}
                      defaultValue={project.type}
                      disabled={isViewingMode}
                      items={type}
                      onClick={({ target }) => setProjType(target?.value)}
                    />
                  </Grid>
                )} */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={isOrganizationVisible ? 3 : false}
                >
                  <Controller
                    id="projectManager"
                    as={
                      <FilterSelect keys={{ label: "label", value: "_id" }} />
                    }
                    fullWidth
                    name="projectManager"
                    label="PM"
                    placeholder="Select PM"
                    items={PMs}
                    error={errors.pm?.message}
                    control={control}
                    defaultValue={project.projectManager}
                    disabled={isViewingMode}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={isOrganizationVisible ? false : 6}
                >
                  {isSupervisorVisible && (
                    <Controller
                      id="supervisor"
                      as={
                        <FilterSelect keys={{ label: "label", value: "_id" }} />
                      }
                      fullWidth
                      name="supervisor"
                      label="Supervisor"
                      placeholder="Select supervisor"
                      items={supervisors}
                      error={errors.supervisor?.message}
                      control={control}
                      defaultValue={project.supervisor}
                      disabled={isViewingMode}
                    />
                  )}
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.description}
                  >
                    Status
                    <br />
                    {Math.round(project.status) || 0}%
                  </Typography>
                </Grid>
                {[PERMISSION_TYPES.superAdmin, PERMISSION_TYPES.admin].includes(
                  currentUser?.permissions
                ) && (
                  <Grid item xs={3} sm={3} md={3}>
                    <Typography variant="body2" color="textPrimary">
                      Auto Scheduling
                    </Typography>
                    <Switch
                      id="autoSchedulerEnabled"
                      name="autoSchedulerEnabled"
                      checked={autoSchedule}
                      onChange={() => setAutoSchedule(!autoSchedule)}
                      disabled={isViewingMode}
                    />
                  </Grid>
                )}
                {[PERMISSION_TYPES.superAdmin, PERMISSION_TYPES.admin].includes(
                  currentUser?.permissions
                ) && (
                  <Grid item xs={3} sm={3} md={3}>
                    <Typography variant="body2" color="textPrimary">
                      Receive Emails
                    </Typography>
                    <Switch
                      id="emails"
                      name="emails"
                      checked={emails}
                      onChange={() => setEmails(!emails)}
                      disabled={isViewingMode}
                    />
                  </Grid>
                )}
                {[PERMISSION_TYPES.superAdmin, PERMISSION_TYPES.admin].includes(
                  currentUser?.permissions
                ) && (
                  <Grid item xs={3} sm={3} md={3}>
                    <Typography variant="body2" color="textPrimary">
                      Receive Notifications
                    </Typography>
                    <Switch
                      id="notifications"
                      name="notifications"
                      checked={notifications}
                      onChange={() => setNotifications(!notifications)}
                      disabled={isViewingMode}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore className="icons_small" />}
                    >
                      Users
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <UserTransfer
                          users={filteresUsers}
                          assignedUsers={assignedUserList}
                          onAssignedUsers={handleAssignedUsers}
                          isViewingMode={isViewingMode}
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12}>
                  {renderBottomButtons()}
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </AccordionDetails>
      {loading && <Loader />}
    </Accordion>
  );
};

export default memo(ProjectForm);
