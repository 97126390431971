import { Grid } from "@material-ui/core";
import { memo } from "react";
import { NO_DATA_FOUND_IMAGE_PATH } from "utils/constants";

const NoDataAvailable = ({ bottomTitle = "No data to show" }) => {
  return (
    <Grid container columns={12} alignItems={"center"} justify={"center"}>
      <div className="d-jsb-cen-col">
        <img
          className="no-data-img"
          src={NO_DATA_FOUND_IMAGE_PATH}
          alt="no-data"
        />
        <p className="no-data-text">{bottomTitle}</p>
      </div>
    </Grid>
  );
};

export default memo(NoDataAvailable);
