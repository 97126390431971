import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Card,
  CircularProgress,
} from "@material-ui/core";
import LINKS from "utils/constants/links";
import { ChangeRequestHelper } from "../helpers";
import { getAllRequests } from "services/api-change-request";
export default function AllRequests({ projectId }) {
  const [loading, setLoading] = useState(false);
  const [changeRequest, setChangeRequest] = useState([]);
  const history = useHistory();

  const { CHANGE_REQUEST_LIST_TABLE_COLUMN, CHANGE_REQUEST_STATUS } =
    ChangeRequestHelper();

  const getRequestList = async () => {
    setLoading(true);
    await getAllRequests(projectId)
      .then((resp) => {
        setChangeRequest(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>> ", err);
      });
  };

  useEffect(() => {
    getRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );

  if (!changeRequest.length)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>No Change Requests available.</p>
      </div>
    );

  return (
    <Card>
      <TableContainer style={{ height: "70vh", maxHeight: "70vh" }}>
        <Table stickyHeader>
          <TableHead>
            {CHANGE_REQUEST_LIST_TABLE_COLUMN.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {changeRequest.map((doc, index) => (
              <TableRow key={index}>
                {CHANGE_REQUEST_LIST_TABLE_COLUMN.map((column) => {
                  let value = doc[column.id];
                  if (column.id === "status") {
                    value = CHANGE_REQUEST_STATUS.find(
                      (status) => status.value === doc[column.id]
                    )?.label;
                  }

                  if (column.id === "ecrNumber") {
                    value = (
                      <Typography
                        className="pointer"
                        color="primary"
                        onClick={() =>
                          history.push(
                            LINKS.EDIT_CHANGE_REQUEST.HREF.replace(
                              ":projectId",
                              projectId
                            ).replace(":id", doc._id)
                          )
                        }
                      >
                        {value}
                      </Typography>
                    );
                  }
                  if (column.id === "title") {
                    value = <Typography>{value}</Typography>;
                  }
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
