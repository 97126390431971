import { keyMirror } from "utils/helpers/utility";

export const PERMISSION_TYPES = {
  superAdmin: "SUPERADMIN",
  admin: "ADMIN",
  supervisor: "SUPERVISOR",
  projectManager: "PROJECT_MANAGER",
  user: "USER",
  viewer: "VIEWER",
  hr: "HR"
};

export const ENVIRONMENT_TYPES = {
  local: "local",
  dev: "development",
  prod: "production",
  qa: "qa",
  qms: "qms",
};

export const FORM_MODE = keyMirror({
  create: null,
  update: null,
  view: null,
});

export const POPUP_TYPE = keyMirror({
  info: null,
  confirm: null,
  error: null,
  inactive: null,
});

export const ACTION_TYPES = {
  create: "CREATE",
  update: "UPDATE",
  delete: "DELETE",
  createNested: "CREATE_NESTED",
  updateNested: "UPDATE_NESTED",
  deleteNested: "DELETE_NESTED",
};

export const ENTITY_NAME_TYPES = {
  event: "Event",
  metaSystem: "MetaSystem",
  organization: "Organization",
  project: "Project",
  sow: "SOW",
  system: "System",
  user: "User",
  workflow: "Workflow",
  dcr: "Dcr",
  document: "Document",
  documentApproval: "DocumentApproval",
  deviation: "Deviation",
  changeRequest: "ChangeRequest",
};

export const SORT_DIRS = keyMirror({
  none: null,
  asc: null,
  desc: null,
});

export const ACTION_TYPES_MAP = {
  CREATE: "Create",
  UPDATE: "Update",
  DELETE: "Delete",
  CREATE_NESTED: "Create nested",
  UPDATE_NESTED: "Update nested",
  DELETE_NESTED: "Delete nested",
};
