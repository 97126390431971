import React from "react";
import { Dialog, DialogContent } from "components/UI/VektorDialog";
import {
  Box,
  Button,
  IconButton,
  TextField,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import FilterSelect from "components/UI/Selects/FilterSelect";
import { AddCircle, Close } from "@material-ui/icons";
const TaskDialog = ({
  title,
  open,
  onClose,
  task,
  users,
  onCreate,
  onUpdate,
  onCellChange,
  departments,
  onAddTask
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogContent>
        <Box width={"300px"}>
          <h2>Edit Task</h2>
          <div className="mb-10">
            <TextField
              fullWidth
              label="Name"
              type="string"
              name="name"
              InputProps={{
                inputProps: { min: "0", max: "100", step: "1" },
              }}
              onChange={onCellChange}
              value={task.name}
            ></TextField>
          </div>
          <div className="mb-10">
            <DatePicker
              fullWidth
              label="Start Date"
              name="start"
              value={task.actualStartDate ? moment((task.actualStartDate).split("T")[0]).format("MM/DD/YY") : null}
              format="MM/dd/yy"
              onChange={(date) =>
                onCellChange({
                  target: { type: "date", value: date, name: "start" },
                })
              }
            />
          </div>
          <div className="mb-10">
            <DatePicker
              fullWidth
              label="End Date"
              name="end"
              value={task.end ? moment((task.end).split("T")[0]).format("MM/DD/YY") : null}
              format="MM/dd/yy"
              onChange={(date) =>
                onCellChange({
                  target: { type: "date", value: date, name: "end" },
                })
              }
              minDate={task.actualStartDate ? moment((task.actualStartDate).split("T")[0]).format("MM/DD/YY") : null}
            />
          </div>
          <div className="mb-10 d-flex d-jsb-c">
            <DatePicker
              fullWidth
              label="Completion Date"
              name="completion"
              value={task.completion || null}
              format="MM/dd/yy"
              onChange={(date) =>
                onCellChange({
                  target: { type: "date", value: date, name: "completion" },
                })
              }
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onCellChange({
                        target: {
                          type: "date",
                          value: null,
                          name: "completion",
                        },
                      });
                    }}
                    className="pd-0"
                  >
                    <Close />
                  </IconButton>
                ),
              }}
            />
          </div>
          <div className="mb-10">
            <FilterSelect
              label="Department"
              placeholder="Select department"
              items={departments.length > 0 ? departments[0].departments : []}
              name="department"
              keys={{
                label: "label",
                value: "_id",
              }}
              onChange={onCellChange}
              value={task.department}
            />
          </div>
          <div className="mb-10">
            <FilterSelect
              label="Resource"
              placeholder="Select users"
              items={users.filter((u) => u.department === task.department)}
              name="resource"
              keys={{
                label: "name",
                value: "_id",
              }}
              onChange={onCellChange}
              value={task.resource}
            />
          </div>
          <div className="d-jsb-r mb-20">
            <IconButton onClick={() => onAddTask(task)}>
              <AddCircle />
            </IconButton>
            <div>Add Sub Task</div>
          </div>
          <div className="d-jsb-r mb-20">
            <Button variant="contained" color="disabled" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-10"
              onClick={onUpdate}
            >
              Update
            </Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TaskDialog;
