import {
  Card,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
} from "@material-ui/core";
import Loader from "components/Loader";
import { memo, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  delInvoice,
  filterInvoice,
  getInvoices,
  postInvoice,
  putInvoice,
  getPO,
} from "services/api-finance";
import { TABLE_INVOICE_COLUMN, TABLE_INVOICE_COLUMN_TOTAL } from "../helpers";
import { PriorityHigh } from "@material-ui/icons";
import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as DeleteIcon } from "icons/delete.svg";
import moment from "moment";
import AddInvoice from "./AddInvoice";
import { useDispatch } from "react-redux";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE, SORT_DIRS } from "utils/constants";
import FinanceFilter from "./FinanceFilter";
import { getVendors } from "services/api-vendor";
import { getDepartments } from "services/api-organization";
import { useTableSort } from "utils/hooks";
import _ from "lodash";
import DocumentViewer from "components/DocumentViewer";
import NoDataAvailable from "components/NoDataAvailable";

const newInvoice = {
  invoiceNumber: "",
  type: "SERVICE",
  invoiceDate: moment(new Date()).format("YYYY-MM-DD[T00:00:00.000Z]"),
  startDate: moment(new Date()).format("YYYY-MM-DD[T00:00:00.000Z]"),
  endDate: moment(new Date()).format("YYYY-MM-DD[T00:00:00.000Z]"),
  purchaseOrder: "",
  amount: 0,
};

const Invoices = ({
  projectId,
  project,
  openAdd,
  handleClose,
  openFilter,
  handleFilterClose,
  enableFilterClear,
  showClear,
  clearFilter,
}) => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
  const [vendorList, setVendorList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editInvoice, setEditInvoice] = useState();
  const dispatch = useDispatch();
  const [loadMore, setLoadMore] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [viewFile, setViewFile] = useState();
  const [filterBy, setFilterBy] = useState("default");
  const [filterId, setFilterId] = useState("");

  const { sortedRows, handleSort } = useTableSort(invoiceList);
  const [order, setOrder] = useState(SORT_DIRS.asc);
  const [orderBy, setOrderBy] = useState(null);

  const getVendorList = async () => {
    if (project?.organization) {
      await getVendors(project?.organization)
        .then(async (resp) => {
          await setVendorList(resp);
        })
        .catch((err) => {});
    }
  };

  const getDepartmentList = async () => {
    if (project?.organization) {
      await getDepartments(project?.organization)
        .then(async (resp) => {
          await setDepartmentList(resp.data);
        })
        .catch((err) => {});
    }
  };

  const getPurchaseOrderList = async () => {
    if (project?.organization) {
      await getPO(projectId, 0, null)
        .then(async (resp) => {
          !_.isEqual(purchaseOrderList, resp.data) &&
            setPurchaseOrderList(resp.data);
        })
        .catch((err) => {});
    }
  };

  const getInvoicesList = (skip, limit, type) => {
    let list = [...invoiceList];
    if (type === "initial") {
      setLoading(true);
    }
    getInvoices(projectId, skip, limit)
      .then((resp) => {
        setLoading(false);
        if (type === "loadMore") {
          setInvoiceList([...list, ...resp.data]);
          setTotalInvoiceAmount(resp.totalInvoiceAmount);
          if ([...list, ...resp.data].length === resp.pagination.count) {
            setLoadMore(false);
          }
        } else if (type === "initial") {
          setInvoiceList([...resp.data]);
          setTotalInvoiceAmount(resp.totalInvoiceAmount);
          if (resp.data.length === resp.pagination.count) {
            setLoadMore(false);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    setPaginationLoading(true);
    if (filterBy === "default") {
      getInvoicesList(invoiceList.length, 10, "loadMore");
    } else {
      getInvoiceByFilter(invoiceList.length, 10, "loadMore");
    }
  };
  const openEditForm = (index) => {
    setEditInvoice(invoiceList[index]);
    setOpen(true);
  };

  const saveInvoice = (data) => {
    setLoading(true);
    if (data._id) {
      putInvoice(projectId, data._id, data)
        .then((resp) => {
          const updatedList = invoiceList.map((invoice) => {
            if (invoice._id === data._id) {
              invoice = resp.upudatedOrderInvoice;
            }
            return invoice;
          });
          setInvoiceList(updatedList);
          setTotalInvoiceAmount(resp.totalInvoiceAmount);
          closeForm();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      postInvoice(projectId, data)
        .then((resp) => {
          setInvoiceList([resp.newOrderInvoice, ...invoiceList]);
          setTotalInvoiceAmount(resp.totalInvoiceAmount);
          closeForm();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const onDelete = async (index) => {
    const data = invoiceList[index];

    dispatch(
      setPopup({
        popupType: POPUP_TYPE.confirm,
        popupText: "Are you sure you want to delete this Order Invoice?",
        onConfirm: async () => {
          setLoading(true);
          delInvoice(projectId, data._id)
            .then((resp) => {
              setInvoiceList(invoiceList.filter((inv) => inv._id !== data._id));
              setTotalInvoiceAmount(resp.totalInvoiceAmount);
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
            });
        },
      })
    );
  };

  const handleFileView = (file) => {
    setViewFile(file);
    setOpenFile(true);
  };

  const getInvoiceByFilter = (skip, limit, type) => {
    let list = [...invoiceList];
    if (type === "initial") {
      list = [];
      setLoading(true);
    }
    filterInvoice(projectId, filterBy, filterId, skip, limit)
      .then((resp) => {
        setLoading(false);
        setInvoiceList([...list, ...resp.data]);
        setTotalInvoiceAmount(totalInvoiceAmount + resp.totalAmount);
        if ([...list, ...resp.data].length === resp.pagination.count) {
          setLoadMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleFilter = (filterData) => {
    if (filterData.filterBy === "byVendor" && filterData.vendor) {
      paginationLoading && setPaginationLoading(false);
      setLoadMore(true);
      setFilterBy(filterData.filterBy);
      setFilterId(filterData.vendor);
      setTotalInvoiceAmount(0);
      setInvoiceList([]);
      handleFilterClose();
      enableFilterClear();
    } else if (
      filterData.filterBy === "byDepartment" &&
      filterData.department
    ) {
      paginationLoading && setPaginationLoading(false);
      setLoadMore(true);
      setFilterBy(filterData.filterBy);
      setFilterId(filterData.department);
      setTotalInvoiceAmount(0);
      setInvoiceList([]);
      handleFilterClose();
      enableFilterClear();
    } else if (
      filterData.filterBy === "byPurchaseOrder" &&
      filterData.purchaseOrder
    ) {
      paginationLoading && setPaginationLoading(false);
      setLoadMore(true);
      setFilterBy(filterData.filterBy);
      setFilterId(filterData.purchaseOrder);
      setTotalInvoiceAmount(0);
      setInvoiceList([]);
      handleFilterClose();
      enableFilterClear();
    } else {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: "Please selected all fields",
        })
      );
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === SORT_DIRS.asc;
    const nextOrder = isAsc ? SORT_DIRS.desc : SORT_DIRS.asc;
    setOrder(nextOrder);
    setOrderBy(property);
    handleSort(property, nextOrder);
  };

  const createSortHandler = (columnId) => (event) => {
    handleRequestSort(event, columnId);
  };

  useEffect(() => {
    getVendorList();
    getDepartmentList();
    getPurchaseOrderList();
    // getInvoicesList(0, 20, "initial");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      ["byVendor", "byDepartment", "byPurchaseOrder"].includes(filterBy) &&
      filterId &&
      invoiceList.length === 0
    ) {
      getInvoiceByFilter(0, 20, "initial");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy, filterId]);
  useEffect(() => {
    if (openAdd === "invoice" && open === false) {
      setEditInvoice(newInvoice);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAdd]);
  useEffect(() => {
    if (showClear === false) {
      setFilterBy("default");
      getInvoicesList(0, 20, "initial");
      paginationLoading && setPaginationLoading(false);
      setLoadMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showClear]);
  const closeForm = () => {
    setOpen(false);
    handleClose();
  };
  useEffect(() => {
    return () => {
      clearFilter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      {sortedRows?.length && !loading ? (
        <TableContainer>
          <InfiniteScroll
            dataLength={sortedRows?.length}
            hasMore={loadMore}
            next={() => {
              if (!paginationLoading) {
                handleLoadMore();
              }
            }}
            loader={
              sortedRows?.length >= 20 &&
              loadMore && (
                <div className="d-jsb-cen">
                  <CircularProgress size={20} className="mb-10" />
                </div>
              )
            }
            scrollThreshold={0.9}
            height={window.innerHeight * 0.65}
          >
            <Table stickyHeader>
              <TableHead>
                {TABLE_INVOICE_COLUMN.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {!["action", "attachment", "vendor"].includes(column.id) ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={
                          orderBy === column.id ? order : SORT_DIRS.asc
                        }
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableHead>
              <TableBody>
                {sortedRows.map((doc, index) => (
                  <TableRow key={index}>
                    {TABLE_INVOICE_COLUMN.map((column) => {
                      let value = doc[column.id];
                      if (column.id === "invoiceNumber") {
                        value = (
                          <div className="txt-capital">
                            {doc?.invoiceNumber}
                          </div>
                        );
                      }
                      if (column.id === "invoiceDate") {
                        value = moment(value?.split("T")[0]).format("MM/DD/YY");
                      }
                      if (column.id === "type") {
                        value = (
                          <div className="txt-capitalize">{doc?.type}</div>
                        );
                      }
                      if (column.id === "vendor") {
                        value = doc?.vendorInfo?.name;
                      }
                      if (column.id === "period") {
                        const a = moment(doc.startDate?.split("T")[0]);
                        const b = moment(doc.endDate?.split("T")[0]);
                        value = b.diff(a, "days") + 1;
                      }
                      if (column.id === "amount") {
                        value = (
                          <div className="d-jsb-c" style={{ maxWidth: 70 }}>
                            <span
                              className={doc.poRemainingAmount < 0 && "txt-red"}
                            >
                              $&nbsp;{value?.toLocaleString()}
                            </span>

                            {doc.poRemainingAmount < 0 && (
                              <Tooltip title={`PO Amount - ${doc.poAmount}`}>
                                <PriorityHigh style={{ fontSize: 15 }} />
                              </Tooltip>
                            )}
                          </div>
                        );
                      }
                      if (column.id === "attachment") {
                        const fileName = doc?.attachment
                          ? doc?.attachment?.fileName?.substring(
                              doc?.attachment?.fileName?.indexOf("-") + 1
                            )
                          : "";
                        value = fileName ? (
                          <a
                            role="button"
                            tabindex={index}
                            href="#foo"
                            name="#foo"
                            className="txt-wrap"
                            onClick={() => handleFileView(doc?.attachment)}
                          >
                            {fileName}
                          </a>
                        ) : (
                          ""
                        );
                      }
                      if (column.id === "action") {
                        value = (
                          <div className="d-flex">
                            <IconButton
                              className="pd-0"
                              onClick={() => openEditForm(index)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              className="pd-0"
                              onClick={() => onDelete(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
                {invoiceList.length === 0 && (
                  <div className="pd-20">No Data Available</div>
                )}
              </TableBody>
              <TableHead className="bottom-sticky">
                {TABLE_INVOICE_COLUMN_TOTAL.map((column) =>
                  ["Blank"].includes(column.label) ? (
                    <TableCell></TableCell>
                  ) : ["Total"].includes(column.label) ? (
                    <TableCell>{column.label}</TableCell>
                  ) : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label === "Amount" &&
                        "$ " + totalInvoiceAmount?.toLocaleString()}
                    </TableCell>
                  )
                )}
              </TableHead>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      ) : null}
      {!sortedRows?.length && !loading && <NoDataAvailable />}
      {loading && <Loader />}
      {open && (
        <AddInvoice
          open={open}
          projectId={projectId}
          handleClose={closeForm}
          editInvoice={editInvoice}
          saveInvoice={saveInvoice}
          purchaseOrderList={purchaseOrderList}
        />
      )}
      {openFile && (
        <DocumentViewer
          open={openFile}
          fileData={viewFile}
          handleClose={() => setOpenFile(false)}
        />
      )}
      {openFilter && (
        <FinanceFilter
          open={openFilter}
          handleFilterClose={handleFilterClose}
          vendorList={vendorList}
          departmentList={departmentList}
          purchaseOrderList={purchaseOrderList}
          handleFilter={handleFilter}
          type="invoice"
        />
      )}
    </Card>
  );
};

export default memo(Invoices);
