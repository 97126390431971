import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableBody,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Fragment, useEffect, useRef } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LINKS from "utils/constants/links";
import { useSelector } from "react-redux";
import useFeatureFlag from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import { getFormattedDate } from "../snapshotHelper";
import { ToolTipStatusText } from "../../PhaseSnapshot/components/helper";
// import { ReactComponent as CheckCircleFilledIcon } from "icons/check_circle_filled.svg";
import { getColoredIconComponent } from "pages/Projects/ProjectInsight/constant";
import { getDeliverableMatrixIcon } from "./helper";

export const CustomTableCell = withStyles({
  root: {
    borderBottom: "none",
    boxShadow: "inset 0px 0px 0px 0.25px grey",
    zIndex: 1,
    padding: "1px",
    backgroundColor: "white",
  },
})(TableCell);

const getExeceutionColor = (completion, end, status) => {
  let today = new Date();
  today = completion || today.toISOString();
  return getFormattedDate(today) > getFormattedDate(end) &&
    Math.round(status) < 100
    ? "#D32F2F"
    : getFormattedDate(end) >= getFormattedDate(today) &&
      Math.round(status) === 100
    ? "#5BA300"
    : getFormattedDate(end) < getFormattedDate(today) &&
      Math.round(status) === 100
    ? "#FFB950"
    : "#0953B9";
};

// const getCheckbox = (color) => {
//   return (
//     <CheckCircleFilledIcon
//       className={`${
//         color === "#27AE60"
//           ? "green__icon"
//           : color === "#F39C12"
//           ? "yellow__icon"
//           : "grey__icon"
//       }`}
//     />
//   );
// };

export const DeliverableMatrixTable = ({
  classes,
  deliverableMap,
  filteredSystemList,
  filteredDeliverableObject,
  setHeaderHeight,
  isRequired,
  systemList,
  newfilteredDeliverableObject,
}) => {
  const metaSystems = useSelector((state) => state.projects?.metaSystems || []);
  const { projectId } = useParams();
  const ref = useRef();
  const systemFeatureFlag = useFeatureFlag(FEATURE_FLAGS.Systems);

  useEffect(() => {
    setHeaderHeight(ref.current.clientHeight - 0.5);
    // eslint-disable-next-line
  }, [ref, deliverableMap, filteredDeliverableObject]);

  const handleSystemRedirect = (name) => {
    const filteredMetasystem = metaSystems.find((val) => val.name === name);
    const link = LINKS.EDIT_META_SYSTEM.HREF.replace(":projectId", projectId)
      .replace(":systemId", filteredMetasystem?._id)
      .replace(":mainSystemId", filteredMetasystem?.mainSystem?._id);
    window.open(link, "_blank");
  };

  return (
    <TableContainer
      style={{
        display: "table",
        tableLayout: "fixed",
      }}
    >
      <Table
        className={classes.table}
        aria-label="simple table"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <TableHead>
          <TableRow ref={ref}>
            <TableCell
              align="center"
              className={`${classes.customTableHeaderCellStyle} snapshotheader-left-border-radius`}
              style={{ zIndex: 3 }}
              colSpan={4}
              key={"systemcell"}
            >
              Master
            </TableCell>
            <TableCell
              align="center"
              className={classes.customTableHeaderCellStyle}
              style={{ zIndex: 3 }}
              colSpan={4}
              key={"systemcell"}
            >
              Systems
            </TableCell>
            {deliverableMap?.map((deliverable, index) => {
              return (
                <TableCell
                  style={{ whiteSpace: "nowrap", zIndex: 2 }}
                  align="center"
                  colSpan={8}
                  className={`${classes.customTableHeaderCellStyle} ${
                    index === deliverableMap?.length - 1
                      ? "snapshotheader-right-border-radius"
                      : ""
                  }`}
                  key={deliverable?.name + "cell"}
                >
                  {deliverable.name}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={4}
              style={{ zIndex: 3 }}
              className={[
                classes.stickyTop1,
                classes.customTableSubHeaderCellStyle,
                classes.snapshotSubheaderRight,
              ]}
              key={"emptycell"}
            ></TableCell>

            <TableCell
              colSpan={4}
              style={{ zIndex: 3 }}
              className={[
                classes.stickyTop1,
                classes.customTableSubHeaderCellStyle,
                classes.snapshotSubheaderRight,
              ]}
              key={"emptycell"}
            ></TableCell>

            {deliverableMap?.map((deliverable, index) => {
              return (
                <Fragment key={"ifrrevapr" + deliverable.name}>
                  <TableCell
                    colSpan={4}
                    align="center"
                    className={[
                      classes.stickyTop1,
                      classes.customTableSubHeaderCellStyle,
                      classes.cellWidth,
                    ]}
                    key={deliverable.name + "IFR"}
                  >
                    D
                  </TableCell>

                  <TableCell
                    colSpan={4}
                    align="center"
                    className={[
                      classes.stickyTop1,
                      classes.customTableSubHeaderCellStyle,
                      classes.snapshotSubheaderRight,
                      classes.cellWidth,
                    ]}
                    key={deliverable.name + "Execution"}
                  >
                    E
                  </TableCell>
                </Fragment>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {newfilteredDeliverableObject?.map((row) => {
            return (
              <>
                {row?.systems?.map((sys, sysIndex) => {
                  return (
                    <TableRow>
                      {sysIndex === 0 && (
                        <TableCell
                          colSpan={4}
                          rowSpan={row.systems.length}
                          align="center"
                          className={[
                            classes.sticky,
                            classes.customTableSystemInitialCellStyle,
                            classes.snapshotSubheaderRight,
                            classes.systemNameText,
                          ]}
                          style={{ whiteSpace: "nowrap" }}
                          key={row + "cell"}
                        >
                          {row?.name}
                        </TableCell>
                      )}
                      <TableCell
                        component="th"
                        scope="row"
                        colSpan={4}
                        align="center"
                        style={{ whiteSpace: "nowrap" }}
                        className={[
                          classes.sticky,
                          classes.customTableSystemInitialCellStyle,
                          classes.snapshotSubheaderRight,
                        ]}
                      >
                        <div
                          className={`${
                            systemFeatureFlag && classes.systemNameText
                          } pointer`}
                          onClick={() => {
                            systemFeatureFlag &&
                              handleSystemRedirect(sys?.name);
                          }}
                        >
                          {sys?.name}
                        </div>
                      </TableCell>
                      {sys?.deliverables?.map(
                        (deliverable, deliverableIndex) => {
                          const approverList = [];
                          deliverable?.approver?.forEach((m) =>
                            approverList.push(...m.users)
                          );
                          const reviewerList = [];
                          deliverable?.reviewer?.forEach((m) =>
                            reviewerList.push(...m.users)
                          );
                          return deliverable ? (
                            <>
                              {deliverable?.type !== "EXECUTION" ? (
                                <>
                                  <TableCell
                                    colSpan={4}
                                    align="center"
                                    justify="center"
                                    className={[
                                      classes.customTableDelIconCellStyle,
                                      classes.snapshotSubheaderLeft,
                                      classes.snapshotDelIconRight,
                                    ]}
                                    style={{ whiteSpace: "nowrap" }}
                                    key={
                                      row?.name +
                                      sys?.name +
                                      deliverable.name +
                                      "cellDocument"
                                    }
                                  >
                                    {isRequired ? (
                                      <div
                                        style={{
                                          color: "#5A83BB",
                                        }}
                                      >
                                        R
                                      </div>
                                    ) : (
                                      <Tooltip
                                        title={ToolTipStatusText(
                                          deliverable,
                                          approverList,
                                          reviewerList
                                        )}
                                      >
                                        {getDeliverableMatrixIcon(
                                          deliverable,
                                          approverList,
                                          reviewerList
                                        )}
                                      </Tooltip>
                                    )}
                                  </TableCell>

                                  <TableCell
                                    colSpan={4}
                                    align="center"
                                    justify="center"
                                    className={[
                                      classes.customTableDelIconEmptyCellStyle,
                                      classes.snapshotSubheaderRight,
                                      classes.snapshotDelIconLeft,
                                    ]}
                                    style={{ whiteSpace: "nowrap" }}
                                    key={
                                      row?.name +
                                      sys?.name +
                                      deliverable?.name +
                                      "cellExecution"
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <TableCell
                                    colSpan={4}
                                    align="center"
                                    justify="center"
                                    className={[
                                      classes.customTableDelIconEmptyCellStyle,
                                      classes.snapshotSubheaderLeft,
                                      classes.snapshotDelIconRight,
                                    ]}
                                    style={{ whiteSpace: "nowrap" }}
                                    key={
                                      row?.name +
                                      sys?.name +
                                      deliverable?.name +
                                      "cellDocument"
                                    }
                                  />

                                  <TableCell
                                    colSpan={4}
                                    align="center"
                                    justify="center"
                                    className={[
                                      classes.customTableSystemInitialCellStyle,
                                      classes.snapshotSubheaderRight,
                                      classes.systemNameText,
                                    ]}
                                    style={{ whiteSpace: "nowrap" }}
                                    key={
                                      row?.name +
                                      sys?.name +
                                      deliverable?.name +
                                      "cellExecution"
                                    }
                                  >
                                    {isRequired ? (
                                      <div
                                        style={{
                                          color: "#5A83BB",
                                        }}
                                      >
                                        R
                                      </div>
                                    ) : deliverable?.status === 100 ? (
                                      <Tooltip
                                        key={
                                          row?.name +
                                          "cellExecutionCheckTooltip"
                                        }
                                        title={ToolTipStatusText(
                                          deliverable,
                                          approverList,
                                          reviewerList
                                        )}
                                      >
                                        {getColoredIconComponent(
                                          "execution_filled",
                                          getExeceutionColor(
                                            deliverable?.completion,
                                            deliverable?.end,
                                            deliverable?.status
                                          )
                                        )}
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        key={
                                          row?.name +
                                          "cellExecutionCheckTooltip"
                                        }
                                        title={ToolTipStatusText(
                                          deliverable,
                                          approverList,
                                          reviewerList
                                        )}
                                      >
                                        <div>
                                          {getColoredIconComponent(
                                            "execution_outline",
                                            getExeceutionColor(
                                              deliverable?.completion,
                                              deliverable?.end,
                                              deliverable?.status
                                            )
                                          )}
                                        </div>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <TableCell
                                colSpan={4}
                                align="center"
                                className={[
                                  classes.customTableDelIconEmptyCellStyle,
                                  classes.snapshotSubheaderLeft,
                                  classes.snapshotDelIconRight,
                                ]}
                                style={{ whiteSpace: "nowrap" }}
                                key={
                                  row?.name +
                                  sys?.name +
                                  deliverableIndex +
                                  "cellDocument"
                                }
                              />
                              <TableCell
                                colSpan={4}
                                align="center"
                                className={[
                                  classes.customTableDelIconEmptyCellStyle,
                                  classes.snapshotSubheaderRight,
                                  classes.snapshotDelIconLeft,
                                ]}
                                style={{ whiteSpace: "nowrap" }}
                                key={
                                  row?.name +
                                  sys?.name +
                                  deliverableIndex +
                                  "cellExecution"
                                }
                              />
                            </>
                          );
                        }
                      )}
                    </TableRow>
                  );
                })}
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
