import { PERMISSION_TYPES } from "utils/constants";

export const PROJECT_DEFAULT_VALUES = {
  name: "",
  number: "",
  supervisor: "",
  _id: "",
  organization: "",
  type: "",
  projectManager: "",
  assignedUsers: [],
  autoSchedulerEnabled: false,
  receiveEmails: true,
  receiveNotifications: true,
};
export const ALLOWED_ROLES = [
  PERMISSION_TYPES.superAdmin,
  PERMISSION_TYPES.admin,
  PERMISSION_TYPES.supervisor,
];
