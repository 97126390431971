import React, { memo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
// import { ReactComponent as CloseIcon } from "icons/cross_circle.svg";
import { makeStyles } from "@material-ui/core/styles";
import { setCustomPopup } from "redux/actions/customPopupAction";
import { useDispatch, useSelector } from "react-redux";
import { POPUP_TYPE } from "utils/constants";
import { ColorButton } from "../Buttons";
import { ReactComponent as DeleteForeverIcon } from "icons/delete_forever.svg";

export const POPUP_ICONS = {
  delete: "DELETE",
  save: "SAVE",
};

const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.custom.palette.black,
  },
  actionButtons: {
    justifyContent: "center",
    marginBottom: theme.spacing(5),
  },
  dialogTitle: (props) => ({
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 20,
    padding: 0,
    margin: 0,
    // marginBottom: !props.popupHeader && theme.spacing(5),
  }),
  dialogContentText: {
    color: theme.custom.palette.black,
    whiteSpace: "pre-line",
    textAlign: "center",
  },
}));

const CustomPopup = () => {
  const dispatch = useDispatch();
  const {
    popupType = POPUP_TYPE.inactive,
    popupText = "",
    popupHeader = "",
    onConfirm = async () => {},
    onCancel = async (type = "") => {},
    confirmText = "Confirm",
    cancelText = "Cancel",
    cancelColor = "",
    confirmColor = "",
    icon,
  } = useSelector((state) => state.customPopup);
  const classes = useStyles({ popupHeader });

  const handleClose = async (type) => {
    await dispatch(setCustomPopup({ popupType: POPUP_TYPE.inactive }));
    await onCancel(type);
  };

  const handleConfirm = async () => {
    await dispatch(setCustomPopup({ popupType: POPUP_TYPE.inactive }));
    await onConfirm();
  };

  if (popupType === POPUP_TYPE.inactive) {
    return null;
  }

  const PopupIconComponent = (icon) => {
    switch (icon) {
      case POPUP_ICONS.delete:
        return <DeleteForeverIcon className="icon-xl-large mt-20 mb-20" />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Dialog
        open={popupType !== POPUP_TYPE.inactive}
        onClose={() => handleClose("close")}
        // maxWidth="xs"
        // fullWidth={false}
        aria-labelledby="max-width-dialog-title"
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <DialogTitle
          id="max-width-dialog-title"
          className={classes.dialogTitle}
        >
          {PopupIconComponent(icon)}
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {popupHeader}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentText}>
            {popupText}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className={classes.actionButtons}
          style={{ paddingLeft: 100, paddingRight: 100, gap: 20 }}
        >
          {popupType === POPUP_TYPE.confirm ? (
            <>
              {cancelColor === "" && (
                <Button
                  style={{ borderRadius: 100 }}
                  color="default"
                  variant="outlined"
                  onClick={handleClose}
                >
                  {cancelText}
                </Button>
              )}
              {cancelColor !== "" && (
                <ColorButton
                  style={{ borderRadius: 100 }}
                  colour="red"
                  onClick={handleClose}
                >
                  {cancelText}
                </ColorButton>
              )}
              {confirmColor === "" && (
                <Button
                  style={{ borderRadius: 100 }}
                  color="primary"
                  variant="contained"
                  onClick={handleConfirm}
                >
                  {confirmText}
                </Button>
              )}

              {confirmColor !== "" && (
                <ColorButton
                  colour={`${confirmColor}`}
                  style={{ borderRadius: 100 }}
                  className="mr-5"
                  onClick={handleConfirm}
                >
                  {confirmText}
                </ColorButton>
              )}
            </>
          ) : (
            <Button color="success" variant="contained" onClick={handleClose}>
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default memo(CustomPopup);
