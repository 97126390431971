import React, { useState, useEffect } from "react";
import { getAllDeviations } from "services/api-deviation";
import { useHistory } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Card,
  CircularProgress,
} from "@material-ui/core";
import LINKS from "utils/constants/links";
import { DeviationHelper } from "../helpers";
export default function AllDeviations({ projectId }) {
  const [loading, setLoading] = useState(false);
  const [deviations, setDeviations] = useState([]);
  const history = useHistory();

  const { DEVIATIONS_LIST_TABLE_COLUMN, DEVIATION_STATUS } = DeviationHelper();

  const getDeviationList = async () => {
    setLoading(true);
    await getAllDeviations(projectId)
      .then((resp) => {
        setDeviations(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>> ", err);
      });
  };

  useEffect(() => {
    getDeviationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );

  if (!deviations.length)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>No Deviations available.</p>
      </div>
    );

  return (
    <Card>
      <TableContainer style={{ height: "70vh", maxHeight: "70vh" }}>
        <Table stickyHeader>
          <TableHead>
            {DEVIATIONS_LIST_TABLE_COLUMN.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {deviations.map((doc, index) => (
              <TableRow key={index}>
                {DEVIATIONS_LIST_TABLE_COLUMN.map((column) => {
                  let value = doc[column.id];
                  if (column.id === "status") {
                    value = DEVIATION_STATUS.find(
                      (status) => status.value === doc[column.id]
                    )?.label;
                  }

                  if (column.id === "deviationNumber") {
                    value = (
                      <Typography
                        className="pointer"
                        color="primary"
                        onClick={() =>
                          history.push(
                            LINKS.EDIT_DEVIATIONS.HREF.replace(
                              ":projectId",
                              projectId
                            ).replace(":id", doc._id)
                          )
                        }
                      >
                        {value}
                      </Typography>
                    );
                  }
                  if (column.id === "title") {
                    value = <Typography>{value}</Typography>;
                  }
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
