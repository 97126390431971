import { useSelector } from "react-redux";
import { PERMISSION_TYPES } from "utils/constants";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";

const useFeatureFlag = (feature) => {
  const accessedFeatures = useSelector(
    (state) => state?.modulePreference?.accessibleFeature
  );
  const currentUser = useSelector((state) => state.auth.currentUser);

  if(currentUser.permissions === PERMISSION_TYPES.superAdmin && feature!=="library"){
    return true
  }

  if (feature && Object.values(FEATURE_FLAGS).includes(feature) && !accessedFeatures?.includes(feature)) {
    return false;
  }
  return true;
};

export default useFeatureFlag;
