import React, { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import PageHeader from "parts/PageHeader";
import LINKS from "utils/constants/links";
import Loader from "components/Loader";
import { getProjects } from "redux/actions/projects";
import { round } from "utils/helpers/utility";

import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import {
  getInsights,
  getPhaseDetail,
  getPhases,
} from "../../../redux/actions/projects";
import { DashboardChart } from "pages/Dashboard/components/DashboardCard/components";
import InsightsPieChart from "./components/InsightsPieChart/InsightsPieChart";
import InsightsGanttChart from "./components/InsightsGanttChart/InsightsGanttChart";
import { Pie } from "react-chartjs-2";
import SystemChart from "./components/SystemChart/SystemChart";
import { chartColors, textColor } from "./constant";
import { green, grey } from "@material-ui/core/colors";
// import { Replay } from "@material-ui/icons";
import { ReactComponent as RefreshIcon } from "icons/refresh.svg";
import { IconButton } from "@material-ui/core";

const ChartWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -5px;
  text-align: center;
  z-index: 0;
`;

const ProjectInsight = () => {
  const { projectId } = useParams();

  const projects = useSelector((state) => state.projects);

  const project = projects.results.find((item) => item._id === projectId);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [phaseLoading, setPhaseLoading] = useState(false);

  const [phaseId, setPhaseId] = useState();
  const [selectedSystem, setSelectedSystem] = useState();
  const [refresh, setRefresh] = useState(true);
  const {
    insights,
    insights: { systems },
    phases: { metaSystems },
  } = useSelector((state) => state.projects);

  const {
    phaseDetail: { phaseData },
  } = useSelector((state) => state.projects.phases);

  const changePhaseId = (id) => {
    setPhaseId(id);
  };

  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (refresh) {
      setPhaseId("");
      setLoading(true);
      const fetchData = async () => {
        await dispatch(getInsights(projectId));
        await dispatch(getPhases(projectId));
      };
      fetchData();
      setLoading(false);
      setRefresh(false);
    }
  }, [dispatch, projectId, refresh]);

  useEffect(() => {
    if (phaseId !== undefined && phaseId !== "") {
      setPhaseLoading(true);
      const fetchData = async () => {
        await dispatch(getPhaseDetail(projectId, phaseId));
        setPhaseLoading(false);
      };
      fetchData();
    }
  }, [dispatch, phaseId, projectId]);

  useEffect(() => {
    setPhaseId(phaseData !== undefined ? phaseData[0]?._id : "");
  }, [phaseData]);
  useEffect(() => {
    setSelectedSystem(systems !== undefined ? systems[0] : "");
  }, [systems]);

  const getStatus = (metaSystems, data) => {
    let systemCount = 0;
    const status = metaSystems
      .filter(
        (system) => (system.projectPhase || system.autoPhaseId) === data._id
      )
      .reduce((accumulator, system) => {
        systemCount++;
        return accumulator + parseFloat(system.mainSystem.status);
      }, 0);

    return Math.round(round(status / systemCount, 2));
  };

  const pieChartData = {
    labels: phaseData?.map((data) => {
      return data.phaseName;
    }),
    datasets: [
      {
        data: phaseData?.map((data) => {
          const overallStatus = getStatus(metaSystems, data);
          return overallStatus;
        }),
        backgroundColor: phaseData?.map((data, index) => {
          return chartColors[index];
        }),

        borderWidth: 1,
      },
    ],
  };

  const ganttChartData = phaseData?.map((data, index) => {
    const overallStatus = getStatus(metaSystems, data);

    return {
      actualStart: data.startDate,
      actualEnd: data.endDate,
      start: data.startDate,
      end: data.endDate,
      status: overallStatus + "%",
      progress: overallStatus,
      phaseName: data.phaseName,
      id: data._id,
      styles: { progressColor: chartColors[index % 9] },
      type: "insight",
    };
  });

  const NAV_LINKS = [
    LINKS.PROJECT_MANAGEMENT,
    LINKS.PROJECTS,
    {
      HREF: LINKS.EDIT_PROJECT.HREF.replace(":id", projectId),
      TITLE: project?.name || "Not Found",
    },
  ];
  const childrenSystems = systems?.filter(
    (system) => system?.parentSystem === selectedSystem?._id
  );
  const systemData = {
    labels:
      childrenSystems?.length !== 0
        ? childrenSystems
            ?.map((system) => system?.status)
            ?.every((item) => item === 0)
          ? ["No Data Available"]
          : childrenSystems?.map((system) => system?.name)
        : [selectedSystem?.name],
    datasets: [
      {
        data:
          childrenSystems?.length !== 0
            ? childrenSystems
                ?.map((system) => system?.status)
                ?.every((item) => item === 0)
              ? [100]
              : childrenSystems?.map((system) => system?.status)
            : [selectedSystem?.status],
        backgroundColor:
          childrenSystems?.length !== 0
            ? childrenSystems
                ?.map((system) => system?.status)
                ?.every((item) => item === 0)
              ? [grey[200]]
              : childrenSystems?.map((system, index) => chartColors[index])
            : [green[500]],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <>
      <PageHeader title={` Insights`} links={NAV_LINKS} />
      <Grid container justify="space-between">
        <Grid item xs={1}>
          <Typography variant="h6">Dashboard</Typography>
        </Grid>
        <Grid item xs={4} style={{ display: "flex ", alignItems: "center" }}>
          <Grid container justify="flex-end">
            <Typography variant="button" color="textPrimary">
              {new Date().toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
              <IconButton
                size="small"
                onClick={() => {
                  setRefresh(true);
                }}
                style={{ marginLeft: "5px" }}
              >
                {/* <Replay /> */}
                <RefreshIcon />
              </IconButton>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        style={{ marginTop: "5px", overflowX: "none" }}
        className="d-jsb-c"
      >
        <Grid item xs={12} xl={3}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={12}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h6"
                    style={{ color: textColor, alignSelf: "right" }}
                  >
                    Project Status
                  </Typography>
                  <DashboardChart
                    fontSize={14}
                    data={{
                      height: 160,
                      width: 160,
                      type: "Project Status",
                      phases: ["Completed", "Remaining"],
                      status: insights?.projectDetail?.status,
                    }}
                  ></DashboardChart>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <Card style={{ height: "100%" }}>
                <CardContent style={{ paddingLeft: 30, paddingRight: 30 }}>
                  <Grid container spacing={6} style={{ marginTop: 5 }}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justify="space-between"
                        spacing={1}
                        style={{
                          borderBottom: "1px solid #EDEFF0",
                        }}
                      >
                        <Grid item xs={2}>
                          <Typography
                            style={{ color: textColor }}
                            variant="subtitle1"
                          >
                            {insights?.projectDetail?.totalSystems}
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Grid container justify="flex-end">
                            <Typography
                              style={{ color: textColor, alignSelf: "right" }}
                              variant="subtitle1"
                            >
                              Total Systems
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        style={{
                          borderBottom: "1px solid #EDEFF0",
                        }}
                        justify="space-between"
                        spacing={1}
                      >
                        <Grid item xs={2}>
                          <Typography
                            style={{ color: textColor }}
                            variant="subtitle1"
                          >
                            {insights?.projectDetail?.totalDeliverables}
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Grid container justify="flex-end">
                            <Typography
                              style={{ color: textColor }}
                              variant="subtitle1"
                            >
                              Total Deliverables
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify="space-between" spacing={1}>
                        <Grid item xs={3}>
                          <Typography
                            style={{ color: textColor }}
                            variant="subtitle1"
                          ></Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container justify="flex-end">
                            <Typography
                              style={{ color: textColor }}
                              variant="subtitle1"
                            >
                              Total Cost
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={9}>
          <Card style={{ height: "100%" }}>
            <CardContent>
              <Grid>
                <InsightsPieChart data={pieChartData} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <InsightsGanttChart
                chartData={ganttChartData || []}
                setPhase={changePhaseId}
              />
              <SystemChart loading={phaseLoading} phaseId={phaseId} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    style={{ color: "#6281B6", alignSelf: "right" }}
                    variant="h6"
                  >
                    System Status
                  </Typography>
                </Grid>
                <Grid item>
                  <select
                    style={{
                      border: "0px",
                      fontSize: "14px",
                      color: "#6281B6",
                      width: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    name="system"
                    id="system"
                    onChange={(e) => {
                      setSelectedSystem(
                        systems?.find((system) => system._id === e.target.value)
                      );
                    }}
                  >
                    {systems
                      ?.filter((system) => system.parentSystem === undefined)
                      ?.map((system) => {
                        return (
                          <option key={system._id} value={system._id}>
                            {system.name}
                          </option>
                        );
                      })}
                  </select>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <ChartWrapper>
                  <DoughnutInner variant="h4">
                    <Typography variant="h4">
                      {Math.round(selectedSystem?.status || 0)}%
                    </Typography>
                  </DoughnutInner>
                  <Pie
                    data={systemData}
                    height={250}
                    width={350}
                    options={{
                      maintainAspectRatio: false,
                      responsive: false,
                      cutout: "90",
                      plugins: {
                        legend: false,
                        datalabels: {
                          display: false,
                        },
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              let label = context.label || "";

                              if (label) {
                                label += ": ";
                              }
                              if (context.parsed !== null) {
                                if (
                                  context.label !==
                                  "No data available for children systems"
                                ) {
                                  label += context.parsed + " %";
                                }
                              }
                              return label;
                            },
                          },
                        },
                      },
                    }}
                  />
                </ChartWrapper>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {loading && <Loader />}
    </>
  );
};

export default memo(ProjectInsight);
