import { getJQ, del, post, patch, get } from "services/axios";

const getFolders = async (projectId, parent, category) => {
  return await getJQ(`v2/library/project/${projectId}`, {
    parent,
    category,
  });
};
const searchLibrary = async (projectId, searchQuery) => {
  return await getJQ(`v2/library/project/${projectId}/search`, {
    searchQuery,
  });
};
const createFolder = async (projectId, payload) => {
  return await post(`v2/library/project/${projectId}/folder`, payload);
};
const updateFolder = async (projectId, resourceId, payload) => {
  return await patch(
    `v2/library/project/${projectId}/folderOrFile/${resourceId}`,
    payload
  );
};
const addFile = async (projectId, payload) => {
  return await post(`v2/library/project/${projectId}/file`, payload);
};
const deleteFileFolder = async (projectId, resourceId, type) => {
  return await del(
    `v2/library/project/${projectId}/folderOrFile/${resourceId}`,
    { type }
  );
};
const viewFile = async (projectId,fileId) => {
  return await get(`v2/library/project/${projectId}/file/${fileId}`);
};

export {
  getFolders,
  createFolder,
  addFile,
  deleteFileFolder,
  updateFolder,
  searchLibrary,
  viewFile
};
