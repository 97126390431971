import PageHeader from "parts/PageHeader";
import { memo, useEffect, useRef, useState } from "react";
import LINKS from "utils/constants/links";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProjects } from "redux/actions/projects";
import { getDocumentSnapshot, shareSnapshot } from "services/api-project";
import { toJpeg, toBlob } from "html-to-image";
import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
} from "@material-ui/core";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import Loader from "components/Loader";
import { ReactComponent as FilterIcon } from "icons/filter.svg";
import { ReactComponent as DownloadIcon } from "icons/download.svg";
import { ReactComponent as ShareIcon } from "icons/share.svg";
import { ReactComponent as RefreshIcon } from "icons/refresh.svg";
import { DOCUMENT_SNAPSHOT_LEGENDS, ICON_KEY_OBJ } from "../../constant";
import { DocumentSnapshotTable } from "./components/DocumentSnapshotTable";
import { EditorState, RichUtils } from "draft-js";
import {
  DATE_SORT_DIRS,
  downloadDocumentSnapshotExcel,
  getDefaultSubject,
} from "./components/helper";
import SharePopupModel from "../PhaseSnapshot/components/SharePopupModel";
import SnapshotFilter, {
  FILTERS,
} from "../PhaseSnapshot/components/SnapshotFilter";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",
  },
  scrollPaper: {
    marginTop: "10px",
    width: "100%",
    overflow: "auto",
    maxHeight: "600px",
  },
  customTableHeaderCellStyle: {
    borderBottom: "none",
    zIndex: 1,
    // padding: "5px 20px",
    backgroundColor: "#E2ECF6",
    color: "black",
    fontWeight: "bold",
    fontSize: "0.9rem",
    position: "sticky",
    left: 0,
    top: 0,
  },
  systemNameText: {
    fontSize: "0.75rem",
    fontWeight: "600",
    color: "#1C1B1F",
    padding: 0,
    margin: 0,
  },
  customTableDelIconCellStyle: {
    backgroundColor: "white",
    border: "none",
    padding: "10px 10px",
    borderTop: "1px solid #C5C5C7",
    borderBottom: "1px solid #C5C5C7",
  },
  customTableDelIconEmptyCellStyle: {
    backgroundColor: "#F0F0F0",
    border: "none",
    // padding: "10px 0px",
    borderTop: "1px solid #C5C5C7",
    borderBottom: "1px solid #C5C5C7",
  },
  snapshotSubheaderRight: {
    borderRight: "1px solid #C5C5C7",
  },
  snapshotSubheaderLeft: {
    borderLeft: "1px solid #C5C5C7",
  },
  documentNumberText: {
    whiteSpace: "nowrap",
    fontSize: "0.75rem",
    fontWeight: "600",
    color: "#0953B9",
    padding: "0px 0px",
    margin: 0,
  },
  actualDocumentNumberText: {
    whiteSpace: "nowrap",
    fontSize: "0.625rem",
    fontWeight: "600",
    color: "#676869",
    padding: "0px 0px",
    margin: 0,
  },
  userText: {
    whiteSpace: "nowrap",
    fontSize: "0.75rem",
    color: "#000000",
    // padding: "1px 0px",
    margin: 0,
  },
  toolTipText: {
    textAlign: "center",
    fontSize: "0.75rem",
    padding: "5px 0px",
    margin: 0,
  },
});

const TABLE_MAP = [
  {
    tableHeadName: "Deliverable",
    keyName: "deliverableName",
  },
  {
    tableHeadName: "Document Number",
    keyName: "documentNumber",
    secondKeyName: "actualDocumentNumber",
  },
  {
    tableHeadName: "Document Title",
    keyName: "documentTitle",
  },
  {
    tableHeadName: "Review Due Date",
    keyName: "reviewDueDate",
  },
  {
    tableHeadName: "Approval Due Date",
    keyName: "approvalDueDate",
  },
  {
    tableHeadName: "Reviewers",
    keyName: "reviewers",
  },
  {
    tableHeadName: "Approvers",
    keyName: "approvers",
  },
  {
    tableHeadName: "Document Status",
    keyName: "documentStatus",
  },
];

const DocumentSnapshot = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);
  const project = projects.results.find((item) => item._id === projectId);
  const users = useSelector((state) => state.users.results);

  const [headerHeight, setHeaderHeight] = useState(0);
  const classes = useStyles(headerHeight);

  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [snapshotData, setSnapshotData] = useState([]);
  const [filteredSnapshotData, setFilteredSnapshotData] = useState([]);

  const [systemFilter, setSystemFilter] = useState([]);
  const [deliverableFilter, setDeliverableFilter] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElShare, setAnchorElShare] = useState(null);
  const downloadRef = useRef(null);

  const [openShareDetail, setOpenShareDetail] = useState(false);
  const [shareDetails, setShareDetails] = useState({
    to: [],
    subject: "",
    body: "",
    attachment: null,
    type: "",
  });

  const [order, setOrder] = useState(DATE_SORT_DIRS.ASC);
  const [orderBy, setOrderBy] = useState(null);

  const [error, setError] = useState({ to: false });
  const [editorStateBody, setEditorStateBody] = useState(
    EditorState.createEmpty()
  );

  const openShare = Boolean(anchorElShare);
  const open = Boolean(anchorEl);

  const projectUsers = users?.filter((user) =>
    project?.assignedUsers?.includes(user._id)
  );

  const NAV_LINKS = [
    LINKS.PROJECT_MANAGEMENT,
    LINKS.PROJECTS,
    {
      HREF: LINKS.EDIT_PROJECT.HREF.replace(":id", projectId),
      TITLE: project?.name || "Not Found",
    },
  ];

  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
  }, [dispatch, projectId]);

  useEffect(() => {
    const fetchDocumentSnapshot = async () => {
      setLoading(true);
      await getDocumentSnapshot(projectId).then((resp) => {
        setSnapshotData(resp);
        setFilteredSnapshotData(resp.groupedDocuments);
        let tempDeliverableNames = [];
        resp?.filters?.deliverables?.forEach((f) => {
          tempDeliverableNames.push(f.name);
        });

        setDeliverableFilter(
          [...new Set(tempDeliverableNames)]?.map((d) => ({
            name: d,
            selected: false,
          }))
        );

        setSystemFilter(
          resp?.filters?.systems?.map((s) => ({
            name: s.name,
            selected: false,
            id: s.systemId,
          }))
        );
      });
      setLoading(false);
    };
    fetchDocumentSnapshot();
  }, [projectId]);

  useEffect(() => {
    const systemList = systemFilter
      ?.filter((s) => s.selected)
      ?.map((m) => m.id);
    const deliverableList = deliverableFilter
      ?.filter((s) => s.selected)
      ?.map((m) => m.name);
    if (
      systemFilter?.some((s) => s.selected) ||
      deliverableFilter?.some((s) => s.selected)
    ) {
      setFilteredSnapshotData(
        snapshotData?.groupedDocuments
          ?.filter((f) => {
            if (
              systemFilter?.some((s) => s.selected) &&
              deliverableFilter?.some((s) => s.selected)
            ) {
              const documentsDeliverbales = f.documents?.filter(
                (doc) =>
                  deliverableList.includes(doc.deliverableName) &&
                  systemList.includes(doc.systemId)
              );
              return documentsDeliverbales?.length;
            } else if (systemFilter?.some((s) => s.selected)) {
              return systemList?.includes(f.systemId);
            } else if (deliverableFilter?.some((s) => s.selected)) {
              const documentsDeliverbales = f.documents?.filter((doc) =>
                deliverableList.includes(doc.deliverableName)
              );
              return documentsDeliverbales?.length;
            }
            return true;
          })
          ?.map((f) => {
            if (deliverableFilter?.some((s) => s.selected)) {
              if (deliverableList?.length) {
                const documentsDeliverbales = f.documents?.filter((doc) =>
                  deliverableList.includes(doc.deliverableName)
                );
                return { ...f, documents: documentsDeliverbales };
              }
            }
            return { ...f };
          })
      );
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  }, [systemFilter, deliverableFilter, snapshotData]);

  const noDataDiv = () => {
    return (
      <div
        className="d-jsb-cen w-100"
        style={{ height: "100px", backgroundColor: "white" }}
        ref={downloadRef}
      >
        No data available
      </div>
    );
  };

  const getFileName = () => {
    let date = new Date();
    return (
      project?.name +
      "_document_" +
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getFullYear().toString().slice(2) +
      "@" +
      date.getHours() +
      "_" +
      date.getMinutes()
    );
  };

  const handleDownload = () => {
    setAnchorEl(null);
    downloadDocumentSnapshotExcel(
      setIsDownloading,
      projectId,
      project,
      dispatch,
      {
        filter: {
          systems:
            systemFilter?.filter((s) => s.selected)?.map((m) => m.id) || [],
          deliverables:
            deliverableFilter?.filter((s) => s.selected)?.map((m) => m.name) ||
            [],
        },
        sortBy: {
          [orderBy]: order,
        },
      }
    );
  };

  const handleDownloadImage = () => {
    setAnchorEl(null);
    setIsDownloading(true);
    if (downloadRef.current === null) {
      setIsDownloading(false);
      return;
    }
    toJpeg(downloadRef.current, {
      cacheBust: true,
      width: downloadRef.current?.scrollWidth || 0,
      height: downloadRef.current?.scrollHeight || 0 + 25,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = getFileName();
        link.href = dataUrl;
        link.click();
        setIsDownloading(false);
      })
      .catch((err) => {
        setIsDownloading(false);
        console.log(err);
      });
  };

  const handleShareDetail = (data) => {
    setOpenShareDetail(data);
  };

  const handleShareCancel = () => {
    handleShareDetail(false);
    setEditorStateBody(EditorState.createEmpty());
    setShareDetails({
      to: [],
      subject: getDefaultSubject(project),
      body: "",
      attachment: null,
    });
  };

  const setImageBlob = async () => {
    setShareDetails({ ...shareDetails, type: "Image" });
    const imageBlob = await toBlob(downloadRef.current, {
      cacheBust: true,
      width: downloadRef.current.scrollWidth,
      height: downloadRef.current.scrollHeight + 25,
      type: "image/jpeg",
    });
    setShareDetails({ ...shareDetails, attachment: imageBlob, type: "Image" });
  };

  const handleShare = () => {
    const receivers = shareDetails.to.map((user) => {
      if (typeof user === "string") {
        return user;
      } else {
        return user.email;
      }
    });
    if (receivers.length === 0) {
      setError({ ...error, to: true });
      return;
    } else {
      setError({ ...error, to: false });
    }
    let fileName = getFileName() + ".jpeg";
    let data = new FormData();
    if (shareDetails.type === "Image") {
      data.append("file", new File([shareDetails?.attachment], fileName));
    }
    data.append("subject", shareDetails.subject);
    data.append("body", shareDetails.body);
    data.append("receivers", receivers);
    data.append("type", shareDetails.type);
    data.append("snapshotType", "document");
    data.append(
      "filters",
      JSON.stringify({
        systems:
          systemFilter?.filter((s) => s.selected)?.map((m) => m.id) || [],
        deliverables:
          deliverableFilter?.filter((s) => s.selected)?.map((m) => m.name) ||
          [],
      })
    );
    data.append(
      "sortBy",
      JSON.stringify({
        [orderBy]: order,
      })
    );
    data.append("projectId", projectId);
    setLoading(true);
    shareSnapshot(projectId, data)
      .then(() => {
        setLoading(false);
        handleShareCancel();
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: err?.response?.data?.info || "Error sharing snapshot",
          })
        );
      });
  };

  const handleKeyCommandBody = (command) => {
    const newState = RichUtils.handleKeyCommand(editorStateBody, command);
    if (newState) {
      setEditorStateBody(newState);
      return true;
    }
    return false;
  };

  const handleRefresh = async () => {
    setLoading(true);
    await getDocumentSnapshot(projectId).then((resp) => {
      setSnapshotData(resp);
      let tempDeliverableNames = [];
      resp?.filters?.deliverables?.forEach((f) => {
        tempDeliverableNames.push(f.name);
      });
      setDeliverableFilter(
        [...new Set(tempDeliverableNames)]?.map((d) => ({
          name: d,
          selected: false,
        }))
      );
      setSystemFilter(
        resp?.filters?.systems?.map((s) => ({
          name: s.name,
          selected: false,
          id: s.systemId,
        }))
      );
      setFilteredSnapshotData(resp.groupedDocuments);
      setIsFilter(false);
    });
    setLoading(false);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleClearFilter = () => {
    let tempDeliverableNames = [];
    snapshotData?.filters?.deliverables?.forEach((f) => {
      tempDeliverableNames.push(f.name);
    });
    setDeliverableFilter(
      [...new Set(tempDeliverableNames)]?.map((d) => ({
        name: d,
        selected: false,
      }))
    );
    setSystemFilter(
      snapshotData?.filters?.systems?.map((s) => ({
        name: s.name,
        selected: false,
        id: s.systemId,
      }))
    );
    setFilteredSnapshotData(snapshotData?.groupedDocuments);
    setIsFilter(false);
  };

  const handleFilterApply = (systemFilterList, deliverableFilterList) => {
    setSystemFilter(systemFilterList);
    setDeliverableFilter(deliverableFilterList);
    handleCloseFilter();
  };

  const handleSort = (property, sortOrder) => {
    const tempData = filteredSnapshotData.map((m) => {
      if (m.documents.length > 1) {
        const [validDatesArray, invalidDatesArray] = m.documents.reduce(
          ([valid, invalid], doc) => {
            if (doc[property]) {
              valid.push(doc);
            } else {
              invalid.push(doc);
            }
            return [valid, invalid];
          },
          [[], []]
        );

        const sortedDocs = validDatesArray.sort((a, b) => {
          const val1 = new Date(a[property]).valueOf();
          const val2 = new Date(b[property]).valueOf();
          return sortOrder === DATE_SORT_DIRS.ASC ? val1 - val2 : val2 - val1;
        });

        return { ...m, documents: [...invalidDatesArray, ...sortedDocs] };
      }
      return m;
    });

    const [validTempData, invalidTempData] = tempData.reduce(
      ([valid, invalid], item) => {
        if (item.documents.some((doc) => doc[property])) {
          valid.push(item);
        } else {
          invalid.push(item);
        }
        return [valid, invalid];
      },
      [[], []]
    );

    validTempData.sort((a, b) => {
      const val1 = new Date(
        a.documents.find((doc) => doc[property])[property]
      ).valueOf();
      const val2 = new Date(
        b.documents.find((doc) => doc[property])[property]
      ).valueOf();
      return sortOrder === DATE_SORT_DIRS.ASC ? val1 - val2 : val2 - val1;
    });

    setFilteredSnapshotData([...validTempData, ...invalidTempData]);
  };

  // const handleSort = (property, sortOrder) => {
  //   let tempData = _.cloneDeep(filteredSnapshotData);
  //   tempData = tempData?.map((m) => {
  //     if (m.documents.length > 1) {
  //       const validDatesArray = m.documents?.filter((f) => f[property]);
  //       const invalidDatesArray = m.documents?.filter((f) => !f[property]);
  //       const sortedDocs = validDatesArray?.sort((a, b) => {
  //         const val1 = a[property] && new Date(a[property]).valueOf();
  //         const val2 = b[property] && new Date(b[property]).valueOf();
  //         const comparison = val1 - val2;
  //         return sortOrder === DATE_SORT_DIRS.ASC ? comparison : -comparison;
  //       });
  //       return { ...m, documents: [...invalidDatesArray, ...sortedDocs] };
  //     }
  //     return m;
  //   });
  //   let validTempData = tempData?.filter((f) =>
  //     f.documents?.some((s) => s[property])
  //   );
  //   let invalidTempData = tempData?.filter(
  //     (f) => !f.documents?.some((s) => s[property])
  //   );
  //   validTempData = validTempData.sort((a, b) => {
  //     const validDatesArrayA = a.documents?.filter((f) => f[property]);
  //     const validDatesArrayB = b.documents?.filter((f) => f[property]);
  //     const val1 =
  //       validDatesArrayA[0][property] &&
  //       new Date(validDatesArrayA[0][property]).valueOf();
  //     const val2 =
  //       validDatesArrayB[0][property] &&
  //       new Date(validDatesArrayB[0][property]).valueOf();

  //     const comparison = val1 - val2;
  //     return sortOrder === DATE_SORT_DIRS.ASC ? comparison : -comparison;
  //   });
  //   setFilteredSnapshotData([...validTempData, ...invalidTempData]);
  // };

  const handleSortBy = (property) => {
    const isAsc = orderBy === property && order === DATE_SORT_DIRS.ASC;
    const nextOrder = isAsc ? DATE_SORT_DIRS.DESC : DATE_SORT_DIRS.ASC;
    setOrder(nextOrder);
    setOrderBy(property);
    handleSort(property, nextOrder);
  };

  return (
    <>
      <PageHeader title={LINKS.DOCUMENT_SNAPSHOT.TITLE} links={NAV_LINKS} />
      <Grid container>
        <Grid item xs={6} alignItems="center">
          <div className="d-flex jsb-l ">
            <IconButton
              disabled={loading}
              className="round-icon-button"
              onClick={() => setOpenFilter(true)}
            >
              <FilterIcon className="primary__icon" />
            </IconButton>

            {isFilter && (
              <div className="ml-10">
                <Chip
                  onClick={() => {
                    handleClearFilter();
                  }}
                  label="Clear"
                  className="border-r-20 chip"
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={6} container justify="flex-end">
          <IconButton
            size="small"
            className="ml-10 round-icon-button"
            variant="outlined"
            id="download-button"
            aria-controls={open ? "download-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            {isDownloading ? (
              <CircularProgress size={22} color="secondary" />
            ) : (
              <DownloadIcon className="primary__icon" />
            )}
          </IconButton>
          <Menu
            className="mt-50"
            id="download-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "download-button",
            }}
          >
            <MenuItem onClick={handleDownload}>Download as excel</MenuItem>
            <MenuItem onClick={handleDownloadImage}>Download as image</MenuItem>
          </Menu>

          <IconButton
            className="ml-10 round-icon-button"
            size="small"
            id="share-button"
            aria-controls={openShare ? "share-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openShare ? "true" : undefined}
            onClick={(event) => {
              setShareDetails({
                ...shareDetails,
                subject: getDefaultSubject(project),
              });
              setAnchorElShare(event.currentTarget);
            }}
          >
            <ShareIcon className="primary__icon" />
          </IconButton>
          <Menu
            className="mt-50"
            id="share-menu"
            anchorEl={anchorElShare}
            open={openShare}
            onClose={() => {
              setAnchorElShare(null);
            }}
            MenuListProps={{
              "aria-labelledby": "share-button",
            }}
          >
            <MenuItem
              onClick={() => {
                setAnchorElShare(null);
                setOpenShareDetail(true);
                setShareDetails({
                  ...shareDetails,
                  type: "Excel",
                });
              }}
            >
              Share as excel
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorElShare(null);
                setImageBlob();
                setOpenShareDetail(true);
              }}
            >
              Share as image
            </MenuItem>
          </Menu>

          <IconButton
            size="small"
            className="ml-10 round-icon-button"
            onClick={handleRefresh}
          >
            <RefreshIcon className="primary__icon" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid className="mt-20 mb-10">
          <div
            className="ai-fd-row"
            style={{
              gap: "2.5rem",
            }}
          >
            {DOCUMENT_SNAPSHOT_LEGENDS?.map((m) => (
              <div className="ai-fd-row">
                {m?.icon ? (
                  ICON_KEY_OBJ[m.icon]
                ) : (
                  <div
                    className="legend-indicator"
                    style={{
                      backgroundColor: m.color,
                    }}
                  ></div>
                )}
                <div className="fs-x-small ml-10">{m.label}</div>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>

      {!loading && (
        <Grid container>
          <Grid container>
            <Paper
              className={classes.scrollPaper}
              style={{
                borderRadius: "0px",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            >
              {!loading &&
                (filteredSnapshotData?.length === 0 ||
                  _.isEmpty(filteredSnapshotData)) &&
                noDataDiv()}
              {!loading &&
                filteredSnapshotData?.length !== 0 &&
                !_.isEmpty(filteredSnapshotData) && (
                  <div ref={downloadRef}>
                    <DocumentSnapshotTable
                      classes={classes}
                      snapshotData={filteredSnapshotData}
                      projectId={projectId}
                      tableMap={TABLE_MAP}
                      // newfilteredDeliverableObject={
                      //   newfilteredDeliverableObject
                      // }
                      // isRequired={isRequired}
                      // users={users}
                      // systemList={systemList}
                      setHeaderHeight={setHeaderHeight}
                      order={order}
                      setOrder={setOrder}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                      handleSortBy={handleSortBy}
                    />
                  </div>
                )}
            </Paper>
          </Grid>
        </Grid>
      )}

      <Modal open={openFilter} onClose={handleCloseFilter}>
        <SnapshotFilter
          activeFilters={[FILTERS.SYSTEM, FILTERS.DELIVERABLE]}
          showSaveFilter={false}
          handleCloseFilter={handleCloseFilter}
          handleFilterApply={handleFilterApply}
          handleClearFilter={handleClearFilter}
          systemFilter={systemFilter}
          deliverableFilter={deliverableFilter}
          projectId={projectId}
          project={project}
        />
      </Modal>

      <SharePopupModel
        openShareDetail={openShareDetail}
        handleShareDetail={handleShareDetail}
        handleShareCancel={handleShareCancel}
        handleShare={handleShare}
        handleDownloadImage={handleDownloadImage}
        handleDownload={handleDownload}
        projectUsers={projectUsers}
        shareDetails={shareDetails}
        setShareDetails={setShareDetails}
        handleKeyCommandBody={handleKeyCommandBody}
        editorStateBody={editorStateBody}
        setEditorStateBody={setEditorStateBody}
        project={project}
        error={error}
        setError={setError}
      />

      {loading && <Loader />}
    </>
  );
};

export default memo(DocumentSnapshot);
