import { updateDeliverable } from "redux/actions/metaSystem";
import { restrict } from "../../../../../../utils/helpers/utility";
import {
  exportProjectSnapShot,
  exportProjectSystemSnapShot,
  exportProjectDepartmentSnapShot,
} from "services/api-project";
import { saveAs } from "file-saver";
import moment from "moment";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";

export const updateComment = async (
  data,
  systemId,
  deliverableList,
  setDeliverableList,
  setInputLoading,
  dispatch
) => {
  setInputLoading(data);
  await dispatch(
    updateDeliverable({
      ...restrict(data, [
        "_id",
        "plannedHours",
        "workedHours",
        "start",
        "end",
        "completion",
        "status",
        "department",
        "activity",
        "resource",
        "approver",
        "reviewer",
        "executed",
        "comment",
      ]),
      mainId: systemId,
    })
  );
  let deliverables = [...deliverableList];
  let index = deliverables?.findIndex(
    (deliverable) => deliverable?._id === data?._id
  );
  deliverables[index].comment = data.comment;
  setDeliverableList(deliverables);
  setInputLoading({});
};

export const generateExcelDownloadData = (
  departments,
  filteredDeliverableObject,
  getDepartmentUserList,
  users,
  setCSVData
) => {
  let body = [];

  body.push([
    "Systems",
    "Deliverables",
    ...departments?.map((department) => department.label),
  ]);

  const data = Object.keys(filteredDeliverableObject).map((key) => {
    return filteredDeliverableObject[key].map((deliverable, index) => [
      Math.round(filteredDeliverableObject[key].length / 2 - 1) === index
        ? key
        : "",
      deliverable.name,
      ...departments.map((department) => {
        const { filteredApprover, filteredReviewer } = getDepartmentUserList(
          department._id,
          deliverable.reviewer,
          deliverable.approver
        );
        return deliverable.status >= 50 &&
          (filteredReviewer !== undefined || filteredApprover !== undefined)
          ? filteredReviewer?.filter((user) => user?.approved === true)
              ?.length !== filteredReviewer?.length
            ? filteredReviewer
                ?.map((val, index) => {
                  return index === 0
                    ? `Review Pending:\n${
                        users.find((user) => user._id === val.userid)?.name ||
                        ""
                      }`
                    : users.find((user) => user._id === val.userid)?.name || "";
                })
                ?.join("\n")
            : filteredApprover?.filter((user) => user?.approved === true)
                ?.length !== filteredApprover?.length
            ? filteredApprover
                ?.map((val, index) => {
                  return index === 0
                    ? `ApprovalPending:\n${
                        users.find((user) => user._id === val.userid)?.name ||
                        ""
                      }`
                    : users.find((user) => user._id === val.userid)?.name || "";
                })
                ?.join("\n")
            : filteredReviewer?.filter((user) => user?.approved === true)
                ?.length === filteredReviewer?.length &&
              filteredApprover?.length === undefined
            ? "Reviewed"
            : filteredApprover?.filter((user) => user?.approved === true)
                ?.length === filteredApprover?.length
            ? "Approved"
            : ""
          : "";
      }),
    ]);
  });
  data.forEach((val) => {
    val.forEach((subVal) => {
      body.push(subVal);
    });
  });
  setCSVData(body);
};

export const downloadExcel = async (
  setIsDownloading,
  selectedPhases,
  projectId,
  deliverableFilter,
  systemFilter,
  statusFilter,
  project,
  dispatch
) => {
  try {
    setIsDownloading(true);
    const phaseIds = [...selectedPhases.map((s) => s.value)];
    const response = await exportProjectSnapShot(projectId, {
      phase: {
        phaseIds: phaseIds,
      },
      filters: {
        deliverable: deliverableFilter
          .filter((deliverable) => deliverable.selected)
          ?.map((deliverable) => deliverable.name),
        system: systemFilter
          .filter((system) => system.selected)
          ?.map((system) => system.id),
        status: statusFilter
          .filter((sta) => sta.selected)
          ?.map((sta) => sta.name),
      },
    });
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${project.name}_snapshot_${moment().format("MMM DD@HH:mm")}`);
  } catch (error) {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.error,
        popupText: "Failed to export file.",
      })
    );
  } finally {
    setIsDownloading(false);
  }
};

export const downloadDepartmentSnapshotExcel = async (
  setIsDownloading,
  selectedPhases,
  projectId,
  deliverableFilter,
  systemFilter,
  statusFilter,
  project,
  dispatch
) => {
  try {
    setIsDownloading(true);
    const phaseIds = [...selectedPhases.map((s) => s.value)];
    const response = await exportProjectDepartmentSnapShot(projectId, {
      phase: {
        phaseIds: phaseIds,
      },
      filters: {
        deliverable: deliverableFilter
          .filter((deliverable) => deliverable.selected)
          ?.map((deliverable) => deliverable.name),
        system: systemFilter
          .filter((system) => system.selected)
          ?.map((system) => system.id),
        status: statusFilter
          .filter((sta) => sta.selected)
          ?.map((sta) => sta.name),
      },
    });
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${project.name}_snapshot_${moment().format("MMM DD@HH:mm")}`);
  } catch (error) {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.error,
        popupText: "Failed to export file.",
      })
    );
  } finally {
    setIsDownloading(false);
  }
};

export const downloadExcelSystemSnapshot = async (
  setIsDownloading,
  projectId,
  phaseList,
  systemList,
  filteredSystemList,
  project,
  dispatch
) => {
  try {
    setIsDownloading(true);
    const response = await exportProjectSystemSnapShot(projectId, {
      filters: {
        phasesList: phaseList
          .filter((phase) => phase?.selected)
          ?.map((phase) => phase?.name),
        systemsList: systemList
          .filter((system) => system?.selected)
          ?.map((system) => system?.name),
        systemStatusList: filteredSystemList,
      },
    });
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(
      blob,
      `${project.name}_system_snapshot_${moment().format("MMM DD@HH:mm")}`
    );
  } catch (error) {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.error,
        popupText: "Failed to export file.",
      })
    );
  } finally {
    setIsDownloading(false);
  }
};
