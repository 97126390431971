import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableBody,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as CheckCircleFilledIcon } from "icons/check_circle_filled.svg";
import { ReactComponent as CheckCircleOutlineIcon } from "icons/check_circle_outline.svg";
import { ReactComponent as DraftIcon } from "icons/draft_icon.svg";
import { ReactComponent as ClockIcon } from "icons/clock.svg";
import { Fragment, useEffect, useRef } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LINKS from "utils/constants/links";
import { useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import useFeatureFlag from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import moment from "moment";
import {
  colorRA,
  getFormattedDate,
  // getFormattedDate
} from "../snapshotHelper";
import { isEmpty } from "utils/helpers/utility";
import { getColoredIconComponent } from "pages/Projects/ProjectInsight/constant";

export const CustomTableCell = withStyles({
  root: {
    borderBottom: "none",
    boxShadow: "inset 0px 0px 0px 0.25px grey",
    zIndex: 1,
    padding: "1px",
    backgroundColor: "white",
  },
})(TableCell);

export const CustomTableSubHeaderCell = withStyles({
  root: {
    borderBottom: "none",
    zIndex: 1,
    padding: "1px",
    backgroundColor: "red",
  },
})(TableCell);

export const CustomTableHeaderCell = withStyles({
  root: {
    borderBottom: "none",
    // boxShadow: "inset 0px 0px 0px 0.25px grey",
    zIndex: 1,
    padding: "15px 0px",
    backgroundColor: "#E2ECF6",
    color: "black",
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
})(TableCell);

const getPendingIcon = (color) => {
  return (
    <ClockIcon
      className={`${
        color === "red"
          ? "red__icon"
          : color === "#5E5E5E"
          ? "grey__icon"
          : "grey__icon"
      }`}
    />
  );
};

const getDefaultFragment = (key) => {
  return <Fragment key={key}></Fragment>;
};

const userDiv = (color, value) => {
  return (
    <p style={{ color: color }} className="snapshot-user-text">
      {value}
    </p>
  );
};

// const getExeceutionColor = (completion, end, status) => {
//   let today = new Date();
//   today = today.toISOString();
//   return getFormattedDate(today) > getFormattedDate(end) &&
//     !completion &&
//     Math.round(status) < 100
//     ? "red"
//     : completion
//     ? getFormattedDate(end) >= getFormattedDate(completion) &&
//       Math.round(status) === 100
//       ? "#27AE60"
//       : getFormattedDate(end) < getFormattedDate(completion) &&
//         Math.round(status) === 100
//       ? "#F39C12"
//       : "primary"
//     : "primary";
// };

const getCheckbox = (color) => {
  return getColoredIconComponent("check_circle_filled", color);
};

const getApproverReviewerDiv = (key, val) => {
  return (
    <p key={key}>
      {val}
      <br />
    </p>
  );
};

const checkRejection = (approvers) => {
  let list = approvers?.map((approver) => {
    return approver?.users?.map((user) => {
      return user.rejected || false;
    });
  });
  let flatList = [].concat(...(list || []));

  return flatList.includes(true);
};

const getRejectedUsers = (approvers, color) => {
  const rejectedUsers = []
    .concat(
      ...(approvers?.map((approver) => {
        return approver?.users?.map((user) => {
          return user.rejected ? user : null;
        });
      }) || [])
    )
    .filter((data) => data !== null);
  return (
    <Tooltip
      title={
        <div>
          {rejectedUsers?.map((val) => {
            return getApproverReviewerDiv("approver" + val?.userid, val?.name);
          })}
        </div>
      }
    >
      <Close style={{ color: color }} />
    </Tooltip>
  );
};

const getUserBorderBottom = () => {
  return <div className="snapshot-user-border"></div>;
};

export const DeliverableSnapshotTable = ({
  classes,
  deliverableMap,
  filteredDeliverableObject,
  setHeaderHeight,
  newfilteredDeliverableObject,
}) => {
  const metaSystems = useSelector((state) => state.projects?.metaSystems || []);
  const { projectId } = useParams();
  const ref = useRef();
  const systemFeatureFlag = useFeatureFlag(FEATURE_FLAGS.Systems);

  useEffect(() => {
    setHeaderHeight(ref.current.clientHeight - 0.5);
    // eslint-disable-next-line
  }, [ref, deliverableMap, filteredDeliverableObject]);

  const handleSystemRedirect = (name) => {
    const filteredMetasystem = metaSystems.find((val) => val.name === name);
    const link = LINKS.EDIT_META_SYSTEM.HREF.replace(":projectId", projectId)
      .replace(":systemId", filteredMetasystem?._id)
      .replace(":mainSystemId", filteredMetasystem?.mainSystem?._id);
    window.open(link, "_blank");
  };

  return (
    <TableContainer
      style={{
        display: "table",
        tableLayout: "fixed",
      }}
    >
      <Table
        className={classes.table}
        aria-label="simple table"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <TableHead>
          <TableRow ref={ref}>
            <TableCell
              align="center"
              className={`${classes.customTableHeaderCellStyle} snapshotheader-left-border-radius`}
              style={{ zIndex: 3 }}
              colSpan={4}
              key={"systemcell"}
            >
              Master
            </TableCell>
            <TableCell
              align="center"
              className={classes.customTableHeaderCellStyle}
              style={{ zIndex: 3 }}
              colSpan={4}
              key={"systemcell"}
            >
              Systems
            </TableCell>
            {deliverableMap?.map((deliverable, index) => {
              return (
                <TableCell
                  style={{ whiteSpace: "nowrap" }}
                  align="center"
                  colSpan={28}
                  className={`${classes.customTableHeaderCellStyle} ${
                    index === deliverableMap?.length - 1
                      ? "snapshotheader-right-border-radius"
                      : ""
                  }`}
                  key={deliverable?.name + "cell"}
                >
                  {deliverable.name}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={4}
              style={{
                zIndex: 3,
              }}
              className={[
                classes.stickyTop1,
                classes.customTableSubHeaderCellStyle,
                classes.snapshotSubheaderRight,
              ]}
              key={"emptycell"}
            ></TableCell>
            <TableCell
              colSpan={4}
              style={{
                zIndex: 3,
              }}
              className={[
                classes.stickyTop1,
                classes.customTableSubHeaderCellStyle,
                classes.snapshotSubheaderRight,
                classes.snapshotSubheaderLeft,
              ]}
              key={"emptycell"}
            ></TableCell>

            {deliverableMap?.map((deliverable, index) => {
              return (
                <Fragment key={"ifrrevapr" + deliverable.name}>
                  <TableCell
                    colSpan={4}
                    align="center"
                    className={[
                      classes.stickyTop1,
                      classes.customTableSubHeaderCellStyle,
                      classes.cellWidth,
                    ]}
                    key={deliverable.name + "IFR"}
                  >
                    Draft
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    align="center"
                    className={[
                      classes.stickyTop1,
                      classes.customTableSubHeaderCellStyle,
                      classes.cellWidth,
                    ]}
                    key={deliverable.name + "Review"}
                  >
                    IFR
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    align="center"
                    className={[
                      classes.stickyTop1,
                      classes.customTableSubHeaderCellStyle,
                      classes.cellWidth,
                    ]}
                    key={deliverable.name + "Review"}
                  >
                    R
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    align="center"
                    className={[
                      classes.stickyTop1,
                      classes.customTableSubHeaderCellStyle,
                      classes.cellWidth,
                    ]}
                    key={deliverable.name + "IFA"}
                  >
                    IFA
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    align="center"
                    className={[
                      classes.stickyTop1,
                      classes.customTableSubHeaderCellStyle,
                      classes.cellWidth,
                    ]}
                    key={deliverable.name + "Approval"}
                  >
                    A
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    align="center"
                    className={[
                      classes.stickyTop1,
                      classes.customTableSubHeaderCellStyle,
                      classes.cellWidth,
                    ]}
                    key={deliverable.name + "Master"}
                  >
                    Signed
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    align="center"
                    className={[
                      classes.stickyTop1,
                      classes.customTableSubHeaderCellStyle,
                      classes.snapshotSubheaderRight,
                      classes.cellWidth,
                    ]}
                    key={deliverable.name + "Execution"}
                  >
                    Exec
                  </TableCell>
                </Fragment>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {newfilteredDeliverableObject?.map((row) => {
            return (
              <>
                {row.systems?.map((sys, sysIndex) => {
                  return (
                    <TableRow>
                      {sysIndex === 0 && (
                        <TableCell
                          colSpan={4}
                          rowSpan={row.systems.length}
                          align="center"
                          className={[
                            classes.sticky,
                            classes.customTableSystemInitialCellStyle,
                            classes.snapshotSubheaderRight,
                            classes.systemNameText,
                          ]}
                          style={{ whiteSpace: "nowrap" }}
                          key={row + "cell"}
                        >
                          {row.name}
                        </TableCell>
                      )}
                      <TableCell
                        component="th"
                        scope="row"
                        colSpan={4}
                        align="center"
                        style={{ whiteSpace: "nowrap", zIndex: 2 }}
                        className={[
                          classes.sticky,
                          classes.customTableSystemInitialCellStyle,
                          classes.snapshotSubheaderRight,
                        ]}
                      >
                        <div
                          className={`${
                            systemFeatureFlag && classes.systemNameText
                          } pointer`}
                          onClick={() => {
                            systemFeatureFlag &&
                              handleSystemRedirect(sys?.name);
                          }}
                        >
                          {sys.name}
                        </div>
                      </TableCell>
                      {sys.deliverables.map((deliverable, deliverableIndex) => {
                        let approveIndex = 0;
                        let reviewIndex = 0;
                        return deliverable ? (
                          <>
                            {deliverable?.type !== "EXECUTION" ? (
                              <>
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconCellStyle,
                                    classes.snapshotSubheaderLeft,
                                    classes.snapshotDelIconRight,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellDraft"
                                  }
                                >
                                  {Number(deliverable?.draft?.status || 0) ===
                                  100 ? (
                                    <Tooltip
                                      title={`Draft - ${parseInt(
                                        deliverable?.draft?.status || 0
                                      )}%`}
                                    >
                                      {getCheckbox(
                                        colorRA(
                                          deliverable?.draft?.completion,
                                          deliverable?.draft?.end,
                                          Number(
                                            deliverable?.draft?.status || 0
                                          )
                                        )
                                      )}
                                    </Tooltip>
                                  ) : Number(deliverable?.draft?.status || 0) >
                                      0 &&
                                    Number(deliverable?.draft?.status || 0) <
                                      100 ? (
                                    <Tooltip
                                      title={`Draft - ${parseInt(
                                        deliverable?.draft?.status || 0
                                      )}%`}
                                    >
                                      <DraftIcon
                                        className={`${
                                          getFormattedDate(
                                            deliverable?.draft?.completion ||
                                              moment()?.format(
                                                "YYYY-MM-DD[T00:00:00.000Z]"
                                              )
                                          ) >
                                          getFormattedDate(
                                            deliverable?.draft?.end ||
                                              deliverable?.end
                                          )
                                            ? "red__icon"
                                            : "grey__icon"
                                        }`}
                                      />
                                    </Tooltip>
                                  ) : Number(
                                      deliverable?.draft?.status || 0
                                    ) === 0 ? (
                                    <Tooltip
                                      title={`Draft - ${parseInt(
                                        deliverable?.draft?.status || 0
                                      )}%`}
                                    >
                                      {getPendingIcon(
                                        getFormattedDate(
                                          deliverable?.draft?.completion ||
                                            moment()?.format(
                                              "YYYY-MM-DD[T00:00:00.000Z]"
                                            )
                                        ) >
                                          getFormattedDate(
                                            deliverable?.draft?.end ||
                                              deliverable?.end
                                          )
                                          ? "red"
                                          : ""
                                      )}
                                    </Tooltip>
                                  ) : null}
                                </TableCell>
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconCellStyle,
                                    classes.snapshotDelIconRight,
                                    classes.snapshotDelIconLeft,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellIFR"
                                  }
                                >
                                  <Tooltip
                                    title={`IFR - ${parseInt(
                                      deliverable?.ifr?.status || 0
                                    )}%`}
                                  >
                                    {deliverable.ifr.issued
                                      ? getCheckbox(deliverable?.colorIFR)
                                      : getPendingIcon(
                                          getFormattedDate(
                                            deliverable?.ifr?.completion ||
                                              moment()?.format(
                                                "YYYY-MM-DD[T00:00:00.000Z]"
                                              )
                                          ) >
                                            getFormattedDate(
                                              deliverable?.ifr?.end ||
                                                deliverable?.end
                                            )
                                            ? "red"
                                            : ""
                                        )}
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconCellStyle,
                                    classes.snapshotDelIconRight,
                                    classes.snapshotDelIconLeft,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellReview"
                                  }
                                >
                                  {/* <Tooltip
                                    title={`IFR - ${parseInt(
                                      deliverable?.review?.status || 0
                                    )}%`}
                                  > */}
                                  {deliverable.reviewer.length !== 0 ? (
                                    Math.round(
                                      deliverable.review.status || 0
                                    ) === 100 ? (
                                      <Tooltip
                                        title={`Review - ${parseInt(
                                          deliverable?.review?.status || 0
                                        )}%`}
                                      >
                                        {getCheckbox(deliverable?.colorReview)}
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title={`Review - ${parseInt(
                                          deliverable?.review?.status || 0
                                        )}%`}
                                      >
                                        <div>
                                          {deliverable.reviewer?.map((val) => {
                                            return val?.users?.map(
                                              (userVal) => {
                                                if (!userVal.approved) {
                                                  reviewIndex++;
                                                  if (userVal?.name) {
                                                    return (
                                                      <Fragment
                                                        key={
                                                          "reviewer" +
                                                          userVal.userid
                                                        }
                                                      >
                                                        {userDiv(
                                                          deliverable?.colorReview,
                                                          userVal?.name
                                                        )}
                                                        {deliverable.reviewUser !==
                                                          reviewIndex &&
                                                          deliverable.reviewUser !==
                                                            1 &&
                                                          getUserBorderBottom()}
                                                      </Fragment>
                                                    );
                                                  } else {
                                                    return getDefaultFragment(
                                                      "reviewer" +
                                                        userVal.userid
                                                    );
                                                  }
                                                } else {
                                                  return getDefaultFragment(
                                                    "reviewer" + userVal.userid
                                                  );
                                                }
                                              }
                                            );
                                          })}
                                        </div>
                                      </Tooltip>
                                    )
                                  ) : Math.round(deliverable.review.status) !==
                                    100 ? (
                                    <Tooltip
                                      title={`Review - ${parseInt(
                                        deliverable?.review?.status || 0
                                      )}%`}
                                    >
                                      {getPendingIcon(
                                        getFormattedDate(
                                          deliverable?.review?.completion ||
                                            moment()?.format(
                                              "YYYY-MM-DD[T00:00:00.000Z]"
                                            )
                                        ) >
                                          getFormattedDate(
                                            deliverable?.review?.end ||
                                              deliverable?.end
                                          )
                                          ? "red"
                                          : ""
                                      )}
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={`Review - ${parseInt(
                                        deliverable?.review?.status || 0
                                      )}%`}
                                    >
                                      {getCheckbox(deliverable?.colorReview)}
                                    </Tooltip>
                                  )}
                                  {/* </Tooltip> */}
                                </TableCell>
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconCellStyle,
                                    classes.snapshotDelIconRight,
                                    classes.snapshotDelIconLeft,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellIFA"
                                  }
                                >
                                  <Tooltip
                                    title={`IFA - ${parseInt(
                                      deliverable?.ifa?.status || 0
                                    )}%`}
                                  >
                                    {deliverable.ifa.issued
                                      ? getCheckbox(deliverable?.colorIFA)
                                      : getPendingIcon(
                                          getFormattedDate(
                                            deliverable?.ifa?.completion ||
                                              moment()?.format(
                                                "YYYY-MM-DD[T00:00:00.000Z]"
                                              )
                                          ) >
                                            getFormattedDate(
                                              deliverable?.ifa?.end ||
                                                deliverable?.end
                                            )
                                            ? "red"
                                            : ""
                                        )}
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconCellStyle,
                                    classes.snapshotDelIconRight,
                                    classes.snapshotDelIconLeft,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellApprove"
                                  }
                                >
                                  {/* <Tooltip
                                    title={`IFR - ${parseInt(
                                      deliverable?.ifa?.status || 0
                                    )}%`}
                                  > */}
                                  {deliverable.approver.length !== 0 ? (
                                    Math.round(
                                      deliverable.approval.status || 0
                                    ) === 100 ? (
                                      <Tooltip
                                        title={`Approve - ${parseInt(
                                          deliverable?.approval?.status || 0
                                        )}%`}
                                      >
                                        {getCheckbox(
                                          deliverable?.colorApproval
                                        )}
                                      </Tooltip>
                                    ) : checkRejection(deliverable.approver) ? (
                                      getRejectedUsers(
                                        deliverable.approver,
                                        deliverable.colorApproval
                                      )
                                    ) : (
                                      <Tooltip
                                        title={`Approve - ${parseInt(
                                          deliverable?.approval?.status || 0
                                        )}%`}
                                      >
                                        <div>
                                          {deliverable.approver?.map((val) => {
                                            return val?.users?.map(
                                              (userVal) => {
                                                if (!userVal.approved) {
                                                  approveIndex++;
                                                  if (userVal?.name) {
                                                    return (
                                                      <Fragment
                                                        key={
                                                          "approver" +
                                                          userVal.userid
                                                        }
                                                      >
                                                        {userDiv(
                                                          deliverable?.colorApproval,
                                                          userVal?.name
                                                        )}
                                                        {deliverable.approve !==
                                                          approveIndex &&
                                                          deliverable.approve !==
                                                            1 &&
                                                          getUserBorderBottom()}
                                                      </Fragment>
                                                    );
                                                  } else {
                                                    return getDefaultFragment(
                                                      "approver" +
                                                        userVal.userid
                                                    );
                                                  }
                                                } else {
                                                  return getDefaultFragment(
                                                    "approver" + userVal.userid
                                                  );
                                                }
                                              }
                                            );
                                          })}
                                        </div>
                                      </Tooltip>
                                    )
                                  ) : Math.round(
                                      deliverable.approval.status
                                    ) !== 100 ? (
                                    <Tooltip
                                      title={`Approve - ${parseInt(
                                        deliverable?.approval?.status || 0
                                      )}%`}
                                    >
                                      {getPendingIcon(
                                        getFormattedDate(
                                          deliverable?.approval?.completion ||
                                            moment()?.format(
                                              "YYYY-MM-DD[T00:00:00.000Z]"
                                            )
                                        ) >
                                          getFormattedDate(
                                            deliverable?.approval?.end ||
                                              deliverable?.end
                                          )
                                          ? "red"
                                          : ""
                                      )}
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={`Approve - ${parseInt(
                                        deliverable?.approval?.status || 0
                                      )}%`}
                                    >
                                      {getCheckbox(deliverable?.colorApproval)}
                                    </Tooltip>
                                  )}
                                  {/* </Tooltip> */}
                                </TableCell>
                                {deliverable?.master ? (
                                  <TableCell
                                    colSpan={4}
                                    align="center"
                                    className={[
                                      classes.customTableDelIconCellStyle,
                                      classes.snapshotDelIconRight,
                                      classes.snapshotDelIconLeft,
                                    ]}
                                    style={{ whiteSpace: "nowrap" }}
                                    key={
                                      row?.name +
                                      sys?.name +
                                      deliverable.name +
                                      "cellSigned"
                                    }
                                  >
                                    <Tooltip
                                      title={`Signed - ${parseInt(
                                        deliverable?.approval?.status || 0
                                      )}%`}
                                    >
                                      {isEmpty(deliverable?.masterFile)
                                        ? getPendingIcon(
                                            getFormattedDate(
                                              deliverable?.approval
                                                ?.completion ||
                                                moment()?.format(
                                                  "YYYY-MM-DD[T00:00:00.000Z]"
                                                )
                                            ) >
                                              getFormattedDate(
                                                deliverable?.approval?.end ||
                                                  deliverable?.end
                                              )
                                              ? "red"
                                              : ""
                                          )
                                        : getCheckbox(deliverable?.colorMaster)}
                                    </Tooltip>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    className={[
                                      classes.customTableDelIconEmptyCellStyle,
                                      classes.snapshotDelIconRight,
                                      classes.snapshotDelIconLeft,
                                    ]}
                                    colSpan={4}
                                    align="center"
                                    key={
                                      row?.name +
                                      sys?.name +
                                      deliverable.name +
                                      "cellSigned"
                                    }
                                  ></TableCell>
                                )}
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconEmptyCellStyle,
                                    classes.snapshotSubheaderRight,
                                    classes.snapshotDelIconLeft,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellExecution"
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconEmptyCellStyle,
                                    classes.snapshotSubheaderLeft,
                                    classes.snapshotDelIconRight,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellDraft"
                                  }
                                />
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconEmptyCellStyle,
                                    classes.snapshotDelIconRight,
                                    classes.snapshotDelIconLeft,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellIFR"
                                  }
                                />
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconEmptyCellStyle,
                                    classes.snapshotDelIconRight,
                                    classes.snapshotDelIconLeft,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellReview"
                                  }
                                />
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconEmptyCellStyle,
                                    classes.snapshotDelIconRight,
                                    classes.snapshotDelIconLeft,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellIFA"
                                  }
                                />
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconEmptyCellStyle,
                                    classes.snapshotDelIconRight,
                                    classes.snapshotDelIconLeft,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellApprove"
                                  }
                                />
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableDelIconEmptyCellStyle,
                                    classes.snapshotDelIconRight,
                                    classes.snapshotDelIconLeft,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellMaster"
                                  }
                                />
                                <TableCell
                                  colSpan={4}
                                  align="center"
                                  className={[
                                    classes.customTableSystemInitialCellStyle,
                                    classes.snapshotSubheaderRight,
                                    classes.systemNameText,
                                  ]}
                                  style={{ whiteSpace: "nowrap" }}
                                  key={
                                    row?.name +
                                    sys?.name +
                                    deliverable.name +
                                    "cellExecution"
                                  }
                                >
                                  {deliverable.status === 0 && (
                                    <Tooltip
                                      key={
                                        row?.name +
                                        "cellExecutionPendingIconTooltip"
                                      }
                                      title={
                                        Math.round(deliverable.status || 0) +
                                        "%"
                                      }
                                    >
                                      <ClockIcon
                                        className="grey__icon"
                                        key={
                                          row?.name + "cellExecutionPendingIcon"
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                  {deliverable.status === 100 && (
                                    <Tooltip
                                      key={
                                        row?.name + "cellExecutionCheckTooltip"
                                      }
                                      title={
                                        Math.round(deliverable.status || 0) +
                                        "%"
                                      }
                                    >
                                      <CheckCircleFilledIcon
                                        key={row?.name + "cellExecutionCheck"}
                                        className="green__icon"
                                      />
                                    </Tooltip>
                                  )}
                                  {deliverable.status < 100 &&
                                    deliverable.status > 0 && (
                                      <Tooltip
                                        key={
                                          row?.name +
                                          "cellExecutionCheckTooltip"
                                        }
                                        title={
                                          Math.round(deliverable.status || 0) +
                                          "%"
                                        }
                                      >
                                        <CheckCircleOutlineIcon
                                          key={row?.name + "cellExecutionCheck"}
                                          className="green__icon"
                                        />
                                      </Tooltip>
                                    )}
                                </TableCell>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <TableCell
                              colSpan={4}
                              align="center"
                              className={[
                                classes.customTableDelIconEmptyCellStyle,
                                classes.snapshotSubheaderLeft,
                                classes.snapshotDelIconRight,
                              ]}
                              style={{ whiteSpace: "nowrap" }}
                              key={
                                row?.name +
                                sys?.name +
                                deliverableIndex +
                                "cellDraft"
                              }
                            />
                            <TableCell
                              colSpan={4}
                              align="center"
                              className={[
                                classes.customTableDelIconEmptyCellStyle,
                                classes.snapshotDelIconRight,
                                classes.snapshotDelIconLeft,
                              ]}
                              style={{ whiteSpace: "nowrap" }}
                              key={
                                row?.name +
                                sys?.name +
                                deliverableIndex +
                                "cellIFR"
                              }
                            />
                            <TableCell
                              colSpan={4}
                              align="center"
                              className={[
                                classes.customTableDelIconEmptyCellStyle,
                                classes.snapshotDelIconRight,
                                classes.snapshotDelIconLeft,
                              ]}
                              style={{ whiteSpace: "nowrap" }}
                              key={
                                row?.name +
                                sys?.name +
                                deliverableIndex +
                                "cellReview"
                              }
                            />
                            <TableCell
                              colSpan={4}
                              align="center"
                              className={[
                                classes.customTableDelIconEmptyCellStyle,
                                classes.snapshotDelIconRight,
                                classes.snapshotDelIconLeft,
                              ]}
                              style={{ whiteSpace: "nowrap" }}
                              key={
                                row?.name +
                                sys?.name +
                                deliverableIndex +
                                "cellIFA"
                              }
                            />
                            <TableCell
                              colSpan={4}
                              align="center"
                              className={[
                                classes.customTableDelIconEmptyCellStyle,
                                classes.snapshotDelIconRight,
                                classes.snapshotDelIconLeft,
                              ]}
                              style={{ whiteSpace: "nowrap" }}
                              key={
                                row?.name +
                                sys?.name +
                                deliverableIndex +
                                "cellApprove"
                              }
                            />
                            <TableCell
                              colSpan={4}
                              align="center"
                              className={[
                                classes.customTableDelIconEmptyCellStyle,
                                classes.snapshotDelIconRight,
                                classes.snapshotDelIconLeft,
                              ]}
                              style={{ whiteSpace: "nowrap" }}
                              key={
                                row?.name +
                                sys?.name +
                                deliverableIndex +
                                "cellMaster"
                              }
                            />
                            <TableCell
                              colSpan={4}
                              align="center"
                              className={[
                                classes.customTableDelIconEmptyCellStyle,
                                classes.snapshotSubheaderRight,
                                classes.snapshotDelIconLeft,
                              ]}
                              style={{ whiteSpace: "nowrap" }}
                              key={
                                row?.name +
                                sys?.name +
                                deliverableIndex +
                                "cellExecution"
                              }
                            />
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
