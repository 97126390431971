import moment from "moment";
import _ from "lodash";

export const ChangeRequestHelper = () => {
  const CHANGE_REQUEST_LIST_TABLE_COLUMN = [
    { id: "ecrNumber", label: "ECR No.", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    {
      id: "actualEcrNumber",
      label: "Actual ECR No.",
      minWidth: 100,
    },
  ];

  const CHANGE_REQUEST_STATUS = [
    { value: "INITIATION_DRAFT", label: "Draft" },
    { value: "INITIATION_REVIEW_PENDING", label: "Initiation Review Pending" },
    { value: "INITIATION_REVIEWED", label: "Initiation Reviewed" },
    {
      value: "INITIATION_APPROVAL_PENDING",
      label: "Initiation Approval Pending",
    },
    { value: "INITIATION_APPROVED", label: "Initiation Approved" },
    { value: "INITIATION_REJECTED", label: "Initiation Rejected" },
    { value: "EXECUTION_PENDING", label: "Execution Pending" },
    { value: "EXECUTED", label: "Executed" },
    { value: "EXECUTION_DRAFT", label: "Execution Draft" },
    {
      value: "EXECUTION_REVIEW_PENDING",
      label: "Execution Review Pending",
    },
    {
      value: "EXECUTION_REVIEWED",
      label: "Execution Reviewed",
    },
    {
      value: "EXECUTION_APPROVAL_PENDING",
      label: "Execution Approval Pending",
    },
    {
      value: "EXECUTION_APPROVED",
      label: "Execution Approved",
    },
    {
      value: "EXECUTION_REJECTED",
      label: "Execution Approval Rejected",
    },
    { value: "CLOSURE_DRAFT", label: "Closure Draft" },
    {
      value: "CLOSURE_REVIEW_PENDING",
      label: "Closure Review Pending",
    },
    {
      value: "CLOSURE_REVIEWED",
      label: "Closure Reviewed",
    },
    {
      value: "CLOSURE_APPROVAL_PENDING",
      label: "Closure Approval Pending",
    },
    { value: "CLOSED", label: "Closed" },
    { value: "CLOSURE_REJECTED", label: "Closure Rejected" },
  ];

  const CHANGE_REQUEST_DEFAULT_VALUE = {
    ecrNumber: undefined,
    actualEcrNumber: "",
    creationDate: null,
    changeType: null,
    department: null,
    status: "INITIATION_DRAFT",
    description: "",
    actionItems: [],
    impactedSystems: [],
    associatedSystems: [],
    impactedDeliverables: [],
    impactedDocuments: [],
    associatedUsers: { initiation: [] },
  };

  return {
    CHANGE_REQUEST_LIST_TABLE_COLUMN,
    CHANGE_REQUEST_STATUS,
    CHANGE_REQUEST_DEFAULT_VALUE,
  };
};

export const CHANGE_REQUEST_STAGE = [
  {
    id: 0,
    label: "Initiation",
    status: [
      "INITIATION_DRAFT",
      "INITIATION_REVIEW_PENDING",
      "INITIATION_REVIEWED",
      "INITIATION_APPROVAL_PENDING",
      "INITIATION_APPROVED",
      "INITIATION_REJECTED",
    ],
  },
  {
    id: 1,
    label: "Execution",
    status: [
      "INITIATION_APPROVED",
      "EXECUTION_PENDING",
      "EXECUTED",
      "EXECUTION_DRAFT",
      "EXECUTION_REVIEW_PENDING",
      "EXECUTION_REVIEWED",
      "EXECUTION_APPROVAL_PENDING",
      "EXECUTION_APPROVED",
      "EXECUTION_REJECTED",
    ],
  },
  {
    id: 2,
    label: "Closure",
    status: [
      "EXECUTION_APPROVED",
      "CLOSURE_DRAFT",
      "CLOSURE_REVIEW_PENDING",
      "CLOSURE_REVIEWED",
      "CLOSURE_APPROVAL_PENDING",
      "CLOSURE_REJECTED",
      "CLOSED",
    ],
  },
];

export const INITIATION_MANDATORY_FIELDS_ERROR = {
  ecrNumber: true,
  actualEcrNumber: true,
  creationDate: true,
  changeType: true,
  department: true,
  status: true,
  description: true,
  actionItems: true,
  impactedSystems: true,
  associatedSystems: true,
  impactedDeliverables: true,
  impactedDocuments: true,
  associatedUsers: true,
};

export const changeType = [
  {
    label: "Automation",
    value: "AUTOMATION",
  },
  {
    label: "Mechanical",
    value: "MECHANICAL",
  },
  {
    label: "Documentation",
    value: "DOCUMENTATION",
  },
  {
    label: "Electrical",
    value: "ELECTRICAL",
  },
];

export const statusTypes = [
  { label: "Draft", value: "DRAFT" },
  { label: "Sent for Pre-Approval", value: "SENT_FOR_APPROVAL" },
  { label: "Pre-Approved", value: "APPROVED" },
  { label: "Sent for Approval", value: "SENT_FOR_APPROVAL" },
  { label: "Approved", value: "APPROVED" },
  { label: "Executing", value: "EXECUTING" },
  { label: "Executed", value: "EXECUTED" },
  { label: "Sent for Closure", value: "SENT_FOR_CLOSURE" },
  { label: "Closed", value: "CLOSED" },
];

export const USER_ROLE = [
  { value: "INITIATOR", label: "Initiator" },
  { value: "CO_INITIATOR", label: "Co Initiator" },
  { value: "REVIEWER", label: "Reviewer" },
  { value: "APPROVER", label: "Approver" },
  { value: "BOTH", label: "Reviewer & Approver" },
];

export const USER_STATUS = [
  { value: "INITIATED", label: "Initiated" },
  { value: "ASSIGNED", label: "Assigned" },
  { value: "APPROVAL_PENDING", label: "Approval Pending" },
  { value: "APPROVAL_REJECTED", label: "Approval Rejected" },
  { value: "APPROVED", label: "Approved" },
  { value: "REVIEW_PENDING", label: "Review Pending" },
  { value: "REVIEWED", label: "Reviewed" },
];

export const COMPLETED_TASKS_ACTION = [
  { value: "REVIEWED", label: "Reviewed" },
  { value: "APPROVED", label: "Approved" },
  { value: "APPROVAL_REJECTED", label: "Rejected" },
  { value: "COMPLETED", label: "Action Items Completed" },
];
export const TABLE_COLUMN_ATTACHMENTS = [
  { key: "fileName", label: "Name", minWidth: 100 },
  { key: "title", label: "Description", minWidth: 100 },
  { key: "user", label: "Uploaded By", minWidth: 100 },
  { key: "action", label: "Action", minWidth: 100 },
];

export const TABLE_COLUMN_ACTION_ITEM_ATTACHMENTS = [
  { key: "fileName", label: "fileName" },
  { key: "actions", label: "Actions", minWidth: 100 },
];

export const TABLE_COLUMN_IMPACTED_SYSTEMS = [
  { key: "phase", label: "Phase", minWidth: 100 },
  { key: "name", label: "System Name", minWidth: 100 },
  { key: "equipmentNumber", label: "Equipment Number", minWidth: 100 },
  { key: "action", label: "", minWidth: 100 },
];

export const TABLE_COLUMN_IMPACTED_DOCUMENTS = [
  { key: "documentNumber", label: "Document Number", minWidth: 150 },
  { key: "documentTitle", label: "Document Title", minWidth: 200 },
  { key: "version", label: "Version", minWidth: 80 },
  { key: "documentStatus", label: "Document Status", minWidth: 100 },
  { key: "action", label: "", minWidth: 100 },
];

export const TABLE_COLUMN_USERS = [
  { key: "userName", label: "User", minWidth: 100 },
  { key: "department", label: "Department", minWidth: 60 },
  { key: "role", label: "Role", minWidth: 60 },
];

export const TABLE_COLUMN_REVIEW_APPROVAL = [
  { key: "department", label: "Department", minWidth: 60 },
  { key: "userName", label: "User", minWidth: 100 },
  { key: "role", label: "Role", minWidth: 60 },
  { key: "status", label: "Status", minWidth: 60 },
  { key: "note", label: "Notes", minWidth: 100 },
];

export const TABLE_COLUMN_REVISION_HISTORY = [
  { key: "date", label: "Date" },
  { key: "userName", label: "User", minWidth: 100 },
  { key: "note", label: "Action", minWidth: 100 },
];

export const TABLE_ACTION = [
  { key: "checkBox", label: "Select All", minWidth: 50 },
  { key: "sequence", label: "Action Item Number", minWidth: 50 },
  { key: "name", label: "Action", minWidth: 100 },
  { key: "date", label: "Due Date", minWidth: 100 },
  { key: "system", label: "System", minWidth: 100 },
  { key: "deliverable", label: "Deliverable", minWidth: 100 },
  { key: "actionOwner", label: "Action Owner", minWidth: 100 },
];

export const TABLE_ACTION_EXECUTION = [
  { key: "checkBox", label: "Select All", minWidth: 50 },
  { key: "sequence", label: "Action Item Number", minWidth: 50 },
  { key: "name", label: "Action", minWidth: 100 },
  { key: "date", label: "Due Date", minWidth: 100 },
  { key: "system", label: "System", minWidth: 100 },
  { key: "deliverable", label: "Deliverable", minWidth: 100 },
  { key: "actionOwner", label: "Action Owner", minWidth: 100 },
  { key: "description", label: "Description", minWidth: 100 },
  { key: "status", label: "Status", minWidth: 100 },
  { key: "completionDate", label: "Completion Date", minWidth: 100 },
  { key: "attachment", label: "Attachment", minWidth: 100 },
];

export const SUBMIT_ERROR_MESSAGE = {
  INITIATION_SUBMIT_FOR_REVIEW: "Atleast one Reviewer required",
  INITIATION_SUBMIT_FOR_APPROVAL: "Atleast one Approver required",
  EXECUTION_SUBMIT_FOR_REVIEW: "Atleast one Reviewer required",
  EXECUTION_SUBMIT_FOR_APPROVAL: "Atleast one Approver required",
  CLOSURE_SUBMIT_FOR_REVIEW: "Atleast one Reviewer required",
  CLOSURE_SUBMIT_FOR_APPROVAL: "Atleast one Approver required",
};

export const ACTION_LIST_STATUS = {
  ASSIGNED: "Assigned",
  COMPLETED: "Completed",
};

export const resetUserStatus = (userList) => {
  let list = [...userList];
  list = list.map((user) => {
    if (!["INITIATOR", "CO_INITIATOR"].includes(user.role)) {
      user = {
        ...user,
        status: "ASSIGNED",
        note: "",
      };
    }
    return user;
  });
  return list;
};

export const checkAccess = (userList, currentUser) => {
  const user = userList?.find((data) => data.user === currentUser._id);
  if (["INITIATOR", "CO_INITIATOR"].includes(user?.role)) {
    return true;
  }
  return false;
};

export const getUserRole = (userList, deviationStatus, currentUser) => {
  let user = userList.filter((data) => data?.userInfo?._id === currentUser._id);
  if (user.length > 1) {
    if (
      [
        "INITIATION_REVIEW_PENDING",
        "INITIATION_REVIEWED",
        "EXECUTION_REVIEW_PENDING",
        "EXECUTION_REVIEWED",
        "CLOSURE_REVIEWED",
      ].includes(deviationStatus) &&
      user.filter((data) => data.role === "REVIEWER")
    ) {
      user = user.filter((data) => data.role === "REVIEWER");
    }
    if (
      [
        "INITIATION_APPROVAL_PENDING",
        "INITIATION_APPROVAL_REJECTED",
        "INITIATION_APPROVED",
        "EXECUTION_APPROVAL_PENDING",
        "EXECUTION_APPROVAL_REJECTED",
        "EXECUTION_APPROVED",
        "CLOSURE_APPROVAL_PENDING",
        "CLOSURE_REJECTED",
      ].includes(deviationStatus) &&
      user.filter((data) => data.role === "APPROVER")
    ) {
      user = user.filter((data) => data.role === "APPROVER");
    }
  }
  return user[0]?.role;
};

export const setChangeRequestValues = (changeRequest) => {
  let deliverables =
    [].concat(
      ...changeRequest?.impactedDeliverables?.map((value) => {
        return value.deliverables.map(
          (deliverable) => value.system + "-" + deliverable.deliverable
        );
      })
    ) || [];
  let documents = changeRequest?.impactedDocuments?.map((document) => {
    return { value: document.document, label: document.documentNumber };
  });
  const data = {
    ..._.cloneDeep(changeRequest),
    impactedDeliverables: deliverables,
    impactedDocuments: documents,
  };
  return data;
};

export const handleChangeRequestChange = (
  changeRequest,
  documents,
  type,
  value,
  name,
  value1 = "",
  value2 = ""
) => {
  if (type === "date" && value !== null) {
    value = moment(value).format("YYYY-MM-DD[T00:00:00.000Z]");
  }
  let updatedData = { ...changeRequest, [name]: value };
  if (name === "cqv") {
    updatedData.cqv = !changeRequest.cqv;
  }
  if (name === "impactedSystemsRemove") {
    updatedData.impactedSystems = value;
    updatedData.impactedDeliverables = value1;
    updatedData.impactedDocuments = value2;
  }
  if (name === "associatedSystemsRemove") {
    updatedData.associatedSystems = value;
  }
  if (name === "impactedSystems") {
    updatedData.impactedSystems = value;
    let prevArray = value1.map((data) => data.system);
    let nextArray = value.map((data) => data.system);
    let difference = prevArray
      .filter((x) => !nextArray.includes(x))
      .concat(nextArray.filter((x) => !prevArray.includes(x)));
    let documentsValue = [...changeRequest.impactedDocuments];
    difference.forEach((system) => {
      if (prevArray.includes(system)) {
        documentsValue = documentsValue.filter(
          (document) => document.system !== system
        );
      } else {
        let systemDocuments = documents.filter(
          (document) => document?.system?._id === system
        );
        let documentsIds = documentsValue.map((document) => document.value);
        systemDocuments.forEach((data) => {
          if (!documentsIds.includes(data.system._id)) {
            documentsValue.push({
              label: data.documentNumber,
              value: data._id,
              system: data.system._id,
            });
          }
        });
      }
    });
    updatedData.impactedDocuments = documentsValue;
  }
  return updatedData;
};

export const arrangeData = (
  submitType,
  formData,
  files,
  systems,
  documents
) => {
  const data = {
    ...formData,
    files: files,
    impactedDeliverables: systems
      .filter((system) =>
        formData.impactedSystems
          .map((system) => system.system)
          .includes(system._id)
      )
      .map((system) => {
        return {
          system: system._id,
          systemName: system.name,
          deliverables: system.deliverables
            .map((deliverable) => {
              if (
                formData?.impactedDeliverables.includes(
                  system._id + "-" + deliverable._id
                )
              ) {
                return {
                  deliverable: deliverable._id,
                  deliverableName: deliverable.name,
                };
              } else {
                return null;
              }
            })
            .filter((deliverable) => deliverable !== null),
        };
      })
      .filter((system) => system.deliverables.length),
    impactedDocuments: documents
      .filter((document) =>
        formData.impactedDocuments
          .map((document) => document.value)
          .includes(document._id)
      )
      .map((document) => {
        return {
          document: document._id,
          documentTitle: document.documentTitle,
          documentNumber: document.documentNumber,
          documentStatus: document.documentStatus,
        };
      }),
    associatedUsers: {
      initiation: formData.associatedUsers?.initiation?.map((user) =>
        _.omit(user, "department")
      ),
      execution: formData.associatedUsers?.execution?.map((user) =>
        _.omit(user, "department")
      ),
      closure: formData.associatedUsers?.closure?.map((user) =>
        _.omit(user, "department")
      ),
    },
  };
  if (submitType) {
    data.action = submitType;
  }
  return data;
};

export const checkUserStatus = (
  status,
  users,
  currentUser,
  currentUserRole
) => {
  if (
    [
      "INITIATION_REVIEW_PENDING",
      "EXECUTION_REVIEW_PENDING",
      "CLOSURE_REVIEW_PENDING",
    ].includes(status) &&
    currentUserRole === "REVIEWER" &&
    users?.find(
      (user) =>
        user.user === currentUser._id && user.status === "REVIEW_PENDING"
    )
  ) {
    return true;
  }
  if (
    [
      "INITIATION_APPROVAL_PENDING",
      "EXECUTION_APPROVAL_PENDING",
      "CLOSURE_APPROVAL_PENDING",
    ].includes(status) &&
    currentUserRole === "APPROVER" &&
    users?.find(
      (user) =>
        user.user === currentUser._id && user.status === "APPROVAL_PENDING"
    )
  ) {
    return true;
  }
  return false;
};

export const checkCompleted = (data) => {
  const list = data.filter((val) => val.status === "COMPLETED");
  if (list.length === data.length || data.length === 0) {
    return true;
  }
  return false;
};
