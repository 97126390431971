export const DASHBOARD_CHART_OPTIONS = {
  maintainAspectRatio: false,
  responsive: false,
  legend: {
    display: false,
  },
  cutout: "70%",
  plugins: {
    datalabels:{
      display: false
    }
  }
};
