import { GOLDEN_RATIO } from "utils/constants";
import { keyMirror } from "utils/helpers/utility";

export const ELEMENT_TYPES = keyMirror({
  node: "input_node",
  edge: "CUSTOM_EDGE",
});

export const GRAPH_PROPS = keyMirror({
  arrowHeadColor: "#4d84c0",
  height: window.innerHeight > 1067 ? window.innerHeight - 617 : 350,
  zIndex: 1250,
});

export const NODE_PROPS = keyMirror({
  width: 170,
  height: Math.round(170 / GOLDEN_RATIO),
  label: "Deliverable",
  marginX: 15,
  marginY: 15,
});

export const EDGE_PROPS = keyMirror({
  type: ELEMENT_TYPES.edge,
  arrowHeadType: "arrowclosed",
  style: {
    strokeWidth: 1,
    pointerEvents: "all",
    stroke: GRAPH_PROPS.arrowHeadColor,
  },
});

export const LAYOUT_DIRS = keyMirror({
  vertical: "TB",
  horizontal: "LR",
});

export const IDENTIFIERS = keyMirror({
  targetLeft: "target-left",
  targetTop: "target-top",
  targetRight: "target-right",
  targetBottom: "target-bottom",

  sourceLeft: "source-left",
  sourceTop: "source-top",
  sourceRight: "source-right",
  sourceBottom: "source-bottom",
});

export const HANDLE_TYPES = keyMirror({
  target: null,
  source: null,
});

export const NODE_DIALOGS = keyMirror({
  edit: {
    type: "edit",
    title: "Edit",
    ignoreButton: "Cancel",
    proceedButton: "Save",
  },
  add: {
    type: "add",
    title: "Add deliverable details",
    ignoreButton: "Cancel",
    proceedButton: "Save",
  },
  delete: {
    type: "delete",
    title: "Delete",
    content:
      "Are you sure you want to delete it? This action cannot be undone.",
    ignoreButton: "Cancel",
    proceedButton: "Save",
  },
  milestone: {
    type: "milestone",
    title: "Milestone",
    content: "Are you sure you want to change milestone status ?",
    ignoreButton: "Cancel",
    proceedButton: "Save",
  },
  master: {
    type: "master",
    title: "Master",
    content: "Action confirmation",
    ignoreButton: "Cancel",
    proceedButton: "Save",
  },
  duration: {
    type: "duration",
    title: "Edit Duration In Days",
    ignoreButton: "Cancel",
    proceedButton: "Save",
  },
});

export const GRAPH_EVENTS = keyMirror({
  graphInit: null,
  graphLayout: null,
  graphMigrate: null,
  nodeCreate: null,
  nodeLabelChange: null,
  nodeDelete: null,
  edgeCreate: null,
  edgeDelete: null,
  nodePosChange: null,
  milestoneChange: null,
  masterChange: null,
  durationChange: null,
});

export const COLORS = {
  green: "#008000",
  yellow: "#ffff00",
  red: "#ff0000",
};

export const MARKER_ENDS = {
  blue: "react-flow__arrowclosed",
  green: "react-flow__markerend-green",
  red: "react-flow__markerend-red",
};
