import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { getBudget, putBudget } from "services/api-finance";
import { TABLE_BUDGET, getTxtColor } from "../helpers";
import Loader from "components/Loader";
import AddBudget from "./AddBudget";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import { useDispatch } from "react-redux";
import BudgetPieChart from "./BudgetPieChart";

const { memo, useEffect, useState } = require("react");

const Budget = ({ projectId, openAdd, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [budgetList, setBudgetList] = useState([]);
  const [totalAmount, setTotal] = useState();
  const [remainingAmount, setRemaining] = useState();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    getBudget(projectId)
      .then((resp) => {
        setLoading(false);
        setBudgetList(resp?.budget);
        setTotal(resp?.totalAmount);
        setRemaining(resp?.remainingAmount);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [projectId]);

  useEffect(() => {
    if (openAdd === "budget" && open === false) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAdd]);
  const handleSaveBudget = (list) => {
    setLoading(true);
    const data = { budget: list };
    putBudget(projectId, data)
      .then((resp) => {
        setLoading(false);
        closeForm();
        setBudgetList(resp?.budget);
        setTotal(resp?.totalAmount);
        setRemaining(resp?.remainingAmount);
      })
      .catch((err) => {
        handleClose();
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: err?.response?.data?.info || "Error",
          })
        );
        console.log("error >>>>> ", err);
        setLoading(false);
      });
  };

  const closeForm = () => {
    setOpen(false);
    handleClose();
  };
  return (
    <Grid container column={12} spacing={3}>
      <Grid item xs={6}>
        <Card>
          <TableContainer style={{ maxHeight: 460 }}>
            <Table stickyHeader>
              <TableHead className="">
                {TABLE_BUDGET.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableHead>
              <TableBody>
                {budgetList.map((row, index) => (
                  <TableRow key={index}>
                    {TABLE_BUDGET.map((column) => {
                      let value = row[column.id];
                      if (column.id === "budget") {
                        value = value?.toLocaleString();
                      }
                      if (column.id === "consumedBudget") {
                        value = value?.toLocaleString();
                      }
                      if (column.id === "remainingAmount") {
                        value = (
                          <span
                            className={getTxtColor(
                              row.budget,
                              row.remainingAmount
                            )}
                          >
                            {row.remainingAmount?.toLocaleString()}
                          </span>
                        );
                      }
                      return (
                        <TableCell key={index + column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
              <TableHead className="bottom-sticky">
                <TableCell style={{ minWidth: 100 }}>Total</TableCell>
                <TableCell style={{ minWidth: 100 }}>{totalAmount?.toLocaleString()}</TableCell>
                <TableCell style={{ minWidth: 100 }}></TableCell>
                <TableCell
                  style={{ minWidth: 100 }}
                  className={getTxtColor(totalAmount, remainingAmount)}
                >
                  {remainingAmount?.toLocaleString()}
                </TableCell>
              </TableHead>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
      {totalAmount > 0 && (
        <Grid item xs={6} md={5} xl={4}>
          <Card className="ht-100">
            <BudgetPieChart data={budgetList} />
          </Card>
        </Grid>
      )}
      {open && (
        <AddBudget
          open={open}
          handleClose={closeForm}
          budgetList={budgetList}
          totalAmount={totalAmount}
          handleSave={handleSaveBudget}
        />
      )}
      {loading && <Loader />}
    </Grid>
  );
};

export default memo(Budget);
