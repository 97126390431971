import React, { useState, useEffect, memo } from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Card,
  CircularProgress,
} from "@material-ui/core";
import LINKS from "utils/constants/links";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { TABLE_COLUMN_PATIENT } from "../helper";
import { getPatients } from "services/api-patient-data";
import moment from "moment";
import Loader from "components/Loader";

const PatientList = ({ projectId }) => {
  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [patientList, setPatientList] = useState([]);
  const history = useHistory();
  const [initialLoad, setInitialLoad] = useState(true);

  const getAllPatients = async (skip, limit, type = "") => {
    if (type === "" || type === "reset") {
      setLoading(true);
    }
    if (type === "loadMore") {
      setPaginationLoading(true);
    }
    await getPatients(projectId, skip, limit)
      .then((resp) => {
        setPatientList(
          type === "reset" ? resp.data : [...patientList, ...resp.data]
        );
        if (
          type !== "reset" &&
          [...patientList, ...resp.data].length === resp.pagination.count
        ) {
          setLoadMore(false);
        }
        if (type === "reset") {
          setLoadMore(true);
        }
        setLoading(false);
        setPaginationLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setPaginationLoading(false);
        console.log("error >>>>> ", err);
      });
  };

  useEffect(() => {
    setInitialLoad(false);
  }, []);

  const handleLoadMore = () => {
    getAllPatients(patientList.length, 5, "loadMore");
  };

  useEffect(() => {
    if (!initialLoad) {
      getAllPatients(0, 20, "reset");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad]);

  return (
    <Card>
      <InfiniteScroll
        dataLength={patientList?.length}
        hasMore={loadMore}
        next={() => {
          if (!paginationLoading) {
            handleLoadMore();
          }
        }}
        loader={
          patientList?.length >= 20 &&
          loadMore && (
            <div className=" d-jsb-cen">
              <CircularProgress size={20} className="mb-10" />
            </div>
          )
        }
        scrollThreshold={0.9}
        height={600}
      >
        <Table stickyHeader>
          <TableHead>
            {TABLE_COLUMN_PATIENT.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {patientList.map((patient, index) => (
              <TableRow key={index}>
                {TABLE_COLUMN_PATIENT.map((column) => {
                  let value = patient[column.id];
                  if (column.id === "patientId") {
                    value = (
                      <Typography
                        className="pointer"
                        color="primary"
                        onClick={() =>
                          history.push(
                            LINKS.EDIT_PATIENT.HREF.replace(
                              ":projectId",
                              projectId
                            ).replace(":patientId", patient._id)
                          )
                        }
                      >
                        {value}
                      </Typography>
                    );
                  }
                  if (column.id === "dob") {
                    value = moment(value?.split("T")[0]).format("MM/DD/YYYY");
                  }
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </InfiniteScroll>
      {loading && <Loader />}
    </Card>
  );
};

export default memo(PatientList);
