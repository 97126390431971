import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { memo, useState } from "react";

const FILTER = [
  { value: "byVendor", label: "Vendor" },
  { value: "byDepartment", label: "Department" },
  { value: "byPurchaseOrder", label: "Purchase Order" },
];

const PurchaseOrderFilter = ({
  open,
  handleFilterClose,
  vendorList,
  departmentList,
  purchaseOrderList,
  handleFilter,
  type,
}) => {
  const filter = type === "invoice" ? FILTER : FILTER.slice(0, 2);
  const [filterData, setFilterData] = useState();

  const handleChange = ({ target: { value, name } }) => {
    const updatedData = { ...filterData, [name]: value };
    setFilterData(updatedData);
  };

  return (
    <Dialog open={open} handleClose={handleFilterClose} fullWidth>
      <DialogTitle>
        {type === "purchaseOrder"
          ? "Filter Purchase Orders"
          : "Filter Invoices"}
      </DialogTitle>
      <div className="pd-10 mb-10">
        <Grid container columns={12} className="mb-10">
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-label" required>
                Filter By
              </InputLabel>
              <Select
                fullWidth
                labelId="select-label"
                label="Filter By"
                name="filterBy"
                value={filterData?.filterBy || ""}
                onChange={handleChange}
              >
                {filter.map((fil, index) => {
                  return (
                    <MenuItem key={index} value={fil.value}>
                      {fil.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container columns={12}>
          {["byVendor"].includes(filterData?.filterBy) && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label" required>
                  Vendor List
                </InputLabel>
                <Select
                  fullWidth
                  name="vendor"
                  variant="outlined"
                  label="Vendor List"
                  value={filterData?.vendor || ""}
                  onChange={handleChange}
                >
                  {vendorList.map((vendor, index) => (
                    <MenuItem key={index} value={vendor._id}>
                      {vendor.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {["byDepartment"].includes(filterData?.filterBy) && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label" required>
                  Department List
                </InputLabel>
                <Select
                  fullWidth
                  name="department"
                  variant="outlined"
                  label="Department List"
                  value={filterData?.department || ""}
                  onChange={handleChange}
                >
                  {departmentList.map((dept, index) => (
                    <MenuItem key={index} value={dept._id}>
                      {dept.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {["byPurchaseOrder"].includes(filterData?.filterBy) && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label" required>
                  Purchase Order List
                </InputLabel>
                <Select
                  fullWidth
                  name="purchaseOrder"
                  variant="outlined"
                  label="Purchase Order List"
                  value={filterData?.purchaseOrder || ""}
                  onChange={handleChange}
                >
                  {purchaseOrderList.map((poOrder, index) => (
                    <MenuItem key={index} value={poOrder._id}>
                      {poOrder.poNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </div>
      <div className="pd-10 d-jsb-r">
        <Button
          className="mr-10"
          variant="contained"
          onClick={handleFilterClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleFilter(filterData)}
        >
          Apply
        </Button>
      </div>
    </Dialog>
  );
};
export default memo(PurchaseOrderFilter);
