import React, { memo } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { darken } from "polished";
import { Chip, ListItem, ListItemText } from "@material-ui/core";

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  &:hover {
    background-color: ${(props) => darken(0.05, "white")};
  }

  // &.${(props) => props.activeClassName} {
  //   background-color: ${(props) => darken(0.1, "white")};
  // }
`;

const LinkText = styled(ListItemText)`
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const SidebarLink = ({ name, to, badge, isActive }) => (
  <Link
    button
    dense
    component={NavLink}
    to={to}
    //  activeClassName="active"
    style={{ backgroundColor: isActive ? "#5A83BB" : "" }}
  >
    <LinkText className={isActive ? "txt-white txt-bold" : ""}>{name}</LinkText>
    {badge ? <LinkBadge label={badge} /> : ""}
  </Link>
);

export default memo(SidebarLink);
