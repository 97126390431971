import React from "react";
import "./TaskListDefault.css";
import moment from "moment";
import useFeatureFlag from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";

const getDateFormat = (date) => {
  return moment(date).format("MM/DD/YYYY");
};

export const TaskListDefault = ({
  rowHeight,
  rowWidth,
  tasks,
  fontFamily,
  fontSize,
  locale,
  onExpanderClick,
}) => {
  const systemFeatureFlag = useFeatureFlag(FEATURE_FLAGS.Systems);
  const deliverableFeatureFlag = useFeatureFlag(FEATURE_FLAGS.Deliverables);

  return (
    <div
      className="taskListWrapper"
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      {tasks.map((t) => {
        let expanderSymbol = "";
        if (t.hideChildren === false) {
          expanderSymbol = "▼";
        } else if (t.hideChildren === true) {
          expanderSymbol = "▶";
        }
        if (
          t.subChildren === true &&
          t.expandedDeliverables.includes(t.id) &&
          t.type !== "project"
        ) {
          expanderSymbol = "▼";
        } else if (
          t.subChildren === true &&
          !t.expandedDeliverables.includes(t.id) &&
          t.type !== "project"
        ) {
          expanderSymbol = "▶";
        }

        return (
          <div
            className="taskListTableRow"
            style={{ height: rowHeight }}
            key={`${t.id}row`}
          >
            <div
              className="taskListCell"
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
              title={t.displayName || t.name}
            >
              <div
                className={`taskListNameWrapper ${
                  t.type === "project"
                    ? ""
                    : t.project
                    ? "del-div"
                    : "sub-del-div"
                }`}
              >
                <div
                  className={` ${expanderSymbol}
                      ? taskListExpander
                      : taskListEmptyExpander`}
                  style={{
                    marginTop: "2px",
                    paddingLeft:
                      t.deliverableType === "EXECUTION" ? "12.5px" : "",
                  }}
                  onClick={() => {
                    if (t.type === "project") {
                      onExpanderClick(t);
                      t.handleSystemDropdown(t.id);
                    } else {
                      if (t.deliverableType !== "EXECUTION") {
                        t.handleDeliverableDropdown(t.id);
                      }
                    }
                  }}
                >
                  {expanderSymbol}
                </div>
                {t?.type === "project" && (
                  <div
                    className={
                      systemFeatureFlag ? "taskListName" : "cursor-default"
                    }
                    style={{
                      fontWeight: t.type === "project" ? "bold" : "normal",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    onClick={() => {
                      if (!t.subDeliverable && t.method) {
                        t.method(
                          t.type === "project" ? "system" : "deliverable",
                          t.systemId,
                          t.mainSystemId,
                          t.type === "project" ? "" : t.id.split("-")[1]
                        );
                      }
                    }}
                  >
                    {t.name}
                  </div>
                )}
                {t?.type !== "project" && (
                  <div
                    className={
                      !t.subDeliverable
                        ? deliverableFeatureFlag
                          ? "taskListName"
                          : "cursor-default"
                        : "taskListSubName"
                    }
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    onClick={() => {
                      if (!t.subDeliverable && t.method) {
                        t.method(
                          t.type === "project" ? "system" : "deliverable",
                          t.systemId,
                          t.mainSystemId,
                          t.type === "project" ? "" : t.id.split("-")[1]
                        );
                      }
                    }}
                  >
                    {t.displayName || t.name}
                  </div>
                )}
              </div>
            </div>
            <div
              className="taskListCell"
              style={{
                minWidth: "80px",
                maxWidth: "80px",
              }}
            >
              {getDateFormat(t.start)}
            </div>
            <div
              className="taskListCell"
              style={{
                minWidth: "80px",
                maxWidth: "80px",
              }}
            >
              {getDateFormat(t.end)}
            </div>
          </div>
        );
      })}
    </div>
  );
};
