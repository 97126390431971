import {
  Box,
  Button,
  Chip,
  Grid,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { memo, useRef } from "react";
import { getFileName } from "./helper";
import { Editor } from "draft-js";
import Toolbar from "pages/Projects/ProjectCustomNotification/CustomNotificationForm/Toolbar/Toolbar";
import { stateToHTML } from "draft-js-export-html";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  maxHeight: 600,
  background: "white",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "12px",
};

const styleMap = {
  UNDERLINE: {
    color: "#5a83bb",
    textDecoration: "underline",
  },
};

const SharePopupModel = ({
  openShareDetail,
  handleShareDetail,
  handleShareCancel,
  handleShare,
  handleDownloadImage,
  handleDownload,
  projectUsers,
  shareDetails,
  setShareDetails,
  handleKeyCommandBody,
  editorStateBody,
  setEditorStateBody,
  project,
  error,
  setError,
}) => {
  const editorBody = useRef(null);
  const focusEditorBody = () => {
    editorBody.current.focus();
  };

  return (
    <Modal open={openShareDetail} onClose={() => handleShareDetail(false)}>
      <Box style={style}>
        <Grid container className="pd-10">
          <Grid item xs={12}>
            <Typography variant="h6"> Email Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}>
                <InputLabel className="mt-10" error={error.to}>
                  To ({shareDetails.to.length})
                </InputLabel>
                <Autocomplete
                  className="mt-10"
                  multiple
                  limitTags={2}
                  options={projectUsers}
                  freeSolo
                  clearOnBlur
                  value={shareDetails.to}
                  getOptionLabel={(option) => option.email}
                  onChange={(e, value) => {
                    if (value.length) {
                      setError({ ...error, to: false });
                    }
                    setShareDetails({ ...shareDetails, to: value });
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      return (
                        <Chip
                          variant="outlined"
                          label={
                            typeof option === "string" ? option : option.email
                          }
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => {
                    const { InputProps, ...restParams } = params;
                    const { startAdornment, ...restInputProps } = InputProps;
                    return (
                      <TextField
                        {...restParams}
                        error={error.to}
                        helperText={error.to ? "User list cannot be empty" : ""}
                        variant="outlined"
                        onBlur={({ target }) => {
                          if (target.value !== "") {
                            setShareDetails({
                              ...shareDetails,
                              to: [...shareDetails.to, target.value],
                            });
                          }
                        }}
                        InputProps={{
                          ...restInputProps,
                          startAdornment: (
                            <div
                              style={{
                                maxHeight: 60,
                                overflowY: "auto",
                              }}
                            >
                              {startAdornment}
                            </div>
                          ),
                        }}
                      />
                    );
                  }}
                ></Autocomplete>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}>
                <InputLabel className="mt-10">Subject</InputLabel>
                <TextField
                  className="mt-10"
                  variant="outlined"
                  fullWidth
                  value={shareDetails.subject}
                  onChange={({ target }) => {
                    setShareDetails({
                      ...shareDetails,
                      subject: target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="mt-10">Body</InputLabel>
            <div
              className="editor-wrapper mt-10"
              style={{
                border: "1px solid #CCCCCC",
              }}
              onClick={focusEditorBody}
            >
              <Toolbar
                editorState={editorStateBody}
                setEditorState={setEditorStateBody}
              />
              <div className="editor-container editor-wrapper-body">
                <Editor
                  ref={editorBody}
                  handleKeyCommand={handleKeyCommandBody}
                  customStyleMap={styleMap}
                  editorState={editorStateBody}
                  onChange={(editorStateBody) => {
                    const contentState = editorStateBody.getCurrentContent();
                    setShareDetails({
                      ...shareDetails,
                      body: stateToHTML(contentState),
                    });
                    setEditorStateBody(editorStateBody);
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="mt-10" style={{ display: "flex" }}>
            <InputLabel style={{ marginTop: "2.5px" }}>
              Attachement ({shareDetails.type}):
            </InputLabel>
            <span
              className="ml-10 snapshot-link"
              onClick={() => {
                if (shareDetails.type === "Image") {
                  handleDownloadImage();
                } else {
                  handleDownload();
                }
              }}
            >
              {getFileName(project)}
            </span>
          </Grid>
          <Grid item xs={12} className="mt-10">
            <Grid container justify="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  handleShareCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleShare}
                className="ml-10"
                variant="contained"
                color="primary"
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(SharePopupModel);
