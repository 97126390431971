import "gantt-task-react/dist/index.css";
import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { textColor } from "../../constant";
import moment from "moment";
import CustomGanttChart from "./Ganttchart";

export default function InsightsGanttChart({ chartData, setPhase }) {
  const [dateFilter, setDateFilter] = useState(new Date().getFullYear());
  const yearStart = moment([dateFilter])
    .startOf("year")
    .format("YYYY-MM-DD[T00:00:00.000Z]");
  const yearEnd = moment([dateFilter])
    .endOf("year")
    .format("YYYY-MM-DD[T00:00:00.000Z]");
  let startDateValues = chartData?.map((value) => {
    return new Date(value.start).getFullYear();
  });
  let endDateValues = chartData?.map((value) => {
    return new Date(value.end).getFullYear();
  });
  let dateValues = [...new Set([...startDateValues, ...endDateValues])];

  const availableYear = dateFilter;

  const data = chartData
    ?.filter(
      (task) =>
        new Date(task.start).getFullYear() === availableYear ||
        new Date(task.end).getFullYear() === availableYear
    )
    ?.map((data) => {
      let start = data.start;
      let end = data.end;
      return {
        ...data,
        start: moment(start).isAfter(yearStart) ? start : yearStart,
        end: moment(end).isBefore(yearEnd) ? end : yearEnd,
      };
    });

  let finalData = [
    {
      start: "",
      end: "",
    },
  ];

  if (data.length !== 0) {
    finalData = data?.map((a) => {
      return { ...a, method: setPhase };
    });
  }

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography style={{ color: textColor }} variant="h6">
            Phases Schedule
          </Typography>
        </Grid>
        {chartData?.length !== 0 && (
          <Grid item xs={6}>
            <Grid container justify="center">
              <Button
                style={{
                  color: !dateValues.filter((date) => date < availableYear)
                    .length
                    ? "grey"
                    : textColor,
                }}
                onClick={() => {
                  setDateFilter(availableYear - 1);
                }}
                disabled={
                  !dateValues.filter((date) => date < availableYear).length
                }
              >
                {"<<"}
              </Button>
              <label
                style={{ color: textColor, fontSize: 18, marginTop: "6px" }}
              >
                {dateFilter}
              </label>
              <Button
                style={{
                  color: !dateValues.filter((date) => date > availableYear)
                    .length
                    ? "grey"
                    : textColor,
                }}
                onClick={() => {
                  setDateFilter(availableYear + 1);
                }}
                disabled={
                  !dateValues.filter((date) => date > availableYear).length
                }
              >
                {">>"}
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          {chartData.length !== 0 && (
            <CustomGanttChart
              chartData={chartData}
              dateFilter={dateFilter}
              finalData={finalData}
              setPhase={setPhase}
            ></CustomGanttChart>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
