import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardHeader,
  Link,
} from "@material-ui/core";
import { textColor } from "../../constant";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  LabelList,
  Tooltip,
} from "recharts";
import "./SystemChart.css";
import moment from "moment";
// import { ExpandMore } from "@material-ui/icons";
import { ReactComponent as ExpandMore } from "icons/arrow_down.svg";
import { CircularProgress } from "@material-ui/core";
import { Tooltip as IconTooltip, IconButton } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import LINKS from "../../../../../utils/constants/links";
import FeatureFlag from "utils/hocs/FeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";

const SystemChart = ({ loading, phaseId }) => {
  const { projectId } = useParams();
  const [deliverableFilter, setDeliverableFilter] = useState("");
  const {
    phaseDetail: {
      systemData,
      completedCount,
      notStarted,
      inProgressCount,
      totalCount,
      releaseToUse,
    },
    deliverableDetail: { deliverableData },
    phaseName,
  } = useSelector((state) => state.projects.phaseDetail);
  const systemValues = systemData?.map((detail) => {
    return {
      name: detail.name,
      status: Math.round(detail.status),
      statusPercent: Math.round(detail.status) + "%",
    };
  });
  const colors = ["#34A02C", "ffff00", "#E86767"];
  const deliverableList = deliverableData?.map((deliverable) => {
    const diffThreshold =
      deliverable.differentialWeight *
      (moment(deliverable.end) - moment(deliverable.start));
    const differential = deliverable.calculated.differential;
    let diffColor = "";
    if (differential <= 0) {
      diffColor = 0;
    } else if (differential <= diffThreshold) {
      diffColor = 1;
    } else {
      diffColor = 2;
    }
    return {
      status: Math.round(deliverable.status),
      statusPercent: Math.round(deliverable.status) + "%",
      name: deliverable.name,
      systemName: deliverable.systemName,
      colorIndex: diffColor,
      executed: deliverable.executed,
      resource: deliverable.resource,
      approver: deliverable.approver,
      reviewer: deliverable.reviewer,
      draft: deliverable.draft,
      ifr: deliverable.ifr,
      review: deliverable.review,
      ifa: deliverable.ifa,
      approval: deliverable.approval,
      type: deliverable.type,
      id: deliverable._id,
    };
  });

  const deliverableValues = deliverableList.filter((deliverable) => {
    if (deliverableFilter === "") {
      return deliverable.systemName === systemValues[0].name;
    } else {
      return deliverable.systemName === deliverableFilter;
    }
  });

  const approvedList = deliverableList.filter((data) => {
    return (
      data.ifa.issued === true &&
      Math.round(data.approval.status || 0) >= 0 &&
      Math.round(data.approval.status || 0) <= 100
    );
  });
  const filteredApprovedList = deliverableValues.filter((data) => {
    return (
      data.ifa.issued === true &&
      Math.round(data.approval.status || 0) >= 0 &&
      Math.round(data.approval.status || 0) <= 100
    );
  });
  const approvedCount = approvedList.length;

  const filteredApprovedCount = filteredApprovedList.length;

  const reviewedList = deliverableList
    .filter((data) => {
      return (
        data.ifr.issued === true &&
        Math.round(data.review.status || 0) >= 0 &&
        Math.round(data.review.status || 0) <= 100
      );
    })
    ?.filter((del) => !approvedList.map((del) => del.id).includes(del.id));
  const reviewedCount = reviewedList?.length;
  const filteredReviewedList = deliverableValues
    .filter((data) => {
      return (
        data.ifr.issued === true &&
        Math.round(data.review.status || 0) >= 0 &&
        Math.round(data.review.status || 0) <= 100
      );
    })
    ?.filter(
      (del) => !filteredApprovedList.map((del) => del.id).includes(del.id)
    );
  const filteredReviewedCount = filteredReviewedList.length;

  const draftCount = deliverableList.filter((data) => {
    return (
      Math.round(data.draft.status || 0) >= 0 &&
      Math.round(data.draft.status || 0) <= 100 &&
      data.type !== "EXECUTION"
    );
  }).length;
  const filteredDraftCount = deliverableValues.filter((data) => {
    return (
      Math.round(data.draft.status || 0) >= 0 &&
      Math.round(data.draft.status || 0) <= 100 &&
      data.type !== "EXECUTION"
    );
  }).length;

  const executedList = deliverableList.filter(
    (del) => del.type === "EXECUTION" && Math.round(del.status) === 100
  );
  const executedCount = executedList.length;
  const inExecutionList = deliverableList.filter(
    (del) =>
      del.type === "EXECUTION" &&
      Math.round(del.status) >= 0 &&
      Math.round(del.status) < 100
  );
  const inExecutionCount = inExecutionList.length;
  const filteredInExecutionList = deliverableValues.filter(
    (del) =>
      del.type === "EXECUTION" &&
      Math.round(del.status) >= 0 &&
      Math.round(del.status) < 100
  );
  const filteredInExecutionCount = filteredInExecutionList.length;
  const filteredExecutedList = deliverableValues.filter(
    (del) => del.type === "EXECUTION" && Math.round(del.status) === 100
  );
  const filteredExecutedCount = filteredExecutedList.length;
  const history = useHistory();
  return (
    <div>
      {!loading && (
        <div>
          <Grid
            container
            style={{
              borderBottom:
                phaseName !== undefined ? "1px solid #EEEFF0" : "none",
            }}
          >
            <Grid
              xs={6}
              item
              style={{
                marginTop: "30px",
              }}
            >
              <Typography
                style={{ color: "#6281B6" }}
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                {phaseName}
              </Typography>
            </Grid>
            <FeatureFlag feature={FEATURE_FLAGS.DeliverableSnapshot}>
              <Grid
                item
                xs={6}
                style={{
                  marginTop: "30px",
                }}
              >
                <Grid container justify="flex-end">
                  <Link
                    style={{ cursor: "pointer", fontSize: "14px" }}
                    onClick={() => {
                      history.push(
                        LINKS.DELIVERABLE_SNAPSHOT.HREF.replace(
                          ":projectId",
                          projectId
                        ),
                        phaseId
                      );
                    }}
                  >
                    Deliverable Snapshot
                  </Link>
                </Grid>
              </Grid>
            </FeatureFlag>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container style={{ marginTop: "30px" }}>
                <Grid item xs={12}>
                  <Grid container justify="space-between">
                    <Grid item xs={6}>
                      <Typography style={{ color: textColor }} variant="h6">
                        System Detail
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "10px" }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={7}>
                      <Card
                        style={{
                          height: "380px",
                          overflowY: "auto",
                        }}
                      >
                        <CardContent style={{ marginTop: "10px" }}>
                          <div style={{ overflowX: "auto", overflowY: "clip" }}>
                            {systemValues.length !== 0 && (
                              <BarChart
                                width={
                                  systemValues.length *
                                  (systemValues.length < 3 ? 200 : 120)
                                }
                                height={310}
                                data={systemValues}
                                margin={{
                                  top: 20,
                                  right: 40,
                                  left: 0,
                                  bottom: 5,
                                }}
                              >
                                <YAxis padding={{ top: 10 }}></YAxis>
                                <XAxis
                                  tickFormatter={(label) =>
                                    `${label.substr(0, 10)}${
                                      label.length <= 10 ? "" : "..."
                                    }`
                                  }
                                  onClick={(e) => {
                                    setDeliverableFilter(e.value);
                                  }}
                                  tickLine={false}
                                  dataKey="name"
                                />
                                <Tooltip />
                                <Bar barSize={40} dataKey="status">
                                  <LabelList
                                    dataKey="statusPercent"
                                    position="top"
                                  />
                                </Bar>
                              </BarChart>
                            )}
                            {systemValues.length === 0 && (
                              <div
                                className="no-data-available"
                                style={{ height: "320px" }}
                              >
                                No data available
                              </div>
                            )}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <Card style={{ height: "100%" }}>
                        <CardContent
                          style={{ paddingLeft: 30, paddingRight: 30 }}
                        >
                          <Grid
                            container
                            spacing={10}
                            style={{ marginTop: "15px" }}
                          >
                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                                style={{
                                  borderBottom: "1px solid #EDEFF0",
                                }}
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    style={{
                                      color: textColor,
                                      alignSelf: "right",
                                    }}
                                    variant="subtitle1"
                                  >
                                    Total Systems
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container justify="flex-end">
                                    <Typography
                                      style={{ color: textColor }}
                                      variant="subtitle1"
                                    >
                                      {totalCount}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                                style={{
                                  borderBottom: "1px solid #EDEFF0",
                                }}
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    style={{
                                      color: textColor,
                                      alignSelf: "right",
                                    }}
                                    variant="subtitle1"
                                  >
                                    Not Started
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container justify="flex-end">
                                    <Typography
                                      style={{ color: textColor }}
                                      variant="subtitle1"
                                    >
                                      {notStarted}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                                style={{
                                  borderBottom: "1px solid #EDEFF0",
                                }}
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    style={{
                                      color: textColor,
                                      alignSelf: "right",
                                    }}
                                    variant="subtitle1"
                                  >
                                    In Progress
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container justify="flex-end">
                                    <Typography
                                      style={{ color: textColor }}
                                      variant="subtitle1"
                                    >
                                      {inProgressCount}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                                style={{
                                  borderBottom: "1px solid #EDEFF0",
                                }}
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    style={{
                                      color: textColor,
                                      alignSelf: "right",
                                    }}
                                    variant="subtitle1"
                                  >
                                    Completed
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container justify="flex-end">
                                    {" "}
                                    <Typography
                                      style={{ color: textColor }}
                                      variant="subtitle1"
                                    >
                                      {completedCount}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    style={{
                                      color: textColor,
                                      alignSelf: "right",
                                    }}
                                    variant="subtitle1"
                                  >
                                    Release to use
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container justify="flex-end">
                                    {" "}
                                    <Typography
                                      style={{ color: textColor }}
                                      variant="subtitle1"
                                    >
                                      {releaseToUse}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container style={{ marginTop: "30px" }}>
                <Grid item xs={12}>
                  <Typography style={{ color: textColor }} variant="h6">
                    Deliverables Chart
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={7}>
                      <Card style={{ height: "100%" }}>
                        <CardContent
                          style={{
                            marginTop: "10px",
                            padding: "20px",
                            height: "400px",
                          }}
                        >
                          <div style={{ overflowX: "auto", overflowY: "clip" }}>
                            {deliverableList.length !== 0 && (
                              <BarChart
                                width={
                                  deliverableList.length *
                                  (deliverableList.length < 3 ? 200 : 120)
                                }
                                height={360}
                                data={deliverableList}
                                margin={{
                                  top: 20,
                                  right: 40,
                                  left: 0,
                                  bottom: 5,
                                }}
                              >
                                <Tooltip />
                                <YAxis padding={{ top: 10 }}></YAxis>
                                <XAxis
                                  tickLine={false}
                                  dataKey="name"
                                  tickFormatter={(label) =>
                                    `${label.substr(0, 10)}${
                                      label.length <= 10 ? "" : "..."
                                    }`
                                  }
                                />
                                <Bar barSize={40} dataKey="status">
                                  {deliverableList.map((a, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={colors[a.colorIndex]}
                                    />
                                  ))}
                                  <LabelList
                                    dataKey="statusPercent"
                                    position="top"
                                  />
                                </Bar>
                              </BarChart>
                            )}
                            {deliverableList.length === 0 && (
                              <div
                                className="no-data-available"
                                style={{ height: "360px" }}
                              >
                                No data available
                              </div>
                            )}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <Card style={{ height: "100%" }}>
                        <CardContent
                          style={{ paddingLeft: 30, paddingRight: 30 }}
                        >
                          <Grid
                            container
                            spacing={10}
                            style={{ marginTop: "15px" }}
                          >
                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                                style={{
                                  borderBottom: "1px solid #EDEFF0",
                                }}
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    style={{
                                      color: textColor,
                                      alignSelf: "right",
                                    }}
                                    variant="subtitle1"
                                  >
                                    Total Deliverables
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container justify="flex-end">
                                    <Typography
                                      style={{ color: textColor }}
                                      variant="subtitle1"
                                    >
                                      {deliverableList.length}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                                style={{
                                  borderBottom: "1px solid #EDEFF0",
                                }}
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    style={{
                                      color: textColor,
                                      alignSelf: "right",
                                    }}
                                    variant="subtitle1"
                                  >
                                    Draft
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container justify="flex-end">
                                    <Typography
                                      style={{ color: textColor }}
                                      variant="subtitle1"
                                    >
                                      {draftCount -
                                        reviewedCount -
                                        approvedCount}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                                style={{
                                  borderBottom: "1px solid #EDEFF0",
                                }}
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    style={{
                                      color: textColor,
                                      alignSelf: "right",
                                    }}
                                    variant="subtitle1"
                                  >
                                    Reviewed
                                    {reviewedList?.length !== 0 && (
                                      <IconTooltip
                                        aria-multiline={true}
                                        title={
                                          <>
                                            {reviewedList?.map((a) => {
                                              return (
                                                <p
                                                  style={{ fontSize: "14px" }}
                                                  key={"reviewed" + a?.name}
                                                >
                                                  {a?.name}
                                                  <br />
                                                </p>
                                              );
                                            })}
                                          </>
                                        }
                                      >
                                        <IconButton
                                          size="small"
                                          className="info-icon"
                                        >
                                          <Info></Info>
                                        </IconButton>
                                      </IconTooltip>
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container justify="flex-end">
                                    <Typography
                                      style={{ color: textColor }}
                                      variant="subtitle1"
                                    >
                                      {reviewedCount}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                                style={{
                                  borderBottom: "1px solid #EDEFF0",
                                }}
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    style={{
                                      color: textColor,
                                      alignSelf: "right",
                                    }}
                                    variant="subtitle1"
                                  >
                                    Approved
                                    {approvedList?.length !== 0 && (
                                      <IconTooltip
                                        aria-multiline={true}
                                        title={
                                          <>
                                            {approvedList?.map((a) => {
                                              return (
                                                <p
                                                  style={{ fontSize: "14px" }}
                                                  key={"approved" + a?.name}
                                                >
                                                  {a?.name}
                                                  <br />
                                                </p>
                                              );
                                            })}
                                          </>
                                        }
                                      >
                                        <IconButton
                                          size="small"
                                          className="info-icon"
                                        >
                                          <Info></Info>
                                        </IconButton>
                                      </IconTooltip>
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container justify="flex-end">
                                    <Typography
                                      style={{ color: textColor }}
                                      variant="subtitle1"
                                    >
                                      {approvedCount}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                                style={{
                                  borderBottom: "1px solid #EDEFF0",
                                }}
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    style={{
                                      color: textColor,
                                      alignSelf: "right",
                                    }}
                                    variant="subtitle1"
                                  >
                                    In Execution
                                    {inExecutionList?.length !== 0 && (
                                      <IconTooltip
                                        aria-multiline={true}
                                        title={
                                          <>
                                            {inExecutionList?.map((a) => {
                                              return (
                                                <p
                                                  style={{ fontSize: "14px" }}
                                                  key={"inexecution" + a?.name}
                                                >
                                                  {a?.name}
                                                  <br />
                                                </p>
                                              );
                                            })}
                                          </>
                                        }
                                      >
                                        <IconButton
                                          size="small"
                                          className="info-icon"
                                        >
                                          <Info></Info>
                                        </IconButton>
                                      </IconTooltip>
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container justify="flex-end">
                                    {" "}
                                    <Typography
                                      style={{ color: textColor }}
                                      variant="subtitle1"
                                    >
                                      {inExecutionCount}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                              >
                                <Grid item xs={10}>
                                  <Typography
                                    style={{
                                      color: textColor,
                                      alignSelf: "right",
                                    }}
                                    variant="subtitle1"
                                  >
                                    Executed
                                    {executedList?.length !== 0 && (
                                      <IconTooltip
                                        aria-multiline={true}
                                        title={
                                          <>
                                            {executedList?.map((a) => {
                                              return (
                                                <p
                                                  style={{ fontSize: "14px" }}
                                                  key={"executed" + a?.name}
                                                >
                                                  {a?.name}
                                                  <br />
                                                </p>
                                              );
                                            })}
                                          </>
                                        }
                                      >
                                        <IconButton
                                          size="small"
                                          className="info-icon"
                                        >
                                          <Info></Info>
                                        </IconButton>
                                      </IconTooltip>
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container justify="flex-end">
                                    <Typography
                                      style={{ color: textColor }}
                                      variant="subtitle1"
                                    >
                                      {executedCount}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container style={{ marginTop: "30px" }}>
                <Grid item xs={12} style={{ marginTop: "10px" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore className="icons_small" />}
                    >
                      <CardHeader
                        title={
                          deliverableFilter === ""
                            ? systemValues[0]?.name
                            : deliverableFilter
                        }
                        className="pd-0 ml-10"
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        item
                        xs={12}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <Grid container spacing={4}>
                          <Grid item xs={12} lg={7}>
                            <Card style={{ height: "100%" }}>
                              <CardContent
                                style={{
                                  marginTop: "10px",
                                  padding: "20px",
                                  height: "400px",
                                }}
                              >
                                <div
                                  style={{
                                    overflowX: "auto",
                                    overflowY: "clip",
                                  }}
                                >
                                  {deliverableValues?.length !== 0 && (
                                    <BarChart
                                      width={
                                        deliverableValues.length *
                                        (deliverableValues.length < 3
                                          ? 200
                                          : 120)
                                      }
                                      height={360}
                                      data={deliverableValues}
                                      margin={{
                                        top: 20,
                                        right: 40,
                                        left: 0,
                                        bottom: 5,
                                      }}
                                    >
                                      <Tooltip />
                                      <YAxis padding={{ top: 10 }}></YAxis>
                                      <XAxis
                                        tickLine={false}
                                        dataKey="name"
                                        tickFormatter={(label) =>
                                          `${label.substr(0, 10)}${
                                            label.length <= 10 ? "" : "..."
                                          }`
                                        }
                                      />
                                      <Bar barSize={40} dataKey="status">
                                        {deliverableValues.map((a, index) => (
                                          <Cell
                                            key={`cell-${index}`}
                                            fill={colors[a.colorIndex]}
                                          />
                                        ))}
                                        <LabelList
                                          dataKey="statusPercent"
                                          position="top"
                                        />
                                      </Bar>
                                    </BarChart>
                                  )}
                                  {deliverableValues?.length === 0 && (
                                    <div
                                      className="no-data-available"
                                      style={{ height: "320px" }}
                                    >
                                      No data available
                                    </div>
                                  )}
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} lg={5}>
                            <Card style={{ height: "100%" }}>
                              <CardContent
                                style={{ paddingLeft: 30, paddingRight: 30 }}
                              >
                                <Grid
                                  container
                                  spacing={10}
                                  style={{ marginTop: "15px" }}
                                >
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      justify="space-between"
                                      spacing={1}
                                      style={{
                                        borderBottom: "1px solid #EDEFF0",
                                      }}
                                    >
                                      <Grid item xs={10}>
                                        <Typography
                                          style={{
                                            color: textColor,
                                            alignSelf: "right",
                                          }}
                                          variant="subtitle1"
                                        >
                                          Total Deliverables
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                          <Typography
                                            style={{ color: textColor }}
                                            variant="subtitle1"
                                          >
                                            {deliverableValues.length}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      justify="space-between"
                                      spacing={1}
                                      style={{
                                        borderBottom: "1px solid #EDEFF0",
                                      }}
                                    >
                                      <Grid item xs={10}>
                                        <Typography
                                          style={{
                                            color: textColor,
                                            alignSelf: "right",
                                          }}
                                          variant="subtitle1"
                                        >
                                          Draft
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                          <Typography
                                            style={{ color: textColor }}
                                            variant="subtitle1"
                                          >
                                            {filteredDraftCount -
                                              filteredReviewedCount -
                                              filteredApprovedCount}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      justify="space-between"
                                      spacing={1}
                                      style={{
                                        borderBottom: "1px solid #EDEFF0",
                                      }}
                                    >
                                      <Grid item xs={10}>
                                        <Typography
                                          style={{
                                            color: textColor,
                                            alignSelf: "right",
                                          }}
                                          variant="subtitle1"
                                        >
                                          Reviewed
                                          {filteredReviewedList?.length !==
                                            0 && (
                                            <IconTooltip
                                              aria-multiline={true}
                                              title={
                                                <>
                                                  {filteredReviewedList?.map(
                                                    (a) => {
                                                      return (
                                                        <p
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                          key={
                                                            "filteredreviewed" +
                                                            a?.name
                                                          }
                                                        >
                                                          {a?.name}
                                                          <br />
                                                        </p>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              }
                                            >
                                              <IconButton
                                                size="small"
                                                className="info-icon"
                                              >
                                                <Info></Info>
                                              </IconButton>
                                            </IconTooltip>
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                          <Typography
                                            style={{ color: textColor }}
                                            variant="subtitle1"
                                          >
                                            {filteredReviewedCount}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      justify="space-between"
                                      spacing={1}
                                      style={{
                                        borderBottom: "1px solid #EDEFF0",
                                      }}
                                    >
                                      <Grid item xs={10}>
                                        <Typography
                                          style={{
                                            color: textColor,
                                            alignSelf: "right",
                                          }}
                                          variant="subtitle1"
                                        >
                                          Approved
                                          {filteredApprovedList?.length !==
                                            0 && (
                                            <IconTooltip
                                              aria-multiline={true}
                                              title={
                                                <>
                                                  {filteredApprovedList?.map(
                                                    (a) => {
                                                      return (
                                                        <p
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                          key={
                                                            "filteredapproved" +
                                                            a?.name
                                                          }
                                                        >
                                                          {a?.name}
                                                          <br />
                                                        </p>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              }
                                            >
                                              <IconButton
                                                size="small"
                                                className="info-icon"
                                              >
                                                <Info></Info>
                                              </IconButton>
                                            </IconTooltip>
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                          <Typography
                                            style={{ color: textColor }}
                                            variant="subtitle1"
                                          >
                                            {filteredApprovedCount}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      justify="space-between"
                                      spacing={1}
                                      style={{
                                        borderBottom: "1px solid #EDEFF0",
                                      }}
                                    >
                                      <Grid item xs={10}>
                                        <Typography
                                          style={{
                                            color: textColor,
                                            alignSelf: "right",
                                          }}
                                          variant="subtitle1"
                                        >
                                          In Execution
                                          {filteredInExecutionList?.length !==
                                            0 && (
                                            <IconTooltip
                                              aria-multiline={true}
                                              title={
                                                <>
                                                  {filteredInExecutionList?.map(
                                                    (a) => {
                                                      return (
                                                        <p
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                          key={
                                                            "filteredinexecution" +
                                                            a?.name
                                                          }
                                                        >
                                                          {a?.name}
                                                          <br />
                                                        </p>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              }
                                            >
                                              <IconButton
                                                size="small"
                                                className="info-icon"
                                              >
                                                <Info></Info>
                                              </IconButton>
                                            </IconTooltip>
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                          {" "}
                                          <Typography
                                            style={{ color: textColor }}
                                            variant="subtitle1"
                                          >
                                            {filteredInExecutionCount}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      justify="space-between"
                                      spacing={1}
                                    >
                                      <Grid item xs={10}>
                                        <Typography
                                          style={{
                                            color: textColor,
                                            alignSelf: "right",
                                          }}
                                          variant="subtitle1"
                                        >
                                          Executed
                                          {filteredExecutedList?.length !==
                                            0 && (
                                            <IconTooltip
                                              aria-multiline={true}
                                              title={
                                                <>
                                                  {filteredExecutedList?.map(
                                                    (a) => {
                                                      return (
                                                        <p
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                          key={
                                                            "filteredexecuted" +
                                                            a?.name
                                                          }
                                                        >
                                                          {a?.name}
                                                          <br />
                                                        </p>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              }
                                            >
                                              <IconButton
                                                size="small"
                                                className="info-icon"
                                              >
                                                <Info></Info>
                                              </IconButton>
                                            </IconTooltip>
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                          <Typography
                                            style={{ color: textColor }}
                                            variant="subtitle1"
                                          >
                                            {filteredExecutedCount}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {loading && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          style={{ height: "100px" }}
        >
          <Grid item xs={12} style={{ marginTop: "30px" }}>
            {" "}
            <CircularProgress color="secondary" />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default memo(SystemChart);
