import { del, post, get, patch } from "services/axios";

const getVendors = async (organizationId) =>
  await get(`/v2/organization/${organizationId}/vendors/`);

const createVendor = async (organizationId, req) =>
  await post(`/v2/organization/${organizationId}/vendors/`, req);

const updateVendor = async (organizationId, id, req) =>
  await patch(`/v2/organization/${organizationId}/vendors/${id}`, req);

const deleteVendor = async (organizationId, id) =>
  await del(`/v2/organization/${organizationId}/vendors/${id}`);

export { getVendors, createVendor, updateVendor, deleteVendor };
