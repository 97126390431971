import { memo, useEffect, useState, useRef } from "react";
import PageHeader from "parts/PageHeader";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LINKS from "utils/constants/links";
import { getProjects } from "redux/actions/projects";
import Toolbar from "../ProjectCustomNotification/CustomNotificationForm/Toolbar/Toolbar";
import {
  Modal,
  Box,
  Grid,
  Typography,
  InputLabel,
  TextField,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { Autocomplete } from "@mui/material";
import { CalendarFilter } from "../Calendar/CalendarFilter";
import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import moment from "moment";
import { toJpeg, toBlob } from "html-to-image";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
// import { GridFilterAltIcon } from "@material-ui/data-grid";
// import ShareIcon from "@mui/icons-material/Share";
// import Download from "@mui/icons-material/Download";
// import { Replay } from "@material-ui/icons";
import { getPhases, shareSnapshot } from "services/api-project";
import Loader from "components/Loader";
import {
  colorRA,
  SYSTEM_STATUS_TYPES,
} from "../ProjectInsight/components/DeliverableSnapshot/snapshotHelper";
import { downloadExcelSystemSnapshot } from "../ProjectInsight/components/DeliverableSnapshot/components/helper";
import { ReactComponent as FilterIcon } from "icons/filter.svg";
import { ReactComponent as DownloadIcon } from "icons/download.svg";
import { ReactComponent as ShareIcon } from "icons/share.svg";
import { ReactComponent as RefreshIcon } from "icons/refresh.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  maxHeight: 600,
  background: "white",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "12px",
};

const styleMap = {
  UNDERLINE: {
    color: "#5a83bb",
    textDecoration: "underline",
  },
};

const CustomTableCell = withStyles({
  root: {
    borderBottom: "none",
    boxShadow: "inset 0px 0px 0px 0.25px grey",
    zIndex: 1,
    padding: "5px",
    backgroundColor: "white",
  },
})(TableCell);

const SystemSnapshot = () => {
  const { projectId } = useParams();
  const projects = useSelector((state) => state.projects);
  const project = projects.results.find((item) => item._id === projectId);
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(false);
  const [openShareDetail, setOpenShareDetail] = useState(false);
  const [error, setError] = useState({ to: false });
  const [shareDetails, setShareDetails] = useState({
    to: [],
    subject: "",
    body: "",
    attachment: null,
    type: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorElShare, setAnchorElShare] = useState(null);
  const openShare = Boolean(anchorElShare);
  const downloadRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [metaSystems, setMetaSystems] = useState([]);
  const [systemList, setSystemList] = useState([]);
  const [phaseList, setPhaseList] = useState([]);
  const [systemStatusList, setSystemStatusList] = useState([]);

  const [phases, setPhases] = useState([]);
  // console.log("System phases>>>>>>", phases);
  const editorBody = useRef(null);
  const [editorStateBody, setEditorStateBody] = useState(
    EditorState.createEmpty()
  );
  const users = useSelector((state) => state.users.results);

  const projectUsers = users?.filter((user) =>
    project?.assignedUsers?.includes(user._id)
  );
  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
  }, [dispatch, projectId]);

  const fetchData = async () => {
    setLoading(true);
    await getPhases(projectId)
      .then((response) => {
        // console.log("Response>>>>>>>>>>>><<<<<<<",response)
        const { metaSystems, phases } = response?.data;

        setMetaSystems(metaSystems);
        setPhases(phases);
        let systemStatusList = SYSTEM_STATUS_TYPES.map((type) => ({
          ...type,
          name: type.LABEL,
          selected: false,
        }));
        setSystemStatusList(systemStatusList);
        setSystemList(
          metaSystems
            .filter((sys) => {
              return (
                !sys?.parentSystem ||
                metaSystems.filter((system) => system.parentSystem === sys._id)
                  .length !== 0
              );
            })
            .map((system) => {
              return {
                name: system.name,
                _id: system._id,
                selected: false,
                status: system.mainSystem.status,
              };
            })
        );
        setPhaseList(
          phases.map((phase) => {
            return { name: phase.name, _id: phase._id, selected: false };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error fetching system snapshot data ==>", err);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkFilter();
    // eslint-disable-next-line
  }, [systemList, systemStatusList, phaseList]);

  const NAV_LINKS = [
    LINKS.PROJECT_MANAGEMENT,
    LINKS.PROJECTS,
    {
      HREF: LINKS.EDIT_PROJECT.HREF.replace(":id", projectId),
      TITLE: project?.name || "Not Found",
    },
  ];

  const focusEditorBody = () => {
    editorBody.current.focus();
  };
  const handleKeyCommandBody = (command) => {
    const newState = RichUtils.handleKeyCommand(editorStateBody, command);
    if (newState) {
      setEditorStateBody(newState);
      return true;
    }
    return false;
  };

  const getFileName = () => {
    let date = new Date();
    return (
      project?.name +
      "_system_snapshot_" +
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getFullYear().toString().slice(2) +
      "@" +
      date.getHours() +
      "_" +
      date.getMinutes()
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDownloadImage = () => {
    handleCloseMenu();
    if (downloadRef.current === null) {
      return;
    }
    toJpeg(downloadRef.current, {
      cacheBust: true,
      width: downloadRef.current?.scrollWidth || 0,
      height: downloadRef.current?.scrollHeight || 0 + 25,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = getFileName();
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDefaultSubject = () => {
    let date = moment().format("MM/DD/YYYY");
    return (
      "Vektor " + project?.name + " - System Snapshot " + date.split("T")[0]
    );
  };

  const handleShareCancel = () => {
    setOpenShareDetail(false);
    setEditorStateBody(EditorState.createEmpty());
    setShareDetails({
      to: [],
      subject: getDefaultSubject(),
      body: "",
      attachment: null,
    });
  };

  const setImageBlob = async () => {
    setShareDetails({ ...shareDetails, type: "Image" });
    const imageBlob = await toBlob(downloadRef.current, {
      cacheBust: true,
      width: downloadRef.current.scrollWidth,
      height: downloadRef.current.scrollHeight + 25,
      type: "image/jpeg",
    });

    setShareDetails({ ...shareDetails, attachment: imageBlob, type: "Image" });
  };

  const handleRefresh = async () => {
    setLoading(true);

    await fetchData();
    setLoading(false);
  };

  const handleFilterClear = () => {
    systemList.map((system) => {
      return (system.selected = false);
    });

    systemStatusList.map((status) => {
      return (status.selected = false);
    });
    phaseList.map((phase) => {
      return (phase.selected = false);
    });

    setSystemList(systemList);
    setSystemStatusList(systemStatusList);

    setPhaseList(phaseList);
    setIsFilter(false);
  };

  const handleOpen = () => setOpenFilter(true);
  const handleClose = () => setOpenFilter(false);

  const handleSystemRedirect = (system) => {
    const link = LINKS.EDIT_META_SYSTEM_INCOMPLETE.HREF.replace(
      ":projectId",
      projectId
    )
      .replace(":systemId", system?._id)
      .replace(":mainSystemId", system?.mainSystem?._id);
    window.open(link, "_blank");
  };

  const checkFilter = () => {
    let filter = false;
    if (systemList.filter((system) => system.selected).length > 0) {
      filter = true;
    }
    if (systemStatusList.filter((system) => system.selected).length > 0) {
      filter = true;
    }
    if (phaseList.filter((phase) => phase.selected).length > 0) {
      filter = true;
    }

    setIsFilter(filter);
  };

  const filteredSystemList = systemList
    .filter((sort) =>
      systemStatusList.some((status) => {
        if (systemStatusList.filter((status) => status.selected).length) {
          if (
            status.selected &&
            status.LABEL === "Not Started" &&
            sort.status === 0
          ) {
            return true;
          } else if (
            status.selected &&
            status.LABEL === "In Progress" &&
            sort.status > 0 &&
            sort.status < 100
          ) {
            return true;
          } else if (
            status.selected &&
            status.LABEL === "Completed" &&
            sort.status === 100
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
    )
    .map((system) => system.name);

  const filteredData = metaSystems
    .filter((sort) =>
      systemList.some((system) =>
        systemList.filter((system) => system.selected).length
          ? system.selected && system.name === sort.name
          : system
      )
    )
    .filter((sort) => filteredSystemList.includes(sort.name));

  const filteredDataPhases = phases.filter((sort) =>
    phaseList.some((phase) =>
      phaseList.filter((phase) => phase.selected).length
        ? phase.selected && phase.name === sort.name
        : phase
    )
  );

  let snapshotData = {};
  filteredData
    .filter((sys) => {
      return (
        !sys?.parentSystem ||
        metaSystems.filter((system) => system.parentSystem === sys._id)
          .length !== 0
      );
    })
    .forEach((system) => {
      snapshotData[system.name] = {
        ...system,
        phaseSystems: metaSystems.filter(
          (subSystem) => system._id === subSystem?.parentSystem
        ),
      };
    });

  const handleDownload = () => {
    handleCloseMenu();
    downloadExcelSystemSnapshot(
      () => {},
      projectId,
      phaseList,
      systemList,
      filteredSystemList,
      project,
      dispatch
    );
  };

  const handleShare = () => {
    const receivers = shareDetails.to.map((user) => {
      if (typeof user === "string") {
        return user;
      } else {
        return user.email;
      }
    });
    if (receivers.length === 0) {
      setError({ ...error, to: true });
      return;
    } else {
      setError({ ...error, to: false });
    }
    let fileName = getFileName() + ".jpeg";
    let data = new FormData();
    if (shareDetails.type === "Image") {
      data.append("file", new File([shareDetails?.attachment], fileName));
    }
    data.append("subject", shareDetails.subject);
    data.append("body", shareDetails.body);
    data.append("receivers", receivers);
    data.append("type", shareDetails.type);
    data.append("snapshotType", "system");
    data.append(
      "filters",
      JSON.stringify({
        phasesList: phaseList
          .filter((phase) => phase.selected)
          ?.map((phase) => phase.name),
        systemsList: systemList
          .filter((system) => system.selected)
          ?.map((system) => system.name),
        systemStatusList: filteredSystemList,
      })
    );
    data.append("projectId", projectId);
    setLoading(true);
    shareSnapshot(projectId, data)
      .then(() => {
        setLoading(false);
        handleShareCancel();
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: err?.response?.data?.info || "Error sharing snapshot",
          })
        );
      });
  };

  const overAllStatus = (phases, systemData) => {
    let sum = 0;

    for (const phase of phases) {
      let sytemPercantage = systemData?.phaseSystems?.find((system) =>
        system?.name?.includes(phase?.name)
      )?.mainSystem?.status;
      let phaseWeight = phase.weightage;
      console.log(phase.name + "==" + phaseWeight + "==" + sytemPercantage);

      sum = sum + (sytemPercantage * phaseWeight) / 100;
    }
    return sum;
  };

  return (
    <>
      <Modal open={openFilter} onClose={handleClose}>
        <CalendarFilter
          systemList={systemList}
          phaseList={phaseList}
          statusList={systemStatusList}
          setSystemList={setSystemList}
          setStatusList={setSystemStatusList}
          setPhaseList={setPhaseList}
          handleClose={handleClose}
          type="system-snapshot"
        />
      </Modal>
      <Modal
        open={openShareDetail}
        onClose={() => {
          setOpenShareDetail(false);
        }}
      >
        <Box style={style}>
          <Grid container className="pd-10">
            <Grid item xs={12}>
              <Typography variant="h6"> Email Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={8}>
                  <InputLabel className="mt-10" error={error.to}>
                    To ({shareDetails.to.length})
                  </InputLabel>
                  <Autocomplete
                    className="mt-10"
                    multiple
                    limitTags={2}
                    options={projectUsers}
                    freeSolo
                    clearOnBlur
                    value={shareDetails.to}
                    getOptionLabel={(option) => option.email}
                    onChange={(e, value) => {
                      if (value.length) {
                        setError({ ...error, to: false });
                      }
                      setShareDetails({ ...shareDetails, to: value });
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            variant="outlined"
                            label={
                              typeof option === "string" ? option : option.email
                            }
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    renderInput={(params) => {
                      const { InputProps, ...restParams } = params;
                      const { startAdornment, ...restInputProps } = InputProps;
                      return (
                        <TextField
                          {...restParams}
                          error={error.to}
                          helperText={
                            error.to ? "User list cannot be empty" : ""
                          }
                          variant="outlined"
                          onBlur={({ target }) => {
                            if (target.value !== "") {
                              setShareDetails({
                                ...shareDetails,
                                to: [...shareDetails.to, target.value],
                              });
                            }
                          }}
                          InputProps={{
                            ...restInputProps,
                            startAdornment: (
                              <div
                                style={{
                                  maxHeight: 60,
                                  overflowY: "auto",
                                }}
                              >
                                {startAdornment}
                              </div>
                            ),
                          }}
                        />
                      );
                    }}
                  ></Autocomplete>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={8}>
                  <InputLabel className="mt-10">Subject</InputLabel>
                  <TextField
                    className="mt-10"
                    variant="outlined"
                    fullWidth
                    value={shareDetails.subject}
                    onChange={({ target }) => {
                      setShareDetails({
                        ...shareDetails,
                        subject: target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="mt-10">Body</InputLabel>
              <div
                className="editor-wrapper mt-10"
                style={{
                  border: "1px solid #CCCCCC",
                }}
                onClick={focusEditorBody}
              >
                <Toolbar
                  editorState={editorStateBody}
                  setEditorState={setEditorStateBody}
                />
                <div className="editor-container editor-wrapper-body">
                  <Editor
                    ref={editorBody}
                    handleKeyCommand={handleKeyCommandBody}
                    customStyleMap={styleMap}
                    editorState={editorStateBody}
                    onChange={(editorStateBody) => {
                      const contentState = editorStateBody.getCurrentContent();
                      setShareDetails({
                        ...shareDetails,
                        body: stateToHTML(contentState),
                      });
                      setEditorStateBody(editorStateBody);
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className="mt-10" style={{ display: "flex" }}>
              <InputLabel style={{ marginTop: "2.5px" }}>
                Attachement ({shareDetails.type}):
              </InputLabel>
              <span
                className="ml-10 snapshot-link"
                onClick={() => {
                  if (shareDetails.type === "Image") {
                    handleDownloadImage();
                  } else {
                    handleDownload();
                  }
                }}
              >
                {getFileName()}
              </span>
            </Grid>
            <Grid item xs={12} className="mt-10">
              <Grid container justify="flex-end">
                <Button
                  variant="contained"
                  onClick={() => {
                    handleShareCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleShare}
                  className="ml-10"
                  variant="contained"
                  color="primary"
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <PageHeader title={` System snapshot`} links={NAV_LINKS} />
      <Grid container>
        <Grid item xs={12}>
          {!loading && (
            <Grid container justify="flex-start">
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={7}>
                    <Grid container justify="flex-start">
                      <Grid item>
                        <div
                          className="d-flex jsb-l mb-10"
                          style={{ height: "30px" }}
                        >
                          <IconButton
                            onClick={(e) => {
                              handleOpen();
                            }}
                          >
                            {/* <GridFilterAltIcon /> */}
                            <FilterIcon />
                          </IconButton>
                          <div className="mr-10">Filter By</div>
                          {isFilter && (
                            <div>
                              <Chip
                                onClick={() => handleFilterClear()}
                                label="Clear"
                                className="border-r-20 chip"
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <Grid container>
                          <div>
                            <IconButton
                              size="small"
                              className="ml-10"
                              variant="outlined"
                              id="download-button"
                              aria-controls={open ? "download-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                            >
                              {/* <Download /> */}
                              <DownloadIcon />
                            </IconButton>
                            <Menu
                              className="mt-40"
                              id="download-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleCloseMenu}
                              MenuListProps={{
                                "aria-labelledby": "download-button",
                              }}
                            >
                              <MenuItem onClick={handleDownload}>
                                Download as excel
                              </MenuItem>
                              <MenuItem onClick={handleDownloadImage}>
                                Download as image
                              </MenuItem>
                            </Menu>
                          </div>

                          <IconButton
                            className="ml-10"
                            size="small"
                            id="share-button"
                            aria-controls={openShare ? "share-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openShare ? "true" : undefined}
                            onClick={(event) => {
                              setShareDetails({
                                ...shareDetails,
                                subject: getDefaultSubject(),
                              });
                              setAnchorElShare(event.currentTarget);
                            }}
                          >
                            <ShareIcon />
                          </IconButton>
                          <Menu
                            className="mt-40"
                            id="share-menu"
                            anchorEl={anchorElShare}
                            open={openShare}
                            onClose={() => {
                              setAnchorElShare(null);
                            }}
                            MenuListProps={{
                              "aria-labelledby": "share-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setAnchorElShare(null);
                                setOpenShareDetail(true);
                                setShareDetails({
                                  ...shareDetails,
                                  type: "Excel",
                                });
                              }}
                            >
                              Share as excel
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setAnchorElShare(null);
                                setImageBlob();
                                setOpenShareDetail(true);
                              }}
                            >
                              Share as image
                            </MenuItem>
                          </Menu>
                          <IconButton
                            size="small"
                            className="ml-10"
                            onClick={handleRefresh}
                          >
                            {/* <Replay fontSize="10" /> */}
                            <RefreshIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {!loading && (
          <Grid item xs={12}>
            {filteredData?.length !== 0 && (
              <TableContainer style={{ height: "70vh" }}>
                <Table stickyHeader innerRef={downloadRef}>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell>Systems</CustomTableCell>
                      <CustomTableCell>Overall Status</CustomTableCell>
                      {filteredDataPhases?.map((phase) => {
                        return <CustomTableCell>{phase.name}</CustomTableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(snapshotData).map((system) => {
                      return (
                        <TableRow>
                          <CustomTableCell>{system}</CustomTableCell>
                          <CustomTableCell>
                            <span
                              className={
                                snapshotData[system].phaseSystems.length
                                  ? ""
                                  : "snapshot-link"
                              }
                              onClick={() => {
                                if (!snapshotData[system].phaseSystems.length) {
                                  handleSystemRedirect(snapshotData[system]);
                                }
                              }}
                            >
                              {Math.round(
                                overAllStatus(phases, snapshotData[system])
                              ) || snapshotData[system].mainSystem.status}{" "}
                              %
                              {/* {  console.log(snapshotData[system].mainSystem.status)} */}
                            </span>
                          </CustomTableCell>
                          {filteredDataPhases?.map((phase) => {
                            let phaseSystem = snapshotData[
                              system
                            ]?.phaseSystems?.find((system) =>
                              system?.name?.includes(phase?.name)
                            );
                            // {console.log("phaseSystem////////////", phaseSystem)}

                            let endDate =
                              moment
                                ?.max(
                                  phaseSystem?.mainSystem?.deliverables?.map(
                                    (del) => moment(del?.end?.split("T")[0])
                                  )
                                )
                                ?.format("YYYY-MM-DDT00:00:00.000Z") || null;
                            return (
                              <CustomTableCell
                                style={{
                                  color: !phaseSystem
                                    ? "#bdbdbd"
                                    : colorRA(
                                        null,
                                        endDate,
                                        phaseSystem?.mainSystem?.status,
                                        "snapshot"
                                      ),
                                  backgroundColor: phaseSystem
                                    ? "white"
                                    : "#bdbdbd",
                                }}
                              >
                                <span
                                  className="snapshot-link"
                                  onClick={() => {
                                    handleSystemRedirect(phaseSystem);
                                  }}
                                >
                                  {phaseSystem
                                    ? phaseSystem?.mainSystem?.status + "%"
                                    : ""}
                                </span>
                              </CustomTableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {filteredData?.length === 0 && (
              <div className="no-data-available">No data available</div>
            )}
          </Grid>
        )}
      </Grid>
      {loading && <Loader />}
    </>
  );
};

export default memo(SystemSnapshot);
