import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip, Menu, MenuItem, IconButton } from "@material-ui/core";

import { logoutUser } from "redux/actions/authActions";
import LINKS from "utils/constants/links";
import { ReactComponent as Account } from "icons/account.svg";

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await dispatch(logoutUser());
    window.location.pathname = LINKS.SIGN_IN.HREF;
  };

  const handleEditProfile = async () => {
    history.push(LINKS.EDIT_PROFILE.HREF);
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          id="profile-button"
          aria-controls={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorMenu) ? 'true' : undefined}
          onClick={toggleMenu}
        >
          <Account className="white__icon icon-large" />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        aria-labelledby="profile-button"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}
        <MenuItem onClick={handleEditProfile}>Edit profile</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default memo(UserDropdown);
