import React, { memo, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { ReactComponent as NotificationIcon } from "icons/notifications.svg";
import {
  Tooltip,
  Menu,
  IconButton as MuiIconButton,
  Badge,
  Grid,
  CardHeader,
  Link,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LINKS from "utils/constants/links";
import { useSelector, useDispatch } from "react-redux";
import { updateNotification } from "redux/actions/notificationActions";
import { ReactComponent as OpenInNew } from "icons/open_in_new.svg";
import NotificationList from "../../../../pages/Notification/Shared/NotificationList";
import { handleNavigation } from "pages/Notification/Shared/helpers";
const IconButton = styled(MuiIconButton)`
  svg {
    width: 26px;
    height: 26px;
  }
`;

function Notification() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = useState(0);
  const { currentUser } = useSelector((state) => state.auth);

  const { results, newCount, next } = useSelector(
    (state) => state?.notifications
  );

  useEffect(() => {
    setNotifications(results);
  }, [results]);

  const handleNotificationClick = async (notification, type, id) => {
    dispatch(
      updateNotification(
        {
          id: type === "read-one" ? id : currentUser?._id,
          type,
        },
        notification?.read
      )
    );
    if (type === "read-one") {
      handleNavigation(history, notification);
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton
          aria-owns={Boolean(anchorEl) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Badge
            badgeContent={newCount}
            color="error"
            style={{ transform: "translate(25px, -10px)" }}
          ></Badge>
          <NotificationIcon className="white__icon" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="notifications"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 400,
            height: notifications?.length === 0 ? 200 : 400,
          },
        }}
      >
        <CardHeader
          title={"Notifications"}
          action={
            <div>
              <IconButton
                onClick={() => {
                  history.push(LINKS.NOTIFICATIONS.HREF);
                }}
              >
                <OpenInNew />
              </IconButton>
            </div>
          }
        />
        {notifications?.length === 0 && (
          <Grid container justify="center" style={{ marginTop: "50px" }}>
            <Grid item xs={5}>
              No notifications available
            </Grid>
          </Grid>
        )}

        {notifications?.length !== 0 && (
          <Grid container justify="flex-end">
            <Grid item xs={4}>
              <Link
                className="ml-20 mb-10 pointer"
                onClick={() => {
                  handleNotificationClick(null, "read-all", currentUser?._id);
                }}
              >
                Mark all as read
              </Link>
            </Grid>
          </Grid>
        )}
        <NotificationList
          notifications={notifications}
          fullScreen={false}
          hasMore={next}
          handleNotificationClick={handleNotificationClick}
        />
      </Menu>
    </React.Fragment>
  );
}

export default memo(Notification);
