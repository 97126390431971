import {
  Card,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import Loader from "components/Loader";
import { memo, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { delPO, filterPO, getPO, postPO, putPO } from "services/api-finance";
import {
  TABLE_PO_COLUMN,
  getTxtColor,
  TABLE_PO_COLUMN_TOTAL,
} from "../helpers";
import { ReactComponent as AttachIcon } from "icons/attach_file.svg";
import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as DeleteIcon } from "icons/delete.svg";
import moment from "moment";
import AddPurchaseOrder from "./AddPurchaseOrder";
import { useDispatch } from "react-redux";
import { POPUP_TYPE, SORT_DIRS } from "utils/constants";
import { setPopup, showError } from "redux/actions/popupActions";
import { getVendors } from "services/api-vendor";
import { getDepartments } from "services/api-organization";
import FinanceFilter from "./FinanceFilter";
import { useTableSort } from "utils/hooks";
import { Highlighted } from "utils/helpers/hightlightText";
import NoDataAvailable from "components/NoDataAvailable";
import DocumentViewer from "components/DocumentViewer";

const newVendor = {
  amount: 0,
  vendor: "",
  poNumber: "",
  poDescription: "",
  issueDate: moment(new Date()).format("YYYY-MM-DD[T00:00:00.000Z]"),
};

const PurchaseOrder = ({
  projectId,
  project,
  openAdd,
  handleClose,
  openFilter,
  handleFilterClose,
  enableFilterClear,
  showClear,
  clearFilter,
  searchText,
  initialLoad,
}) => {
  const [poList, setPOList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [totalPurchaseOrderAmount, setTotalPurchaseOrderAmount] = useState(0);
  const [
    totalPurchaseOrderRemainingAmount,
    setTotalPurchaseOrderRemainingAmount,
  ] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [open, setOpen] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [editPO, setEditPO] = useState();
  const [filterBy, setFilterBy] = useState("default");
  const [filterId, setFilterId] = useState("");
  const dispatch = useDispatch();
  const [openFile, setOpenFile] = useState(false);
  const [viewFile, setViewFile] = useState();

  const { sortedRows, handleSort } = useTableSort(poList);
  const [order, setOrder] = useState(SORT_DIRS.asc);
  const [orderBy, setOrderBy] = useState(null);
  const timer = useRef(null);

  const getVendorList = async () => {
    if (project?.organization) {
      await getVendors(project?.organization)
        .then(async (resp) => {
          await setVendorList(resp);
        })
        .catch((err) => {});
    }
  };

  const getDepartmentList = async () => {
    if (project?.organization) {
      await getDepartments(project?.organization)
        .then(async (resp) => {
          await setDepartmentList(resp.data);
        })
        .catch((err) => {});
    }
  };

  const getPurchaseOrder = (skip, limit, type, query = "") => {
    let list = [...poList];
    if (type === "initial") {
      list = [];
      setLoading(true);
    }
    getPO(projectId, skip, limit, query)
      .then((resp) => {
        setLoading(false);
        setPOList([...list, ...resp.data]);
        if (query && type === "initial") {
          setTotalPurchaseOrderAmount(resp.totalAmount);
          setTotalPurchaseOrderRemainingAmount(resp.totalRemainingAmount);
        } else if (query && type === "loadMore") {
          setTotalPurchaseOrderAmount(
            totalPurchaseOrderAmount + resp.totalAmount
          );
          setTotalPurchaseOrderRemainingAmount(
            totalPurchaseOrderRemainingAmount + resp.totalRemainingAmount
          );
        } else {
          setTotalPurchaseOrderAmount(resp.totalPurchaseOrderAmount);
          setTotalPurchaseOrderRemainingAmount(
            resp.totalPurchaseOrderRemainingAmount
          );
        }

        if ([...list, ...resp.data].length === resp.pagination.count) {
          setLoadMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getPurchaseOrderByFilter = (skip, limit, type) => {
    let list = [...poList];
    if (type === "initial") {
      list = [];
      setLoading(true);
    }
    filterPO(projectId, filterBy, filterId, skip, limit)
      .then((resp) => {
        setLoading(false);
        setPOList([...list, ...resp.data]);
        setTotalPurchaseOrderAmount(
          totalPurchaseOrderAmount + resp.totalAmount
        );
        setTotalPurchaseOrderRemainingAmount(
          totalPurchaseOrderRemainingAmount + resp.totalRemainingAmount
        );
        if ([...list, ...resp.data].length === resp.pagination.count) {
          setLoadMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    setPaginationLoading(true);
    if (filterBy === "default") {
      getPurchaseOrder(poList.length, 10, "loadMore", searchText);
    } else {
      getPurchaseOrderByFilter(poList.length, 10, "loadMore");
    }
  };
  const closeForm = () => {
    setOpen(false);
    handleClose();
  };
  const openEditForm = (index) => {
    setEditPO(poList[index]);
    setOpen(true);
  };
  const saveVendor = (data) => {
    setLoading(true);
    if (data._id) {
      putPO(projectId, data._id, data)
        .then((resp) => {
          const updatedList = poList.map((po) => {
            if (po._id === data._id) {
              po = resp.updatedOrder;
            }
            return po;
          });
          setPOList(updatedList);
          setTotalPurchaseOrderAmount(resp.totalPurchaseOrderAmount);
          setTotalPurchaseOrderRemainingAmount(
            resp.totalPurchaseOrderRemainingAmount
          );
          closeForm();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      postPO(projectId, data)
        .then((resp) => {
          setPOList([resp.newPurchaseOrder, ...poList]);
          setTotalPurchaseOrderAmount(resp.totalPurchaseOrderAmount);
          setTotalPurchaseOrderRemainingAmount(
            resp.totalPurchaseOrderRemainingAmount
          );
          closeForm();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  const deletePO = (delId) => {
    if (delId) {
      setLoading(true);
      delPO(projectId, delId)
        .then((resp) => {
          setPOList(poList.filter((po) => po._id !== delId));
          setTotalPurchaseOrderAmount(resp.totalPurchaseOrderAmount);
          setTotalPurchaseOrderRemainingAmount(
            resp.totalPurchaseOrderRemainingAmount
          );
          closeForm();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data.code === 403) {
            dispatch(
              showError("Cannot Delete Purchase Order. Invoices exists.")
            );
          }
        });
    }
  };
  const setDeleteInfo = async (index) => {
    const data = await poList[index];
    onDelete(data._id);
  };

  const onDelete = async (delId) => {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.confirm,
        popupText: "Are you sure you want to delete this Purchase Order?",
        onConfirm: async () => {
          await deletePO(delId);
        },
      })
    );
  };
  const handleFilter = (filterData) => {
    if (filterData.filterBy === "byVendor" && filterData.vendor) {
      paginationLoading && setPaginationLoading(false);
      setLoadMore(true);
      setFilterBy(filterData.filterBy);
      setFilterId(filterData.vendor);
      setTotalPurchaseOrderAmount(0);
      setTotalPurchaseOrderRemainingAmount(0);
      setPOList([]);
      handleFilterClose();
      enableFilterClear();
    } else if (
      filterData.filterBy === "byDepartment" &&
      filterData.department
    ) {
      paginationLoading && setPaginationLoading(false);
      setLoadMore(true);
      setFilterBy(filterData.filterBy);
      setFilterId(filterData.department);
      setTotalPurchaseOrderAmount(0);
      setTotalPurchaseOrderRemainingAmount(0);
      setPOList([]);
      handleFilterClose();
      enableFilterClear();
    } else {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: "Please selected all fields",
        })
      );
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === SORT_DIRS.asc;
    const nextOrder = isAsc ? SORT_DIRS.desc : SORT_DIRS.asc;
    setOrder(nextOrder);
    setOrderBy(property);
    handleSort(property, nextOrder);
  };

  const createSortHandler = (columnId) => (event) => {
    handleRequestSort(event, columnId);
  };

  useEffect(() => {
    getVendorList();
    getDepartmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      ["byVendor", "byDepartment"].includes(filterBy) &&
      filterId &&
      poList.length === 0
    ) {
      getPurchaseOrderByFilter(0, 20, "initial");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy, filterId]);

  useEffect(() => {
    if (showClear === false) {
      getPurchaseOrder(0, 20, "initial", searchText);
      paginationLoading && setPaginationLoading(false);
      setLoadMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showClear]);
  useEffect(() => {
    if (openAdd === "purchaseOrder" && open === false) {
      setEditPO(newVendor);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAdd]);
  useEffect(() => {
    return () => {
      clearFilter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialLoad) {
      clearTimeout(timer.current);
      timer.current = setTimeout(
        () => {
          setPOList([]);
          getPurchaseOrder(0, 20, "initial", searchText);
        },
        searchText === "" ? 0 : 1000
      );
    }
    // eslint-disable-next-line
  }, [searchText, initialLoad]);

  const handleFileView = (file) => {
    setViewFile(file);
    setOpenFile(true);
  };

  return (
    <Card>
      {sortedRows?.length && !loading ? (
        <TableContainer>
          <InfiniteScroll
            dataLength={sortedRows?.length}
            hasMore={loadMore}
            next={() => {
              // if (!paginationLoading) {
              handleLoadMore();
              // }
            }}
            loader={
              sortedRows?.length >= 20 &&
              loadMore && (
                <div className="d-jsb-cen">
                  <CircularProgress size={20} className="mb-10" />
                </div>
              )
            }
            scrollThreshold={0.9}
            height={window.innerHeight * 0.65}
          >
            <Table stickyHeader>
              <TableHead>
                {TABLE_PO_COLUMN.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ minWidth: column.minWidth }}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    {!["action", "consumed", "attachment"].includes(
                      column.id
                    ) ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={
                          orderBy === column.id ? order : SORT_DIRS.asc
                        }
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableHead>
              <TableBody>
                {sortedRows.map((row, index) => (
                  <TableRow key={index}>
                    {TABLE_PO_COLUMN.map((column) => {
                      let value = row[column.id];
                      if (column.id === "vendor") {
                        value = row?.vendorInfo?.name;
                      }
                      if (column.id === "amount") {
                        value = "$ " + value?.toLocaleString();
                      }
                      if (["poDescription", "poNumber"].includes(column.id)) {
                        value = (
                          <Highlighted text={value} highlight={searchText} />
                        );
                      }
                      if (column.id === "consumed") {
                        value = row?.amount - row?.remainingAmount;
                        value = "$ " + value.toLocaleString();
                      }
                      if (column.id === "remainingAmount") {
                        value = (
                          <span
                            className={getTxtColor(
                              row.amount,
                              row.remainingAmount
                            )}
                          >
                            $&nbsp;{row.remainingAmount?.toLocaleString()}
                          </span>
                        );
                      }
                      if (column.id === "department") {
                        value = row?.vendorInfo?.departmentName;
                      }
                      if (column.id === "attachment") {
                        const fileName = row?.attachment
                          ? row?.attachment?.fileName?.substring(
                              row?.attachment?.fileName?.indexOf("-") + 1
                            )
                          : "";
                        value = fileName ? (
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => handleFileView(row?.attachment)}
                          >
                            <AttachIcon className="primary__icon icons_small" />
                          </IconButton>
                        ) : (
                          ""
                        );
                      }
                      if (
                        ["issueDate", "latestInvoiceDate"].includes(
                          column.id
                        ) &&
                        value
                      ) {
                        value = moment(row[column.id]?.split("T")[0]).format(
                          "MM/DD/YY"
                        );
                      }
                      if (column.id === "action") {
                        value = (
                          <>
                            <IconButton
                              className="pd-0"
                              onClick={() => openEditForm(index)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              className="pd-0"
                              onClick={() => setDeleteInfo(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
                {poList.length === 0 && (
                  <div className="pd-20">No Data Available</div>
                )}
              </TableBody>
              <TableHead className="bottom-sticky">
                {TABLE_PO_COLUMN_TOTAL.map((column) =>
                  ["Blank"].includes(column.label) ? (
                    <TableCell key={column.id} align="center"></TableCell>
                  ) : ["Total"].includes(column.label) ? (
                    <TableCell key={column.id} align="center">
                      {column.label}
                    </TableCell>
                  ) : (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.id === "amount" ? (
                        "$ " + totalPurchaseOrderAmount?.toLocaleString()
                      ) : column.id === "remainingAmount" ? (
                        <span
                          className={getTxtColor(
                            totalPurchaseOrderAmount,
                            totalPurchaseOrderRemainingAmount
                          )}
                        >
                          $&nbsp;
                          {totalPurchaseOrderRemainingAmount?.toLocaleString()}
                        </span>
                      ) : (
                        column.id === "consumed" &&
                        "$ " +
                          (
                            totalPurchaseOrderAmount -
                            totalPurchaseOrderRemainingAmount
                          )?.toLocaleString()
                      )}
                    </TableCell>
                  )
                )}
              </TableHead>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      ) : null}
      {!sortedRows?.length && !loading && <NoDataAvailable />}
      {loading && <Loader />}
      {open && (
        <AddPurchaseOrder
          projectId={projectId}
          open={open}
          handleClose={closeForm}
          editPO={editPO}
          vendorList={vendorList}
          saveVendor={saveVendor}
        />
      )}
      {openFile && (
        <DocumentViewer
          open={openFile}
          fileData={viewFile}
          handleClose={() => setOpenFile(false)}
        />
      )}
      {openFilter && (
        <FinanceFilter
          open={openFilter}
          handleFilterClose={handleFilterClose}
          vendorList={vendorList}
          departmentList={departmentList}
          handleFilter={handleFilter}
          type="purchaseOrder"
        />
      )}
    </Card>
  );
};

export default memo(PurchaseOrder);
