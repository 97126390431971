import { del, get, getJQ, patch, post } from "./axios";

const getPatients = async (projectId, skip = 0, limit = null) =>
  await getJQ(`v2/cellglass/project/${projectId}/patients`, {
    skip,
    limit,
  });

const getPatientById = async (projectId, patientId) => {
  return await get(`v2/cellglass/project/${projectId}/patients/${patientId}`);
};

const addPatient = async (projectId, payload) => {
  return await post(`v2/cellglass/project/${projectId}/patients`, payload);
};

const editPatient = async (projectId, patientId, payload) => {
  return await patch(
    `v2/cellglass/project/${projectId}/patients/${patientId}`,
    payload
  );
};

const deletePatient = async (projectId, patientId) => {
  return await del(`v2/cellglass/project/${projectId}/patients/${patientId}`);
};

const getSampleCategories = async (projectId) =>
  await get(`v2/cellglass/project/${projectId}/sampleCategories`);

const addOrUpdateSampleCategories = async (projectId, payload) => {
  return await post(
    `v2/cellglass/project/${projectId}/sampleCategories`,
    payload
  );
};

const deleteSampleCategory = async (projectId, sampleCategoryId) => {
  return await del(
    `v2/cellglass/project/${projectId}/sampleCategories/${sampleCategoryId}`
  );
};

const getSampleCategoryStages = async (projectId, sampleCategory = null) =>
  await getJQ(`v2/cellglass/project/${projectId}/sampleCategoryStages`, {
    sampleCategory,
  });

const addOrUpdateSampleCategoryStages = async (projectId, payload) => {
  return await post(
    `v2/cellglass/project/${projectId}/sampleCategoryStages`,
    payload
  );
};

const deleteSampleCategoryStage = async (projectId, sampleCategoryStageId) => {
  return await del(
    `v2/cellglass/project/${projectId}/sampleCategoryStages/${sampleCategoryStageId}`
  );
};

const addPatientSample = async (projectId, patientId, payload) => {
  return await post(
    `v2/cellglass/project/${projectId}/patients/${patientId}/samples`,
    payload
  );
};

const scanPatientSample = async (identifier) => {
  return await get(`v2/cellglass/patients/samples/${identifier}/scan`);
};

const deleteSample = async (projectId, patientId, sampleId) => {
  return await del(
    `v2/cellglass/project/${projectId}/patients/${patientId}/samples/${sampleId}`
  );
};

export {
  getPatients,
  getPatientById,
  addPatient,
  editPatient,
  deletePatient,
  getSampleCategories,
  addOrUpdateSampleCategories,
  deleteSampleCategory,
  getSampleCategoryStages,
  addOrUpdateSampleCategoryStages,
  deleteSampleCategoryStage,
  addPatientSample,
  scanPatientSample,
  deleteSample,
};
