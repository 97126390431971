import { memo, useEffect, useRef, useState } from "react";
import {
  // useHistory,
  useParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "parts/PageHeader";
import LINKS from "utils/constants/links";
import {
  getPhases,
  getMultiPhaseDetail,
} from "../../../../../redux/actions/projects";
import { addPhaseSnapshotFilter } from "services/api-project";
import { getProjects } from "../../..//../../redux/actions/projects";
// import { readMetaSystem } from "redux/actions/metaSystem";
import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
} from "@material-ui/core";
import Select, { components } from "react-select";
import { PhaseSnapshotTable } from "./components/phaseSnapshot";
import SnapshotFilter from "./components/SnapshotFilter";
import {
  SYSTEM_STATUS_TYPES,
  getFileName,
  downloadPhaseSnapshotExcel,
  getDefaultSubject,
} from "./components/helper";
import { EditorState, RichUtils } from "draft-js";
import Loader from "components/Loader";
import { toJpeg, toBlob } from "html-to-image";
import SharePopupModel from "./components/SharePopupModel";
import { shareMatrixSnapshot } from "services/api-project";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import { ReactComponent as FilterIcon } from "icons/filter.svg";
import { ReactComponent as DownloadIcon } from "icons/download.svg";
import { ReactComponent as ShareIcon } from "icons/share.svg";
import { ReactComponent as RefreshIcon } from "icons/refresh.svg";
import ActionTypes from "utils/constants/action-types";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} className="mr-10" />
      {children}
    </components.Option>
  );
};

const PhaseSnapshot = () => {
  const { projectId } = useParams();
  // const history = useHistory();
  const projects = useSelector((state) => state.projects);
  const project = projects.results.find((item) => item._id === projectId);

  const {
    deliverableDetail: { deliverableData },
    phaseDetail: { systemData, masterSystemData },
    phaseData: currentPhaseData,
  } = useSelector((state) => state.projects.phaseDetail);
  const {
    phaseDetail: { phaseData },
    phases: phasesOrderData,
  } = useSelector((state) => state.projects.phases);
  const users = useSelector((state) => state.users.results);
  const projectUsers = users?.filter((user) =>
    project?.assignedUsers?.includes(user._id)
  );

  const phaseSnapshotFilters =
    project?.phaseSnapshotFilters?.filter((f) => !f?.phase && f?.phaseIds) ||
    [];

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedPhases, setSelectedPhases] = useState([]);
  const [newSelectedPhases, setNewSelectedPhases] = useState([]);
  const [unselectedPhases, setUnselectedPhases] = useState([]);
  const [reducerPhases, setReducerPhases] = useState([]);
  const [phaseLoading, setPhaseLoading] = useState(false);
  const [deliverableList, setDeliverableList] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const [systemFilter, setSystemFilter] = useState([]);
  const [deliverableFilter, setDeliverableFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  const [selectedSavedFilter, setSelectedSavedFilter] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorElShare, setAnchorElShare] = useState(null);
  const openShare = Boolean(anchorElShare);
  const [openShareDetail, setOpenShareDetail] = useState(false);
  const [shareDetails, setShareDetails] = useState({
    to: [],
    subject: "",
    body: "",
    attachment: null,
    type: "",
  });
  const [error, setError] = useState({ to: false });
  const [editorStateBody, setEditorStateBody] = useState(
    EditorState.createEmpty()
  );
  const downloadRef = useRef(null);

  const NAV_LINKS = [
    LINKS.PROJECT_MANAGEMENT,
    LINKS.PROJECTS,
    {
      HREF: LINKS.EDIT_PROJECT.HREF.replace(":id", projectId),
      TITLE: project?.name || "Not Found",
    },
  ];

  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (
      phaseData.length &&
      !selectedPhases.length &&
      !newSelectedPhases.length
    ) {
      setSelectedPhases([
        {
          label: phaseData[0].phaseName,
          value: phaseData[0]._id,
        },
      ]);
      const checkReducer =
        reducerPhases?.includes(phaseData[0]._id) ||
        currentPhaseData
          ?.map((data) => data.phaseId)
          ?.includes(phaseData[0]._id);
      if (!checkReducer) {
        setNewSelectedPhases([phaseData[0]._id]);
      }
    }
    // eslint-disable-next-line
  }, [phaseData]);

  useEffect(() => {
    if (currentPhaseData?.length) {
      currentPhaseData?.map((data) => data.phaseId);
      setReducerPhases(currentPhaseData?.map((data) => data.phaseId));
    }
    const tempUnselectedPhases = currentPhaseData
      ?.map((data) => data.phaseId)
      .filter((data) => {
        return !selectedPhases.find((select) => select.value === data);
      });
    setUnselectedPhases(tempUnselectedPhases);
    // eslint-disable-next-line
  }, [currentPhaseData, phaseData, selectedPhases]);

  useEffect(() => {
    const commonSys = [];
    deliverableList?.forEach((m) => {
      !commonSys.find((f) => m.systemName === f.name) &&
        commonSys.push({ name: m.systemName, id: m.metaSystemId });
    });
    setSystemFilter(
      commonSys?.map((m) => {
        const existingSystemFilter = systemFilter?.find(
          (f) => f.id === m.id && f.selected
        );
        return existingSystemFilter
          ? selectedSavedFilter?.filters.systems
            ? {
                ...existingSystemFilter,
                selected: selectedSavedFilter.filters.systems.includes(m.id),
              }
            : { ...existingSystemFilter }
          : selectedSavedFilter?.filters.systems
          ? {
              ...m,
              selected: selectedSavedFilter.filters.systems.includes(m.id),
            }
          : {
              ...m,
              selected: false,
            };
      })
    );
    const commonDel = [];
    deliverableList?.forEach((m) => {
      !commonDel.includes(m.name) && commonDel.push(m.name);
    });
    setDeliverableFilter(
      commonDel?.map((m) => {
        const existingDeliverableFilter = deliverableFilter?.find(
          (f) => f.name === m && f.selected
        );
        return existingDeliverableFilter
          ? selectedSavedFilter?.filters.deliverables
            ? {
                ...existingDeliverableFilter,
                selected: selectedSavedFilter.filters.deliverables.includes(m),
              }
            : { ...existingDeliverableFilter }
          : selectedSavedFilter?.filters.deliverables
          ? {
              name: m,
              selected: selectedSavedFilter.filters.deliverables.includes(m),
            }
          : {
              name: m,
              selected: false,
            };
      })
    );
    // eslint-disable-next-line
  }, [deliverableList]);

  useEffect(() => {
    setStatusFilter(
      SYSTEM_STATUS_TYPES?.map((type) => {
        const existingStatusFilter = statusFilter?.find(
          (f) => f.name === type.name && f.selected
        );
        return existingStatusFilter
          ? selectedSavedFilter?.filters.status
            ? {
                ...existingStatusFilter,
                selected: selectedSavedFilter?.filters.status.includes(
                  type.name
                ),
              }
            : { ...existingStatusFilter }
          : selectedSavedFilter?.filters.status
          ? {
              ...type,
              name: type.LABEL,
              selected: selectedSavedFilter?.filters.status.includes(
                type.LABEL
              ),
            }
          : {
              ...type,
              name: type.LABEL,
              selected: false,
            };
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getPhases(projectId, setLoading));
    };
    if (!phaseData.length) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [dispatch, projectId]);

  useEffect(() => {
    if (newSelectedPhases.length > 0) {
      const multiPhaseIdsObj = {
        phaseIds: newSelectedPhases,
      };

      const newFetchData = async (multiPhaseIdsObj) => {
        setPhaseLoading(true);
        await dispatch(getMultiPhaseDetail(projectId, multiPhaseIdsObj, true));
        // await dispatch(
        //   readMetaSystem({ project: projectId }, true, setPhaseLoading)
        // );
        setPhaseLoading(false);
      };

      if (phaseData.length) {
        newFetchData(multiPhaseIdsObj);
        setNewSelectedPhases([]);
      }
    }

    // eslint-disable-next-line
  }, [newSelectedPhases, phaseData]);

  useEffect(() => {
    let deliverableList = deliverableData
      ?.filter((del) => !unselectedPhases?.includes(del.phaseId))
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map((deliverable) => ({
        ...deliverable,
        reviewer: deliverable.reviewer.map((val) => {
          return {
            ...val,
            users: val.users.map((user) => {
              return {
                ...user,
                name: users.find((userVal) => userVal._id === user.userid)
                  ?.name,
              };
            }),
          };
        }),
        approver: deliverable.approver.map((val) => {
          return {
            ...val,
            users: val.users.map((user) => {
              return {
                ...user,
                name: users.find((userVal) => userVal._id === user.userid)
                  ?.name,
              };
            }),
          };
        }),
      }));
    setDeliverableList(deliverableList);
    // eslint-disable-next-line
  }, [deliverableData, unselectedPhases]);

  useEffect(() => {
    let tempFilter = false;
    if (
      systemFilter.some((sys) => sys.selected) ||
      deliverableFilter.some((del) => del.selected) ||
      statusFilter.some((status) => status.selected)
    ) {
      tempFilter = true;
    }
    setIsFilter(tempFilter);
  }, [systemFilter, deliverableFilter, statusFilter]);

  const handleSetNewSelectedPhases = (data) => {
    setNewSelectedPhases(data);
  };

  const handleSetSelectedSavedFilter = (data) => {
    setSelectedSavedFilter(data);
  };

  const getMultiPhaseList = () => {
    return phaseData.map((ph) => ({ label: ph.phaseName, value: ph._id }));
  };

  const handleChangePhase = (value) => {
    setSelectedPhases(value);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleOnBlur = (value) => {
    const tempNewSelectedPhases = selectedPhases
      .filter((data) => !reducerPhases.includes(data.value))
      .map((data) => data.value);

    const tempUnselectedPhases = reducerPhases.filter((data) => {
      return !selectedPhases.find((select) => select.value === data);
    });

    if (tempNewSelectedPhases.length > 0) {
      setNewSelectedPhases(tempNewSelectedPhases);
    }

    setUnselectedPhases(tempUnselectedPhases);
  };

  const handleFilterApply = (
    systemFilterList,
    deliverableFilterList,
    statusFilterList
  ) => {
    setSystemFilter(systemFilterList);
    setDeliverableFilter(deliverableFilterList);
    setStatusFilter(statusFilterList);

    handleCloseFilter();
  };

  const handleClearFilter = () => {
    setSelectedSavedFilter(undefined);
    setSystemFilter(systemFilter?.map((m) => ({ ...m, selected: false })));
    setDeliverableFilter(
      deliverableFilter?.map((m) => ({ ...m, selected: false }))
    );
    setStatusFilter(
      statusFilter.map((m) => ({
        ...m,
        selected: false,
      }))
    );
  };

  const filteredData = deliverableList
    .filter((f) =>
      isFilter && systemFilter.some((sys) => sys.selected)
        ? systemFilter
            .filter((m) => m.selected)
            .map((sys) => sys.id)
            .includes(f.metaSystemId)
        : true
    )
    .filter((f) =>
      isFilter && deliverableFilter.some((del) => del.selected)
        ? deliverableFilter
            .filter((f) => f.selected)
            .map((del) => del.name)
            .includes(f.name)
        : true
    )
    .filter((f) =>
      isFilter && statusFilter.some((sys) => sys.selected)
        ? statusFilter
            .filter((f) => f.selected)
            .map((status) => status.name)
            .includes(
              f.status === 0
                ? "Not Started"
                : f.status > 0 && f.status < 100
                ? "In Progress"
                : f.status === 100
                ? "Completed"
                : ""
            )
        : true
    );

  const filteredDeliverableObject = masterSystemData
    ?.filter((f) => {
      const systemID = systemData
        ?.filter(
          (sys) =>
            sys.parentSystem === f.masterSystemId ||
            f.masterSystemId === sys._id
        )
        ?.map((f) => f._id);
      const systemInstances = filteredData?.filter((del) =>
        systemID?.includes(del.metaSystemId)
      );
      return systemInstances.length;
    })
    ?.map((m) => {
      const systemID = systemData
        ?.filter(
          (sys) =>
            sys.parentSystem === m.masterSystemId ||
            m.masterSystemId === sys._id
        )
        ?.map((m) => m._id);
      const systemInstances = filteredData?.filter((del) =>
        systemID?.includes(del.metaSystemId)
      );
      const masterSystemDeliverables = [];

      systemInstances.forEach((o) => {
        if (!masterSystemDeliverables?.find((ms) => ms.name === o.name)) {
          const deliverablesPhaseList = selectedPhases.map((phase) =>
            filteredData.find(
              (f) =>
                f.phaseId === phase.value &&
                f.name === o.name &&
                systemID?.includes(f.metaSystemId)
            )
          );
          masterSystemDeliverables.push({
            name: o.name,
            orderIndex: Math.min(
              ...deliverablesPhaseList
                ?.filter((f) => f)
                .map(
                  (m) =>
                    phasesOrderData?.find((f) => f._id === m.phaseId).orderIndex
                )
            ),
            deliverables: deliverablesPhaseList,
          });
        }
      });

      return {
        name: m.name,
        masterSystemDeliverables: masterSystemDeliverables?.sort(
          (a, b) => a.orderIndex - b.orderIndex
        ),
      };
    });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDownloadImage = () => {
    handleCloseMenu();
    setIsDownloading(true);
    if (downloadRef.current === null) {
      setIsDownloading(false);
      return;
    }
    toJpeg(downloadRef.current, {
      cacheBust: true,
      width: downloadRef.current?.scrollWidth || 0,
      height: downloadRef.current?.scrollHeight || 0 + 25,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = getFileName(project);
        link.href = dataUrl;
        link.click();
        setIsDownloading(false);
      })
      .catch((err) => {
        setIsDownloading(false);
        console.log(err);
      });
  };

  const handleRefresh = async () => {
    setPhaseLoading(true);
    handleClearFilter();
    const selectedPhaseIds = selectedPhases?.map((ph) => ph?.value);
    const multiPhaseIdsObj = {
      phaseIds: selectedPhaseIds,
    };

    await dispatch(getMultiPhaseDetail(projectId, multiPhaseIdsObj));
    setPhaseLoading(false);
  };

  const handleDownload = () => {
    handleCloseMenu();
    downloadPhaseSnapshotExcel(
      setIsDownloading,
      selectedPhases,
      projectId,
      systemFilter,
      deliverableFilter,
      statusFilter,
      project,
      dispatch
    );
  };

  const getLoading = () => {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ height: "100px" }}
      >
        <Grid item xs={12} className="mt-30">
          <CircularProgress color="secondary" />
        </Grid>
      </Grid>
    );
  };

  const handleShareDetail = (data) => {
    setOpenShareDetail(data);
  };

  const setImageBlob = async () => {
    setShareDetails({ ...shareDetails, type: "Image" });
    const imageBlob = await toBlob(downloadRef.current, {
      cacheBust: true,
      width: downloadRef.current.scrollWidth,
      height: downloadRef.current.scrollHeight + 25,
      type: "image/jpeg",
    });

    setShareDetails({ ...shareDetails, attachment: imageBlob, type: "Image" });
  };

  const hanldeOnClickShare = (event) => {
    setShareDetails({
      ...shareDetails,
      subject: getDefaultSubject(project),
    });
    setAnchorElShare(event.currentTarget);
  };

  const handleShareAsExcel = () => {
    setAnchorElShare(null);
    setOpenShareDetail(true);
    setShareDetails({
      ...shareDetails,
      type: "Excel",
    });
  };

  const handleShareAsImage = () => {
    setAnchorElShare(null);
    setImageBlob();
    setOpenShareDetail(true);
  };

  const handleShareCancel = () => {
    handleShareDetail(false);
    setEditorStateBody(EditorState.createEmpty());
    setShareDetails({
      to: [],
      subject: getDefaultSubject(),
      body: "",
      attachment: null,
    });
  };

  const handleKeyCommandBody = (command) => {
    const newState = RichUtils.handleKeyCommand(editorStateBody, command);
    if (newState) {
      setEditorStateBody(newState);
      return true;
    }
    return false;
  };

  const handleShare = () => {
    const receivers = shareDetails.to.map((user) => {
      if (typeof user === "string") {
        return user;
      } else {
        return user.email;
      }
    });
    if (receivers.length === 0) {
      setError({ ...error, to: true });
      return;
    } else {
      setError({ ...error, to: false });
    }
    let fileName = getFileName() + ".jpeg";
    let data = new FormData();
    if (shareDetails.type === "Image") {
      data.append("file", new File([shareDetails?.attachment], fileName));
    }
    data.append("subject", shareDetails.subject);
    data.append("body", shareDetails.body);
    data.append("receivers", receivers);
    data.append("type", shareDetails.type);
    data.append("snapshotType", "phase");
    data.append(
      "phase",
      JSON.stringify({
        phaseIds: [...selectedPhases.map((s) => s.value)],
      })
    );
    data.append(
      "filters",
      JSON.stringify({
        deliverable: deliverableFilter
          .filter((deliverable) => deliverable.selected)
          ?.map((deliverable) => deliverable.name),
        system: systemFilter
          .filter((system) => system.selected)
          ?.map((system) => system.id),
        status: statusFilter
          .filter((sta) => sta.selected)
          ?.map((sta) => sta.name),
      })
    );
    data.append("projectId", projectId);
    setLoading(true);
    shareMatrixSnapshot(projectId, data)
      .then(() => {
        setLoading(false);
        handleShareCancel();
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: err?.response?.data?.info || "Error sharing snapshot",
          })
        );
      });
  };

  const handleSaveFilterData = (
    newFilters,
    snapshotFilters,
    systemFilterList,
    deliverableFilterList,
    statusFilterList
  ) => {
    addPhaseSnapshotFilter(projectId, [newFilters, ...snapshotFilters])
      .then((response) => {
        dispatch({ type: ActionTypes.EDIT_PROJECT, payload: response?.data });
        const responseFilter = response?.data?.phaseSnapshotFilters?.find(
          (f) => f.name === newFilters.name
        );
        handleSetSelectedSavedFilter(responseFilter || newFilters);
        handleFilterApply(
          systemFilterList,
          deliverableFilterList,
          statusFilterList
        );
      })
      .catch((err) => {
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText:
              err?.response?.data?.info || "Error saving phase snapshot filter",
          })
        );
      });
  };

  return (
    <>
      <PageHeader title={`Phase Snapshot`} links={NAV_LINKS} />
      <Grid container className="pb-10">
        <Grid item xs={6} alignItems="center">
          <Grid container alignItems="center">
            <Grid item xs={4} style={{ zIndex: 100 }}>
              <Select
                isMulti
                value={selectedPhases}
                placeholder={"Select Phase"}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                options={getMultiPhaseList()}
                hideSelectedOptions={false}
                onChange={handleChangePhase}
                components={{
                  Option: InputOption,
                }}
                onBlur={handleOnBlur}
              />
            </Grid>
            <Grid item>
              <div className="d-flex jsb-l ">
                <IconButton onClick={() => setOpenFilter(true)}>
                  <FilterIcon />
                </IconButton>
                <div className="mr-10">Filter By</div>
                {isFilter && (
                  <div>
                    <Chip
                      onClick={handleClearFilter}
                      label="Clear"
                      className="border-r-20 chip"
                    />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="flex-end">
            <IconButton
              size="small"
              className="ml-10"
              variant="outlined"
              id="download-button"
              aria-controls={open ? "download-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              {isDownloading ? (
                <CircularProgress size={22} color="secondary" />
              ) : (
                // <Download />
                <DownloadIcon />
              )}
            </IconButton>
            <Menu
              className="mt-40"
              id="download-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "download-button",
              }}
            >
              <MenuItem onClick={handleDownload}>Download as excel</MenuItem>
              <MenuItem onClick={handleDownloadImage}>
                Download as image
              </MenuItem>
            </Menu>

            <IconButton
              className="ml-10"
              size="small"
              id="share-button"
              aria-controls={openShare ? "share-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openShare ? "true" : undefined}
              onClick={hanldeOnClickShare}
            >
              <ShareIcon />
            </IconButton>
            <Menu
              className="mt-40"
              id="share-menu"
              anchorEl={anchorElShare}
              open={openShare}
              onClose={() => {
                setAnchorElShare(null);
              }}
              MenuListProps={{
                "aria-labelledby": "share-button",
              }}
            >
              <MenuItem onClick={handleShareAsExcel}>Share as excel</MenuItem>
              <MenuItem onClick={handleShareAsImage}>Share as image</MenuItem>
            </Menu>

            <IconButton size="small" className="ml-10" onClick={handleRefresh}>
              {/* <Replay fontSize="10" /> */}
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {phaseLoading ? (
        getLoading()
      ) : filteredDeliverableObject?.length === 0 ||
        selectedPhases?.length === 0 ? (
        <div
          className="d-jsb-cen w-100"
          style={{ height: "100px", backgroundColor: "white" }}
          ref={downloadRef}
        >
          No data available
        </div>
      ) : (
        <Grid container>
          <Paper
            style={{
              marginTop: "10px",
              width: "100%",
              overflow: "auto",
              maxHeight: "600px",
            }}
          >
            <div ref={downloadRef}>
              <PhaseSnapshotTable
                filteredDeliverableObject={filteredDeliverableObject}
                selectedPhases={selectedPhases}
                projectId={projectId}
              />
            </div>
          </Paper>
        </Grid>
      )}

      <Modal open={openFilter} onClose={handleCloseFilter}>
        <SnapshotFilter
          handleSetNewSelectedPhases={handleSetNewSelectedPhases}
          handleSetSelectedSavedFilter={handleSetSelectedSavedFilter}
          handleSaveFilterData={handleSaveFilterData}
          setSelectedPhases={setSelectedPhases}
          handleCloseFilter={handleCloseFilter}
          handleFilterApply={handleFilterApply}
          handleClearFilter={handleClearFilter}
          snapshotFilters={phaseSnapshotFilters}
          selectedSavedFilter={selectedSavedFilter}
          systemFilter={systemFilter}
          deliverableFilter={deliverableFilter}
          statusFilter={statusFilter}
          selectedPhases={selectedPhases}
          reducerPhases={reducerPhases}
          phaseData={phaseData}
          projectId={projectId}
          project={project}
        />
      </Modal>

      <SharePopupModel
        openShareDetail={openShareDetail}
        handleShareDetail={handleShareDetail}
        handleShareCancel={handleShareCancel}
        handleShare={handleShare}
        handleDownloadImage={handleDownloadImage}
        handleDownload={handleDownload}
        projectUsers={projectUsers}
        shareDetails={shareDetails}
        setShareDetails={setShareDetails}
        handleKeyCommandBody={handleKeyCommandBody}
        editorStateBody={editorStateBody}
        setEditorStateBody={setEditorStateBody}
        project={project}
        error={error}
        setError={setError}
      />

      {loading && <Loader />}
    </>
  );
};

export default memo(PhaseSnapshot);
