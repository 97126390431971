import joi from "joi";

import { STRING_INPUT_VALID, SELECT_VALID } from "utils/constants/validations";
// import { ENVIRONMENT } from "config";

export const schema = joi.object().keys({
  name: STRING_INPUT_VALID,
  number: STRING_INPUT_VALID,
  organization: SELECT_VALID,
  // type: (ENVIRONMENT?.toLowerCase() !== "qms")? joi.string().allow("", null): SELECT_VALID,
  type: joi.string().allow("", null),
  projectManager: joi.string().allow("", null),
  supervisor: joi.string().allow("", null),
  assignedUsers: joi.array(),
});
