import React, { useState, useEffect } from "react";
import { searchDocuments } from "services/api-document";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Card,
  CircularProgress,
  TableContainer,
} from "@material-ui/core";
import { DocumentHelper } from "pages/Projects/Documents/helpers";
import LINKS from "utils/constants/links";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

export default function AllDocuments({ projectId }) {
  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [documents, setDocuments] = useState([]);
  const history = useHistory();
  const { TABLE_COLUMN_DRAFT, DOC_STATUS } = DocumentHelper();

  const getDocumentLists = async (skip, limit, type) => {
    if (type === "initial") {
      setLoading(true);
    }
    await searchDocuments(projectId, "", skip, limit)
      .then((resp) => {
        setDocuments([...documents, ...resp.data]);
        setLoading(false);
        setPaginationLoading(false);
        if ([...documents, ...resp.data].length === resp.pagination.count) {
          setLoadMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setPaginationLoading(false);
        console.log("error >>>>> ", err);
      });
  };

  useEffect(() => {
    getDocumentLists(0, 20, "initial");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = () => {
    setPaginationLoading(true);
    getDocumentLists(documents.length, 5, "loadMore");
  };

  if (loading)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );

  if (!documents.length)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>No Documents found.</p>
      </div>
    );

  return (
    <Card>
      <TableContainer>
        <InfiniteScroll
          dataLength={documents?.length}
          hasMore={loadMore}
          next={() => {
            if (!paginationLoading) {
              handleLoadMore();
            }
          }}
          loader={
            documents?.length >= 20 &&
            loadMore && (
              <div className="d-jsb-cen">
                <CircularProgress size={20} className="mb-10" />
              </div>
            )
          }
          scrollThreshold={0.9}
          height={window.innerHeight * 0.65}
        >
          <Table stickyHeader>
            <TableHead>
              {TABLE_COLUMN_DRAFT.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {documents.map((doc, index) => (
                <TableRow key={index}>
                  {TABLE_COLUMN_DRAFT.map((column) => {
                    let value = doc[column.id];
                    if (column.id === "system") {
                      value = value?.name
                    }
                    if (column.id === "documentStatus") {
                      value = DOC_STATUS.find(
                        (status) => status.value === doc[column.id]
                      )?.label;
                    }
                    if (column.id === "deliverable") {
                      value = doc?.system?.mainSystem?.deliverables?.find(del => del._id === value)?.name;
                    }
                    if (column.id === "documentNumber") {
                      value = (
                        <Typography
                          className="pointer"
                          color="primary"
                          onClick={() =>
                            history.push(
                              LINKS.EDIT_DOCUMENTS.HREF.replace(
                                ":projectId",
                                projectId
                              ).replace(":id", doc._id)
                            )
                          }
                        >
                          {value}
                        </Typography>
                      );
                    }
                    if (column.id === "documentTitle") {
                      value = <Typography>{value}</Typography>;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </TableContainer>
    </Card>
  );
}
