import PageHeader from "parts/PageHeader";
import { memo, useEffect, useRef, useState } from "react";
import LINKS from "utils/constants/links";
import { getNavLinks } from "../ProjectPhases/helpers";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjects } from "redux/actions/projects";
import { Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import useUserPermissions from "utils/hooks/useUserPermission";
import { SettingsRounded } from "@material-ui/icons";
import { ColorButton } from "components/UI/Buttons";
import PatientList from "./components/PatientList";

const PatientData = () => {
  const [searchText, setSearchText] = useState("");
  const { projectId } = useParams();
  const history = useHistory();
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const project = useSelector((state) =>
    state.projects.results.find((p) => p._id === projectId)
  );
  const { isAdmin } = useUserPermissions();

  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
  }, [dispatch, projectId]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    // handleSearchDebounced(e.target.value);
  };

  return (
    <>
      <PageHeader
        title={LINKS.PATIENT_LIST.TITLE}
        links={getNavLinks(project?.name, projectId)}
      />
      <Grid
        container
        columns={12}
        justify="flex-end"
        alignItems="center"
        className="mb-10"
      >
        <Grid item xs={4} className="txt-end">
          <TextField
            value={searchText}
            onChange={handleSearchChange}
            variant="outlined"
            inputRef={searchRef}
            size="small"
            label="Search"
            style={{
              marginRight: "10px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton className="pd-0">
                    <SearchIcon />
                  </IconButton>
                  {searchText && (
                    <IconButton className="pd-0">
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <ColorButton
            colour="lightGreen"
            onClick={() =>
              history.push(
                LINKS.ADD_PATIENT.HREF.replace(":projectId", projectId)
              )
            }
          >
            Register Patient
          </ColorButton>
          {isAdmin && (
            <>
              <IconButton
                className="ml-5 pd-0"
                onClick={() =>
                  history.push(
                    LINKS.CELL_GLASS_SETTINGS.HREF.replace(
                      ":projectId",
                      projectId
                    )
                  )
                }
              >
                <SettingsRounded />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
      <PatientList projectId={projectId} />
    </>
  );
};

export default memo(PatientData);
