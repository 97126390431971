import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "parts/PageHeader";
import LINKS from "utils/constants/links";
import { getNavLinks } from "../ProjectPhases/helpers";
import { useParams } from "react-router-dom";
import { getProjects } from "redux/actions/projects";
import Loader from "components/Loader";
import {
  addFile,
  createFolder,
  deleteFileFolder,
  getFolders,
  searchLibrary,
  updateFolder,
} from "services/api-library";
import {
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  debounce,
} from "@material-ui/core";
import "./index.css";
import { MoreVertRounded } from "@material-ui/icons";
import { ColorButton } from "components/UI/Buttons";
// import SearchIcon from "@material-ui/icons/Search";
// import CloseIcon from "@material-ui/icons/Close";
import EditFolder from "./components/EditFolder";
import { uploadMultipleFiles } from "services/api-general";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import MoveFolder from "./components/MoveFolder";
import BreadcrumbsFolder from "./components/BreadcrumbsFolder";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { InputAdornment, TextField } from "@mui/material";
import { FileIcon, defaultStyles } from "react-file-icon";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { Highlighted } from "utils/helpers/hightlightText";
import { ReactComponent as SearchIcon } from "icons/search.svg";
import { ReactComponent as CloseIcon } from "icons/cross_circle.svg";
import UserShareSelector from "./components/UserShareSelector";
import _ from "lodash";

const Library = () => {
  const [loading, setLoading] = useState(false);
  const [folderList, setFolderList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [addFolder, setAddFolder] = useState(false);
  const [folder, setFolder] = useState();
  const [selectedData, setSelectedData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMove, setOpenMove] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [shareDialog, setShareDialog] = useState(false);
  const [dropdownUserList, setDropdownUserlist] = useState([]);
  const [publicAccess, setPublicAccess] = useState(true);
  const searchRef = useRef(null);
  const { projectId, category, libraryId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const fileInput = useRef();
  const { results: users } = useSelector(({ users }) => users);
  const associatedUsersIds = useSelector(
    (state) =>
      state.projects.results.find((project) => project._id === projectId)
        ?.assignedUsers
  );

  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
  }, [projectId, dispatch]);

  const project = useSelector((state) =>
    state.projects.results.find((p) => p._id === projectId)
  );

  useEffect(() => {
    getFolderList(libraryId ? libraryId : null, category ? category : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, libraryId, category]);

  const getFolderList = async (parent, category) => {
    setLoading(true);
    await getFolders(projectId, parent, category)
      .then((resp) => {
        setFolder(parent);
        setLoading(false);
        setFolderList(resp?.folders);
        setBreadcrumbs(resp?.breadcrumbs);
        setFileList(resp?.files);
      })
      .catch((err) => {
        setLoading(false);
        const errMessage = err.response?.data?.info || "File Error";
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: errMessage,
          })
        );
        console.log("error >>>>>> ", err);
      });
  };

  const ownerCheck = (userId, ownerId) => {
    const owner = userId === ownerId ? true : false;
    return owner;
  };

  const getUserList = async (allowedUsersList, ownerId) => {
    setDropdownUserlist(
      _.uniqBy(
        [
          ...users
            .filter((user) => associatedUsersIds?.includes(user._id))
            ?.map((user) => {
              return {
                ...user,
                selected: allowedUsersList?.includes(user._id)
                  ? true
                  : false || ownerCheck(user._id, ownerId),
                label: user.name,
                value: user._id,
                owner: ownerCheck(user._id, ownerId),
              };
            }),
        ],
        "_id"
      )
    );
  };

  const newFolder = async (name) => {
    setLoading(true);
    const data = {
      name: name,
      parent: folder,
      category: "CUSTOM",
      predefined: false,
    };
    await createFolder(projectId, data)
      .then((resp) => {
        const folders = folderList;
        folders.push(resp);
        setFolderList(folders);
        setLoading(false);
        setAddFolder(false);
        setSelectedData(null);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>>> ", err);
      });
  };

  const getAllowedUsersList = () => {
    const list = dropdownUserList
      .filter((user) => user.selected)
      .map((user) => user._id);

    return list;
  };

  const updateName = async (name) => {
    setLoading(true);
    const data = {
      name: name,
      type: selectedData?.type,
      allowedUsersList: getAllowedUsersList(),
      publicAccess: publicAccess,
    };
    await updateFolder(projectId, selectedData?._id, data)
      .then((resp) => {
        setShareDialog(false);
        if (data.type === "FOLDER") {
          const folders = folderList;
          folders.map((folder) => {
            if (folder._id === resp?._id) {
              folder.name = resp.name;
              folder.allowedUsersList = resp.allowedUsersList;
              folder.publicAccess = resp.publicAccess;
            }
            return folder;
          });
          setFolderList(folders);
        } else {
          const files = fileList;
          files.map((file) => {
            if (file._id === resp?._id) {
              file.name = resp.name;
              file.allowedUsersList = resp.allowedUsersList;
              file.publicAccess = resp.publicAccess;
            }
            return file;
          });
          setFileList(files);
        }
        setLoading(false);
        setAddFolder(false);
        setSelectedData(null);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>>> ", err);
      });
  };

  const moveTo = async (folderId) => {
    setLoading(true);
    const data = {
      folder: folderId,
      type: selectedData?.type,
    };
    await updateFolder(projectId, selectedData?._id, data)
      .then((resp) => {
        if (data.type === "FOLDER") {
          const folders = folderList;
          const index = folders.findIndex((x) => x._id === selectedData?._id);
          folders.splice(index, 1);
          setFolderList(folders);
        } else {
          const files = fileList;
          const index = files.findIndex((x) => x._id === selectedData?._id);
          files.splice(index, 1);
          setFileList(files);
        }
        setLoading(false);
        setOpenMove(false);
        setSelectedData(null);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>>> ", err);
      });
  };

  const newFile = async (files) => {
    setLoading(true);

    const filesToUpload = files.map((file) => ({
      file,
      name: file.fileName.substring(file.fileName.indexOf("-") + 1),
    }));
    const data = {
      folder: folder,
      files: filesToUpload,
    };
    await addFile(projectId, data)
      .then((resp) => {
        const files = [...fileList, ...resp];
        setFileList(files);
        setLoading(false);
        setAddFolder(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>>> ", err);
      });
  };

  const handleCapture = ({ target }) => {
    if (target.files?.length) {
      upload(target.files);
    }
  };

  const upload = async (files) => {
    setLoading(true);
    let formData = new FormData();
    Object.values(files).forEach((file) => {
      formData.append("files", file);
    });

    formData.append("module", "library");
    formData.append("projectId", projectId);
    await uploadMultipleFiles(formData)
      .then((resp) => {
        setLoading(false);
        newFile(resp.data);
      })
      .catch((err) => {
        setLoading(false);
        const errMessage = err.response?.data?.info || "File Error";
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: errMessage,
          })
        );
        console.log("error >>>>> ", err);
      });
  };

  const handleDelete = async (resourceId, type) => {
    setLoading(true);
    await deleteFileFolder(projectId, resourceId, type)
      .then((resp) => {
        if (type === "FOLDER") {
          const folders = folderList;
          const index = folders.findIndex((x) => x._id === resourceId);
          folders.splice(index, 1);
          setFolderList(folders);
        } else {
          const files = fileList;
          const index = files.findIndex((x) => x._id === resourceId);
          files.splice(index, 1);
          setFileList(files);
        }
        setLoading(false);
        setSelectedData(null);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>>> ", err);
      });
  };

  const handleMenuClick = (e, index, type) => {
    const fileData = type === "FILE" ? fileList[index] : folderList[index];
    setPublicAccess(fileData.publicAccess);
    getUserList(fileData.allowedUsersList, fileData.owner);
    const data = {
      _id: fileData._id,
      name: fileData.name,
      type,
      predefined: fileData.predefined,
    };
    setSelectedData(data);
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e, reset = true) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
    if (reset) {
      setSelectedData(null);
    }
  };

  const handleOptionClick = (e, option) => {
    switch (option) {
      case "Rename":
        setAddFolder(true);
        break;
      case "Move":
        setOpenMove(true);
        break;
      case "Delete":
        onDelete();
        break;
      case "Copy link":
        if (selectedData.type === "FOLDER") {
          navigator.clipboard.writeText(
            window.location.href + `/${selectedData._id}`
          );
        } else {
          let modifiedUrl = window.location.href.replace("/DMS_FILE", "");
          modifiedUrl = modifiedUrl.replace(
            "/library/file/",
            "/library/null/file/"
          );

          let url = `${modifiedUrl}/file/${selectedData._id}`;
          url = url.replace("/library/file/", "/library/null/file/");
          navigator.clipboard.writeText(url);
        }
        break;
      case "Share":
        OnShare();
        break;
      default:
        return;
    }
    handleMenuClose(e, false);
  };

  const onDelete = () => {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.confirm,
        popupText: `Are you sure you want to delete this ${
          selectedData.type === "FILE" ? "file" : "folder"
        }`,
        onConfirm: async () => {
          await handleDelete(selectedData._id, selectedData.type);
        },
      })
    );
  };

  const OnShare = () => {
    setShareDialog(true);
  };

  const shareDialogClose = () => {
    setShareDialog(false);
  };

  const getOptions = (index, type) => {
    const openMenu = Boolean(anchorEl);
    const options = selectedData?.predefined
      ? ["Copy link"]
      : ["Rename", "Move", "Delete", "Copy link", "Share"];

    return (
      <div key={index}>
        <IconButton
          className="pd-0"
          onClick={(e) => handleMenuClick(e, index, type)}
        >
          <MoreVertRounded />
        </IconButton>
        {anchorEl !== null &&
          (fileList[index]?._id === selectedData?._id ||
            folderList[index]?._id === selectedData?._id) && (
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  onClick={(e) => handleOptionClick(e, option)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          )}
      </div>
    );
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    handleSearchDebounced(e.target.value);
  };

  const searchLibraryFilesAndFolders = async (value) => {
    setLoading(true);
    await searchLibrary(projectId, value)
      .then((resp) => {
        setFolderList(resp?.folders);
        setBreadcrumbs([]);
        setFileList(resp?.files);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>>> ", err);
      });
  };

  const handleSearchDebounced = useMemo(
    () =>
      debounce((value) => {
        if (value) {
          searchLibraryFilesAndFolders(value);
        } else {
          getFolderList(libraryId);
        }
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fileType = (fileName) => {
    return fileName?.split(".")?.pop();
  };

  const openFolder = (folder) => {
    if (folder?.category === "DMS_FILE" && breadcrumbs.length === 1) {
      history.push(
        LINKS.LIBRARY_CATEGORY_NESTED.HREF.replace(":projectId", projectId)
          .replace(":category", folder.category)
          .replace(":libraryId", folder._id)
      );
    } else {
      if (breadcrumbs[0]?.name === "DMS") {
        history.push(
          LINKS.LIBRARY_CATEGORY_NESTED.HREF.replace(":projectId", projectId)
            .replace(":category", "DMS_FILE")
            .replace(":libraryId", folder._id)
        );
      } else {
        history.push(
          LINKS.LIBRARY_NESTED.HREF.replace(":projectId", projectId).replace(
            ":libraryId",
            folder._id
          )
        );
      }
    }
  };

  const openFile = (libraryId, fileId) => {
    history.push(
      LINKS.LIBRARY_FILE_VIEW.HREF.replace(":projectId", projectId)
        .replace(":libraryId", libraryId)
        .replace(":fileId", fileId)
    );
  };

  const updatePublicAccess = () => {
    setPublicAccess(!publicAccess);
  };

  const updateUserSelection = (userId, isSelected) => {
    let data = [];
    data = dropdownUserList;
    let users = data.map((user) => {
      if (user._id === userId) {
        return { ...user, selected: isSelected };
      } else {
        return user;
      }
    });
    setDropdownUserlist(users);
  };

  return (
    <>
      <PageHeader
        title={LINKS.LIBRARY.TITLE}
        links={getNavLinks(project?.name, projectId)}
      />
      <div className="d-jsb-c mb-10">
        {breadcrumbs ? (
          <BreadcrumbsFolder
            breadcrumbs={breadcrumbs}
            getFolderList={getFolderList}
          />
        ) : (
          <Typography>Search Results</Typography>
        )}
        <div>
          <TextField
            value={searchText}
            onChange={handleSearchChange}
            variant="outlined"
            inputRef={searchRef}
            size="small"
            label="Search"
            style={{
              marginRight: "10px",
            }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            InputProps={{
              style: { height: 37, width: 222 },

              endAdornment: (
                <InputAdornment>
                  <IconButton className="pd-0">
                    <SearchIcon />
                  </IconButton>
                  {searchText && (
                    <IconButton
                      className="pd-0"
                      onClick={() => {
                        setSearchText("");
                        getFolderList(libraryId);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <ColorButton colour="lightGreen" onClick={() => setAddFolder(true)}>
            Add Folder
          </ColorButton>
          <ColorButton
            colour="lightGreen"
            className="ml-5"
            onClick={() => fileInput.current.click()}
          >
            Upload File
            <input
              accept=".pdf, .docx, .doc, .xlsx, .xls"
              className="d-none"
              multiple
              type="file"
              name="file"
              ref={fileInput}
              onChange={(e) => handleCapture(e)}
            />
          </ColorButton>
        </div>
      </div>
      {fileList?.length > 0 && (
        <Card className="pb-10 mb-10">
          <div className="pd-10">File List</div>
          <div className="d-flex f-wrap">
            {fileList?.map((fileData, index) => (
              <Card
                className="file-card"
                key={index}
                onClick={() => {
                  openFile(fileData?.folder, fileData?._id);
                }}
              >
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justify="center"
                  className="file-icon-grid"
                >
                  <Grid item className="file-icon-card">
                    <Card item md={0} className="file-icon-card">
                      <FileIcon
                        fold={false}
                        extension={fileType(fileData?.file?.fileName)}
                        {...defaultStyles[fileType(fileData?.file?.fileName)]}
                      />
                    </Card>
                  </Grid>
                </Grid>

                <Grid container justify="space-between">
                  <Grid item md={11} alignItems="center">
                    <Tooltip title={fileData?.name} className="mt-5 file-name">
                      <div className="txt-elps wd-100">
                        <Highlighted
                          text={fileData?.name}
                          highlight={searchText}
                        />
                      </div>
                    </Tooltip>
                    {fileData?.info?.version !== null && (
                      <div className="txt-elps wd-100 version-info">
                        Version: {fileData?.info?.version}
                      </div>
                    )}
                  </Grid>
                  <Grid item md={1} className="folder-menu-file">
                    {getOptions(index, "FILE")}
                  </Grid>
                </Grid>
              </Card>
            ))}
          </div>
        </Card>
      )}
      {folderList?.length > 0 && (
        <>
          <Card className="pb-10">
            <div className="pd-10">Folder List</div>
            <div className="d-flex f-wrap">
              {folderList?.map((folder, index) => (
                <Card
                  className="folder-card"
                  key={index}
                  onClick={() => {
                    openFolder(folder);
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item md={2}>
                      <Card item className="folder-icon-card">
                        <FolderOutlinedIcon className="folder-icon" />
                      </Card>
                    </Grid>

                    <Grid item md={9} alignItems="center">
                      <Tooltip title={folder?.name}>
                        <div className="txt-elps wd-100 file-name">
                          <Highlighted
                            text={folder?.name}
                            highlight={searchText}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item md={1} className="folder-menu">
                      {getOptions(index, "FOLDER")}
                      {/* {folder?.predefined && <IconButton></IconButton>} */}
                    </Grid>
                  </Grid>
                </Card>
              ))}
            </div>
          </Card>
        </>
      )}
      {loading && <Loader />}
      {addFolder && (
        <EditFolder
          mode={selectedData ? "Edit" : "Add"}
          type={selectedData?.type === "FOLDER" ? "Folder" : "File"}
          open={addFolder}
          handleClose={() => {
            setAddFolder(false);
            setSelectedData(null);
          }}
          folderId={folder}
          onSave={selectedData ? updateName : newFolder}
          editData={selectedData}
        />
      )}
      {openMove && (
        <MoveFolder
          open={openMove}
          handleClose={() => {
            setSelectedData(null);
            setOpenMove(false);
          }}
          projectId={projectId}
          onMove={moveTo}
          selectedData={selectedData}
        />
      )}
      {fileList.length === 0 && folderList.length === 0 && (
        <div>No Data Found</div>
      )}

      <UserShareSelector
        shareDialog={shareDialog}
        shareDialogClose={shareDialogClose}
        dropdownUserList={dropdownUserList}
        updatePublicAccess={updatePublicAccess}
        publicAccess={publicAccess}
        updateName={updateName}
        updateUserSelection={updateUserSelection}
      />
    </>
  );
};

export default memo(Library);
