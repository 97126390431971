import React, { memo } from "react";
import { withTheme } from "styled-components/macro";
import { Pie } from "react-chartjs-2";
import { Chart } from "chart.js";
import { textColor } from "../../constant";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

const InsightsPieChart = ({ data }) => {
  const emptyChart =
    data?.datasets[0]?.data?.reduce((acc, val) => acc + val, 0) || 0;
  let chartData = {};
  if (emptyChart === 0) {
    chartData = {
      labels: [...(data?.labels || "None")],
      datasets: [
        {
          data: [100],
          backgroundColor: ["grey"],

          borderWidth: 1,
        },
      ],
    };
  } else {
    chartData = data;
  }
  return (
    <div className="mt-30">
      <Pie
        data={chartData}
        height={350}
        options={{
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              color: "white",
              font: {
                size: 18,
              },
              formatter: function (value) {
                return emptyChart === 0
                  ? "No Data Available"
                  : value === 0
                  ? ""
                  : value + "%";
              },
            },
            legend: {
              position: "right",
              align: "start",

              title: {
                display: emptyChart === 0 ? false : true,
                text: "Phase Status",
                padding: 30,
                color: textColor,
                font: {
                  size: 18,
                },
              },
              labels: {
                font: {
                  size: 16,
                },
                color: textColor,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default memo(withTheme(InsightsPieChart));
