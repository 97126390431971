import { Dialog, DialogTitle, Grid, Card } from "@material-ui/core";
import { memo, useState, useRef } from "react";
import "./index.css";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import Close from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import LibSelect from "./LibSelect";

import { signUrl } from "services/api-general";
import { decryptData } from "utils/helpers/utility";

const UploadOption = ({
  open,
  projectId,
  handleClose,
  handleCapture,
  handleUploadClose,
  uploads,
  updateLibUploadStatus,
  multipleFiles,
  updateFileBlobLoading,
}) => {
  const fileInput = useRef();
  const [isLibOption, setIsLibOption] = useState(false);
  const getSelectedFile = async (files) => {
    getFileBlob(files);
  };

  const getFileBlob = async (files) => {
    if (multipleFiles) {
      let data = [];
      updateFileBlobLoading(true);
      try {
        for (const item of files) {
          const resp = await signUrl({ fileUrl: item.file.fileUrl });
          const response = await fetch(decryptData(resp.encryptedUrl));
          const blob = await response.blob();
          const file = new File([blob], item.name);
          data.push(file);
        }
        uploads(data, true);
      } catch (err) {
        updateFileBlobLoading(false);
        console.error("error >>>>> ", err);
      }
    } else {
      try {
        const resp = await signUrl({ fileUrl: files[0].file.fileUrl });
        const response = await fetch(decryptData(resp.encryptedUrl));
        const blob = await response.blob();
        const file = new File([blob], files[0].name);
        uploads(file, true);
      } catch (err) {
        console.error("error >>>>> ", err);
      }
    }

    if (updateLibUploadStatus) {
      updateLibUploadStatus(false);
    }
  };

  return (
    <>
      <Dialog fullWidth onClose={handleClose} open={open} maxWidth="xs">
        <div className="title-div">
          <DialogTitle className="card-title">Upload File</DialogTitle>

          <Close className="close-icon" onClick={handleClose} />
        </div>

        <div>
          <Grid container className="mb-20" justify="center">
            <Grid item xs={2}></Grid>
            <Grid style={{ textAlign: "center" }}>
              <Grid item xs={3}>
                <Card
                  item
                  className="option-icon-card"
                  onClick={() => {
                    setIsLibOption(true);
                  }}
                >
                  <MenuBookOutlinedIcon
                    className="option-icon"
                    style={{ fontSize: 80 }}
                  />
                </Card>
              </Grid>
              <Grid item>
                <Typography>Library</Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid style={{ textAlign: "center" }}>
              <Grid item xs={3}>
                <Card
                  item
                  className="option-icon-card"
                  onClick={() => {
                    fileInput.current.click();
                  }}
                >
                  <DesktopWindowsOutlinedIcon
                    className="option-icon"
                    style={{ fontSize: 80 }}
                  />
                  <input
                    accept=".pdf, .docx, .doc, .xlsx, .xls"
                    multiple={multipleFiles}
                    className="d-none"
                    type="file"
                    name="file"
                    ref={fileInput}
                    onChange={(e) => {
                      handleCapture(e);
                    }}
                  />
                </Card>
              </Grid>
              <Grid item>
                <Typography>Computer</Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </div>
      </Dialog>

      {isLibOption && (
        <LibSelect
          open={isLibOption}
          getSelectedFile={getSelectedFile}
          handleUploadClose={handleUploadClose}
          handleClose={() => {
            setIsLibOption(false);
          }}
          projectId={projectId}
          isLibOption={isLibOption}
          multipleFiles={multipleFiles}
        />
      )}
    </>
  );
};
export default memo(UploadOption);
