import { PERMISSION_TYPES } from "utils/constants";

export const ALLOWED_ROLES = [
  PERMISSION_TYPES.superAdmin,
  PERMISSION_TYPES.admin,
  PERMISSION_TYPES.supervisor,
  PERMISSION_TYPES.projectManager,
];
export const CHECKED_FLAG_COLOR = "#40f603";
export const UNCHECKED_FLAG_COLOR = "#ff0200";
