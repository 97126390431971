import "gantt-task-react/dist/index.css";
import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import "./Chart.css";
import { useState } from "react";
import moment from "moment";
import LINKS from "utils/constants/links";
import { colorRA } from "pages/Projects/ProjectInsight/components/DeliverableSnapshot/snapshotHelper";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function CustomGanttChart({
  chartData,
  dateFilter,
  finalData,
  setPhase,
  type = "insights",
  projectId = "",
  graphType = "gantt",
}) {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const yearStart = moment([dateFilter])
    .startOf("year")
    .format("YYYY-MM-DD[T00:00:00.000Z]");

  const yearEnd = moment([dateFilter])
    .endOf("year")
    .format("YYYY-MM-DD[T00:00:00.000Z]");

  const availableYear = dateFilter;

  const data = chartData
    ?.filter(
      (task) =>
        new Date(task.start).getFullYear() === availableYear ||
        new Date(task.end).getFullYear() === availableYear
    )
    ?.map((data) => {
      let start = data.start;
      let end = data.end;
      return {
        ...data,
        start: moment(start).isAfter(yearStart) ? start : yearStart,
        end: moment(end).isBefore(yearEnd) ? end : yearEnd,
      };
    });

  if (data.length !== 0) {
    finalData = data?.map((a) => {
      return { ...a, method: setPhase };
    });
    if (type !== "insights") {
      let systemObject = {};
      finalData.forEach((data) => {
        if (systemObject[data.system]) {
          systemObject[data.system].deliverables.push(data);
        } else {
          systemObject[data.system] = {
            deliverables: [data],
            systemId: data.systemId,
            mainSystemId: data.mainSystemId,
          };
        }
      });
      finalData = systemObject;
    }
  }

  function showTooltip(evt, data) {
    let tooltip = document.getElementById("tooltip-status");
    tooltip.style.width = "180px";
    let value = type === "insights" ? "" : data.name;
    let progress =
      type === "insights" ? "" : "Progress : " + data.progress + " %";
    tooltip.innerHTML =
      value +
      "<br/>" +
      "<b>" +
      data.actualStart.split("T")[0] +
      " to " +
      data.actualEnd.split("T")[0] +
      "</b>" +
      "<br/>" +
      "Duration : " +
      moment(data.end).diff(moment(data.start), "day") +
      " days <br /> " +
      progress;
    tooltip.style.display = "block";
    tooltip.style.left =
      evt.pageX > screenSize.dynamicWidth / 2
        ? evt.pageX - 10 - 180 + "px"
        : evt.pageX + 10 + "px";
    tooltip.style.top =
      evt.pageY > screenSize.dynamicHeight / 2
        ? evt.pageY - 110 - 10 + "px"
        : evt.pageY + 10 + "px";
  }

  function showTooltipName(evt, data) {
    let tooltip = document.getElementById("tooltip-name");
    tooltip.innerHTML = data;
    tooltip.style.display = "block";
    tooltip.style.left = evt.pageX + 10 + "px";
    tooltip.style.top =
      evt.pageY > screenSize.dynamicHeight / 2
        ? evt.pageY - 30 - 10 + "px"
        : evt.pageY + 10 + "px";
  }

  function hideTooltip() {
    var tooltip = document.getElementById("tooltip-status");
    tooltip.style.display = "none";
  }

  function hideTooltipName() {
    var tooltip = document.getElementById("tooltip-name");
    tooltip.style.display = "none";
  }

  const redirectSystems = (systemId, mainSystemId) => {
    const link = LINKS.EDIT_META_SYSTEM.HREF.replace(":projectId", projectId)
      .replace(":systemId", systemId)
      .replace(":mainSystemId", mainSystemId);
    window.open(link, "_blank");
  };

  return type === "insights" ? (
    <div>
      <div id="tooltip-status" className="tooltip-absolute"></div>
      <div id="tooltip-name" className="tooltip-absolute"></div>
      <Grid container justify="center">
        <Grid item xs={12}>
          <div style={{ overflowX: "auto" }}>
            <div className="chart-header-row">
              <div className="chart-header-cell">Phases</div>
              <div className="chart-header-child-row">
                {months.map((value, monthIndex) => {
                  return (
                    <div className="chart-header-cell">
                      <p>{value}</p>
                    </div>
                  );
                })}
              </div>
            </div>

            {finalData?.map((data) => {
              const { start, end } = data;

              let progressWidth = moment(moment(end.split("T")[0])).diff(
                moment(start.split("T")[0]),
                "days"
              );
              let numberOfDays = moment(moment(yearEnd.split("T")[0])).diff(
                moment(yearStart.split("T")[0]),
                "days"
              );
              let numberOfDaysFromStart = moment(
                moment(start.split("T")[0])
              ).diff(moment(yearStart.split("T")[0]), "days");

              return (
                <div className="chart-row">
                  <div
                    className="chart-header-cell cell-bg"
                    onClick={() => {
                      setPhase(data.id);
                    }}
                    onMouseMove={(evt) => showTooltipName(evt, data.phaseName)}
                    onMouseOut={() => hideTooltipName()}
                  >
                    <p className="gantt-chart-text">{data.phaseName}</p>
                  </div>
                  <div className="chart-header-child-row">
                    {/* ABSOLUTE PROGRESS VIEW START */}
                    <div className="insight-chart-progress-absolute-row ">
                      <div
                        style={{
                          display: "flex",
                          flex: numberOfDaysFromStart / numberOfDays,
                          justifyContent: "end",
                        }}
                      >
                        {progressWidth <= 20 && numberOfDaysFromStart > 300 && (
                          <div
                            onMouseMove={(evt) => showTooltip(evt, data)}
                            onMouseOut={() => hideTooltip()}
                          >
                            <p style={{ paddingRight: 5 }}>{data.status}</p>
                          </div>
                        )}
                      </div>
                      <div
                        onMouseMove={(evt) => showTooltip(evt, data)}
                        onMouseOut={() => hideTooltip()}
                        style={{
                          flex: progressWidth / numberOfDays,
                        }}
                        className="insight-chart-progress-bar-bg"
                      >
                        {progressWidth > 20 && (
                          <p
                            style={{
                              color: "white",
                              position: "absolute",
                            }}
                          >
                            {data.status}
                          </p>
                        )}
                        <div
                          style={{
                            flex: data.progress / 100,
                          }}
                          className="insight-chart-progress-bar"
                        ></div>
                        <div
                          style={{
                            flex: 1 - data.progress / 100,
                            backgroundColor: "transparent",
                          }}
                          className="insight-chart-progress-bar"
                        ></div>
                      </div>
                      {progressWidth <= 20 && numberOfDaysFromStart < 30 && (
                        <div
                          onMouseMove={(evt) => showTooltip(evt, data)}
                          onMouseOut={() => hideTooltip()}
                        >
                          <p style={{ paddingLeft: 5 }}>{data.status}</p>
                        </div>
                      )}
                    </div>
                    {/* ABSOLUTE PROGRESS VIEW END */}

                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((value) => {
                      return (
                        <div
                          className="chart-header-cell cell-bg"
                          style={{ alignSelf: "stretch" }}
                        ></div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div>
      <div id="tooltip-status" className="tooltip-absolute"></div>
      <div id="tooltip-name" className="tooltip-absolute"></div>
      <Grid container justify="center">
        <Grid item xs={12}>
          <div
            style={{
              maxHeight: "60vh",
              overflowX: "auto",
            }}
          >
            <div className="chart-header-row">
              <div className="chart-header-cell">System</div>
              <div className="chart-header-child-row">
                {months.map((value) => {
                  return (
                    <div className="chart-header-cell">
                      <p>{value}</p>
                    </div>
                  );
                })}
              </div>
            </div>

            {Object.keys(finalData)?.map((data) => {
              return (
                <>
                  <div className="chart-row ">
                    <div
                      className="chart-header-cell cell-bg"
                      onClick={() => {
                        redirectSystems(
                          finalData[data].systemId,
                          finalData[data].mainSystemId
                        );
                      }}
                      onMouseMove={(evt) => showTooltipName(evt, data)}
                      onMouseOut={() => hideTooltipName()}
                    >
                      <p className="gantt-chart-text">{data}</p>
                    </div>

                    <div className="chart-header-child-row-inner-column">
                      {finalData[data].deliverables.map((value, dataIndex) => {
                        const { start, end } = value;

                        let monthOffSet = new Date(
                          dateFilter,
                          Number(end.split("T")[0].split("-")[1]),
                          0
                        ).getDate();

                        let ganttWidth = moment(moment(end.split("T")[0])).diff(
                          moment(start.split("T")[0]),
                          "days"
                        );

                        let numberOfDays = moment(
                          moment(yearEnd.split("T")[0])
                        ).diff(moment(yearStart.split("T")[0]), "days");

                        let numberOfDaysFromStart = moment(
                          moment(start.split("T")[0])
                        ).diff(moment(yearStart.split("T")[0]), "days");

                        let endDate = moment(end?.split("T")[0]).date() || 0;
                        return (
                          <div className="chart-header-child-row-inner-row ">
                            {/* ABSOLUTE GANTT VIEW START */}
                            {graphType === "gantt" && (
                              <div
                                style={{
                                  paddingTop: 10,
                                }}
                                className="gantt-chart-absolute-row "
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flex: numberOfDaysFromStart / numberOfDays,
                                  }}
                                ></div>

                                <div
                                  onMouseMove={(evt) => showTooltip(evt, value)}
                                  onMouseOut={() => hideTooltip()}
                                  style={{
                                    flex: ganttWidth / numberOfDays,
                                    backgroundColor: colorRA(
                                      value?.completion,
                                      value?.end,
                                      value?.status,
                                      "timeline"
                                    ),
                                  }}
                                  className="gantt-chart-progress-bar"
                                ></div>
                              </div>
                            )}
                            {/* ABSOLUTE GANTT VIEW END */}

                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
                              (monthValue) => {
                                return monthValue ===
                                  moment(
                                    finalData[data].deliverables[
                                      dataIndex
                                    ].end.split("T")[0]
                                  ).month() ? (
                                  <div
                                    key={monthValue}
                                    style={{ alignSelf: "stretch" }}
                                    className="event-cell"
                                    onMouseMove={(evt) =>
                                      showTooltip(evt, value)
                                    }
                                    onMouseOut={() => hideTooltip()}
                                  >
                                    <div className="event-cell-container">
                                      <div className="event-cell-container-star-row">
                                        <div
                                          style={{
                                            display: "flex",
                                            flex: endDate / monthOffSet,
                                          }}
                                        ></div>

                                        <svg
                                          height="18"
                                          width="18"
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          {graphType === "event" && (
                                            <path
                                              d="m6.825 0 6.825 9-6.825 9L0 9l6.825-9Z"
                                              fill={
                                                colorRA(
                                                  value?.completion,
                                                  value?.end,
                                                  value?.status,
                                                  "timeline"
                                                ) || "#FFD700"
                                              }
                                            />
                                          )}
                                        </svg>
                                      </div>
                                      <div
                                        className="event-text-row"
                                        style={{
                                          justifyContent:
                                            endDate < monthOffSet / 3
                                              ? "start"
                                              : endDate < (monthOffSet / 3) * 2
                                              ? "center"
                                              : "end",
                                        }}
                                      >
                                        <p className="event-text">
                                          {value.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    key={monthValue}
                                    style={{ alignSelf: "stretch" }}
                                    className="chart-header-cell cell-bg"
                                  ></div>
                                );
                              }
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
