import { memo, useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { randomBlue } from "../helpers";

const BudgetPieChart = ({ data }) => {
  const [chartData, setChartData] = useState();

  useEffect(() => {
    const sortData = [...data].sort((a, b) => (a.budget < b.budget ? 1 : -1));
    const labels = sortData.map((x) => {
      return x.departmentName;
    });
    const amount = sortData.map((x) => {
      return x.budget;
    });
    let shadeVariable = 50;
    const color = sortData.map((x, index) => {
      if (index === 0) {
        return randomBlue(50);
      } else {
        shadeVariable = shadeVariable + Math.round(50 / sortData?.length);
        return randomBlue(shadeVariable);
      }
    });
    if (labels.length > 0 && amount.length > 0 && color.length > 0) {
      handleChartData(labels, amount, color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChartData = (labels, amount, color) => {
    const chart = {
      labels: labels,
      datasets: [
        {
          label: "Budget Pie Chart",
          data: amount,
          backgroundColor: color,
          borderColor: "white",
          color: "white",
        },
      ],
      hoverOffset: 4,
    };
    setChartData(chart);
  };

  return (
    <div
      className="pd-10"
      style={{
        maxHeight: 455,
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      {chartData && (
        <Pie
          data={chartData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                color: "white",
                font: {
                  size: 0,
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default memo(BudgetPieChart);
