import React, { memo, useState, useEffect, useRef } from "react";
import PageHeader from "parts/PageHeader";
import LINKS from "utils/constants/links";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProjects } from "../../..//../../redux/actions/projects";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { DELIVERABLE_MATRIX_LEGENDS, ICON_KEY_OBJ } from "../../constant";
import "./deliverableMatrix.css";
import { ReactComponent as DropdownArrowIcon } from "icons/arrow_drop_down.svg";
import Loader from "components/Loader";
import {
  Chip,
  Modal,
  Button,
  Paper,
  IconButton,
  Grid,
  Switch,
  Typography,
  CircularProgress,
  Menu,
  MenuItem,
  Box,
  TextField,
  InputLabel,
} from "@material-ui/core";
import {
  getPhases,
  getMultiPhaseDetail,
} from "../../../../../redux/actions/projects";
import { downloadExcel } from "./components/helper";
import { DeliverableMatrixTable } from "./components/deliverableMatrix";
import Select, { components } from "react-select";
import { colorIFRA, colorRA, SYSTEM_STATUS_TYPES } from "./snapshotHelper";
// import { readMetaSystem } from "redux/actions/metaSystem";
import _ from "lodash";
import { toJpeg, toBlob } from "html-to-image";
import moment from "moment";
import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "../../../ProjectCustomNotification/CustomNotificationForm/Shared/NotificationTemplate.css";
import Toolbar from "pages/Projects/ProjectCustomNotification/CustomNotificationForm/Toolbar/Toolbar";
import { Autocomplete } from "@mui/material";
import {
  // shareSnapshot,
  shareMatrixSnapshot,
} from "services/api-project";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import { isEmpty } from "utils/helpers/utility";
import { MatrixFilter } from "./components/MatrixFilter";
import { ReactComponent as FilterIcon } from "icons/filter.svg";
import { ReactComponent as DownloadIcon } from "icons/download.svg";
import { ReactComponent as ShareIcon } from "icons/share.svg";
import { ReactComponent as RefreshIcon } from "icons/refresh.svg";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",
  },
  stickyTop: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
  },
  stickyTop1: {
    position: "sticky",
    top: (headerHeight) => headerHeight,
    left: 0,
    background: "white",
  },
  paper: {
    marginTop: "10px",
    width: "100%",
  },
  scrollPaper: {
    marginTop: "10px",
    width: "100%",
    overflow: "auto",
    maxHeight: "600px",
  },
  customTableHeaderCellStyle: {
    borderBottom: "none",
    zIndex: 1,
    padding: "5px 20px",
    backgroundColor: "#E2ECF6",
    color: "black",
    fontWeight: "bold",
    fontSize: "0.9rem",
    position: "sticky",
    left: 0,
    top: 0,
  },
  customTableSubHeaderCellStyle: {
    backgroundColor: "white",
    border: "none",
    padding: "0px 5px",
    fontSize: "0.75rem",
    borderTop: "1px solid #C5C5C7",
    borderBottom: "1px solid #C5C5C7",
  },
  customTableSystemInitialCellStyle: {
    backgroundColor: "white",
    border: "none",
    padding: "5px 5px",
    borderTop: "1px solid #C5C5C7",
    borderBottom: "1px solid #C5C5C7",
  },
  systemNameText: {
    fontSize: "0.75rem",
    fontWeight: "600",
    color: "#1C1B1F",
    padding: 0,
    margin: 0,
  },
  customTableDelIconCellStyle: {
    backgroundColor: "white",
    border: "none",
    padding: "5px 5px",
    borderTop: "1px solid #C5C5C7",
    borderBottom: "1px solid #C5C5C7",
  },
  customTableDelIconEmptyCellStyle: {
    backgroundColor: "#F0F0F0",
    border: "none",
    padding: "10px 0px",
    borderTop: "1px solid #C5C5C7",
    borderBottom: "1px solid #C5C5C7",
  },
  snapshotSubheaderRight: {
    borderRight: "1px solid #C5C5C7",
    // borderRight: "1px solid #D6D7D7", // old Color Not Visible
  },
  snapshotSubheaderLeft: {
    borderLeft: "1px solid #C5C5C7",
    // borderLeft: "1px solid #D6D7D7", // old Color Not Visible
  },
  snapshotDelIconRight: {
    borderRight: "1px solid #DCDDDD",
    // borderRight: "1px solid #EEEEEE", // old Color Not Visible
  },
  snapshotDelIconLeft: {
    borderLeft: "1px solid #DCDDDD",
    // borderLeft: "1px solid #EEEEEE", // old Color Not Visible
  },
  cellWidth: {
    minWidth: "50px",
    // // maxWidth: "50px",
    // width: "50px",
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  maxHeight: 600,
  background: "white",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "12px",
};

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginLeft: 5,
  marginRight: 5,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
    boxSizing: "border-box",
  },
}));

const styleMap = {
  UNDERLINE: {
    color: "#5a83bb",
    textDecoration: "underline",
  },
};

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrowIcon className="black__icon" />
    </components.DropdownIndicator>
  );
};

const DeliverableMatrix = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [headerHeight, setHeaderHeight] = useState(0);
  const classes = useStyles(headerHeight);
  const [deliverableList, setDeliverableList] = useState([]);
  const [systemList, setSystemList] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [deliverableFilterList, setDeliverableFilterList] = useState([]);
  const [systemStatusList, setSystemStatusList] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const projects = useSelector((state) => state.projects);
  const project = projects.results.find((item) => item._id === projectId);
  const [loading, setLoading] = useState(false);
  const [phaseLoading, setPhaseLoading] = useState(false);
  const [phaseId] = useState(history?.location?.state || "");
  const [shareDetails, setShareDetails] = useState({
    to: [],
    subject: "",
    body: "",
    attachment: null,
    type: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorElShare, setAnchorElShare] = useState(null);
  const openShare = Boolean(anchorElShare);
  const downloadRef = useRef(null);
  const [tableType] = useState(true);
  const [openShareDetail, setOpenShareDetail] = useState(false);
  const editorBody = useRef(null);
  const [editorStateBody, setEditorStateBody] = useState(
    EditorState.createEmpty()
  );
  const [error, setError] = useState({ to: false });

  // const departments = useSelector(
  //   (state) =>
  //     state.organizations.results.find((o) => o._id === project?.organization)
  //       ?.departments || []
  // );
  // const [csvData, setCSVData] = useState([]);

  const {
    deliverableDetail: { deliverableData },
    phaseDetail: { systemData, masterSystemData },
  } = useSelector((state) => state.projects.phaseDetail);

  const {
    phaseDetail: { phaseData },
    phases: phasesOrderData,
  } = useSelector((state) => state.projects.phases);
  const users = useSelector((state) => state.users.results);
  const projectUsers = users?.filter((user) =>
    project?.assignedUsers?.includes(user._id)
  );

  const { phaseData: currentPhaseData } = useSelector(
    (state) => state.projects.phaseDetail
  );

  const [selectedPhases, setSelectedPhases] = useState([]);
  const [newSelectedPhases, setNewSelectedPhases] = useState([]);
  const [unselectedPhases, setUnselectedPhases] = useState([]);
  const [reducerPhases, setReducerPhases] = useState([]);
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (
      phaseData.length &&
      !selectedPhases.length &&
      !newSelectedPhases.length
    ) {
      setSelectedPhases([
        {
          label: phaseData[0].phaseName,
          value: phaseData[0]._id,
        },
      ]);
      const checkReducer =
        reducerPhases?.includes(phaseData[0]._id) ||
        currentPhaseData
          ?.map((data) => data.phaseId)
          ?.includes(phaseData[0]._id);
      if (!checkReducer) {
        setNewSelectedPhases([phaseData[0]._id]);
      }
    }
    // eslint-disable-next-line
  }, [phaseData]);

  useEffect(() => {
    if (currentPhaseData?.length) {
      currentPhaseData?.map((data) => data.phaseId);
      setReducerPhases(currentPhaseData?.map((data) => data.phaseId));
    }
    const tempUnselectedPhases = currentPhaseData
      ?.map((data) => data.phaseId)
      .filter((data) => {
        return !selectedPhases.find((select) => select.value === data);
      });
    setUnselectedPhases(tempUnselectedPhases);
    // eslint-disable-next-line
  }, [currentPhaseData, phaseData, selectedPhases]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getPhases(projectId, setLoading));
    };
    if (!phaseData.length) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [dispatch, projectId]);

  useEffect(() => {
    if (newSelectedPhases.length > 0) {
      const multiPhaseIdsObj = {
        phaseIds: newSelectedPhases,
      };

      const newFetchData = async (multiPhaseIdsObj) => {
        setPhaseLoading(true);
        await dispatch(getMultiPhaseDetail(projectId, multiPhaseIdsObj, true));
        // await dispatch(
        //   readMetaSystem({ project: projectId }, true, setPhaseLoading)
        // );
        setPhaseLoading(false);
      };

      if (phaseData.length) {
        newFetchData(multiPhaseIdsObj);

        setNewSelectedPhases([]);
      }
    }

    // eslint-disable-next-line
  }, [newSelectedPhases, phaseData]);

  useEffect(() => {
    checkFilter();
    // eslint-disable-next-line
  }, [systemList, systemStatusList, deliverableFilterList]);

  useEffect(() => {
    let deliverableList = deliverableData
      ?.filter((del) => !unselectedPhases?.includes(del.phaseId))
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map((deliverable) => {
        let type = deliverable?.type !== "EXECUTION";
        return {
          ...deliverable,
          reviewer: deliverable.reviewer.map((val) => {
            return {
              ...val,
              users: val.users.map((user) => {
                return {
                  ...user,
                  name: users.find((userVal) => userVal._id === user.userid)
                    ?.name,
                };
              }),
            };
          }),
          approver: deliverable.approver.map((val) => {
            return {
              ...val,
              users: val.users.map((user) => {
                return {
                  ...user,
                  name: users.find((userVal) => userVal._id === user.userid)
                    ?.name,
                };
              }),
            };
          }),
          reviewUser: []
            .concat(
              ...(deliverable.reviewer.map((data) => {
                return data.users.map((user) => {
                  return user.approved !== true;
                });
              }) || [])
            )
            .filter((val) => val === true).length,
          approve: []
            .concat(
              ...(deliverable.approver.map((data) => {
                return data.users.map((user) => {
                  return user.approved !== true;
                });
              }) || [])
            )
            .filter((val) => val === true).length,
          reviewAll: [].concat(
            ...(deliverable.reviewer.map((data) => {
              return data.users.map((user) => {
                return user.approved !== true;
              });
            }) || [])
          ).length,
          approveAll: [].concat(
            ...(deliverable.approver.map((data) => {
              return data.users.map((user) => {
                return user.approved !== true;
              });
            }) || [])
          ).length,
          colorIFR: type
            ? colorIFRA(
                deliverable?.ifr?.completion,
                deliverable?.ifr?.end || deliverable?.start,
                deliverable?.ifr?.issued
              )
            : null,
          colorReview: type
            ? colorRA(
                deliverable?.review?.completion,
                deliverable?.review?.end || deliverable?.start,
                deliverable?.review?.status
              )
            : null,
          colorIFA: type
            ? colorIFRA(
                deliverable?.ifa?.completion,
                deliverable?.ifa?.end || deliverable?.start,
                deliverable?.ifa?.issued
              )
            : null,
          colorApproval: type
            ? colorRA(
                deliverable?.approval?.completion,
                deliverable?.approval?.end || deliverable?.end,
                deliverable?.approval?.status
              )
            : null,
          colorMaster: type
            ? colorRA(
                deliverable?.masterDate?.completion,
                deliverable?.masterDate?.end || deliverable?.end,
                isEmpty(deliverable?.masterFile) ? 0 : 100
              )
            : null,
          completion: deliverable.completion,
          orderIndex: deliverable?.orderIndex,
        };
      });

    let deliverableListMap = new Map();

    for (const newdeliverable of deliverableList) {
      deliverableListMap.set(newdeliverable.name, {
        id: deliverableListMap.get(newdeliverable.name)?.id
          ? [
              ...deliverableListMap.get(newdeliverable.name)?.id,
              newdeliverable._id,
            ]
          : [newdeliverable._id],
        systemName: deliverableListMap.get(newdeliverable.name)?.systemName
          ? [
              ...deliverableListMap.get(newdeliverable.name)?.systemName,
              newdeliverable.systemName,
            ]
          : [newdeliverable.systemName],
        selected: false,
      });
    }

    const deliverableFilterList = [];

    deliverableListMap.forEach((value, key) => {
      deliverableFilterList.push({ name: key, ...value });
    });

    let systemStatusList = SYSTEM_STATUS_TYPES.map((type) => ({
      ...type,
      name: type.LABEL,
      selected: false,
    }));
    setSystemStatusList(systemStatusList);
    setDeliverableList(deliverableList);

    setDeliverableFilterList(deliverableFilterList);
    // eslint-disable-next-line
  }, [deliverableData, unselectedPhases]);

  const sortDeliverable = () => {
    let delSort = deliverableList.map((del) => {
      const system = systemList.find((sys) => sys.name === del.systemName);
      del.systemOrder = system?.orderIndex;
      return del;
    });
    delSort = delSort.sort((a, b) => a.systemOrder - b.systemOrder);
    setDeliverableList(delSort);
  };

  useEffect(() => {
    if (systemList.length) {
      sortDeliverable();
    }
    // eslint-disable-next-line
  }, [systemList]);

  useEffect(() => {
    let systemList = systemData
      ?.filter((sys) =>
        deliverableData?.find((del) => del.metaSystemId === sys._id)
      )
      ?.filter((sys) => !unselectedPhases?.includes(sys.phaseId))
      ?.map((system, index) => ({
        ...system,
        systemName: system.name,
        selected: false,
        orderIndex: index,
      }));

    setSystemList(systemList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    systemData,
    unselectedPhases,
    // filteredData
  ]);

  const handleFilterClear = () => {
    systemList.map((system) => {
      return (system.selected = false);
    });

    systemStatusList.map((status) => {
      return (status.selected = false);
    });
    deliverableFilterList.map((deliverable) => {
      return (deliverable.selected = false);
    });

    setSystemList(systemList);
    setSystemStatusList(systemStatusList);

    setDeliverableFilterList(deliverableFilterList);
    setIsFilter(false);
  };

  const checkFilter = () => {
    let filter = false;
    if (systemList.filter((system) => system.selected).length > 0) {
      filter = true;
    }
    if (systemStatusList.filter((system) => system.selected).length > 0) {
      filter = true;
    }
    if (
      deliverableFilterList.filter((deliverable) => deliverable.selected)
        .length > 0
    ) {
      filter = true;
    }

    setIsFilter(filter);
  };

  const NAV_LINKS = [
    LINKS.PROJECT_MANAGEMENT,
    LINKS.PROJECTS,
    {
      HREF: LINKS.EDIT_PROJECT.HREF.replace(":id", projectId),
      TITLE: project?.name || "Not Found",
    },
  ];
  if (history?.location?.state) {
    NAV_LINKS.push({
      HREF: LINKS.PROJECT_INSIGHT.HREF.replace(":projectId", projectId),
      TITLE: "Insights",
    });
  }
  const filteredSystemList = systemList
    .filter((sort) =>
      systemStatusList.some((status) => {
        if (systemStatusList.filter((status) => status.selected).length) {
          if (
            status.selected &&
            status.LABEL === "Not Started" &&
            sort.status === 0
          ) {
            return true;
          } else if (
            status.selected &&
            status.LABEL === "In Progress" &&
            sort.status > 0 &&
            sort.status < 100
          ) {
            return true;
          } else if (
            status.selected &&
            status.LABEL === "Completed" &&
            sort.status === 100
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
    )
    .map((system) => system.name);

  const filteredData = deliverableList
    .filter((sort) =>
      systemList.some((system) =>
        systemList.filter((system) => system.selected).length
          ? system.selected && system.systemName === sort.systemName
          : system
      )
    )
    .filter((sort) =>
      deliverableFilterList.some((deliverable) =>
        deliverableFilterList.filter((deliverable) => deliverable.selected)
          .length
          ? deliverable.selected && deliverable.name === sort.name
          : deliverable
      )
    )
    .filter((sort) => filteredSystemList.includes(sort.systemName));

  const deliverableMap = [
    ...new Map(
      filteredData
        .map((deliverable) => {
          return {
            name: deliverable.name,
            id: deliverable._id,
            type: deliverable?.type,
            systemId: deliverable.systemId,
          };
        })
        .map((item) => [item["name"], item])
    ).values(),
  ];

  const newfilteredDeliverableObject = masterSystemData
    ?.filter((f) => {
      const systemID = systemData
        ?.filter(
          (sys) =>
            sys.parentSystem === f.masterSystemId ||
            sys._id === f.masterSystemId
        )
        ?.filter((f) => filteredData?.find((del) => del.metaSystemId === f._id))
        ?.map((f) => f._id);
      const systemInstances = filteredData?.filter((del) =>
        systemID?.includes(del.metaSystemId)
      );
      return systemInstances.length;
    })
    ?.map((m) => {
      const systemID = systemData
        ?.filter(
          (sys) =>
            sys.parentSystem === m.masterSystemId ||
            sys._id === m.masterSystemId
        )
        ?.filter((f) => filteredData?.find((del) => del.metaSystemId === f._id))
        ?.map((m) => ({ _id: m._id, name: m.name }));
      // const systemInstances = filteredData?.filter((del) =>
      //   systemID?.find((f) => f._id === del.metaSystemId)
      // );
      const masterSystemDeliverables = [];

      systemID.forEach((o) => {
        if (!masterSystemDeliverables?.find((ms) => ms.name === o.name)) {
          const deliverablesPhaseList = deliverableMap.map((phase) =>
            filteredData.find(
              (f) => f.name === phase.name && o._id === f.metaSystemId
            )
          );
          masterSystemDeliverables.push({
            name: o.name,
            orderIndex: Math.min(
              ...deliverablesPhaseList
                ?.filter((f) => f)
                .map(
                  (m) =>
                    phasesOrderData?.find((f) => f._id === m.phaseId).orderIndex
                )
            ),
            deliverables: deliverablesPhaseList,
          });
        }
      });

      return {
        name: m.name,
        systems: masterSystemDeliverables?.sort(
          (a, b) => a.orderIndex - b.orderIndex
        ),
      };
    });

  const handleDownload = () => {
    handleCloseMenu();
    downloadExcel(
      setIsDownloading,
      phaseData,
      selectedPhases,
      projectId,
      deliverableFilterList,
      systemList,
      project,
      dispatch
    );
  };

  const handleRefresh = async () => {
    setPhaseLoading(true);
    handleFilterClear("systems");
    handleFilterClear("systemStatus");
    handleFilterClear("deliverable");
    const selectedPhaseIds = selectedPhases?.map((ph) => ph?.value);
    const multiPhaseIdsObj = {
      phaseIds: selectedPhaseIds,
    };

    await dispatch(getMultiPhaseDetail(projectId, multiPhaseIdsObj));
    setPhaseLoading(false);
  };

  const getLoading = () => {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ height: "100px" }}
      >
        <Grid item xs={12} className="mt-30">
          <CircularProgress color="secondary" />
        </Grid>
      </Grid>
    );
  };

  const noDataDiv = () => {
    return (
      <div
        className="d-jsb-cen w-100"
        style={{ height: "100px", backgroundColor: "white" }}
        ref={downloadRef}
      >
        No data available
      </div>
    );
  };
  const handleOpen = () => setOpenFilter(true);
  const handleClose = () => setOpenFilter(false);

  const getFileName = () => {
    let date = new Date();
    return (
      project?.name +
      "_matrix_" +
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getFullYear().toString().slice(2) +
      "@" +
      date.getHours() +
      "_" +
      date.getMinutes()
    );
  };

  const handleDownloadImage = () => {
    handleCloseMenu();
    setIsDownloading(true);
    if (downloadRef.current === null) {
      setIsDownloading(false);
      return;
    }
    toJpeg(downloadRef.current, {
      cacheBust: true,
      width: downloadRef.current?.scrollWidth || 0,
      height: downloadRef.current?.scrollHeight || 0 + 25,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = getFileName();
        link.href = dataUrl;
        link.click();
        setIsDownloading(false);
      })
      .catch((err) => {
        setIsDownloading(false);
        console.log(err);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getDefaultSubject = () => {
    let date = moment().format("MM/DD/YYYY");
    return (
      "Vektor " + project?.name + " - Deliverable Matrix " + date.split("T")[0]
    );
  };

  const setImageBlob = async () => {
    setShareDetails({ ...shareDetails, type: "Image" });
    const imageBlob = await toBlob(downloadRef.current, {
      cacheBust: true,
      width: downloadRef.current.scrollWidth,
      height: downloadRef.current.scrollHeight + 25,
      type: "image/jpeg",
    });

    setShareDetails({ ...shareDetails, attachment: imageBlob, type: "Image" });
  };

  const focusEditorBody = () => {
    editorBody.current.focus();
  };
  const handleKeyCommandBody = (command) => {
    const newState = RichUtils.handleKeyCommand(editorStateBody, command);
    if (newState) {
      setEditorStateBody(newState);
      return true;
    }
    return false;
  };

  const handleShareCancel = () => {
    setOpenShareDetail(false);
    setEditorStateBody(EditorState.createEmpty());
    setShareDetails({
      to: [],
      subject: getDefaultSubject(),
      body: "",
      attachment: null,
    });
  };

  const handleShare = () => {
    const receivers = shareDetails.to.map((user) => {
      if (typeof user === "string") {
        return user;
      } else {
        return user.email;
      }
    });
    if (receivers.length === 0) {
      setError({ ...error, to: true });
      return;
    } else {
      setError({ ...error, to: false });
    }
    let fileName = getFileName() + ".jpeg";
    let data = new FormData();
    if (shareDetails.type === "Image") {
      data.append("file", new File([shareDetails?.attachment], fileName));
    }
    data.append("subject", shareDetails.subject);
    data.append("body", shareDetails.body);
    data.append("receivers", receivers);
    data.append("type", shareDetails.type);
    data.append(
      "phase",
      JSON.stringify({
        phaseIds: [...selectedPhases.map((s) => s.value)],
      })
    );
    data.append(
      "filters",
      JSON.stringify({
        deliverable: deliverableFilterList
          .filter((deliverable) => deliverable.selected)
          ?.map((deliverable) => deliverable.name),
        system: systemList
          .filter((system) => system.selected)
          ?.map((system) => system.systemName),
      })
    );
    data.append("projectId", projectId);
    setLoading(true);
    shareMatrixSnapshot(projectId, data)
      .then(() => {
        setLoading(false);
        handleShareCancel();
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: err?.response?.data?.info || "Error sharing snapshot",
          })
        );
      });
  };

  const getMultiPhaseList = () => {
    return phaseData.map((ph) => ({ label: ph.phaseName, value: ph._id }));
  };

  const handleChangePhase = (value) => {
    setSelectedPhases(value);
    // setNewSelectedPhases(value?.map((data) => data.value));
  };

  const handleOnBlur = (value) => {
    const tempNewSelectedPhases = selectedPhases
      .filter((data) => !reducerPhases.includes(data.value))
      .map((data) => data.value);

    const tempUnselectedPhases = reducerPhases.filter((data) => {
      return !selectedPhases.find((select) => select.value === data);
    });

    if (tempNewSelectedPhases.length > 0) {
      setNewSelectedPhases(tempNewSelectedPhases);
    }

    setUnselectedPhases(tempUnselectedPhases);
  };

  return (
    <div>
      <Modal open={openFilter} onClose={handleClose}>
        <MatrixFilter
          systemList={systemList}
          deliverableList={deliverableFilterList}
          statusList={systemStatusList}
          setSystemList={setSystemList}
          setStatusList={setSystemStatusList}
          setDeliverableList={setDeliverableFilterList}
          handleClose={handleClose}
          type="snapshot"
          showCustomFilter={false}
          projectId={projectId}
          setLoading={setLoading}
          phaseId={phaseId === "" ? phaseData[0]?._id : phaseId}
          selectedPhases={selectedPhases}
        />
      </Modal>
      <Modal
        open={openShareDetail}
        onClose={() => {
          setOpenShareDetail(false);
        }}
      >
        <Box style={style}>
          <Grid container className="pd-10">
            <Grid item xs={12}>
              <Typography variant="h6"> Email Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={8}>
                  <InputLabel className="mt-10" error={error.to}>
                    To ({shareDetails.to.length})
                  </InputLabel>
                  <Autocomplete
                    className="mt-10"
                    multiple
                    limitTags={2}
                    options={projectUsers}
                    freeSolo
                    clearOnBlur
                    value={shareDetails.to}
                    getOptionLabel={(option) => option.email}
                    onChange={(e, value) => {
                      if (value.length) {
                        setError({ ...error, to: false });
                      }
                      setShareDetails({ ...shareDetails, to: value });
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            variant="outlined"
                            label={
                              typeof option === "string" ? option : option.email
                            }
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    renderInput={(params) => {
                      const { InputProps, ...restParams } = params;
                      const { startAdornment, ...restInputProps } = InputProps;
                      return (
                        <TextField
                          {...restParams}
                          error={error.to}
                          helperText={
                            error.to ? "User list cannot be empty" : ""
                          }
                          variant="outlined"
                          onBlur={({ target }) => {
                            if (target.value !== "") {
                              setShareDetails({
                                ...shareDetails,
                                to: [...shareDetails.to, target.value],
                              });
                            }
                          }}
                          InputProps={{
                            ...restInputProps,
                            startAdornment: (
                              <div
                                style={{
                                  maxHeight: 60,
                                  overflowY: "auto",
                                }}
                              >
                                {startAdornment}
                              </div>
                            ),
                          }}
                        />
                      );
                    }}
                  ></Autocomplete>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={8}>
                  <InputLabel className="mt-10">Subject</InputLabel>
                  <TextField
                    className="mt-10"
                    variant="outlined"
                    fullWidth
                    value={shareDetails.subject}
                    onChange={({ target }) => {
                      setShareDetails({
                        ...shareDetails,
                        subject: target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="mt-10">Body</InputLabel>
              <div
                className="editor-wrapper mt-10"
                style={{
                  border: "1px solid #CCCCCC",
                }}
                onClick={focusEditorBody}
              >
                <Toolbar
                  editorState={editorStateBody}
                  setEditorState={setEditorStateBody}
                />
                <div className="editor-container editor-wrapper-body">
                  <Editor
                    ref={editorBody}
                    handleKeyCommand={handleKeyCommandBody}
                    customStyleMap={styleMap}
                    editorState={editorStateBody}
                    onChange={(editorStateBody) => {
                      const contentState = editorStateBody.getCurrentContent();
                      setShareDetails({
                        ...shareDetails,
                        body: stateToHTML(contentState),
                      });
                      setEditorStateBody(editorStateBody);
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className="mt-10" style={{ display: "flex" }}>
              <InputLabel style={{ marginTop: "2.5px" }}>
                Attachement ({shareDetails.type}):
              </InputLabel>
              <span
                className="ml-10 snapshot-link"
                onClick={() => {
                  if (shareDetails.type === "Image") {
                    handleDownloadImage();
                  } else {
                    handleDownload();
                  }
                }}
              >
                {getFileName()}
              </span>
            </Grid>
            <Grid item xs={12} className="mt-10">
              <Grid container justify="flex-end">
                <Button
                  variant="contained"
                  onClick={() => {
                    handleShareCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleShare}
                  className="ml-10"
                  variant="contained"
                  color="primary"
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <PageHeader title={`Deliverable Matrix`} links={NAV_LINKS} />
      <Grid container>
        <Grid item xs={12}>
          {!loading && (
            <Grid container justify="flex-start" alignItems="center">
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <Grid container justify="flex-start" alignItems="center">
                      <Grid item>
                        <div className="d-flex jsb-l">
                          <IconButton
                            disabled={loading || phaseLoading}
                            className="round-icon-button"
                            onClick={() => {
                              handleOpen();
                            }}
                          >
                            <FilterIcon className="primary__icon" />
                          </IconButton>
                          {isFilter && (
                            <div className="ml-10">
                              <Chip
                                onClick={() => handleFilterClear()}
                                label="Clear"
                                className="border-r-20 chip"
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ zIndex: 100 }}
                        className="ml-10"
                      >
                        <Select
                          isMulti
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: "#79747E",
                              borderRadius: "0.5rem",
                              fontSize: "0.75rem",
                              minWidth: "100px",
                              width: "100px",
                              minHeight: "2.25rem",
                              height: "2.25rem",
                              margin: "0px",
                              padding: "0px",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              padding: "0px 5px 0px 0px",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              height: "2.25rem",
                              padding: "0px 8px",
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                          }}
                          isClearable={false}
                          value={selectedPhases}
                          placeholder={"Phases"}
                          controlShouldRenderValue={false}
                          closeMenuOnSelect={false}
                          options={getMultiPhaseList()}
                          hideSelectedOptions={false}
                          onChange={handleChangePhase}
                          components={{
                            Option: InputOption,
                            DropdownIndicator,
                          }}
                          onBlur={handleOnBlur}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5} container alignItems="center">
                    <Grid container justify="flex-end" alignItems="center">
                      <Grid item>
                        <Grid className="mt-5" container justify="flex-end">
                          <Typography>Status</Typography>
                          <AntSwitch
                            checked={isRequired}
                            onChange={() => setIsRequired(!isRequired)}
                          />
                          <Typography>Required</Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container justify="flex-end">
                          <div>
                            <IconButton
                              size="small"
                              className="ml-10 round-icon-button"
                              variant="outlined"
                              id="download-button"
                              aria-controls={open ? "download-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                            >
                              {isDownloading ? (
                                <CircularProgress size={22} color="secondary" />
                              ) : (
                                <DownloadIcon className="primary__icon" />
                              )}
                            </IconButton>
                            <Menu
                              className="mt-50"
                              id="download-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleCloseMenu}
                              MenuListProps={{
                                "aria-labelledby": "download-button",
                              }}
                            >
                              <MenuItem onClick={handleDownload}>
                                Download as excel
                              </MenuItem>
                              <MenuItem onClick={handleDownloadImage}>
                                Download as image
                              </MenuItem>
                            </Menu>
                          </div>

                          <IconButton
                            className="ml-20 round-icon-button"
                            size="small"
                            id="share-button"
                            aria-controls={openShare ? "share-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openShare ? "true" : undefined}
                            onClick={(event) => {
                              setShareDetails({
                                ...shareDetails,
                                subject: getDefaultSubject(),
                              });
                              setAnchorElShare(event.currentTarget);
                            }}
                          >
                            <ShareIcon className="primary__icon" />
                          </IconButton>
                          <Menu
                            className="mt-40"
                            id="share-menu"
                            anchorEl={anchorElShare}
                            open={openShare}
                            onClose={() => {
                              setAnchorElShare(null);
                            }}
                            MenuListProps={{
                              "aria-labelledby": "share-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setAnchorElShare(null);
                                setOpenShareDetail(true);
                                setShareDetails({
                                  ...shareDetails,
                                  type: "Excel",
                                });
                              }}
                            >
                              Share as excel
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setAnchorElShare(null);
                                setImageBlob();
                                setOpenShareDetail(true);
                              }}
                            >
                              Share as image
                            </MenuItem>
                          </Menu>
                          <IconButton
                            size="small"
                            className="ml-20 round-icon-button"
                            onClick={handleRefresh}
                          >
                            <RefreshIcon className="primary__icon" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid className="mt-20 mb-10">
          <div
            className="ai-fd-row"
            style={{
              gap: "2.5rem",
            }}
          >
            {DELIVERABLE_MATRIX_LEGENDS?.map((m) => (
              <div className="ai-fd-row">
                {m?.icon ? (
                  ICON_KEY_OBJ[m.icon]
                ) : (
                  <div
                    className="legend-indicator"
                    style={{
                      backgroundColor: m.color,
                    }}
                  ></div>
                )}
                <div className="fs-x-small ml-10">{m.label}</div>
              </div>
            ))}
          </div>
        </Grid>
        <Grid item xs={12}>
          {!loading && (
            <Grid container>
              {tableType && (
                <Grid container>
                  <Paper
                    className={classes.scrollPaper}
                    style={{
                      borderRadius: "0px",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    {phaseLoading && getLoading()}
                    {!phaseLoading &&
                      (deliverableData.length === 0 ||
                        _.isEmpty(newfilteredDeliverableObject)) &&
                      noDataDiv()}
                    {!phaseLoading &&
                      deliverableData.length !== 0 &&
                      !_.isEmpty(newfilteredDeliverableObject) && (
                        <div ref={downloadRef}>
                          <DeliverableMatrixTable
                            classes={classes}
                            deliverableMap={deliverableMap}
                            newfilteredDeliverableObject={
                              newfilteredDeliverableObject
                            }
                            isRequired={isRequired}
                            users={users}
                            systemList={systemList}
                            setHeaderHeight={setHeaderHeight}
                          />
                        </div>
                      )}
                  </Paper>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>

      {loading && <Loader />}
    </div>
  );
};
export default memo(DeliverableMatrix);
