import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { readMetaSystem } from "redux/actions/metaSystem";
import {
  createProjectPhase,
  // updateProjectPhase,
  deleteProjectPhase,
  updatePhaseOrder,
} from "redux/actions/projects";
import { ACTIONS } from "pages/Projects/constants";
import { round } from "utils/helpers/utility";
import moment from "moment";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";

const useProjectPhasesEditing = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [editingPhase, setEditingPhase] = useState(null);
  const [activeAction, setActiveAction] = useState("");
  const [loading, setLoading] = useState(false);

  const { results: projects, metaSystems } = useSelector(
    ({ projects }) => projects
  );
  const project = useMemo(
    () => projects.find((item) => item._id === projectId),
    [projectId, projects]
  );
  const currentMetaSystems = useMemo(
    () => metaSystems.filter((m) => m.project === projectId),
    [projectId, metaSystems]
  );
  const isEditingHeader = (orderIndex) =>
    editingPhase?.orderIndex === orderIndex && activeAction === ACTIONS.RENAME;
  const phases = useMemo(
    () =>
      (project?.phases || []).map((p) => {
        const phaseSystems = currentMetaSystems.filter(
          (m) => (m.projectPhase || m.autoPhaseId) === p._id
        );
        if (phaseSystems.length) {
          const sum = phaseSystems.reduce(
            (acc, system) =>
              acc +
              parseFloat(system.mainSystem.calculated?.overallStatus || 0),
            0
          );
          return {
            ...p,
            status: round(sum / phaseSystems.length, 2),
          };
        } else {
          return { ...p, status: 0 };
        }
      }),
    [project, currentMetaSystems]
  );

  useEffect(() => {
    dispatch(readMetaSystem({ project: projectId }));
  }, [dispatch, projectId]);

  const onHeaderClick = async () => {
    setLoading(true);
    const newPhase = {
      mainId: project._id,
      name: `New Phase ${phases.length + 1}`,
      plannedValue: 0,
      start: new Date(),
      end: new Date(),
      orderIndex: phases.length + 1,
    };

    await dispatch(createProjectPhase(newPhase));
    setLoading(false);
  };
  const onHeaderClickCustom = async (phases) => {
    setLoading(true);
    await dispatch(updatePhaseOrder(project._id, phases)).catch((error) => {
      throw error;
    });
    setLoading(false);
  };

  const onDeletePhase = async (index) => {
    setLoading(true);
    const { _id } = phases.find(({ orderIndex }) => index === orderIndex);
    const removedPhase = { _id, mainId: project._id };

    await dispatch(deleteProjectPhase(removedPhase));
    setLoading(false);
    setEditingPhase({});
  };

  const onActionClick = (
    action,
    idx,
    phaseData,
    updateSystemInstance = false
  ) => {
    switch (action) {
      case ACTIONS.RENAME: {
        const activePhase = phases.find(({ orderIndex }) => idx === orderIndex);
        setActiveAction(action);
        setEditingPhase(activePhase);
        break;
      }
      case ACTIONS.DELETE: {
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.confirm,
            popupText: "Are you sure you want to delete this phase?",
            onConfirm: async () => {
              onDeletePhase(idx);
            },
          })
        );

        break;
      }
      case ACTIONS.EDIT: {
        const activePhase = phases.find(({ orderIndex }) => idx === orderIndex);
        setActiveAction(action);
        setEditingPhase(activePhase);
        break;
      }
      case ACTIONS.COMPLETE: {
        onCompleteEditing(phaseData, updateSystemInstance);
        break;
      }

      default:
        break;
    }
  };

  const onChangePhase = ({ type, value, value1, name }) => {
    if (type === "dateRange") {
      setEditingPhase((phase) => ({
        ...phase,
        start: moment(value).format("YYYY-MM-DD[T00:00:00.000Z]"),
        end: moment(value1).format("YYYY-MM-DD[T00:00:00.000Z]"),
      }));
      return;
    }
    setEditingPhase((phase) => ({
      ...phase,
      [name]: type === "date" ? moment(value).toISOString() : value,
    }));
  };

  const onCompleteEditing = async (phaseData, updateSystemInstance) => {
    setLoading(true);
    // const plannedValue = Boolean(editingPhase.plannedValue)
    //   ? editingPhase.plannedValue
    //   : 0;

    // const updatedPhase = {
    //   ...editingPhase,
    //   plannedValue,
    //   mainId: project._id,
    //   id: undefined,
    //   status: undefined,
    // };

    const isCompleted = await dispatch(
      updatePhaseOrder(projectId, phaseData, updateSystemInstance)
    );
    setLoading(false);
    if (isCompleted) {
      setEditingPhase({});
      setActiveAction("");
    }
  };

  return {
    projectId,
    project,
    phases,
    editingPhase,
    currentMetaSystems,
    activeAction,
    loading,
    isEditingHeader,
    onChangePhase,
    onCompleteEditing,
    onActionClick,
    onHeaderClick,
    setEditingPhase,
    onHeaderClickCustom,
  };
};

export default useProjectPhasesEditing;
