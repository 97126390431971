import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { memo } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: 500,
  background: "white",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "12px",
};

const SaveFilter = ({ name, handleCloseSaveFilter, handleDataChange ,handleSaveFilterData}) => {
  return (
    <Box
      style={{
        ...style,
      }}
    >
      <Grid container justify="space-between" className="mb-10">
        <Typography variant="h6" component="h2" className="mr-20">
          Save Filter
        </Typography>

        <IconButton onClick={handleCloseSaveFilter} size="small">
          <Close />
        </IconButton>
      </Grid>

      <Grid item xs={6} className="mb-10">
        <TextField
          value={name}
          label="Filter name"
          variant="outlined"
          name="filterName"
          fullWidth
          onChange={handleDataChange}
        />
      </Grid>

      <Grid container justify="flex-end" className="mb-10">
        <Button variant="outlined" onClick={handleCloseSaveFilter}>
          Cancel
        </Button>
        <Button
          className="ml-10"
          variant="contained"
          color="primary"
          onClick={handleSaveFilterData}
        >
          Save
        </Button>
      </Grid>
    </Box>
  );
};

export default memo(SaveFilter);
