import { get, post, del, put, getJQ } from "services/axios";

const getAllRequests = async (
  projectId,
  searchQuery = null,
  skip = 0,
  limit = null
) =>
  await getJQ(`/v2/ecr/project/${projectId}/changeRequests`, {
    searchQuery,
    skip,
    limit,
  });

const postRequest = async (projectId, params) =>
  await post(`/v2/ecr/project/${projectId}/changeRequest`, params);

const getRequestById = async (projectId, changeRequestId) =>
  await get(`/v2/ecr/project/${projectId}/changeRequest/${changeRequestId}`);

const getMyTasks = async (projectId) =>
  await get(`/v2/ecr/project/${projectId}/changeRequests/myTasks`);

const getCompletedTasks = async (projectId) =>
  await get(`/v2/ecr/project/${projectId}/changeRequests/completedTasks`);

const getClosedRequests = async (projectId) =>
  await get(`/v2/ecr/project/${projectId}/changeRequests/closedTasks`);

const updateRequest = async (projectId, changeRequestId, params) =>
  await put(
    `/v2/ecr/project/${projectId}/changeRequest/${changeRequestId}`,
    params
  );

const approveRequest = async (projectId, changeRequestId, params) =>
  await put(
    `/v2/ecr/project/${projectId}/changeRequest/${changeRequestId}/approve`,
    params
  );

const deleteRequest = async (projectId, changeRequestId) =>
  await del(`/v2/ecr/project/${projectId}/changeRequest/${changeRequestId}`);

export {
  getAllRequests,
  postRequest,
  getRequestById,
  getMyTasks,
  updateRequest,
  deleteRequest,
  approveRequest,
  getCompletedTasks,
  getClosedRequests
};
