import React, { memo } from "react";
import { Card, CardHeader } from "@material-ui/core";
import { ColorButton } from "components/UI/Buttons";

const DetailLinkCard = ({ title, buttonLabel = "Detail", onDetail }) => (
  <Card onClick={onDetail} className="pointer">
    <CardHeader
      title={title}
      action={<ColorButton colour="lightGreen">{buttonLabel}</ColorButton>}
    />
  </Card>
);

export default memo(DetailLinkCard);
