import React, { memo, useContext, useMemo, useState } from "react";
import {
  Box,
  Card,
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import PhaseBox from "./PhaseBox";
import BufferBox from "./PhaseBox/BufferBox";
import { round } from "utils/helpers/utility";
import { SideNavContext } from "routes";
import Loader from "components/Loader";
import FeatureFlag from "utils/hocs/FeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";

const useStyles = (open) =>
  makeStyles(() => ({
    card: {
      marginTop: "25px",
      padding: "12px",
    },
    container: {
      display: "flex",
      position: "relative",
      overflowX: "auto",
      flexWrap: "nowrap",
      maxWidth: open ? "calc(100vw - 340px)" : "calc(100vw - 140px)",
    },
    phaseContainer: {
      paddingTop: 12,
      paddingRight: 12,
      "&:last-child": {
        paddingRight: 0,
      },
    },
  }));

const PhasesListView = ({ project, metaSystems }) => {
  const [searchText, setSearchText] = useState("");
  const { open } = useContext(SideNavContext);
  const classes = useStyles(open)();
  const [loading, setLoading] = useState(false);
  const phases = useMemo(
    () =>
      (project?.phases || []).map((p) => {
        const phaseSystems = metaSystems.filter(
          (m) => (m.projectPhase || m.autoPhaseId) === p._id
        );
        if (phaseSystems.length) {
          const sum = phaseSystems.reduce(
            (acc, system) =>
              acc +
              parseFloat(system.mainSystem.calculated?.overallStatus || 0),
            0
          );

          return {
            ...p,
            status: round(sum / phaseSystems.length, 2),
          };
        } else {
          return { ...p, status: 0 };
        }
      }),
    [project, metaSystems]
  );

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <FeatureFlag feature={FEATURE_FLAGS.UnassignedSystems}>
          <Box>
            <BufferBox
              systems={metaSystems}
              setLoading={setLoading}
              phases={phases}
            />
          </Box>
        </FeatureFlag>
        <FeatureFlag feature={FEATURE_FLAGS.PhaseBoard}>
          <Card xs={12} sm={6} md={3} className={classes.card}>
            <div className="d-jsb-r">
              <TextField
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                variant="outlined"
                size="small"
                label="Search"
                style={{
                  marginRight: "10px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                        {searchText && (
                          <IconButton onClick={() => setSearchText("")}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Grid className={classes.container} container>
              {phases.map((phase) => (
                <Grid
                  className={classes.phaseContainer}
                  key={phase._id}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                >
                  <PhaseBox
                    phase={phase}
                    setLoading={setLoading}
                    status={phase.status}
                    searchText={searchText}
                    phases={phases}
                    systems={metaSystems
                      .filter(
                        (sys) =>
                          sys.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          sys.equipmentNumber
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                      )
                      .filter(({ projectPhase }) => phase._id === projectPhase)}
                    preWorkSystems={metaSystems
                      .filter(
                        (sys) =>
                          sys.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          sys.equipmentNumber
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                      )
                      .filter(
                        ({ autoPhaseId, projectPhase }) =>
                          phase._id === autoPhaseId && !projectPhase
                      )}
                  />
                </Grid>
              ))}
            </Grid>
          </Card>
        </FeatureFlag>
      </DndProvider>
      {loading && <Loader />}
    </div>
  );
};

export default memo(PhasesListView);
