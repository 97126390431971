import React, { memo } from "react";
import { CircularProgress } from "@mui/material";
import { Grid, Typography, Divider } from "@material-ui/core";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications } from "redux/actions/notificationActions";
import { getNotificationIcon } from "../helpers";

function NotificationList(props) {
  const { notifications, hasMore, fullScreen, handleNotificationClick } = props;
  const dispatch = useDispatch();

  const getNotificationmessage = (message) => {
    return parse(message);
  };
  const user = useSelector((state) => state.auth.currentUser._id);
  const handleLoadMore = () => {
    dispatch(fetchNotifications({ userId: user, skip: notifications.length }));
  };

  return (
    <InfiniteScroll
      dataLength={notifications?.length}
      hasMore={hasMore}
      next={handleLoadMore}
      loader={
        notifications?.length !== 0 && (
          <div className=" d-jsb-cen">
            <CircularProgress size={20} className="mb-10" />
          </div>
        )
      }
      scrollThreshold={0.9}
      height={
        fullScreen
          ? window.innerHeight * 0.7
          : notifications?.length === 0
          ? 40
          : 300
      }
      className={fullScreen ? "" : "mt-10"}
    >
      {notifications.map((notification, index) => {
        return (
          <div
            onClick={() =>
              handleNotificationClick(
                notification,
                "read-one",
                notification?._id,
                index
              )
            }
            key={notification?._id}
          >
            <Grid
              container
              className="pointer"
              spacing={0}
              style={{ backgroundColor: notification?.read ? "" : "aliceblue" }}
            >
              <Grid item xs={1}>
                <Grid container justify="center">
                  {getNotificationIcon(notification)}
                </Grid>
              </Grid>
              <Grid item xs={10} className="mb-10 mt-10 p-lr-10">
                <Grid container>
                  <Grid item xs={12} style={{ whiteSpace: "normal" }}>
                    {getNotificationmessage(notification.message, fullScreen)}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {moment(notification.createdAt).fromNow()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} className="d-jsb-cen">
                <Grid container justify={"flex-end"} className="mr-10">
                  {" "}
                  {!notification?.read && <div className="new-notification" />}
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            {!hasMore && index === notifications.length - 1 && (
              <div className="d-jsb-cen mt-10 mb-10">No more Notifications</div>
            )}
          </div>
        );
      })}
    </InfiniteScroll>
  );
}

export default memo(NotificationList);
