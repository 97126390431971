import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  FormControl,
  Modal,
} from "@material-ui/core";
import { memo, useState } from "react";
// import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import { ReactComponent as CloseIcon } from "icons/cross_circle.svg";
import SaveFilter from "./SaveFilter";
import _ from "lodash";

export const FILTERS = {
  SYSTEM: "SYSTEM",
  DELIVERABLE: "DELIVERABLE",
  STATUS: "STATUS",
  PHASE: "PHASE",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: 500,
  background: "white",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "12px",
};
const SnapshotFilter = ({
  showSaveFilter = true,
  activeFilters = [FILTERS.SYSTEM, FILTERS.DELIVERABLE, FILTERS.STATUS],
  handleSetNewSelectedPhases,
  handleSetSelectedSavedFilter,
  handleSaveFilterData,
  snapshotFilters,
  setSelectedPhases,
  handleCloseFilter,
  handleFilterApply,
  handleClearFilter,
  selectedSavedFilter,
  systemFilter,
  deliverableFilter,
  statusFilter,
  selectedPhases,
  reducerPhases,
  phaseData,
  projectId,
  project,
}) => {
  const dispatch = useDispatch();

  const [systemFilterList, setSystemFilterList] = useState(systemFilter);
  const [deliverableFilterList, setDeliverableFilterList] =
    useState(deliverableFilter);
  const [statusFilterList, setStatusFilterList] = useState(statusFilter);
  const [tempSelectedSavedFilter, setTempSelectedSavedFilter] =
    useState(selectedSavedFilter);
  const user = useSelector((state) => state?.auth?.currentUser?._id);
  const [saveFilter, setSaveFilter] = useState(false);
  const [newFilters, setNewFilters] = useState({
    name: "",
    user: user,
    filters: {},
    phaseIds: [],
  });

  const handleFilterListChange = (type, id) => {
    // handleSetSelectedSavedFilter(undefined);
    let tempSystemFilter = _.cloneDeep(systemFilterList);
    let tempDeliverableFilter = _.cloneDeep(deliverableFilterList);
    let tempStatusFilterList = _.cloneDeep(statusFilterList);

    if (type === "system") {
      tempSystemFilter = systemFilterList?.map((value) => {
        if (value?.id === id) {
          return { ...value, selected: !value?.selected };
        } else {
          return value;
        }
      });

      setSystemFilterList(tempSystemFilter);
    }
    if (type === "deliverable") {
      tempDeliverableFilter = deliverableFilterList?.map((value) => {
        if (value?.name === id) {
          return { ...value, selected: !value?.selected };
        } else {
          return value;
        }
      });
      setDeliverableFilterList(tempDeliverableFilter);
    }
    if (type === "status") {
      tempStatusFilterList = statusFilterList?.map((value) => {
        if (value?.name === id) {
          return { ...value, selected: !value?.selected };
        } else {
          return value;
        }
      });
      setStatusFilterList(tempStatusFilterList);
    }

    tempSystemFilter = tempSystemFilter
      ?.filter((f) => f.selected)
      .map((m) => m.id);
    tempDeliverableFilter = tempDeliverableFilter
      ?.filter((f) => f.selected)
      .map((m) => m.name);
    tempStatusFilterList = tempStatusFilterList
      ?.filter((f) => f.selected)
      .map((m) => m.name);

    const savedFilterExist = snapshotFilters
      ?.filter((f) => f.user === user)
      ?.find(
        (f) =>
          _.isEqual(f.filters.systems, tempSystemFilter) &&
          _.isEqual(f.filters.deliverables, tempDeliverableFilter) &&
          _.isEqual(f.filters.status, tempStatusFilterList)
      );

    setTempSelectedSavedFilter(savedFilterExist);
  };

  const handleFilterApplyCheck = () => {
    const tempSystemFilter = systemFilterList
      ?.filter((f) => f.selected)
      .map((m) => m.id);
    const tempDeliverableFilter = deliverableFilterList
      ?.filter((f) => f.selected)
      .map((m) => m.name);
    const tempStatusFilterList = statusFilterList
      ?.filter((f) => f.selected)
      .map((m) => m.name);

    const savedFilterExist = snapshotFilters
      ?.filter((f) => f.user === user)
      ?.find(
        (f) =>
          _.isEqual(f.filters.systems, tempSystemFilter) &&
          _.isEqual(f.filters.deliverables, tempDeliverableFilter) &&
          _.isEqual(f.filters.status, tempStatusFilterList)
      );

    if (
      tempSelectedSavedFilter &&
      (!tempSelectedSavedFilter?.uid || tempSelectedSavedFilter?.uid !== "none")
    ) {
      const tempNewSelectedPhases = phaseData
        ?.filter((f) => tempSelectedSavedFilter.phaseIds.includes(f._id))
        .filter((data) => !reducerPhases.includes(data._id));

      setSelectedPhases([
        ...phaseData
          ?.filter((f) => tempSelectedSavedFilter.phaseIds.includes(f._id))
          .map((m) => ({ label: m.phaseName, value: m._id })),
      ]);

      if (tempNewSelectedPhases.length > 0) {
        handleSetNewSelectedPhases(tempNewSelectedPhases.map((m) => m._id));
      } else {
        handleFilterApply(
          systemFilterList,
          deliverableFilterList,
          statusFilterList
        );
      }
      handleSetSelectedSavedFilter(tempSelectedSavedFilter);
      handleCloseFilter();
    } else if (savedFilterExist) {
      handleSetSelectedSavedFilter(tempSelectedSavedFilter);
      handleFilterApply(
        systemFilterList,
        deliverableFilterList,
        statusFilterList
      );
    } else if (
      (systemFilterList?.some((m) => m.selected) ||
        deliverableFilterList?.some((m) => m.selected) ||
        statusFilterList?.some((m) => m.selected)) &&
      showSaveFilter
    ) {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.confirm,
          popupText: "Do you want to save this as a filter ?",
          confirmText: "Yes",
          cancelText: "No",
          onConfirm: () => {
            const tempNewFilter = {
              ...newFilters,
              phaseIds: selectedPhases?.map((m) => m.value),
            };
            setNewFilters({
              ...tempNewFilter,
              filters: {
                systems: systemFilterList
                  ?.filter((f) => f.selected)
                  .map((m) => m.id),
                deliverables: deliverableFilterList
                  ?.filter((f) => f.selected)
                  .map((m) => m.name),
                status: statusFilterList
                  ?.filter((f) => f.selected)
                  .map((m) => m.name),
              },
            });
            handleOpenSaveFilter();
          },
          onCancel: () => {
            handleFilterApply(
              systemFilterList,
              deliverableFilterList,
              statusFilterList
            );
          },
        })
      );
    } else if (
      tempSelectedSavedFilter?.uid ||
      tempSelectedSavedFilter?.uid === "none"
    ) {
      handleClearFilter();
      handleCloseFilter();
    } else {
      handleFilterApply(
        systemFilterList,
        deliverableFilterList,
        statusFilterList
      );
      handleCloseFilter();
    }
  };

  const handleOpenSaveFilter = () => {
    setSaveFilter(true);
  };

  const handleCloseSaveFilter = () => {
    setSaveFilter(false);
  };

  const handleDataChange = ({ target: { name, value } }) => {
    if (name === "filterName") {
      setNewFilters({ ...newFilters, name: value });
    }
    if (name === "SavedFilter") {
      if (value === "None") {
        setTempSelectedSavedFilter({ name: "None", uid: "none" });
        setSystemFilterList(
          systemFilterList?.map((m) => ({ ...m, selected: false }))
        );
        setDeliverableFilterList(
          deliverableFilterList?.map((m) => ({ ...m, selected: false }))
        );
        setStatusFilterList(
          statusFilterList?.map((m) => ({ ...m, selected: false }))
        );
      } else {
        const data = snapshotFilters?.find((f) => f._id === value);
        if (data) {
          setTempSelectedSavedFilter(data);
          setSystemFilterList(
            systemFilterList?.map((m) =>
              data.filters.systems.includes(m.id)
                ? { ...m, selected: true }
                : { ...m, selected: false }
            )
          );
          setDeliverableFilterList(
            deliverableFilterList?.map((m) =>
              data.filters.deliverables.includes(m.name)
                ? { ...m, selected: true }
                : { ...m, selected: false }
            )
          );
          setStatusFilterList(
            statusFilterList?.map((m) =>
              data.filters.status.includes(m.name)
                ? { ...m, selected: true }
                : { ...m, selected: false }
            )
          );
        }
      }
    }
  };

  return (
    <>
      <Modal open={saveFilter} onClose={handleCloseSaveFilter}>
        <SaveFilter
          name={newFilters.name}
          handleCloseSaveFilter={handleCloseSaveFilter}
          handleDataChange={handleDataChange}
          handleSaveFilterData={() =>
            handleSaveFilterData(
              newFilters,
              snapshotFilters,
              systemFilterList,
              deliverableFilterList,
              statusFilterList
            )
          }
        />
      </Modal>

      <Box
        style={{
          ...style,
          paddingTop: 12,
        }}
      >
        {showSaveFilter && (
          <Grid container justify="space-between" className="mb-20">
            <Grid item xs={6} style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="h6" component="h2" className="mr-20">
                Filter By
              </Typography>

              {snapshotFilters?.filter((f) => f.user === user)?.length !==
                0 && (
                <Grid item xs={8}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel>Saved Filter</InputLabel>
                    <Select
                      name="SavedFilter"
                      value={tempSelectedSavedFilter?._id || ""}
                      label="Saved Filter"
                      onChange={handleDataChange}
                    >
                      <MenuItem value={"None"}>None</MenuItem>
                      {snapshotFilters
                        ?.filter((f) => f.user === user)
                        ?.map((list) => (
                          <MenuItem value={list._id}>{list.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <IconButton onClick={handleCloseFilter} size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
        <Grid
          container
          spacing={8}
          justify="space-evenly"
          className="mb-10"
        >
          {activeFilters?.includes(FILTERS.SYSTEM) && (
            <Grid item xs={4}>
              <InputLabel className="mb-10">
                Systems (
                {systemFilterList?.filter((system) => system?.selected)?.length}
                )
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={systemFilterList}
                renderValue={() => {
                  return systemFilterList
                    ?.filter((system) => system?.selected)
                    ?.map((system) => system?.name)
                    ?.join(", ");
                }}
              >
                {systemFilterList?.map((system) => (
                  <MenuItem
                    className="pd-5"
                    key={system?._id}
                    value={system?._id}
                    onClick={() => handleFilterListChange("system", system?.id)}
                  >
                    <Checkbox
                      className="pd-5"
                      size="small"
                      checked={system?.selected}
                    />
                    <ListItemText primary={system?.name} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {activeFilters?.includes(FILTERS.DELIVERABLE) && (
            <Grid item xs={4}>
              <InputLabel className="mb-10">
                Deliverables (
                {deliverableFilterList?.filter((del) => del?.selected)?.length})
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={deliverableFilterList}
                renderValue={() => {
                  return deliverableFilterList
                    ?.filter((del) => del?.selected)
                    ?.map((del) => del?.name)
                    ?.join(", ");
                }}
              >
                {deliverableFilterList?.map((del) => (
                  <MenuItem
                    className="pd-5"
                    key={del?.name}
                    value={del?.name}
                    onClick={() =>
                      handleFilterListChange("deliverable", del?.name)
                    }
                  >
                    <Checkbox
                      className="pd-5"
                      size="small"
                      checked={del?.selected}
                    />
                    <ListItemText primary={del?.name} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {activeFilters?.includes(FILTERS.STATUS) && (
            <Grid item xs={4}>
              <InputLabel className="mb-10">
                Status (
                {statusFilterList?.filter((status) => status?.selected)?.length}
                )
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={statusFilterList}
                renderValue={() => {
                  return statusFilterList
                    ?.filter((status) => status?.selected)
                    ?.map((status) => status?.name)
                    ?.join(", ");
                }}
              >
                {statusFilterList?.map((status) => (
                  <MenuItem
                    className="pd-5"
                    key={status?._id}
                    value={status?._id}
                    onClick={() =>
                      handleFilterListChange("status", status?.name)
                    }
                  >
                    <Checkbox
                      className="pd-5"
                      size="small"
                      checked={status?.selected}
                    />
                    <ListItemText primary={status?.name} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {activeFilters?.includes(FILTERS.PHASE) && (
            <Grid item xs={4}>
              <InputLabel className="mb-10">
                Phase (
                {statusFilterList?.filter((status) => status?.selected)?.length}
                )
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={statusFilterList}
                renderValue={() => {
                  return statusFilterList
                    ?.filter((status) => status?.selected)
                    ?.map((status) => status?.name)
                    ?.join(", ");
                }}
              >
                {statusFilterList?.map((status) => (
                  <MenuItem
                    className="pd-5"
                    key={status?._id}
                    value={status?._id}
                    onClick={() =>
                      handleFilterListChange("status", status?.name)
                    }
                  >
                    <Checkbox
                      className="pd-5"
                      size="small"
                      checked={status?.selected}
                    />
                    <ListItemText primary={status?.name} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
        </Grid>
        <Grid container justify="flex-end" className="mt-10">
          <Button variant="contained" onClick={handleCloseFilter}>
            CANCEL
          </Button>

          <Button
            className="ml-10"
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => handleFilterApplyCheck()}
          >
            APPLY
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default memo(SnapshotFilter);
