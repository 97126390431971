import React, { useState, useEffect } from "react";
import { getDocument } from "services/api-document";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { DocumentHelper } from "pages/Projects/Documents/helpers";
import LINKS from "utils/constants/links";
import { useHistory } from "react-router-dom";

export default function MyTasks({ projectId }) {
  const [loading, setLoading] = useState(false);
  const [myTaskList, setMyTasks] = useState([]);
  const history = useHistory();

  const { TABLE_MYTASK_COLUMN, DOC_STATUS } = DocumentHelper();

  const getMyTaskList = async () => {
    setLoading(true);
    await getDocument(projectId)
      .then((resp) => {
        setMyTasks(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>> ", err);
      });
  };

  useEffect(() => {
    getMyTaskList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  if (!myTaskList.length)
    return (
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>No documents available.</p>
      </div>
    );
  return (
    <Card>
      <TableContainer style={{ height: "70vh", maxHeight: "70vh" }}>
        <Table stickyHeader className="w-100 h-100">
          <TableHead>
            {TABLE_MYTASK_COLUMN.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {myTaskList.map((doc, index) => (
              <TableRow key={index}>
                {TABLE_MYTASK_COLUMN.map((column) => {
                  let value = doc[column.id];
                  if (column.id === "system") {
                    value = value?.name
                  }
                  if (column.id === "documentStatus") {
                    value = DOC_STATUS.find(
                      (status) => status.value === doc[column.id]
                    )?.label;
                  }
                  if (column.id === "documentNumber") {
                    value = (
                      <Typography
                        className="pointer"
                        color="primary"
                        onClick={() =>
                          history.push(
                            LINKS.EDIT_DOCUMENTS.HREF.replace(
                              ":projectId",
                              projectId
                            ).replace(":id", doc._id)
                          )
                        }
                      >
                        {value}
                      </Typography>
                    );
                  }
                  if (column.id === "documentTitle") {
                    value = <Typography>{value}</Typography>;
                  }
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
