import React, { memo } from "react";
import styled from "styled-components/macro";
import { Chip, ListItem, ListItemText } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import { ReactComponent as ExpandDown } from "icons/arrow_down.svg";
import { ReactComponent as ExpandUp } from "icons/arrow_up.svg";

const Category = styled(ListItem)`
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  height: 50px;
  svg {
    font-size: 20px;
    width: 23px;
    height: 23px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  // &.${(props) => props.activeClassName} {
  //   background-color: ${(props) => "#5A83BB"};
  // }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
    font-weight: ${(props) => (props.activeClassName ? "bold" : "inherit")};
  }
`;

const CategoryBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const SidebarCategory = ({
  name,
  open,
  icon,
  isOpen,
  isCollapsable,
  badge,
  active,
  isActive,
  ...rest
}) => {
  return (
    <Category
      {...rest}
      style={{ backgroundColor: active || isActive ? "#5A83BB" : "" }}
    >
      {open && (
        <div className={active || isActive ? "white__icon" : "grey__icon"}>
          {icon}
        </div>
      )}
      {!open && (
        <Tooltip title={name}>
          <div className={active || isActive ? "white__icon" : "grey__icon"}>
            {icon}
          </div>
        </Tooltip>
      )}
      {open && (
        <CategoryText
          className={active || isActive ? "txt-white txt-bold" : ""}
        >
          {name}
        </CategoryText>
      )}
      {/* {open && isCollapsable ? isOpen ? <ExpandMore /> : <ExpandLess /> : null} */}
      {open && isCollapsable ? (
        isOpen ? (
          <ExpandDown
            // className="icons_small"
            style={{ height: 16, width: 16 }}
          />
        ) : (
          <ExpandUp
            // className="icons_small"
            style={{ height: 16, width: 16 }}
          />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

export default memo(SidebarCategory);
