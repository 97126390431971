import { memo, useEffect, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import _ from "lodash";
import VektorSearchAutoComplete from "pages/Projects/Shared/VektorSearchAutoComplete";
import { getPO } from "services/api-finance";
import { uploadSingleFile } from "services/api-general";
import { useDispatch } from "react-redux";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import { ReactComponent as UploadIcon } from "icons/upload.svg";
import UploadOption from "components/FileUpload/UploadOption";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import useFeatureFlag from "utils/hooks/useFeatureFlag";
import { LIBRARY_FILE_COPY } from "config";
import DocumentViewer from "components/DocumentViewer";

const AddInvoice = ({
  open,
  handleClose,
  editInvoice,
  saveInvoice,
  projectId,
  purchaseOrderList,
}) => {
  const [invoice, setInvoice] = useState();
  const [purchaseOrderAmount, setPurchaseOrderAmount] = useState(0);
  const [purchaseOrderRemaingingAmount, setPurchaseOrderRemaingingAmount] =
    useState(0);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [openFile, setOpenFile] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  // eslint-disable-next-line
  const [copyToLibrary, setCopyToLibrary] = useState(false);
  const fileRef = useRef();
  const dispatch = useDispatch();
  const FeatureFlagLibrary = useFeatureFlag(FEATURE_FLAGS.Library);

  useEffect(() => {
    setInvoice(editInvoice);
    if (editInvoice?.attachment) {
      setFile(editInvoice?.attachment);
    }
  }, [editInvoice]);

  const handleDataChange = ({ target: { type, value, name } }) => {
    console.log({ type, value, name });
    if (type === "date") {
      value = value ? moment(value).format("YYYY-MM-DD[T00:00:00.000Z]") : null;
    }
    const updatedData = { ...invoice, [name]: value };
    if (type === "purchaseOrder") {
      const PODetails = purchaseOrderList.find((f) => f._id === value);
      if (PODetails) {
        setPurchaseOrderAmount(PODetails.amount);
        setPurchaseOrderRemaingingAmount(PODetails.remainingAmount);
      } else {
        setPurchaseOrderAmount(0);
        setPurchaseOrderRemaingingAmount(0);
      }
    }
    if (Object.values(updatedData).every((value) => value)) {
      setError(false);
    }
    setInvoice(updatedData);
  };

  const handleSave = () => {
    const data = _.omit(invoice, ["poNumber", "__v"]);
    if (file) {
      data.attachment = file;
    }
    if (
      Object.values(data)
        .map((m) => (typeof m === "number" ? String(m) : m))
        .every((value) => value)
    ) {
      data.copyToLibrary = copyToLibrary;
      saveInvoice(data);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleUpload = ({ target }) => {
    if (target.files[0]) {
      uploadFile(target.files[0]);
    }
  };

  const uploadFile = async (file, fromLib = false) => {
    setOpenUpload(false);
    setLoading(true);
    let formData = new FormData();
    formData.append("file", file);
    formData.append("module", "invoice");
    formData.append("projectId", projectId);
    await uploadSingleFile(formData)
      .then((resp) => {
        setFile(resp.data);
        setLoading(false);
        if (
          !fromLib &&
          FeatureFlagLibrary &&
          LIBRARY_FILE_COPY.toLowerCase() !== "false"
        ) {
          selectLibStatus();
        }
      })
      .catch((err) => {
        setLoading(false);
        const errMessage = err.response?.data?.info || "File Error";
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: errMessage,
          })
        );
        console.log("error >>>>> ", err);
      });
  };

  const updateLibUploadStatus = (status) => {
    setCopyToLibrary(status);
  };

  const handleUploadClose = () => {
    setOpenUpload(false);
  };

  const selectLibStatus = () => {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.confirm,
        popupText: "Would you like to copy this file to Library?",
        onConfirm: () => {
          setCopyToLibrary(true);
        },
        onCancel: () => {
          setCopyToLibrary(false);
        },
      })
    );
  };

  return (
    <Dialog open={open} handleClose={handleClose} fullWidth>
      <DialogTitle>
        <div className="d-jsb-c">
          {invoice?._id ? "Edit Invoice" : "Add Invoice"}
        </div>
      </DialogTitle>
      <div className="pd-10">
        <Grid container columns={12} spacing={4} className="mb-10">
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Invoice Number"
              value={invoice?.invoiceNumber}
              name="invoiceNumber"
              onChange={handleDataChange}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="invoiceType">Type</InputLabel>
            <Select
              fullWidth
              value={invoice?.type || ""}
              label="Type"
              name="type"
              onChange={handleDataChange}
            >
              <MenuItem value="SERVICE">Service</MenuItem>
              <MenuItem value="PRODUCT">Product</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={4} className="mb-10">
          <Grid item xs={4}>
            <DatePicker
              fullWidth
              required
              label="Invoice Date"
              value={moment(invoice?.invoiceDate?.split("T")[0])}
              format="MM/dd/yy"
              onChange={(date) =>
                handleDataChange({
                  target: { type: "date", value: date, name: "invoiceDate" },
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              fullWidth
              required
              label="Start Date"
              value={moment(invoice?.startDate?.split("T")[0])}
              format="MM/dd/yy"
              onChange={(date) =>
                handleDataChange({
                  target: { type: "date", value: date, name: "startDate" },
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              fullWidth
              required
              label="End Date"
              minDate={moment(invoice?.startDate?.split("T")[0])}
              value={moment(invoice?.endDate?.split("T")[0])}
              format="MM/dd/yy"
              onChange={(date) =>
                handleDataChange({
                  target: { type: "date", value: date, name: "endDate" },
                })
              }
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={4} className="mb-10">
          <Grid item xs={6}>
            <VektorSearchAutoComplete
              value={invoice?.purchaseOrder}
              onChange={handleDataChange}
              endpoint={getPO}
              label="PO Ref No."
              dataKey="purchaseOrder"
              displayKey="poNumber"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Amount"
              value={invoice?.amount}
              type="number"
              name="amount"
              onChange={handleDataChange}
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={4} className="mb-10">
          <Grid item xs={6}>
            <InputLabel className="pb-10">PO Amount</InputLabel>
            <Typography>{purchaseOrderAmount}</Typography>
          </Grid>
          <Grid item xs={6}>
            <InputLabel className="pb-10">PO Remaining Amount</InputLabel>
            <Typography>
              {purchaseOrderRemaingingAmount - invoice?.amount}
            </Typography>
          </Grid>
        </Grid>
        <Grid container columns={12}>
          <Grid item xs={4}>
            <InputLabel className="mb-5">Upload File</InputLabel>
            <input
              accept=".pdf, .docx, .doc, .xlsx, .xls"
              className="d-none"
              type="file"
              name="file"
              ref={fileRef}
              onChange={(e) => handleUpload(e)}
            />
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              component="span"
              onClick={() => {
                FeatureFlagLibrary
                  ? setOpenUpload(true)
                  : fileRef.current.click();
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : file ? (
                <div className="d-jsb-cen">
                  <UploadIcon className="primary__icon" />
                  &nbsp;
                  {file.fileName.substring(file.fileName.indexOf("-") + 1)}
                </div>
              ) : (
                <div className="d-jsb-cen">
                  <UploadIcon className="primary__icon" />
                  &nbsp; File
                </div>
              )}
            </Button>
            <div className="d-jsb-c">
              {file && (
                <Typography
                  variant="caption"
                  className="pointer"
                  color="primary"
                  onClick={() => file && setOpenFile(true)}
                >
                  <u>View File</u>
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="pd-10">
        <div className="d-jsb-r">
          <Button className="mr-10" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
        {error && (
          <div className="d-jsb-r text-error">All fields are madatory</div>
        )}
      </div>
      {openFile && (
        <DocumentViewer
          open={openFile}
          fileData={file}
          handleClose={() => setOpenFile(false)}
        />
      )}
      {openUpload && (
        <UploadOption
          open={openUpload}
          handleCapture={handleUpload}
          uploads={uploadFile}
          updateLibUploadStatus={updateLibUploadStatus}
          handleUploadClose={handleUploadClose}
          handleClose={() => {
            setOpenUpload(false);
          }}
          projectId={projectId}
          multipleFiles={false}
          
        />
      )}
    </Dialog>
  );
};
export default memo(AddInvoice);
