import React, { memo, useState, useMemo, useRef, useEffect } from "react";
import {
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Tab,
} from "@material-ui/core";
// import SearchIcon from "@material-ui/icons/Search";
// import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "parts/PageHeader";
import LINKS from "utils/constants/links";
import { getNavLinks } from "../ProjectPhases/helpers";
import { useHistory, useParams } from "react-router-dom";
import { ColorButton } from "components/UI/Buttons";
import { SettingsRounded } from "@material-ui/icons";
import { PERMISSION_TYPES } from "utils/constants";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import MyTasks from "components/Documents/MyTasks";
import MyDocuments from "components/Documents/MyDocuments";
import MyBookmarks from "components/Documents/MyBookmarks";
import SearchResults from "components/Documents/SearchResults";
import { debounce } from "lodash";
import AllDocuments from "components/Documents/AllDocuments";
import CompletedDocuments from "components/Documents/CompletedDocuments";
import Templates from "components/Documents/Templates";
import { getProjects } from "redux/actions/projects";
import FeatureFlag from "utils/hocs/FeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import { ReactComponent as SearchIcon } from "icons/search.svg";
import { ReactComponent as CloseIcon } from "icons/cross_circle.svg";

const Documents = () => {
  const [activeTab, setActiveTab] = useState("searchResults");
  const [searchText, setSearchText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();
  const { projectId } = useParams();
  const searchRef = useRef(null);
  const dispatch = useDispatch();

  const accessedFeatures = useSelector(
    (state) => state?.modulePreference?.accessibleFeature
  );

  const tabs = [
    {
      label: "My Tasks",
      id: "myTasks",
      component: <MyTasks projectId={projectId} />,
      adminOnly: false,
    },
    {
      label: "Completed Tasks",
      id: "completedTasks",
      component: <CompletedDocuments projectId={projectId} />,
      adminOnly: false,
    },
    {
      label: "Documents",
      id: "approvedDocuments",
      component: <MyDocuments projectId={projectId} />,
      adminOnly: false,
    },
    {
      label: "All Documents",
      id: "allDocuments",
      component: <AllDocuments projectId={projectId} />,
      adminOnly: false,
    },
    {
      label: "Bookmarks",
      id: "bookmarks",
      component: <MyBookmarks projectId={projectId} />,
      adminOnly: false,
    },
    {
      label: "Templates",
      id: "templates",
      component: <Templates projectId={projectId} />,
      adminOnly: false,
    },
    {
      label: "Search Results",
      id: "searchResults",
      component: (
        <SearchResults projectId={projectId} searchTerm={searchTerm} />
      ),
      adminOnly: false,
    },
  ];

  const project = useSelector((state) =>
    state.projects.results.find((p) => p._id === projectId)
  );

  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
  }, [dispatch, projectId]);
  const { permissions } = useSelector(({ auth }) => auth.currentUser);

  useEffect(() => {
    setActiveTab(history.location.hash?.replace("#", ""));
  }, [history.location.hash]);

  useEffect(() => {
    const isPageReloaded = parseInt(localStorage.getItem("isPageReloaded"));
    if (!isPageReloaded) {
      window.location.reload();
      localStorage.setItem("isPageReloaded", 1);
    }
  }, [projectId]);

  const handleTabChange = (event, newValue) => {
    if (newValue === "searchResults") {
      searchRef.current.focus();
    } else {
      searchRef.current.blur();
    }
    setActiveTab(newValue);
    history.push(history.location.pathname + `#${newValue}`);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    handleSearchDebounced(e.target.value);
  };

  const handleSearchDebounced = useMemo(
    () =>
      debounce((value) => {
        if (activeTab !== "searchResults") {
          setActiveTab("searchResults");
          history.push(history.location.pathname + `#searchResults`);
        }
        setSearchTerm(value);
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab]
  );

  const isAdminOrPM = permissions === PERMISSION_TYPES.superAdmin;

  const getTabList = () => {
    let allTabs = isAdminOrPM ? tabs : tabs.filter((tab) => !tab.adminOnly);

    if (!isAdminOrPM) {
      allTabs = allTabs.filter(
        (tab) =>
          accessedFeatures.includes(tab.id) ||
          !Object.values(FEATURE_FLAGS).includes(tab.id)
      );
    }

    if (!activeTab) {
      setActiveTab(allTabs[0].id);
    }
    return (
      <TabList onChange={handleTabChange}>
        {allTabs.map((tab) => {
          return <Tab key={tab.id} label={tab.label} value={tab.id} />;
        })}
      </TabList>
    );
  };
  const getTabPanel = () => {
    const allTabs = isAdminOrPM ? tabs : tabs.filter((tab) => !tab.adminOnly);
    return allTabs.map((tab) => {
      return (
        <TabPanel key={tab.id} className="pd-0" value={tab.id}>
          <FeatureFlag feature={tab.id}>{tab.component}</FeatureFlag>
        </TabPanel>
      );
    });
  };

  const getActionButton = () => {
    return (
      <>
        {activeTab === "templates" ? (
          <ColorButton
            colour="lightGreen"
            onClick={() =>
              history.push(
                LINKS.ADD_DMS_TEMPLATE.HREF.replace(":projectId", projectId)
              )
            }
          >
            Add Template
          </ColorButton>
        ) : (
          <ColorButton
            colour="lightGreen"
            onClick={() =>
              history.push(
                LINKS.ADD_DOCUMENT.HREF.replace(":projectId", projectId)
              )
            }
          >
            Add Document
          </ColorButton>
        )}
        <FeatureFlag feature={FEATURE_FLAGS.DocumentChangeRequest}>
          <ColorButton
            className="ml-10"
            colour="lightGreen"
            onClick={() =>
              history.push(
                LINKS.DOCUMENTS_CHANGE_REQUEST.HREF.replace(
                  ":projectId",
                  projectId
                )
              )
            }
          >
            Change Request
          </ColorButton>
        </FeatureFlag>
      </>
    );
  };

  return (
    <>
      <PageHeader
        title={LINKS.PROJECT_DOCUMENTS.TITLE}
        links={getNavLinks(project?.name, projectId)}
      />
      <TabContext value={activeTab}>
        <Grid
          container
          columns={12}
          justify="space-between"
          alignItems="center"
          className="mb-10"
        >
          <Grid item xs={8}>
            {getTabList()}
          </Grid>
          <Grid item xs={4} className="txt-end">
            <TextField
              value={searchText}
              onChange={handleSearchChange}
              variant="outlined"
              inputRef={searchRef}
              size="small"
              label="Search"
              style={{
                marginRight: "10px",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton className="pd-0">
                      <SearchIcon />
                    </IconButton>
                    {searchText && (
                      <IconButton
                        className="pd-0"
                        onClick={() => {
                          setSearchText("");
                          setSearchTerm("");
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <>
              {![PERMISSION_TYPES.viewer].includes(permissions) &&
                getActionButton()}
              {![PERMISSION_TYPES.viewer, PERMISSION_TYPES.user].includes(
                permissions
              ) && (
                <IconButton
                  className="ml-5 pd-0"
                  onClick={() =>
                    history.push(
                      LINKS.DOCUMENT_SETTINGS.HREF.replace(
                        ":projectId",
                        projectId
                      )
                    )
                  }
                >
                  <SettingsRounded />
                </IconButton>
              )}
            </>
          </Grid>
        </Grid>
        {getTabPanel()}
      </TabContext>
    </>
  );
};

export default memo(Documents);
