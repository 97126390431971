import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreHoriz,
  Palette,
} from "@material-ui/icons";
import { usePhaseBoxLogic } from "./helpers";
import { ColorPicker } from "material-ui-color";
import { updateProjectPhase } from "redux/actions/projects";
import PhaseItem from "../PhaseItem";
import moment from "moment";
import styled from "styled-components/macro";
import useStyles from "./styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "100%",
  height: 5,
  borderRadius: 5,
}));

const PhaseBox = ({
  phase,
  status,
  systems,
  onHeaderClick,
  onActionClick,
  phaseActions,
  searchText,
  setLoading,
  phases,
  preWorkSystems,
}) => {
  const dispatch = useDispatch();
  const { id: project } = useParams();
  const { name, orderIndex, _id, color: defaultColor } = phase || {};
  const [color, setColor] = useState(defaultColor);
  const [expanded, setExpanded] = useState(false);
  const [expandedPreWork, setExpandedPreWork] = useState(false);
  const classes = useStyles({ color });
  const {
    anchorEl,
    isPhaseActions,
    isOpen,
    dropRef,
    dropRefPreWork,
    handleMenuClick,
    handleClose,
    handleActionClick,
  } = usePhaseBoxLogic(_id, orderIndex, phaseActions, onActionClick);
  const isNewPhase = Boolean(onHeaderClick);
  const [colorPaletteOpened, setColorPaletteOpened] = useState(false);
  const completeSystems = useMemo(
    () => systems.filter((s) => s.mainSystem.calculated?.overallStatus === 100),
    [systems]
  );
  const incompleteSystems = useMemo(
    () => systems.filter((s) => s.mainSystem.calculated?.overallStatus < 100),
    [systems]
  );

  const openColorPalette = () => {
    setColor(defaultColor);
    setColorPaletteOpened(true);
  };

  const closeColorPalette = () => {
    dispatch(
      updateProjectPhase({
        ...phase,
        mainId: project,
        color,
        status: undefined,
      })
    );
    setColorPaletteOpened(false);
  };

  const handleColorChange = (newColor) => {
    setColor(`#${newColor.hex}`);
  };

  const handleExpand = () => {
    setExpanded((expanded) => !expanded);
  };

  const handleExpandPreWork = () => {
    setExpandedPreWork(!expandedPreWork);
  };

  useEffect(() => {
    if (searchText !== "") {
      setExpandedPreWork(true);
    } else {
      setExpandedPreWork(false);
    }
  }, [searchText]);

  return (
    <div className={classes.container}>
      <Card className={classes.card} variant="outlined">
        <CardHeader
          className={isNewPhase ? classes.cardHeader : "pd-phase-box"}
          title={
            isNewPhase || typeof name === "object" ? (
              <div>{name}</div>
            ) : (
              <div>
                <div>{name}</div>
                <div className="d-jsb-c">
                  <BorderLinearProgress
                    variant="determinate"
                    value={Math.round(status)}
                  />
                  <div className="txt-sm ml-5">{Math.round(status)}%</div>
                </div>
              </div>
            )
          }
          onClick={onHeaderClick}
          subheader={
            phase.start &&
            phase.end && (
              <Typography color="inherit" className="txt-sm">
                {moment(phase.start).format("MM/DD/YYYY")} -{" "}
                {moment(phase.end).format("MM/DD/YYYY")}
              </Typography>
            )
          }
          action={
            isPhaseActions ? (
              <>
                <IconButton aria-label="settings" onClick={handleMenuClick}>
                  <MoreHoriz />
                </IconButton>
                <Menu
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={isOpen}
                  onClose={handleClose}
                >
                  {phaseActions.map(({ title, action }, idx) => (
                    <MenuItem key={idx} onClick={handleActionClick(action)}>
                      {title}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              isNewPhase === false &&
              (colorPaletteOpened ? (
                <ColorPicker
                  hideTextfield
                  openAtStart
                  value={color}
                  onChange={handleColorChange}
                  onOpen={closeColorPalette}
                />
              ) : (
                <IconButton
                  aria-label="color-palette"
                  onClick={openColorPalette}
                  color="inherit"
                >
                  <Palette color="inherit" />
                </IconButton>
              ))
            )
          }
        />
        <CardContent className={!expanded ? "pb-0" : ""}>
          <Grid
            container
            direction="column"
            justify="space-between"
            spacing={3}
          >
            <Grid
              ref={dropRef}
              item
              spacing={3}
              className="phase-list"
              style={{ height: 350, overflowY: "auto" }}
            >
              {incompleteSystems.map((sys) => (
                <Grid key={sys._id} item xs={12} className="mb-10">
                  <PhaseItem
                    item={sys}
                    searchText={searchText}
                    setLoading={setLoading}
                    phases={phases}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item container spacing={3}>
              <Grid item xs={12} container className="prework-tab">
                <Box
                  width="calc(100% + 32px)"
                  color="inherit"
                  borderTop="1.5px solid"
                  mx={-4}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flex={1}
                >
                  <Typography>Pre-Work ({preWorkSystems.length})</Typography>
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={handleExpandPreWork}
                  >
                    {expandedPreWork ? (
                      <KeyboardArrowUp color="inherit" />
                    ) : (
                      <KeyboardArrowDown color="inherit" />
                    )}
                  </IconButton>
                </Box>
              </Grid>
              {expandedPreWork && (
                <Grid
                  ref={dropRefPreWork}
                  item
                  container
                  spacing={3}
                  className="phase-list"
                  style={{
                    minHeight: 100,
                    maxHeight: 200,
                    overflowY: "auto",
                    marginTop: "6px",
                  }}
                >
                  {preWorkSystems?.map((sys) => (
                    <Grid key={sys._id} item xs={12}>
                      <PhaseItem
                        item={sys}
                        searchText={searchText}
                        setLoading={setLoading}
                        phases={phases}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              <Grid item xs={12} container>
                <Box
                  width="calc(100% + 32px)"
                  color="inherit"
                  borderTop="1.5px solid"
                  mx={-4}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flex={1}
                >
                  <Typography>Completed ({completeSystems.length})</Typography>
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={handleExpand}
                  >
                    {expanded ? (
                      <KeyboardArrowUp color="inherit" />
                    ) : (
                      <KeyboardArrowDown color="inherit" />
                    )}
                  </IconButton>
                </Box>
              </Grid>
              <Grid
                item
                container
                spacing={3}
                className="phase-list"
                style={{
                  maxHeight: 200,
                  overflowY: "auto",
                }}
              >
                {expanded &&
                  completeSystems?.map((sys) => (
                    <Grid key={sys._id} item xs={12} style={{ opacity: 0.5 }}>
                      <PhaseItem
                        item={sys}
                        searchText={searchText}
                        setLoading={setLoading}
                        phases={phases}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default PhaseBox;
