import React, { memo, Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import Loader from "components/Loader";
import DashboardLayout from "layouts/Dashboard";
import AuthLayout from "layouts/Auth";
import Page404 from "pages/auth/Page404";
import AuthGuard from "utils/hocs/AuthGuard";
import GuestGuard from "utils/hocs/GuestGuard";
import {
  dashboardLayoutRoutes,
  authLayoutRoutes,
} from "utils/constants/routes";
import LINKS from "utils/constants/links";
import useAllowedRoutes from "utils/hooks/useAllowedRoutes";
import { useDispatch, useSelector } from "react-redux";
import { SOCKET_EVENTS } from "utils/constants/socket-events";
import { logoutUser } from "redux/actions/authActions";
import { setModulePreference } from "redux/actions/modulePreferenceActions";
import useDisabledRoutes from "utils/hooks/useDisabledRoutes";
import AccessDenied from "pages/auth/AccessDenied";

export const SideNavContext = React.createContext(null);

const childRoutes = (Layout, routes, isAuthGuard, access) =>
  routes.map(({ component: Component, children, path }, index) => {
    const Guard = isAuthGuard ? AuthGuard : GuestGuard;
    const output = [];
    if (Component) {
      output.push(
        <Route
          key={index}
          path={path}
          exact
          render={(props) => {
            return access ? (
              <Guard>
                <Layout>
                  <Component {...props} />
                </Layout>
              </Guard>
            ) : (
              <Guard>
                <Layout>
                  <AccessDenied />
                </Layout>
              </Guard>
            );
          }}
        />
      );
    }

    if (children) {
      children.forEach((element, index) => {
        output.push(
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => {
              return access ? (
                <Guard>
                  <Layout>
                    <element.component {...props} />
                  </Layout>
                </Guard>
              ) : (
                <Guard>
                  <Layout>
                    <AccessDenied />
                  </Layout>
                </Guard>
              );
            }}
          />
        );
      });
    }

    return output;
  });

const Routes = () => {
  const allowedDashboardRoutes = useAllowedRoutes(dashboardLayoutRoutes);
  const disabledDashboardRoutes = useDisabledRoutes(dashboardLayoutRoutes);
  const [open, setOpen] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state?.auth?.currentUser?._id);
  const isInitialized = useSelector(
    (state) => state?.modulePreference?.isInitialized
  );

  const socket = useSelector((state) => state.general.socket);

  React.useEffect(() => {
    dispatch(setModulePreference(localStorage.getItem("accessibleFeature")));
  }, [dispatch]);

  React.useEffect(() => {
    if (isLoggedIn) {
      dispatch({
        type: SOCKET_EVENTS.CONNECT,
        payload: currentUser,
      });
    } else {
      if (!window.location.href.includes("/auth")) {
        localStorage.setItem("requestedURL", window.location.href);
      }
      dispatch({
        type: SOCKET_EVENTS.DISCONNECT,
      });
    }
    return () =>
      dispatch({
        type: SOCKET_EVENTS.DISCONNECT,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoggedIn]);

  React.useEffect(() => {
    if (currentUser?._id && socket) {
      socket.on(`deactivate-${currentUser?._id}`, () => {
        dispatch(logoutUser());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser._id, socket]);

  return (
    <Suspense fallback={<Loader />}>
      {isInitialized && (
        <Router>
          <SideNavContext.Provider value={{ open: open, setOpen: setOpen }}>
            <Switch>
              {childRoutes(DashboardLayout, allowedDashboardRoutes, true, true)}
              {childRoutes(
                DashboardLayout,
                disabledDashboardRoutes,
                true,
                false
              )}
              {childRoutes(AuthLayout, authLayoutRoutes, false, true)}
              <Redirect to={LINKS.OVERVIEW.HREF} />
              <Route
                render={() => (
                  <AuthLayout>
                    <Page404 />
                  </AuthLayout>
                )}
              />
            </Switch>
          </SideNavContext.Provider>
        </Router>
      )}
    </Suspense>
  );
};

export default memo(Routes);
