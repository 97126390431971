import { get, post, del, put, getJQ, patch } from "services/axios";


const getAllDeviations = async (
  projectId,
  searchQuery = null,
  skip = 0,
  limit = null
) =>
  await getJQ(`/v2/aim/project/${projectId}/deviations`, {
    searchQuery,
    skip,
    limit,
  });

const getClosedDeviations = async (
  projectId,
  searchQuery = null,
  skip = 0,
  limit = null
) =>
  await getJQ(`/v2/aim/project/${projectId}/deviations/closedTasks`, {
    searchQuery,
    skip,
    limit,
  });
const postDeviation = async (projectId, params) =>
  await post(`/v2/aim/project/${projectId}/deviation`, params);

const getDeviationById = async (projectId, deviationId) =>
  await get(`/v2/aim/project/${projectId}/deviation/${deviationId}`);

const getMyTasks = async (projectId) =>
  await get(`/v2/aim/project/${projectId}/deviations/myTasks`);

const getCompletedTasks = async (projectId) =>
  await get(`/v2/aim/project/${projectId}/deviations/completedTasks`);

const updateDeviation = async (projectId, deviationId, params) =>
  await put(`/v2/aim/project/${projectId}/deviation/${deviationId}`, params);

const approveDeviation = async (projectId, deviationId, params) =>
  await put(
    `/v2/aim/project/${projectId}/deviation/${deviationId}/approve`,
    params
  );

const deleteDeviation = async (projectId, deviationId) =>
  await del(`/v2/aim/project/${projectId}/deviation/${deviationId}`);

const getAnnotations = async (deviationId, fileId) =>
  await get(`/v2/aim/deviation/${deviationId}/annotations/${fileId}`);

const updateComment = async (projectId, deviationId, params) =>
  await put(
    `/v2/aim/project/${projectId}/deviation/${deviationId}/comment`,
    params
  );

const getSettings = async (projectId) =>
  await get(`v2/projects/${projectId}/moduleSettings/AIM`);

const updateSettings = async (projectId, params) =>
  await put(`v2/projects/${projectId}/moduleSettings/AIM`, params);

const createWorkflow = async (organizationId, params) =>
    await post(`/v2/organization/${organizationId}/workflows`, params)

const getWorkflows = async (organizationId) =>
    await get(`/v2/organization/${organizationId}/workflows`)

const updateWorkflow = async (organizationId, workflowId, params) =>
    await patch(`/v2/organization/${organizationId}/workflows/${workflowId}`,  params)

const deleteWorkflow = async(organizationId, workflowId) =>
    await del(`/v2/organization/${organizationId}/workflows/${workflowId}`)

export {
  getAllDeviations,
  postDeviation,
  getDeviationById,
  updateDeviation,
  deleteDeviation,
  getMyTasks,
  approveDeviation,
  getCompletedTasks,
  getAnnotations,
  getClosedDeviations,
  updateComment,
  getSettings,
  updateSettings,
  createWorkflow,
  getWorkflows,
  deleteWorkflow,
  updateWorkflow,
};
