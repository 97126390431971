import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Autocomplete } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import _ from "lodash";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import useFeatureFlag from "utils/hooks/useFeatureFlag";
import { uploadSingleFile } from "services/api-general";
import { LIBRARY_FILE_COPY } from "config";
import { ReactComponent as UploadIcon } from "icons/upload.svg";
import DocumentViewer from "components/DocumentViewer";
import UploadOption from "components/FileUpload/UploadOption";

const AddPurchaseOrder = ({
  open,
  handleClose,
  editPO,
  vendorList,
  saveVendor,
  projectId,
}) => {
  const [purchaseOrder, setPurchaseOrder] = useState();
  const [selectedVendor, setSelectedVendor] = useState();

  const [copyToLibrary, setCopyToLibrary] = useState(false);
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const fileRef = useRef();
  const FeatureFlagLibrary = useFeatureFlag(FEATURE_FLAGS.Library);
  const [openFile, setOpenFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setPurchaseOrder(editPO);
    if (editPO?.vendorInfo) {
      const data = {
        value: editPO?.vendorInfo?._id,
        name: editPO?.vendorInfo?.name,
      };
      setSelectedVendor(data);
    }
    if (editPO?.attachment) {
      setFile(editPO?.attachment);
    }
  }, [editPO, vendorList]);
  const handleDataChange = ({ target: { type, value, name } }) => {
    if (type === "date") {
      value = value ? moment(value).format("YYYY-MM-DD[T00:00:00.000Z]") : null;
    }
    if (type === "vendor") {
      const selected = {
        value: value,
        name: vendorList.filter((v) => v._id === value)[0]?.name,
      };
      setSelectedVendor(selected);
    }
    const updatedData = { ...purchaseOrder, [name]: value };
    if (Object.values(updatedData).every((value) => value)) {
      setError(false);
    }
    setPurchaseOrder(updatedData);
  };
  const handleSave = () => {
    const data = _.omit(purchaseOrder, ["vendorInfo", "__v"]);
    if (file) {
      data.attachment = file;
    }
    if (
      Object.values(data)
        .map((m) => (typeof m === "number" ? String(m) : m))
        .every((value) => value)
    ) {
      data.copyToLibrary = copyToLibrary;
      saveVendor(data);
      setError(false);
    } else {
      setError(true);
    }
  };
  const handleUpload = ({ target }) => {
    if (target.files[0]) {
      uploadFile(target.files[0]);
    }
  };

  const updateLibUploadStatus = (status) => {
    setCopyToLibrary(status);
  };

  const handleUploadClose = () => {
    setOpenUpload(false);
  };

  const uploadFile = async (file, fromLib = false) => {
    setOpenUpload(false);
    setLoading(true);
    let formData = new FormData();
    formData.append("file", file);
    formData.append("module", "purcaseOrder");
    formData.append("projectId", projectId);
    await uploadSingleFile(formData)
      .then((resp) => {
        setFile(resp.data);
        setLoading(false);
        if (
          !fromLib &&
          FeatureFlagLibrary &&
          LIBRARY_FILE_COPY.toLowerCase() !== "false"
        ) {
          selectLibStatus();
        }
      })
      .catch((err) => {
        setLoading(false);
        const errMessage = err.response?.data?.info || "File Error";
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: errMessage,
          })
        );
        console.log("error >>>>> ", err);
      });
  };

  const selectLibStatus = () => {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.confirm,
        popupText: "Would you like to copy this file to Library?",
        onConfirm: () => {
          setCopyToLibrary(true);
        },
        onCancel: () => {
          setCopyToLibrary(false);
        },
      })
    );
  };

  return (
    <Dialog open={open} handleClose={handleClose} fullWidth>
      <DialogTitle>
        <div className="d-jsb-c">
          {purchaseOrder?._id ? "Edit Purchase Order" : "Add Purchase Order"}
        </div>
      </DialogTitle>
      <div className="pd-10">
        <Grid container columns={12} spacing={4} className="mb-10">
          <Grid item xs={6}>
            <Autocomplete
              disableClearable
              id="search-vendor"
              options={
                vendorList.map((data) => {
                  return {
                    value: data._id,
                    name: data.name,
                  };
                }) || []
              }
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              value={selectedVendor}
              onChange={(e, value) => {
                handleDataChange({
                  target: {
                    name: "vendor",
                    value: value?.value || null,
                    type: "vendor",
                  },
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Vendor" required />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="PO Number"
              value={purchaseOrder?.poNumber}
              name="poNumber"
              onChange={handleDataChange}
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={4} className="mb-10">
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="PO Description"
              value={purchaseOrder?.poDescription}
              name="poDescription"
              onChange={handleDataChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Amount"
              value={purchaseOrder?.amount}
              type="number"
              name="amount"
              onChange={handleDataChange}
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={4}>
          <Grid item xs={6}>
            <DatePicker
              fullWidth
              required
              label="Issue Date"
              value={moment(purchaseOrder?.issueDate?.split("T")[0])}
              format="MM/dd/yy"
              onChange={(date) =>
                handleDataChange({
                  target: { type: "date", value: date, name: "issueDate" },
                })
              }
            />
          </Grid>
          <Grid item xs={6} container>
            <InputLabel className="mb-5">Upload File</InputLabel>
            <input
              accept=".pdf, .docx, .doc, .xlsx, .xls"
              className="d-none"
              type="file"
              name="file"
              ref={fileRef}
              onChange={(e) => handleUpload(e)}
            />
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              component="span"
              onClick={() => {
                FeatureFlagLibrary
                  ? setOpenUpload(true)
                  : fileRef.current.click();
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : file ? (
                <div className="d-jsb-cen">
                  <UploadIcon className="primary__icon" />
                  &nbsp;
                  {file.fileName.substring(file.fileName.indexOf("-") + 1)}
                </div>
              ) : (
                <div className="d-jsb-cen">
                  <UploadIcon className="primary__icon" />
                  &nbsp; File
                </div>
              )}
            </Button>
            <div className="d-jsb-c">
              {file && (
                <Typography
                  variant="caption"
                  className="pointer"
                  color="primary"
                  onClick={() => file && setOpenFile(true)}
                >
                  <u>View File</u>
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="pd-10">
        <div className="d-jsb-r">
          <Button className="mr-10" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
        {error && (
          <div className="d-jsb-r text-error">All fields are madatory</div>
        )}
      </div>
      {openFile && (
        <DocumentViewer
          open={openFile}
          fileData={file}
          handleClose={() => setOpenFile(false)}
        />
      )}
      {openUpload && (
        <UploadOption
          open={openUpload}
          handleCapture={handleUpload}
          uploads={uploadFile}
          updateLibUploadStatus={updateLibUploadStatus}
          handleUploadClose={handleUploadClose}
          handleClose={() => {
            setOpenUpload(false);
          }}
          projectId={projectId}
          multipleFiles={false}
        />
      )}
    </Dialog>
  );
};
export default memo(AddPurchaseOrder);
