import { useDrag } from "react-dnd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMetaSystem } from "redux/actions/metaSystem";

import LINKS from "utils/constants/links";
import useUserPermissions from "utils/hooks/useUserPermission";
import { ALLOWED_ROLES } from "./constants";

const usePhaseItemLogic = (item, canDrag, setLoading) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { included } = useUserPermissions(ALLOWED_ROLES);

  const changeColumn = async (projectPhase) => {
    setLoading(true);
    const params = {
      _id: item._id,
      projectPhase: projectPhase || null,
      autoPhaseId: null,
    };
    await dispatch(updateMetaSystem(params, setLoading));
  };
  const changePreWork = async (projectPhase) => {
    setLoading(true);
    const params = {
      _id: item._id,
      autoPhaseId: projectPhase || null,
      projectPhase: null,
    };
    await dispatch(updateMetaSystem(params, setLoading));
  };

  const [{ isDragging }, dragRef] = useDrag({
    item: { name: item.name, type: "ITEM" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (_, monitor) => {
      const dropResult = monitor.getDropResult();
      if (
        dropResult &&
        item.projectPhase !== dropResult.name &&
        dropResult.type === "phaseDrop"
      ) {
        changeColumn(dropResult.name);
      }
      if (
        dropResult &&
        item.autoPhaseId !== dropResult.name &&
        dropResult.type === "preWorkDrop"
      ) {
        changePreWork(dropResult.name);
      }

      if (dropResult && !dropResult.type) {
        changeColumn(null);
      }
    },
    canDrag: included && canDrag,
  });

  const onClick = () => {
    history.push(
      LINKS.EDIT_META_SYSTEM.HREF.replace(":systemId", item._id)
        .replace(":mainSystemId", item.mainSystem._id)
        .replace(":projectId", item.project)
    );
  };

  return {
    isDragging,
    dragRef,
    onClick,
  };
};

export default usePhaseItemLogic;
