export const TABLE_BUDGET = [
  { id: "departmentName", label: "Line Item", minWidth: 100 },
  { id: "budget", label: "Budget ($)", minWidth: 100 },
  { id: "consumedBudget", label: "Total Spent ($)", minWidth: 100 },
  { id: "remainingAmount", label: "Remaining Amount ($)", minWidth: 100 },
];

export const TABLE_ADD_BUDGET_COLUMN = [
  { id: "departmentName", label: "Line Item", minWidth: 100 },
  { id: "budget", label: "Budget ($)", minWidth: 100 },
  { id: "startDate", label: "Start Date", minWidth: 100 },
  { id: "endDate", label: "End Date", minWidth: 100 },
];

export const FINANCE_ADD_BUTTON = {
  budget: "Add Budget",
  purchaseOrder: "Add PO",
  invoice: "Add Invoice",
};

export const TABLE_PO_COLUMN = [
  { id: "vendor", label: "Vendor", minWidth: 100 },
  { id: "poNumber", label: "PO Number", minWidth: 50 },
  { id: "poDescription", label: "PO Description", minWidth: 100 },
  { id: "department", label: "Budget Line Item", minWidth: 100 },
  { id: "issueDate", label: "Issue Date", minWidth: 100 },
  { id: "amount", label: "PO Amount ($)", minWidth: 100 },
  { id: "consumed", label: "Invoice Total ($)", minWidth: 100 },
  { id: "remainingAmount", label: "Remaining Amount ($)", minWidth: 100 },
  { id: "latestInvoiceDate", label: "As of Date.", minWidth: 100 },
  { id: "attachment", label: "Attachment", minWidth: 60, align: "center" },
  { id: "action", label: "Action", minWidth: 100 },
];

export const TABLE_PO_COLUMN_TOTAL = [
  { id: "total", label: "Total", minWidth: 100, isVisible: true },
  { id: "poNumber", label: "PO Number", minWidth: 50, isVisible: false },
  {
    id: "poDescription",
    label: "PO Description",
    minWidth: 100,
    isVisible: false,
  },
  {
    id: "department",
    label: "Budget Line Item",
    minWidth: 100,
    isVisible: false,
  },
  { id: "issueDate", label: "Issue Date", minWidth: 100, isVisible: false },
  { id: "amount", label: "PO Amount ($)", minWidth: 100, isVisible: true },
  {
    id: "consumed",
    label: "Invoice Total ($)",
    minWidth: 100,
    isVisible: true,
  },
  {
    id: "remainingAmount",
    label: "Remaining Amount ($)",
    minWidth: 100,
    isVisible: true,
  },
  {
    id: "latestInvoiceDate",
    label: "As of Date.",
    minWidth: 100,
    isVisible: false,
  },
  {
    id: "attachment",
    label: "Attachment.",
    minWidth: 100,
    isVisible: false,
  },
  { id: "action", label: "Action", minWidth: 100, isVisible: false },
];

export const TABLE_INVOICE_COLUMN = [
  { id: "invoiceNumber", label: "Invoice Number", minWidth: 100 },
  { id: "invoiceDate", label: "Invoice Date", minWidth: 50 },
  { id: "type", label: "Type", minWidth: 100 },
  { id: "period", label: "Period (In Days)", minWidth: 100 },
  { id: "vendor", label: "Vendor", minWidth: 100 },
  { id: "poNumber", label: "Ref. to PO#", minWidth: 100 },
  { id: "amount", label: "Amount ($)", minWidth: 100 },
  { id: "attachment", label: "Attachment", minWidth: 60 },
  { id: "action", label: "Action", minWidth: 100 },
];

export const TABLE_INVOICE_COLUMN_TOTAL = [
  { id: "total", label: "Total", minWidth: 100 },
  { id: "invoiceDate", label: "Blank", minWidth: 50 },
  { id: "type", label: "Blank", minWidth: 100 },
  { id: "period", label: "Blank", minWidth: 100 },
  { id: "vendor", label: "Blank", minWidth: 100 },
  { id: "poNumber", label: "Blank", minWidth: 100 },
  { id: "amount", label: "Amount", minWidth: 100 },
  { id: "attachment", label: "Blank", minWidth: 60 },
  { id: "action", label: "Blank", minWidth: 100 },
];

export const getTxtColor = (val1, val2) => {
  if (val1 < val2 || val2 < 0) {
    return "txt-red";
  } else {
    return "txt-green";
  }
};

export const randomBlue = (l) => {
  const h = 217;
  const s = 65;
  const color = "hsl(" + h + ", " + s + "%, " + l + "%)";
  return color;
};
