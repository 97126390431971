import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import { useRef } from "react";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import useFeatureFlag from "utils/hooks/useFeatureFlag";
import moment from "moment";
import { getColoredIconComponent } from "pages/Projects/ProjectInsight/constant";
import LINKS from "utils/constants/links";
import { DATE_SORT_DIRS } from "./helper";

export const getFormattedDate = (date) => {
  return moment(date?.split("T")[0])?.format("YYYY-MM-DD[T00:00:00.000Z]");
};

const STATUSES = {
  REVIEWED: "REVIEWED",
  APPROVAL_PENDING: "APPROVAL_PENDING",
  APPROVED: "APPROVED",
  EFFECTIVE: "EFFECTIVE",
};

const statusText = {
  REVIEW_PENDING: "Review Pending",
  APPROVAL_PENDING: "Approval Pending",
  REVIEWED: "Reviewed",
  DRAFT: "Draft",
  APPROVED: "Approved",
  EFFECTIVE: "Effective",
};

const userStatusText = {
  PENDING_APPROVAL: "Pending Approval",
  REJECTED: "Rejected",
  ASSIGNED: "Assigned",
  PENDING_REVIEW: "Pending Review",
};

const COLORS = {
  LATE: "#D32F2F",
  ON_TIME: "#0953B9",
  DEFAULT: "#000000",
  COMPLETED_LATE: "#FFB950",
  COMPLETED_ON_TIME: "#5BA300",
};

const getFinalDate = (completionDate) =>
  completionDate || new Date().toISOString();

const isLate = (finalDate, dueDate) =>
  getFormattedDate(finalDate) > getFormattedDate(dueDate);

const getDocumentStatusColor = (
  dueDate,
  completionDate,
  status,
  userType,
  documentStatus
) => {
  const finalDate = getFinalDate(completionDate);
  if (
    ["REVIEWED", "APPROVAL_PENDING", "APPROVED", "EFFECTIVE"].includes(
      status
    ) &&
    documentStatus !== "DRAFT" &&
    dueDate
  ) {
    return isLate(finalDate, dueDate)
      ? COLORS.COMPLETED_LATE
      : COLORS.COMPLETED_ON_TIME;
  }
  if (
    userType === "REVIEWER" &&
    status === "PENDING_REVIEW" &&
    documentStatus !== "DRAFT" &&
    dueDate
  ) {
    return isLate(finalDate, dueDate) ? COLORS.LATE : COLORS.ON_TIME;
  }

  if (
    userType === "APPROVER" &&
    ["ASSIGNED", "PENDING_APPROVAL"].includes(status) &&
    !["DRAFT", "ASSIGNED", "REVIEWED", "REVIEW_PENDING"].includes(
      documentStatus
    ) &&
    dueDate
  ) {
    return isLate(finalDate, dueDate) ? COLORS.LATE : COLORS.ON_TIME;
  }
  return COLORS.DEFAULT;
};

const getStatusIconColor = (status, dueDate, finalDate) => {
  if (!dueDate) return COLORS.DEFAULT;
  if (["REVIEW_PENDING", "APPROVAL_PENDING"].includes(status)) {
    return isLate(finalDate, dueDate) ? COLORS.LATE : COLORS.ON_TIME;
  }
  if (["DRAFT"].includes(status)) {
    return isLate(finalDate, dueDate) ? COLORS.LATE : COLORS.DEFAULT;
  }
  return isLate(finalDate, dueDate)
    ? COLORS.COMPLETED_LATE
    : COLORS.COMPLETED_ON_TIME;
};

const documentStatusIcon = (
  status,
  approvalDueDate,
  approvalCompletionDate,
  reviewDueDate,
  reviewCompletionDate
) => {
  const finalApproval = getFinalDate(approvalCompletionDate);
  const finalReviewer = getFinalDate(reviewCompletionDate);

  switch (status) {
    case "REVIEW_PENDING":
      return getColoredIconComponent(
        "review_outline",
        getStatusIconColor(status, reviewDueDate, finalReviewer)
      );
    case "REVIEWED":
      return getColoredIconComponent(
        "review_filled",
        getStatusIconColor(status, reviewDueDate, finalReviewer)
      );
    case "APPROVAL_PENDING":
      return getColoredIconComponent(
        "check_circle_outline",
        getStatusIconColor(status, approvalDueDate, finalApproval)
      );
    case "APPROVED":
      return getColoredIconComponent(
        "check_circle_filled",
        getStatusIconColor(status, approvalDueDate, finalApproval)
      );
    case "DRAFT":
      return getColoredIconComponent(
        "draft",
        getStatusIconColor(status, reviewDueDate, new Date().toISOString())
      );
    case "EFFECTIVE":
      return getColoredIconComponent(
        "check_circle_filled",
        getStatusIconColor(status, approvalDueDate, finalApproval)
      );
    default:
      return status;
  }
};

const getTooltipTitle = (users, documentStatus, classes) => {
  if (users?.length) {
    return users.map((user) => (
      <p className={classes.toolTipText}>
        {user.userName} ({user.departmentName})
      </p>
    ));
  }
  return documentStatus === STATUSES.REVIEWED ? "Reviewed" : "Approved";
};

const getUserStyle = (index, length, color, classes) => ({
  paddingBottom: index === length - 1 ? 0 : 5,
  paddingTop: index === 0 ? 0 : 5,
  whiteSpace: "wrap",
  color: color,
  className: `${classes.userText} ${
    index !== length - 1 ? "snapshot-user-border" : ""
  }`,
});

const getPendingUsersList = (
  role,
  users,
  classes,
  documentStatus,
  dueDate,
  completionDate
) => {
  const filteredUsers = users?.filter(
    (user) =>
      ![STATUSES.REVIEWED, STATUSES.APPROVED, STATUSES.EFFECTIVE].includes(
        user.status
      )
  );
  if (
    [
      STATUSES.REVIEWED,
      STATUSES.APPROVED,
      STATUSES.APPROVAL_PENDING,
      STATUSES.EFFECTIVE,
    ].includes(documentStatus) &&
    filteredUsers.length === 0 &&
    users.length > 0
  ) {
    return (
      <Tooltip title={getTooltipTitle(users, documentStatus, classes)}>
        {getColoredIconComponent(
          "check_circle_filled",
          getDocumentStatusColor(
            dueDate,
            completionDate,
            documentStatus,
            role,
            documentStatus
          )
        )}
      </Tooltip>
    );
  }

  return (
    <>
      {filteredUsers?.map((user, index) => (
        <Tooltip
          title={
            <p className={classes.toolTipText}>{userStatusText[user.status]}</p>
          }
        >
          <div
            style={getUserStyle(
              index,
              filteredUsers.length,
              getDocumentStatusColor(
                dueDate,
                completionDate,
                user.status,
                role,
                documentStatus
              ),
              classes
            )}
            className={`${classes.userText} ${
              index !== filteredUsers?.length - 1 ? "snapshot-user-border" : ""
            }`}
          >
            {user.userName} ({user.departmentName})
          </div>
        </Tooltip>
      ))}
    </>
  );
};

export const DocumentSnapshotTable = ({
  order,
  setOrder,
  orderBy,
  setOrderBy,
  handleSortBy,
  classes,
  tableMap,
  setHeaderHeight,
  projectId,
  snapshotData,
}) => {
  const ref = useRef();
  const systemFeatureFlag = useFeatureFlag(FEATURE_FLAGS.Systems);

  const handleSystemRedirect = (systemId, mainSystemId) => {
    const link = LINKS.EDIT_META_SYSTEM.HREF.replace(":projectId", projectId)
      .replace(":systemId", systemId)
      .replace(":mainSystemId", mainSystemId);
    window.open(link, "_blank");
  };

  const handleDeliverableRedirect = (systemId, mainSystemId, delId) => {
    const link = LINKS.EDIT_DELIVERABLE.HREF.replace(":projectId", projectId)
      .replace(":systemId", systemId)
      .replace(":mainSystemId", mainSystemId)
      .replace(":deliverableId", delId);
    window.open(link, "_blank");
  };

  const handleDocumentRedirect = (docId) => {
    const link = LINKS.EDIT_DOCUMENTS.HREF.replace(
      ":projectId",
      projectId
    ).replace(":id", docId);
    window.open(link, "_blank");
  };

  return (
    <TableContainer
      style={{
        display: "table",
        tableLayout: "fixed",
      }}
    >
      <Table
        className={classes.table}
        aria-label="simple table"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <TableHead>
          <TableRow ref={ref}>
            <TableCell
              align="center"
              className={`${classes.customTableHeaderCellStyle} snapshotheader-left-border-radius`}
              style={{ zIndex: 3 }}
              key={"systemcell"}
            >
              System
            </TableCell>
            {tableMap?.map((head, index) => (
              <TableCell
                style={{ whiteSpace: "nowrap", zIndex: 2 }}
                align="center"
                className={`${classes.customTableHeaderCellStyle} ${
                  index === tableMap?.length - 1
                    ? "snapshotheader-right-border-radius"
                    : ""
                }`}
                key={head?.name + "cell"}
              >
                {["reviewDueDate", "approvalDueDate"].includes(head.keyName) ? (
                  <TableSortLabel
                    active={orderBy === head.keyName}
                    direction={
                      orderBy === head.keyName
                        ? order?.toLowerCase()
                        : DATE_SORT_DIRS.ASC.toLowerCase()
                    }
                    onClick={() => {
                      handleSortBy(head.keyName);
                    }}
                  >
                    {head.tableHeadName}
                  </TableSortLabel>
                ) : (
                  head.tableHeadName
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {snapshotData?.map((row) => {
            return (
              <>
                {row.documents?.map((doc, docIndex) => {
                  return (
                    <>
                      <TableRow>
                        {docIndex === 0 && (
                          <TableCell
                            rowSpan={row.documents.length}
                            align="center"
                            className={[
                              classes.sticky,
                              row?.systemName?.includes("NO_SYSTEM")
                                ? classes.customTableDelIconEmptyCellStyle
                                : classes.customTableDelIconCellStyle,
                              classes.snapshotSubheaderRight,
                              classes.systemNameText,
                            ]}
                            style={{ zIndex: 2, whiteSpace: "wrap" }}
                            key={row + "cell"}
                          >
                            <div
                              className="pointer"
                              onClick={() =>
                                row?.systemId &&
                                systemFeatureFlag &&
                                handleSystemRedirect(
                                  row?.systemId,
                                  row?.mainSystemId
                                )
                              }
                            >
                              {!row?.systemName?.includes("NO_SYSTEM") &&
                                row?.systemName}
                            </div>
                          </TableCell>
                        )}
                        <TableCell
                          align="center"
                          style={{ whiteSpace: "wrap" }}
                          className={[
                            classes.sticky,
                            !doc.deliverableName
                              ? classes.customTableDelIconEmptyCellStyle
                              : classes.customTableDelIconCellStyle,
                            classes.snapshotSubheaderRight,
                            classes.systemNameText,
                          ]}
                        >
                          <div
                            className="pointer"
                            onClick={() =>
                              row?.systemId &&
                              doc?.deliverableId &&
                              handleDeliverableRedirect(
                                row?.systemId,
                                row?.mainSystemId,
                                doc?.deliverableId
                              )
                            }
                          >
                            {doc?.deliverableName}
                          </div>
                        </TableCell>
                        <TableCell
                          className={[
                            classes.sticky,
                            !doc.documentNumber
                              ? classes.customTableDelIconEmptyCellStyle
                              : classes.customTableDelIconCellStyle,
                            classes.snapshotSubheaderRight,
                          ]}
                        >
                          <div
                            onClick={() =>
                              doc?._id && handleDocumentRedirect(doc?._id)
                            }
                            className={`${classes.documentNumberText} pointer`}
                          >
                            {doc?.documentNumber}
                          </div>
                          {doc?.actualDocumentNumber && (
                            <div
                              onClick={() =>
                                doc?._id && handleDocumentRedirect(doc?._id)
                              }
                              className={`${classes.actualDocumentNumberText} pointer`}
                            >
                              {doc?.actualDocumentNumber}
                            </div>
                          )}
                        </TableCell>
                        <TableCell
                          className={[
                            classes.sticky,
                            !doc.documentTitle
                              ? classes.customTableDelIconEmptyCellStyle
                              : classes.customTableDelIconCellStyle,
                            classes.snapshotSubheaderRight,
                          ]}
                        >
                          {doc?.documentTitle}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: doc?.reviewDueDate
                              ? getFormattedDate(new Date().toISOString()) >
                                  getFormattedDate(doc?.reviewDueDate) &&
                                ![
                                  "REVIEWED",
                                  "APPROVAL_PENDING",
                                  "APPROVED",
                                  "EFFECTIVE",
                                ].includes(doc?.documentStatus)
                                ? "#D32F2F"
                                : "#000000"
                              : "#000000",
                          }}
                          className={[
                            classes.sticky,
                            !doc.reviewDueDate
                              ? classes.customTableDelIconEmptyCellStyle
                              : classes.customTableDelIconCellStyle,
                            classes.snapshotSubheaderRight,
                          ]}
                        >
                          {doc?.reviewDueDate &&
                            moment(doc?.reviewDueDate?.split("T")[0]).format(
                              "DD-MMM-YYYY"
                            )}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: doc?.approvalDueDate
                              ? getFormattedDate(new Date().toISOString()) >
                                  getFormattedDate(doc?.approvalDueDate) &&
                                !["APPROVED", "EFFECTIVE"].includes(
                                  doc?.documentStatus
                                )
                                ? "#D32F2F"
                                : "#000000"
                              : "#000000",
                          }}
                          className={[
                            classes.sticky,
                            !doc.approvalDueDate
                              ? classes.customTableDelIconEmptyCellStyle
                              : classes.customTableDelIconCellStyle,
                            classes.snapshotSubheaderRight,
                          ]}
                        >
                          {doc?.approvalDueDate &&
                            moment(doc?.approvalDueDate?.split("T")[0]).format(
                              "DD-MMM-YYYY"
                            )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={[
                            classes.sticky,
                            !doc.reviewers.length &&
                            [
                              "REVIEWED",
                              "APPROVED",
                              "APPROVAL_PENDING",
                            ].includes(doc?.documentStatus)
                              ? classes.customTableDelIconEmptyCellStyle
                              : classes.customTableDelIconCellStyle,
                            classes.snapshotSubheaderRight,
                          ]}
                        >
                          <div className="d-jsb-cen-col">
                            {getPendingUsersList(
                              "REVIEWER",
                              doc.reviewers,
                              classes,
                              doc?.documentStatus,
                              doc?.reviewDueDate,
                              doc?.reviewCompletionDate
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          justifyContent="center"
                          className={[
                            classes.sticky,
                            classes.customTableDelIconCellStyle,
                            classes.snapshotSubheaderRight,
                          ]}
                        >
                          <div className="d-jsb-cen-col">
                            {getPendingUsersList(
                              "APPROVER",
                              doc.approvers,
                              classes,
                              doc?.documentStatus,
                              doc?.approvalDueDate,
                              doc?.approvalCompletionDate
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={[
                            classes.sticky,
                            !doc.documentStatus
                              ? classes.customTableDelIconEmptyCellStyle
                              : classes.customTableDelIconCellStyle,
                            classes.snapshotSubheaderRight,
                          ]}
                        >
                          <Tooltip title={statusText[doc?.documentStatus]}>
                            {documentStatusIcon(
                              doc?.documentStatus,
                              doc?.approvalDueDate,
                              doc?.approvalCompletionDate,
                              doc?.reviewDueDate,
                              doc?.reviewCompletionDate
                            )}
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
