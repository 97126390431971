import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { memo, useEffect, useState } from "react";
import moment from "moment";
import { TABLE_ADD_BUDGET_COLUMN } from "../helpers";
import { DatePicker } from "@material-ui/pickers";

const AddBudget = ({
  open,
  handleClose,
  budgetList,
  handleSave,
  totalAmount,
}) => {
  const [list, setList] = useState(budgetList);
  const [total, setTotal] = useState(totalAmount);
  useEffect(() => {
    setList(budgetList);
  }, [budgetList]);

  const handleChange = (value, index) => {
    const data = list;
    data[index].budget = parseInt(value) || 0;
    const total = data
      .map((data) => parseInt(data.budget))
      .reduce((prev, next) => prev + next);
    setList(data);
    setTotal(total);
  };

  const handleDateChange = (value, name, index) => {
    value = moment(value).format("YYYY-MM-DD[T00:00:00.000Z]");
    const updatedData = [...list]
    updatedData[index][name] = value
    setList(updatedData);
  };

  return (
    <Dialog open={open} handleClose={handleClose}>
      <DialogTitle>Edit Budget</DialogTitle>
      <DialogContent>
        <TableContainer style={{ maxHeight: 400 }}>
          <Table stickyHeader>
            <TableHead>
              {TABLE_ADD_BUDGET_COLUMN.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {list.map((row, index) => (
                <TableRow key={index}>
                  {TABLE_ADD_BUDGET_COLUMN.map((column) => {
                    let value = row[column.id];
                    if (column.id === "budget") {
                      value = (
                        <TextField
                          type="number"
                          name="budget"
                          value={row.budget}
                          onChange={(e) => handleChange(e.target.value, index)}
                        />
                      );
                    }
                    if (column.id === "startDate") {
                      value = (
                        <DatePicker
                          label={column.label}
                          value={
                            (row[column.id] &&
                              moment(row[column.id].split("T")[0])) ||
                            null
                          }
                          format="MM/dd/yy"
                          onChange={(date) => {
                            handleDateChange(date, column.id, index);
                          }}
                        />
                      );
                    }
                    if (column.id === "endDate") {
                      value = (
                        <DatePicker
                          label={column.label}
                          minDate={moment(row?.startDate?.split("T")[0])}
                          value={
                            (row[column.id] &&
                              moment(row[column.id].split("T")[0])) ||
                            null
                          }
                          format="MM/dd/yy"
                          onChange={(date) => {
                            handleDateChange(date, column.id, index);
                          }}
                        />
                      );
                    }
                    return (
                      <TableCell key={index + column.id} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider className="mt-5" />
        <Table>
          <TableHead>
            <TableCell style={{ minWidth: 60 }}>Total</TableCell>
            <TableCell style={{ minWidth: 100 }}>{total}</TableCell>
            <TableCell style={{ minWidth: 100 }} />
          </TableHead>
        </Table>
      </DialogContent>
      <div className="d-jsb-r mb-10">
        <Button className="mr-10" variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="mr-10"
          variant="contained"
          color="primary"
          onClick={() => handleSave(list)}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};
export default memo(AddBudget);
