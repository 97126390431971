import React, { memo, useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  TableCell,
  TableRow,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
} from "@material-ui/core";
// import { Plus } from "react-feather";
import { Fragment } from "react";

import LINKS from "utils/constants/links";
import { useHistory, useParams } from "react-router-dom";
import { ColorButton, LinkButton } from "components/UI/Buttons";

import useUserPermissions from "utils/hooks/useUserPermission";
import { ALLOWED_ROLES } from "pages/Projects/ProjectPhases/constants";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { getCustomNotifications } from "redux/actions/projects";
import PageHeader from "parts/PageHeader";
import { getProjects } from "redux/actions/projects";
import InfiniteScroll from "react-infinite-scroll-component";
import TYPES from "utils/constants/action-types";
import { convertFromHTML, ContentState } from "draft-js";
import { Highlighted } from "utils/helpers/hightlightText";

import { ReactComponent as AddCircle } from "icons/add_circle.svg";

const CustomNotificationList = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { included } = useUserPermissions(ALLOWED_ROLES);
  const {
    customNotifications: { results, count },
  } = useSelector((state) => state.projects);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [loadMore, setLoadMore] = useState(true);
  const project = useSelector((state) =>
    state.projects.results.find((p) => p._id === projectId)
  );
  useEffect(() => {
    dispatch({
      type: TYPES.CLEAR_CUSTOM_NOTIFICATIONS,
      payload: { response: [], count: 0 },
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    fetchCustomNotifications();
    // eslint-disable-next-line
  }, [skip]);

  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
    setLimit(5);
    // eslint-disable-next-line
  }, []);

  const handleLoadMore = () => {
    setSkip(results.length);
  };

  useEffect(() => {
    if (results.length && results.length >= count) {
      setLoadMore(false);
    } else {
      setLoadMore(true);
    }
    // eslint-disable-next-line
  }, [results]);

  const fetchCustomNotifications = async () => {
    setLoading(true);
    await dispatch(
      getCustomNotifications(
        projectId,
        skip,
        limit,
        results.length === 0 ? "initial" : "more"
      )
    );
    setLoading(false);
  };

  const getTableBody = () => {
    return results?.map((data) => {
      let rows = [];
      const styleCell = {
        borderBottom: data.notification && data.email ? "0px" : "",
      };
      if (data.notification) {
        rows.push(
          <TableRow key={data._id + "notification"}>
            <TableCell style={styleCell}>
              <LinkButton
                to={LINKS.EDIT_NOTIFICATION_TEMPLATE.HREF.replace(
                  ":projectId",
                  projectId
                ).replace(":id", data._id)}
              >
                <Highlighted
                  text={ContentState.createFromBlockArray(
                    convertFromHTML(data?.notification?.title)
                  ).getPlainText("\u0001")}
                />
              </LinkButton>
            </TableCell>
            <TableCell style={styleCell}>
              {parse(data?.notification?.description || "")}
            </TableCell>
            <TableCell style={styleCell}>In-app Notification</TableCell>
            <TableCell style={styleCell}>{data.module}</TableCell>
            <TableCell style={styleCell}>
              {data.triggered ? "Done" : "Pending"}
            </TableCell>
          </TableRow>
        );
      }
      if (data.email) {
        rows.push(
          <TableRow key={data._id + "email"}>
            <TableCell>
              <LinkButton
                to={LINKS.EDIT_NOTIFICATION_TEMPLATE.HREF.replace(
                  ":projectId",
                  projectId
                ).replace(":id", data._id)}
              >
                <Highlighted
                  text={ContentState.createFromBlockArray(
                    convertFromHTML(data?.email?.subject)
                  ).getPlainText("\u0001")}
                />
              </LinkButton>
            </TableCell>
            <TableCell>
              {parse(data?.notification?.description || "")}
            </TableCell>
            <TableCell>Email</TableCell>
            <TableCell>{data.module}</TableCell>
            <TableCell>{data.triggered ? "Done" : "Pending"}</TableCell>
          </TableRow>
        );
      }
      return rows;
    });
  };

  const NAV_LINKS = [
    LINKS.PROJECT_MANAGEMENT,
    LINKS.PROJECTS,
    {
      HREF: LINKS.EDIT_PROJECT.HREF.replace(":id", projectId),
      TITLE: project?.name || "Not Found",
    },
  ];

  return (
    <Fragment>
      <PageHeader title={LINKS.NOTIFICATION_TEMPLATE.TITLE} links={NAV_LINKS} />
      <Card>
        <CardHeader
          title={
            <Grid container justify="space-between">
              <Grid item className="mt-5">
                Custom Notifications
              </Grid>
            </Grid>
          }
          action={
            <>
              {included && (
                <>
                  <ColorButton
                    colour="lightGreen"
                    onClick={() => {
                      history.push(
                        LINKS.ADD_NOTIFICATION_TEMPLATE.HREF.replace(
                          ":projectId",
                          projectId
                        )
                      );
                    }}
                    startIcon={
                      <AddCircle className="white__icon icon_space_left" />
                    }
                  >
                    Add Custom Notification
                  </ColorButton>
                </>
              )}
            </>
          }
        />
        <div>
          <Table>
            <colgroup>
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Title/Subject</TableCell>
                <TableCell>Description/Body</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Module</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
          </Table>

          {results.length !== 0 && (
            <InfiniteScroll
              dataLength={results?.length}
              hasMore={loadMore}
              next={() => {
                handleLoadMore();
              }}
              loader={
                loadMore && (
                  <div className=" d-jsb-cen">
                    <CircularProgress size={20} className="mb-10" />
                  </div>
                )
              }
              scrollThreshold={0.9}
              height={600}
            >
              <Table>
                <colgroup>
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="19%" />
                </colgroup>
                <TableBody>{getTableBody()}</TableBody>
              </Table>
            </InfiniteScroll>
          )}

          {!loading && results.length === 0 && (
            <div className="d-jsb-cen" style={{ height: "100px" }}>
              {" "}
              No data available
            </div>
          )}
          {loading && results.length === 0 && (
            <div className="d-jsb-cen" style={{ height: "100px" }}>
              <CircularProgress color="primary" />
            </div>
          )}
        </div>
      </Card>
    </Fragment>
  );
};

export default memo(CustomNotificationList);
