export const PROJECT_MODES = {
  EDITING: 'EDITING',
  VIEWING: 'VIEWING',
  CREATION: 'CREATION',
};

export const ACTIONS = {
  RENAME: 'RENAME',
  DELETE: 'DELETE',
  COMPLETE: 'COMPLETE',
  EDIT: 'EDIT',
};
