import React, { memo} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styled from "styled-components";
import DialogActions from "@material-ui/core/DialogActions";

import {
  Button,
  CardHeader,
  Chip,
  Grid,
  FormControl,
  InputLabel,
  Divider,
  Typography,
  Switch,
} from "@material-ui/core";
import Select from "react-select";

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginLeft: 5,
  marginRight: 5,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
    boxSizing: "border-box",
  },
}));

const UserShareSelector = ({
  shareDialog,
  dropdownUserList,
  publicAccess,
  updatePublicAccess,
  shareDialogClose,
  updateName,
  updateUserSelection,
}) => {
  return (
    <Dialog
      fullWidth
      open={shareDialog}
      scroll={"paper"}
      onClose={() => shareDialogClose()}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        Select user to share with
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid className="mt-5" container justify="flex-end">
            <Typography>Private</Typography>
            <AntSwitch
              checked={publicAccess}
              onChange={() => updatePublicAccess()}
            />
            <Typography>Public</Typography>
          </Grid>
          {!publicAccess && (
            <Grid item xs={12}>
              <CardHeader title={"Select User"} />
              <div className="wd-80 pd-10 ml-10">
                <InputLabel id="demo-multiple-chip-label" className="mb-5">
                  Users
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    className="mr-10"
                    labelId="test-select-label"
                    closeMenuOnSelect={false}
                    label="Users"
                    variant="outlined"
                    value=""
                    options={dropdownUserList?.filter((user) => !user.selected)}
                    onChange={({ _id }) => {
                      updateUserSelection(_id, true);
                    }}
                  ></Select>
                </FormControl>
              </div>
              <Divider orientation="vertical" flexItem className="mt-20" />
              <div className="div-user-list">
                {dropdownUserList?.filter((user) => user.selected).length !==
                  0 && (
                  <div>
                    {dropdownUserList.filter((user) => user.selected)
                      ?.length !== 0 &&
                      dropdownUserList
                        ?.filter((user) => user.selected)
                        ?.map((user) => {
                          return (
                            <Chip
                              key={user._id}
                              style={{ margin: "3px", borderRadius: "40px" }}
                              label={user.name}
                              onDelete={user?.owner === false ? () => {
                                updateUserSelection(user._id, false);
                              } : undefined}
                            ></Chip>
                          );
                        })}
                  </div>
                )}
              </div>
            </Grid>
          )}
          {publicAccess && (
            <Typography className="fs-14">
              Every user of the project will have the access.
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            shareDialogClose();
          }}
          colour="default"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            updateName();
          }}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(UserShareSelector);
