import TYPES from "utils/constants/action-types";
import * as projectAPI from "services/api-project";
import { isEmpty } from "utils/helpers/utility";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";

const getProjects =
  (params = {}, refresh = false) =>
  async (dispatch, getState) => {
    const projects = getState().projects.results;

    if (
      !refresh &&
      params?.filter?._id &&
      !isEmpty(projects.find((p) => p._id === params.filter._id))
    ) {
      return;
    }

    await projectAPI
      .getProjects(params)
      .then((response) =>
        dispatch({ type: TYPES.FETCH_PROJECTS, payload: response })
      )
      .catch((error) => console.log("[getProjects] error => ", error));
  };

const addProject = (project, setLoading) => async (dispatch) => {
  // projectAPI
  //   .createProject(project)
  //   .then(({ data }) => dispatch({ type: TYPES.ADD_PROJECT, payload: data }))
  //   .catch((error) => console.log("[addProject] error => ", error));

  try {
    setLoading(true);
    let isCompleted = false;
    const response = await projectAPI.createProject(project);
    console.log("response [addProject] =", response);

    if (response) {
      dispatch({ type: TYPES.ADD_PROJECT, payload: response.data });
      isCompleted = true;
    }
    setLoading(false);
    return isCompleted;
  } catch (error) {
    console.log("[addProject] error => ", error);
    setLoading(false);
  }
};

const editProject =
  (project, setLoading = () => {}) =>
  async (dispatch) => {
    try {
      setLoading(true);
      let isCompleted = false;
      const response = await projectAPI.updateProject(project);

      if (response) {
        const { data: payload } = response;

        dispatch({ type: TYPES.EDIT_PROJECT, payload });
        isCompleted = true;
        setLoading(false);
      }

      return isCompleted;
    } catch (error) {
      console.log("[editProject] error => ", error);
      setLoading(false);
    }
  };

const removeProject =
  (project, setLoading = () => {}) =>
  async (dispatch) => {
    try {
      setLoading(true);

      let isCompleted = false;
      const response = await projectAPI.deleteProject(project);

      if (response) {
        dispatch({ type: TYPES.DELETE_PROJECT, payload: project });
        isCompleted = true;
        setLoading(false);
      }

      return isCompleted;
    } catch (error) {
      console.log("[removeProject] error => ", error);
      setLoading(false);
    }
  };

const createProjectPhase = (phase) => async (dispatch) => {
  try {
    let isCompleted = false;
    const response = await projectAPI.createProjectPhase(phase);

    if (response) {
      const { data: payload } = response;

      dispatch({ type: TYPES.EDIT_PROJECT, payload });
      isCompleted = true;
    }

    return isCompleted;
  } catch (error) {
    console.log("[createProjectPhase] error => ", error);
  }
};

const updateProjectPhase = (phase) => async (dispatch) => {
  try {
    let isCompleted = false;

    const response = await projectAPI.updateProjectPhase(phase);

    if (response) {
      const { data: payload } = response;

      dispatch({ type: TYPES.EDIT_PROJECT, payload });
      isCompleted = true;
    }

    return isCompleted;
  } catch (error) {
    console.log("[updateProjectPhase] error => ", error);
  }
};

const updatePhaseOrder =
  (projectId, phases, updateSystemInstance) => async (dispatch) => {
    try {
      let isCompleted = false;
      const response = await projectAPI.updatePhaseOrder(
        projectId,
        phases,
        updateSystemInstance
      );

      if (response) {
        const { data: payload } = response;

        dispatch({ type: TYPES.EDIT_PROJECT, payload });
        isCompleted = true;
      }

      return isCompleted;
    } catch (error) {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: "Something went wrong, please try again.",
        })
      );

      console.log("[updatePhaseOrder] error => ", error);
      throw error;
    }
  };

const updateProjectOrder = (projects) => async (dispatch) => {
  try {
    let isCompleted = false;
    const response = await projectAPI.updateProjectOrder(projects);

    if (response) {
      const { data: payload } = response;

      dispatch({ type: TYPES.EDIT_PROJECT, payload });
      isCompleted = true;
    }

    return isCompleted;
  } catch (error) {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.error,
        popupText: "Something went wrong, please try again.",
      })
    );

    console.log("[updateProjectOrder] error => ", error);
    throw error;
  }
};

const deleteProjectPhase = (phase) => async (dispatch) => {
  try {
    let isCompleted = false;
    const response = await projectAPI.deleteProjectPhase(phase);

    if (response) {
      const { data: payload } = response;

      dispatch({ type: TYPES.EDIT_PROJECT, payload });
      isCompleted = true;
    }

    return isCompleted;
  } catch (error) {
    console.log("[deleteProjectPhase] error => ", error);
  }
};

const getInsights = (projectId) => async (dispatch) => {
  try {
    const response = await projectAPI.getInsights(projectId);
    if (response) {
      const { data: payload } = response;
      dispatch({ type: TYPES.GET_INSIGHTS, payload });
    }
  } catch (error) {
    console.log("[getInsights] error => ", error);
  }
};
const getPhases =
  (projectId, setLoading = () => {}) =>
  async (dispatch) => {
    try {
      const response = await projectAPI.getPhases(projectId);
      if (response) {
        const { data: payload } = response;
        setLoading(false);
        dispatch({ type: TYPES.GET_PHASES, payload });
      }
    } catch (error) {
      console.log("[getPhases] error => ", error);
      setLoading(false);
    }
  };

const getPhaseDetail = (projectId, phaseId) => async (dispatch) => {
  try {
    const response = await projectAPI.getPhaseDetail(projectId, phaseId);
    if (response) {
      const { data: payload } = response;
      dispatch({ type: TYPES.GET_PHASE_DETAIL, payload });
    }
  } catch (error) {
    console.log("[getPhaseDetail] error => ", error);
  }
};

const getMultiPhaseDetail =
  (projectId, multiPhaseIdsObj, added = false) =>
  async (dispatch) => {
    try {
      const response = await projectAPI.getMultiPhaseDetail(
        projectId,
        multiPhaseIdsObj
      );
      if (response) {
        const data = response;
        const payload = {
          data: data,
          added: added,
        };
        dispatch({ type: TYPES.GET_MULTI_PHASE_DETAIL, payload });
      }
    } catch (error) {
      console.log("[getMultiPhaseDetail] error => ", error);
    }
  };

const getCustomNotifications = (projectId, skip, limit) => async (dispatch) => {
  await projectAPI
    .getCustomNotifications(projectId, skip, limit)
    .then((response) =>
      dispatch({ type: TYPES.FETCH_CUSTOM_NOTIFICATIONS, payload: response })
    )
    .catch((error) => console.log("[getCustomNotifications] error => ", error));
};

const setActiveProject = (projectId) => (dispatch) => {
  dispatch({ type: TYPES.SET_ACTIVE_PROJECT, payload: projectId });
};

export {
  getProjects,
  addProject,
  editProject,
  removeProject,
  createProjectPhase,
  updateProjectPhase,
  deleteProjectPhase,
  getInsights,
  getPhases,
  getPhaseDetail,
  getMultiPhaseDetail,
  updatePhaseOrder,
  updateProjectOrder,
  getCustomNotifications,
  setActiveProject,
};
