import { updateDeliverable } from "redux/actions/metaSystem";
import { restrict } from "../../../../../../utils/helpers/utility";
import {
  // exportProjectSnapShot,
  exportProjectSystemSnapShot,
  exportProjectMulitPhaseMatrix,
} from "services/api-project";
import { saveAs } from "file-saver";
import moment from "moment";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import { colorRA } from "../../DeliverableSnapshot/snapshotHelper";
import { getColoredIconComponent } from "pages/Projects/ProjectInsight/constant";
import { getFormattedDate } from "../snapshotHelper";

export const updateComment = async (
  data,
  systemId,
  deliverableList,
  setDeliverableList,
  setInputLoading,
  dispatch
) => {
  setInputLoading(data);
  await dispatch(
    updateDeliverable({
      ...restrict(data, [
        "_id",
        "plannedHours",
        "workedHours",
        "start",
        "end",
        "completion",
        "status",
        "department",
        "activity",
        "resource",
        "approver",
        "reviewer",
        "executed",
        "comment",
      ]),
      mainId: systemId,
    })
  );
  let deliverables = [...deliverableList];
  let index = deliverables?.findIndex(
    (deliverable) => deliverable?._id === data?._id
  );
  deliverables[index].comment = data.comment;
  setDeliverableList(deliverables);
  setInputLoading({});
};

export const generateExcelDownloadData = (
  departments,
  filteredDeliverableObject,
  getDepartmentUserList,
  users,
  setCSVData
) => {
  let body = [];

  body.push([
    "Systems",
    "Deliverables",
    ...departments?.map((department) => department.label),
  ]);

  const data = filteredDeliverableObject.map(({ name: key }) => {
    return filteredDeliverableObject
      .find((f) => f.name === key)
      .data.map((deliverable, index) => [
        Math.round(
          filteredDeliverableObject.find((f) => f.name === key).data.length /
            2 -
            1
        ) === index
          ? key
          : "",
        deliverable.name,
        ...departments.map((department) => {
          const { filteredApprover, filteredReviewer } = getDepartmentUserList(
            department._id,
            deliverable.reviewer,
            deliverable.approver
          );
          return deliverable.status >= 50 &&
            (filteredReviewer !== undefined || filteredApprover !== undefined)
            ? filteredReviewer?.filter((user) => user?.approved === true)
                ?.length !== filteredReviewer?.length
              ? filteredReviewer
                  ?.map((val, index) => {
                    return index === 0
                      ? `Review Pending:\n${
                          users.find((user) => user._id === val.userid)?.name ||
                          ""
                        }`
                      : users.find((user) => user._id === val.userid)?.name ||
                          "";
                  })
                  ?.join("\n")
              : filteredApprover?.filter((user) => user?.approved === true)
                  ?.length !== filteredApprover?.length
              ? filteredApprover
                  ?.map((val, index) => {
                    return index === 0
                      ? `ApprovalPending:\n${
                          users.find((user) => user._id === val.userid)?.name ||
                          ""
                        }`
                      : users.find((user) => user._id === val.userid)?.name ||
                          "";
                  })
                  ?.join("\n")
              : filteredReviewer?.filter((user) => user?.approved === true)
                  ?.length === filteredReviewer?.length &&
                filteredApprover?.length === undefined
              ? "Reviewed"
              : filteredApprover?.filter((user) => user?.approved === true)
                  ?.length === filteredApprover?.length
              ? "Approved"
              : ""
            : "";
        }),
      ]);
  });
  data.forEach((val) => {
    val.forEach((subVal) => {
      body.push(subVal);
    });
  });
  setCSVData(body);
};

export const downloadExcel = async (
  setIsDownloading,
  phaseData,
  selectedPhases,
  projectId,
  deliverableFilterList,
  systemList,
  project,
  dispatch
) => {
  try {
    setIsDownloading(true);
    const phaseIds = [...selectedPhases.map((s) => s.value)];
    const response = await exportProjectMulitPhaseMatrix(projectId, {
      phase: {
        phaseIds: phaseIds,
      },
      filters: {
        deliverable: deliverableFilterList
          .filter((deliverable) => deliverable.selected)
          ?.map((deliverable) => deliverable.name),
        system: systemList
          .filter((system) => system.selected)
          ?.map((system) => system.systemName),
      },
    });
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${project.name}_matrix_${moment().format("MMM DD@HH:mm")}`);
  } catch (error) {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.error,
        popupText: "Failed to export file.",
      })
    );
  } finally {
    setIsDownloading(false);
  }
};

export const downloadExcelSystemSnapshot = async (
  setIsDownloading,
  projectId,
  phaseList,
  systemList,
  filteredSystemList,
  project,
  dispatch
) => {
  try {
    setIsDownloading(true);
    const response = await exportProjectSystemSnapShot(projectId, {
      filters: {
        phasesList: phaseList
          .filter((phase) => phase?.selected)
          ?.map((phase) => phase?.name),
        systemsList: systemList
          .filter((system) => system?.selected)
          ?.map((system) => system?.name),
        systemStatusList: filteredSystemList,
      },
    });
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(
      blob,
      `${project.name}_system_snapshot_${moment().format("MMM DD@HH:mm")}`
    );
  } catch (error) {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.error,
        popupText: "Failed to export file.",
      })
    );
  } finally {
    setIsDownloading(false);
  }
};

export const getDeliverableMatrixIcon = (
  deliverable,
  approverList,
  reviewerList
) => {
  return deliverable.type !== "EXECUTION"
    ? (approverList?.filter((f) => f.approved).length === approverList.length &&
        approverList.length) ||
      (!approverList.length && Number(deliverable.approval.status) === 100)
      ? getColoredIconComponent(
          "check_circle_filled",
          colorRA(
            !approverList.length
              ? deliverable.approval.completion
              : (
                  approverList
                    ?.filter((f) => f.date)
                    ?.map((m) => new Date(m.date)) || []
                ).length !== 0
              ? moment(
                  Math.max.apply(
                    null,
                    approverList
                      ?.filter((f) => f.date)
                      ?.map((m) => new Date(m.date)) || []
                  )
                ).format("YYYY-MM-DD[T00:00:00.000Z]")
              : undefined,
            deliverable.approval.end,
            deliverable.approval.status
          )
        )
      : (deliverable.ifa.issued &&
          approverList.length &&
          approverList?.filter((f) => f.approved).length !==
            approverList.length) ||
        (!approverList.length &&
          Number(deliverable.approval.status) < 100 &&
          Number(deliverable.approval.status) >= 0 &&
          deliverable.ifa.issued)
      ? getColoredIconComponent(
          "check_circle_outline",
          colorRA(
            !approverList.length
              ? deliverable.approval.completion
              : (
                  approverList
                    ?.filter((f) => f.date)
                    ?.map((m) => new Date(m.date)) || []
                ).length !== 0
              ? moment(
                  Math.max.apply(
                    null,
                    approverList
                      ?.filter((f) => f.date)
                      ?.map((m) => new Date(m.date)) || []
                  )
                ).format("YYYY-MM-DD[T00:00:00.000Z]")
              : undefined,
            deliverable.approval.end,
            deliverable.approval.status
          )
        )
      : (reviewerList?.filter((f) => f.approved).length ===
          reviewerList.length &&
          reviewerList.length) ||
        (!reviewerList.length && Number(deliverable.review.status) === 100)
      ? getColoredIconComponent(
          "review_filled",
          colorRA(
            !reviewerList.length
              ? deliverable.review.completion
              : (
                  reviewerList
                    ?.filter((f) => f.date)
                    ?.map((m) => new Date(m.date)) || []
                ).length !== 0
              ? moment(
                  Math.max.apply(
                    null,
                    reviewerList
                      ?.filter((f) => f.date)
                      ?.map((m) => new Date(m.date)) || []
                  )
                ).format("YYYY-MM-DD[T00:00:00.000Z]")
              : undefined,
            deliverable.review.end,
            deliverable.review.status
          )
        )
      : (deliverable.ifr.issued &&
          reviewerList.length &&
          reviewerList?.filter((f) => f.approved).length !==
            reviewerList.length) ||
        (!reviewerList.length &&
          Number(deliverable.review.status) < 100 &&
          Number(deliverable.review.status) >= 0 &&
          deliverable.ifr.issued)
      ? getColoredIconComponent(
          "review_outline",
          colorRA(
            !reviewerList.length
              ? deliverable.review.completion
              : (
                  reviewerList
                    ?.filter((f) => f.date)
                    ?.map((m) => new Date(m.date)) || []
                ).length !== 0
              ? moment(
                  Math.max.apply(
                    null,
                    reviewerList
                      ?.filter((f) => f.date)
                      ?.map((m) => new Date(m.date)) || []
                  )
                ).format("YYYY-MM-DD[T00:00:00.000Z]")
              : undefined,
            deliverable.review.end,
            deliverable.review.status
          )
        )
      : !deliverable.ifr.issued && Number(deliverable.draft.status) <= 100
      ? getColoredIconComponent(
          "draft",
          colorRA(
            deliverable.draft.completion,
            deliverable.draft.end,
            deliverable.draft.status,
            "",
            false,
            deliverable.name,
            "",
            deliverable.systemName
          )
        )
      : getColoredIconComponent(
          "clock",
          getFormattedDate(
            deliverable?.completion ||
              moment()?.format("YYYY-MM-DD[T00:00:00.000Z]")
          ) > getFormattedDate(deliverable?.end)
            ? "#D32F2F"
            : "#676869"
        )
    : null;
};
