import { lazy } from "react";
import { ReactComponent as TimesheetIcon } from "icons/timesheet.svg";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";

// const SignIn = lazy(() =>
//   lazyRetry(() => import(/* webpackChunkName: 'SignIn' */ "pages/auth/SignIn"))
// );

const TimesheetList = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Timesheet/TimesheetList")
  )
);

const TimeEntry = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Timesheet/TimeEntry")
  )
);

// const TimeEntry = lazy(() =>
//   lazyRetry(() =>
//     import(
//       /* webpackChunkName: 'SignIn' */ "pages/Timesheet/TimeEntry/newIndex"
//     )
//   )
// );

const SettingPage = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Timesheet/SettingPage")
  )
);

const timesheetRoutes = Object.freeze({
  id: LINKS.TIMESHEET.TITLE,
  path: LINKS.TIMESHEET.HREF,
  pathId: "timesheet",
  icon: <TimesheetIcon />,
  component: TimesheetList,
  children: [
    {
      path: LINKS.TIMESHEET.HREF,
      name: LINKS.TIMESHEET.TITLE,
      component: TimesheetList,
    },
    {
      path: LINKS.ADD_TIMESHEET_ENTRY.HREF,
      name: LINKS.ADD_TIMESHEET_ENTRY.TITLE,
      component: TimeEntry,
    },
    {
      path: LINKS.TIMESHEET_SETTING.HREF,
      name: LINKS.TIMESHEET_SETTING.TITLE,
      component: SettingPage,
    },
  ],
});

export default timesheetRoutes;
