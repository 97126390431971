export const FEATURE_FLAGS = {
  ProjectManagement: "projectManagement",
  ProjectDetails: "projectDetails",
  Phases: "phases",
  Systems: "systems",
  SystemDetails: "systemDetails",
  SystemWorkflow: "systemWorkflow",
  UnassignedSystems: "unassignedSystems",
  Deliverables: "deliverables",
  Tasks: "tasks",
  Escalations: "escalations",
  SystemTrendChart: "systemTrendChart",
  Insights: "insights",
  DeliverableSnapshot: "deliverableSnapshot",
  DocumentSnapshot: "documentSnapshot",
  PhaseSnapshot: "phaseSnapshot",
  SystemSnapshot: "systemSnapshot",
  DeliverableMatrix: "deliverableMatrix",
  Scheduler: "scheduler",
  PhaseBoard: "phase-board",

  // DMS
  DocumentManagement: "documentManagement",
  Documents: "documents",
  MyTasks: "myTasks",
  CompletedTasks: "completedTasks",
  AllDocuments: "allDocuments",
  ApprovedDocuments: "approvedDocuments",
  Templates: "templates",

  // DCR
  DocumentChangeRequest: "documentChangeRequest",
  MyDCRTasks: "myDCRTasks",
  DCRCompletedTasks: "dCRCompletedTasks",
  DCRClosedChangeRequests: "dCRClosedChangeRequests",
  DCRAllChangeRequests: "dCRAllChangeRequests",

  // Calendar
  CalendarView: "calendarView",
  WeeklyCalendar: "weeklyCalendar",
  MonthlyCalendar: "monthlyCalendar",
  Milestone: "milestone",
  Whiteboard: "whiteboard",
  Download: "download",

  // DEVIATIONS
  DeviationManagement: "deviationManagement",
  Deviations: "deviations",
  MyDeviations: "myDeviations",
  ClosedDeviations: "closedDeviations",
  CompletedDeviations: "completedDeviations",
  AllDeviations: "allDeviations",

  // CHANGE_REQUESTS
  ChangeRequestManagement: "changeRequestManagement",
  ChangeRequests: "changeRequests",
  MyChangeRequests: "myChangeRequests",
  ClosedChangeRequests: "closedChangeRequests",
  CompletedChangeRequests: "completedChangeRequests",
  AllChangeRequests: "allChangeRequests",

  //FINANCE
  Finance: "finance",
  Budget: "budget",
  PurchaseOrders: "purchaseOrder",
  Invoices: "invoice",

  //LIBRARY
  Library: "library",

  // WORKLOAD-REPORT
  WorkloadManagement: "workloadManagement",
  WorkloadReport: "workloadReport",
  WorkloadEntry: "workloadEntry",

  //Cell-Glass
  CellGlass: "cellGlass",
};
