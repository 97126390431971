import React from "react";
import moment from "moment";
export const SystemGanttTooltip = ({ task, fontSize, fontFamily }) => {
  const style = {
    fontSize,
    fontFamily,
    backgroundColor: "#fff",
    boxShadow: "1px 2px 9px rgba(0,0,0,0.5)",
  };
  const textStyle = { margin: 0, padding: 0, fontSize };
  return (
    <div className=" pd-10" style={style}>
      <b style={{ ...textStyle, fontSize: fontSize + 6 }}>{` ${
        task.type === "project"
          ? task.name
          : task.displayName || task.name + " : "
      } `}</b>

      {/* <b style={{ fontSize: fontSize + 6, margin: 0, padding: 0 }}>{` ${
        task.type === "project"
          ? task.name
          : task.displayName || task.name + " : "
      } ${moment(task.start).format("MM/D/YYYY")} to ${moment(task.end).format(
        "MM/D/YYYY"
      )}`}</b> */}

      <p style={textStyle}>{`Start: ${moment(task.start).format(
        "MM/D/YYYY"
      )}`}</p>

      <p style={textStyle}>{`End: ${moment(task.end).format("MM/D/YYYY")}`}</p>

      <p style={textStyle}>{`Duration: ${~~(
        moment(task.end).diff(moment(task.start)) /
        (1000 * 60 * 60 * 24)
      )} day(s)`}</p>

      <p style={textStyle}>{`Progress: ${task.progress || 0} %`}</p>
    </div>
  );
};
