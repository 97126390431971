import { Button, Divider, IconButton } from "@material-ui/core";
import Loader from "components/Loader";
import { memo, useEffect, useState } from "react";
import { getFolders } from "services/api-library";
import "../index.css";
import { NavigateNextRounded } from "@material-ui/icons";
import BreadcrumbsFolder from "./BreadcrumbsFolder";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";

const MoveFolder = ({ open, handleClose, projectId, onMove, selectedData }) => {
  const [loading, setLoading] = useState(false);
  const [folderList, setFolderList] = useState("");
  const [moveId, setMoveId] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  // eslint-disable-next-line
  const [isLibSelectCall, setIsLibSelectCall] = useState(true);

  useEffect(() => {
    if (folderList.length === 0 && open) {
      getFolderList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getFolderList = async (parent) => {
    const category =
      parent === breadcrumbs[0]?._id
        ? null
        : breadcrumbs[0]?.name === "DMS"
        ? "DMS_FILE"
        : null;

    setLoading(true);
    await getFolders(projectId, parent, category)
      .then((resp) => {
        setLoading(false);
        if (resp?.folders.length > 0) {
          const folderData = resp.folders.filter(
            (folder) => folder._id !== selectedData._id
          );
          setFolderList(folderData);
          setBreadcrumbs(resp?.breadcrumbs);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("error >>>>>> ", err);
      });
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={open}
      maxWidth="xs"
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {"Move " + selectedData?.name}
      </DialogTitle>

      <div className="pd-2 ml-20 mb-5">
        <BreadcrumbsFolder
          breadcrumbs={breadcrumbs}
          getFolderList={getFolderList}
          isLibSelectCall={isLibSelectCall}
        />
      </div>
      <DialogContent dividers={"paper"}>
        <div>
          {folderList.length > 0 &&
            folderList?.map((fileData, index) => (
              <div key={index}>
                <div
                  className={`pointer d-flex pd-10 file-div ${
                    moveId === fileData._id ? "move-selected" : "move-name"
                  }`}
                  onClick={() => setMoveId(fileData?._id)}
                >
                  <FolderOutlinedIcon
                    className={`${
                      moveId === fileData._id ? "selected" : "folderIcon"
                    }`}
                  />
                  <div>{fileData?.name}</div>
                  {moveId === fileData?._id && (
                    <IconButton
                      className="pd-0 navigate-next"
                      onClick={() => getFolderList(moveId)}
                    >
                      <NavigateNextRounded
                        className={`${
                          moveId === fileData._id
                            ? "move-selected"
                            : "move-name"
                        }`}
                      />
                    </IconButton>
                  )}
                </div>
                <Divider fullWidth />
              </div>
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="d-jsb-r">
          <Button variant="contained" onClick={handleClose} className="mr-10">
            Cancel
          </Button>
          <Button
            className="mr-10"
            variant="contained"
            color="primary"
            type="submit"
            disabled={!moveId}
            onClick={() => onMove(moveId)}
          >
            Move
          </Button>
        </div>
      </DialogActions>
      {loading && <Loader />}
    </Dialog>
  );
};
export default memo(MoveFolder);
