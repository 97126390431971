import React from "react";
import { Dialog, DialogContent } from "components/UI/VektorDialog";
import { Box, Button, TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import FilterSelect from "components/UI/Selects/FilterSelect";

const SubTaskDialog = ({
  title,
  open,
  onClose,
  subTask,
  users,
  onCreate,
  onUpdate,
  onCellChange,
  departments,
  onAddTask,
  mainTask
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogContent>
        <Box width={"300px"}>
          <h2>Edit Sub Task</h2>
          <div className="mb-10">
            <TextField
              fullWidth
              label="Name"
              type="string"
              name="name"
              InputProps={{
                inputProps: { min: "0", max: "100", step: "1" },
              }}
              onChange={onCellChange}
              value={subTask.name}
            ></TextField>
          </div>
          <div className="mb-10">
            <DatePicker
              fullWidth
              label="Date"
              name="start"
              value={subTask.start ? moment((subTask.start).split("T")[0]).format("MM/DD/YY") : null}
              format="MM/dd/yy"
              onChange={(date) =>
                onCellChange({
                  target: { type: "date", value: date, name: "start" },
                })
              }
              minDate={mainTask.dateRange ? mainTask.dateRange[0] : null}
              maxDate={mainTask.end}
            />
          </div>
          <div className="mb-10">
            <FilterSelect
              label="Department"
              placeholder="Select department"
              items={departments.length > 0 ? departments[0].departments : []}
              name="department"
              keys={{
                label: "label",
                value: "_id",
              }}
              onChange={onCellChange}
              value={subTask.department}
            />
          </div>
          <div className="mb-10">
            <FilterSelect
              label="Resource"
              placeholder="Select users"
              items={users.filter((u) => u.department === subTask.department)}
              name="resource"
              keys={{
                label: "name",
                value: "_id",
              }}
              onChange={onCellChange}
              value={subTask.resource}
            />
          </div>
          <div className="d-jsb-r mb-20">
              <Button variant="contained" color="disabled" onClick={onClose}>Cancel</Button>
              <Button variant="contained" color="primary" className="ml-10" onClick={onUpdate}>Update</Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SubTaskDialog;