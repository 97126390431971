import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { memo, useEffect, useState } from "react";

const EditFolder = ({ open, handleClose, mode, onSave, editData, type }) => {
  const [folderName, setFolderName] = useState("");
  useEffect(() => {
    if (editData?.name) {
      setFolderName(editData?.name);
    }
  }, [editData]);
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      <DialogTitle>{mode === "Add" ? "Add Folder" : `Edit ${type}`}</DialogTitle>
      <div className="pd-10">
        <Grid container column={12} className="mb-10">
          <Grid item={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="name"
              label="Folder Name"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
          </Grid>
        </Grid>
        <div className="d-jsb-r">
          <Button variant="contained" onClick={handleClose} className="mr-10">
            Cancel
          </Button>
          <Button
            className="mr-10"
            variant="contained"
            color="primary"
            type="submit"
            disabled={!folderName}
            onClick={() => onSave(folderName)}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default memo(EditFolder);
