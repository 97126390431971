import { useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  Box,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  IconButton,
  TextField,
  Modal,
} from "@material-ui/core";
// import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import _ from "lodash";
import { addSnapshotFilters } from "services/api-project";
import ActionTypes from "utils/constants/action-types";
import { Close } from "@material-ui/icons";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: 500,
  background: "white",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "12px",
};

const MenuProps = {
  PaperProps: {
    style: {
      width: 200,
      maxHeight: 400,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const listItemStyle = { style: { whiteSpace: "normal" } };

export const CalendarFilter = (props) => {
  const {
    phaseList = [],
    systemList = [],
    productCodeList = [],
    usersList = [],
    statusList = [],
    departmentsList = [],
    activityList = [],
    deliverableList = [],
    equipmentCategoryList = [],
    equipmentTypeList = [],
    areaList = [],
    systemTypeList = [],
    dateList = [],
    setDateList = () => {},
    setPhaseList = () => {},
    setSystemList = () => {},
    setProductCode = () => {},
    setUsersList = () => {},
    setStatusList = () => {},
    setDepartmentsList = () => {},
    setActivityList = () => {},
    setDeliverableList = () => {},
    setEquipmentCategoryList = () => {},
    setEquipmentTypeList = () => {},
    setAreaList = () => {},
    setSystemTypeList = () => {},
    handleClose,
    type = "calendar",
    projectId = "",
    setLoading = () => {},
    handleFilterSystem = () => {},
    phaseId = "",
    showCustomFilter = false,
  } = props;
  const dispatch = useDispatch();
  let project = useSelector((state) => state?.projects?.results)?.find(
    (project) => project?._id === projectId
  );
  if (projectId) {
    project["snapshotFilters"] = project?.snapshotFilters || [];
  }

  const user = useSelector((state) => state?.auth?.currentUser?._id);
  const [phaseFilter, setPhaseFilter] = useState(phaseList);
  const [systemFilter, setSystemFilter] = useState(systemList);
  const [productFilter, setProductFilter] = useState(productCodeList);
  const [userFilter, setUserFilter] = useState(usersList);
  const [statusFilter, setStatusFilter] = useState(statusList);
  const [departmentFilter, setDepartmentFilter] = useState(departmentsList);
  const [activityFilter, setActivityFilter] = useState(activityList);
  const [deliverableFilter, setDeliverableFilter] = useState(deliverableList);
  const [dateFilter, setDateFilter] = useState(dateList);
  const [equipmentCategoryFilter, setEquipmentCategoryFilter] = useState(
    equipmentCategoryList
  );
  const [equipmentTypeFilter, setEquipmentTypeFilter] =
    useState(equipmentTypeList);
  const [areaFilter, setAreaFilter] = useState(areaList);
  const [systemTypeFilter, setSystemTypeFilter] = useState(systemTypeList);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState(null);
  const [saveFilter, setSaveFilter] = useState(false);
  const [newFilters, setNewFilters] = useState({
    name: "",
    user: user,
    filters: {},
    phase: phaseId,
  });
  const [errors, setErorrs] = useState({ name: false });

  const handleCancel = () => {
    setPhaseFilter(phaseList);
    setSystemFilter(systemList);
    setProductFilter(productCodeList);
    setUserFilter(usersList);
    setStatusFilter(statusList);
    setDepartmentFilter(departmentsList);
    setActivityFilter(activityList);
    setDeliverableFilter(deliverableList);
    setDateFilter(dateList);
    setEquipmentCategoryFilter(equipmentCategoryList);
    setEquipmentTypeFilter(equipmentTypeList);
    setAreaFilter(areaList);
    setSystemTypeFilter(systemTypeList);
    handleClose();
  };

  const handleSnapshotFilter = () => {
    setSystemList(systemFilter);
    setStatusList(statusFilter);
    setDeliverableList(deliverableFilter);
  };
  const handleApply = () => {
    if (type === "snapshot") {
      let systemsSelected = systemFilter
        ?.filter((system) => system?.selected)
        ?.map((system) => system?._id);
      let deliverablesSelected = deliverableFilter
        ?.filter((deliverable) => deliverable?.selected)
        ?.map((deliverable) => deliverable?.id + deliverable?.systemName);
      let statusSelected = statusFilter
        ?.filter((status) => status?.selected)
        ?.map((status) => status?.name);
      let existingFilter = project?.snapshotFilters?.filter(
        (snapshotFilter) => {
          return (
            _.isEqual(snapshotFilter?.filters, {
              systems: systemsSelected,
              deliverables: deliverablesSelected,
              status: statusSelected,
            }) &&
            snapshotFilter?.user === user &&
            snapshotFilter?.phase === phaseId
          );
        }
      );
      if (existingFilter?.length === 0 && showCustomFilter) {
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.confirm,
            popupText: "Do you want to save this as a filter ?",
            confirmText: "Yes",
            cancelText: "No",
            onConfirm: () => {
              handleSnapshotFilter();
              setSaveFilter(true);
              setNewFilters({
                ...newFilters,
                filters: {
                  systems: systemsSelected,
                  deliverables: deliverablesSelected,
                  status: statusSelected,
                },
              });
            },
            onCancel: () => {
              handleSnapshotFilter();
              handleClose();
            },
          })
        );
      } else {
        handleSnapshotFilter();
        handleClose();
      }
    } else if (type === "system") {
      setPhaseList(phaseFilter);
      setEquipmentCategoryList(equipmentCategoryFilter);
      setEquipmentTypeList(equipmentTypeFilter);
      setAreaList(areaFilter);
      setSystemTypeList(systemTypeFilter);

      handleFilterSystem(0, true, {
        category: equipmentCategoryFilter
          .filter((category) => category.selected)
          ?.map((category) => category.VALUE),
        type: equipmentTypeFilter
          .filter((type) => type.selected)
          ?.map((type) => type.VALUE),
        area: areaFilter
          .filter((area) => area.selected)
          ?.map((area) => area._id),
        phase: phaseFilter
          .filter((phase) => phase.selected)
          ?.map((phase) => phase._id),
        systemType: systemTypeFilter
          .filter((system) => system.selected)
          ?.map((system) => system.VALUE),
      });

      handleClose();
    } else {
      setPhaseList(phaseFilter);
      setSystemList(systemFilter);
      setDeliverableList(deliverableFilter);
      setProductCode(productFilter);
      setUsersList(userFilter);
      setDateList(dateFilter);
      setStatusList(statusFilter);
      setDepartmentsList(departmentFilter);
      setActivityList(activityFilter);
      handleClose();
    }
  };

  // const getFilteredDeliverableList = () => {
  //   let selectedSystem = systemFilter
  //     ?.filter((system) => system?.selected)
  //     ?.map((system) => system?.name);
  //   if (selectedSystem?.length) {
  //     return deliverableFilter?.filter((deliverable) => {
  //       return deliverable?.systemName?.some((systemName) =>
  //         selectedSystem?.includes(systemName)
  //       );
  //     });
  //   } else {
  //     return deliverableFilter;
  //   }
  // };

  const handleNewFilterSave = () => {
    if (newFilters?.name === "") {
      setErorrs({ ...errors, name: true });
      return;
    }
    setLoading(true);
    addSnapshotFilters(projectId, [newFilters, ...project.snapshotFilters])
      .then((response) => {
        dispatch({ type: ActionTypes.EDIT_PROJECT, payload: response?.data });
        handleClose();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText:
              err?.response?.data?.info || "Error saving snapshot filter",
          })
        );
      });
  };

  useEffect(() => {
    if (selectedSavedFilter !== null) {
      let selectedFilter = project?.snapshotFilters?.find(
        (filter) => filter?._id === selectedSavedFilter
      )?.filters;
      setSystemFilter(
        systemFilter?.map((system) => {
          if (selectedFilter?.systems?.includes(system?._id)) {
            return { ...system, selected: true };
          } else {
            return { ...system, selected: false };
          }
        })
      );
      setDeliverableFilter(
        deliverableFilter?.map((deliverable) => {
          if (
            selectedFilter?.deliverables?.includes(
              deliverable?.id + deliverable?.systemName
            )
          ) {
            return { ...deliverable, selected: true };
          } else {
            return { ...deliverable, selected: false };
          }
        })
      );
      setStatusFilter(
        statusFilter?.map((status) => {
          if (selectedFilter?.status?.includes(status?.name)) {
            return { ...status, selected: true };
          } else {
            return { ...status, selected: false };
          }
        })
      );
    }
    // eslint-disable-next-line
  }, [selectedSavedFilter]);

  return (
    <div>
      <Modal open={saveFilter} onClose={() => setSaveFilter(false)}>
        <Box style={{ ...style, maxHeight: 200 }}>
          <Grid container>
            <Grid teim xs={12}>
              <Grid container justify="space-between">
                <Typography variant="h6"> Save Filter</Typography>
                <IconButton
                  className="pd-0"
                  size="small"
                  onClick={() => setSaveFilter(false)}
                >
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={6} className="mt-10">
              <TextField
                label="Filter name"
                error={errors?.name}
                helperText={errors?.name ? "Name is required" : ""}
                fullWidth
                variant="outlined"
                onChange={({ target }) => {
                  if (target?.value !== "")
                    setErorrs({ ...errors, name: false });
                  setNewFilters({ ...newFilters, name: target?.value });
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12} className="mt-10">
              <Grid container justify="flex-end">
                <Button
                  variant="contained"
                  onClick={() => setSaveFilter(false)}
                >
                  CANCEL
                </Button>

                <Button
                  className="ml-10"
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleNewFilterSave}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Box
        style={{
          ...style,
          maxHeight:
            type === "calendar" ||
            type === "system" ||
            type === "milestone" ||
            type === "whiteboard"
              ? 400
              : 200,
        }}
      >
        <Grid container justify="space-between">
          <Grid item xs={8}>
            {showCustomFilter && (
              <Grid container justify="flex">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="mt-5"
                >
                  Filter By
                </Typography>
                {project?.snapshotFilters?.filter(
                  (filter) => filter?.user === user && filter.phase === phaseId
                )?.length !== 0 && (
                  <Grid item xs={6} className="ml-10">
                    {type === "snapshot" && (
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        select
                        label="Saved Filter"
                        value={selectedSavedFilter}
                        onChange={({ target }) => {
                          setSelectedSavedFilter(target?.value);
                        }}
                        SelectProps={{ MenuProps: { ...MenuProps } }}
                      >
                        {project?.snapshotFilters?.filter(
                          (filter) => filter?.user === user
                        )?.length !== 0 &&
                          project?.snapshotFilters
                            ?.filter(
                              (filter) =>
                                filter?.user === user &&
                                filter.phase === phaseId
                            )
                            ?.map((filter) => {
                              return (
                                <MenuItem key={filter?._id} value={filter?._id}>
                                  {filter?.name}
                                </MenuItem>
                              );
                            })}
                      </TextField>
                    )}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item>
            <IconButton className="pd-0" size="small" onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container spacing={8} className="mt-10">
          {(type === "calendar" ||
            type === "system" ||
            type === "milestone" ||
            type === "system-snapshot" ||
            type === "whiteboard") && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                Phase ({phaseFilter?.filter((phase) => phase?.selected)?.length}
                )
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={phaseFilter}
                renderValue={() => {
                  return phaseFilter
                    ?.filter((phase) => phase?.selected)
                    ?.map((phase) => phase?.name)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {phaseFilter?.map((phase) => (
                  <MenuItem
                    className="pd-5"
                    key={phase?._id}
                    value={phase?._id}
                  >
                    <Checkbox
                      onClick={() => {
                        setPhaseFilter(
                          phaseFilter?.map((value) => {
                            if (value?._id === phase?._id) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={phase?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={phase?.name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {(type === "snapshot" ||
            type === "calendar" ||
            type === "milestone" ||
            type === "system-snapshot" ||
            type === "whiteboard") && (
            <Grid item xs={4} className="mb-5">
              <InputLabel className="mb-5">
                System (
                {systemFilter?.filter((system) => system?.selected)?.length})
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={systemFilter}
                renderValue={() => {
                  return systemFilter
                    ?.filter((system) => system?.selected)
                    ?.map((system) => system?.name)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {systemFilter?.map((system) => (
                  <MenuItem
                    className="pd-5"
                    key={system?._id}
                    value={system?._id}
                  >
                    <Checkbox
                      onClick={() => {
                        setSelectedSavedFilter(null);
                        setSystemFilter(
                          systemFilter?.map((value) => {
                            if (value?._id === system?._id) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={system?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={system?.name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {(type === "snapshot" || type === "whiteboard") && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                Deliverable (
                {
                  deliverableFilter?.filter(
                    (deliverable) => deliverable?.selected
                  )?.length
                }
                )
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={deliverableFilter}
                renderValue={() => {
                  return deliverableFilter
                    ?.filter((deliverable) => deliverable?.selected)
                    ?.map((deliverable) => deliverable?.name)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {deliverableFilter?.map((deliverable) => (
                  <MenuItem
                    className="pd-5"
                    key={deliverable?.name}
                    value={deliverable?.name}
                  >
                    <Checkbox
                      onClick={() => {
                        setSelectedSavedFilter(null);
                        setDeliverableFilter(
                          deliverableFilter?.map((value) => {
                            if (value?.name === deliverable?.name) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={deliverable?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={deliverable?.name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {type === "whiteboard" && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                Date ({dateFilter?.filter((date) => date?.selected)?.length})
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={dateFilter}
                renderValue={() => {
                  return dateFilter
                    ?.filter((date) => date?.selected)
                    ?.map((date) =>
                      moment(new Date(date?.date)).format("MM/DD/YY")
                    )
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {dateFilter?.map((date) => (
                  <MenuItem
                    className="pd-5"
                    key={date?.date}
                    value={date?.date}
                  >
                    <Checkbox
                      onClick={() => {
                        setDateFilter(
                          dateFilter?.map((value) => {
                            if (value?.date === date?.date) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={date?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={moment(new Date(date?.date)).format("MM/DD/YY")}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {type === "calendar" && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                Product Code (
                {productFilter?.filter((code) => code?.selected)?.length})
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={productFilter}
                renderValue={() => {
                  return productFilter
                    ?.filter((productCode) => productCode?.selected)
                    ?.map((productCode) => productCode?.name)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {productFilter?.map((productCode) => (
                  <MenuItem
                    className="pd-5"
                    key={productCode?.name}
                    value={productCode?.name}
                  >
                    <Checkbox
                      onClick={() => {
                        setProductFilter(
                          productFilter?.map((value) => {
                            if (value?.name === productCode?.name) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={productCode?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={productCode?.name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {(type === "calendar" || type === "whiteboard") && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                Resource ({userFilter?.filter((user) => user?.selected)?.length}
                )
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={userFilter}
                renderValue={() => {
                  return userFilter
                    ?.filter((user) => user?.selected)
                    ?.map((user) => user?.name)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {userFilter?.map((user) => (
                  <MenuItem className="pd-5" key={user?._id} value={user?._id}>
                    <Checkbox
                      onClick={() => {
                        setUserFilter(
                          userFilter?.map((value) => {
                            if (value?._id === user?._id) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={user?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={user?.name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {(type === "snapshot" ||
            type === "calendar" ||
            type === "system-snapshot") && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                Status (
                {statusFilter?.filter((status) => status?.selected)?.length})
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={statusFilter}
                renderValue={() => {
                  return statusFilter
                    ?.filter((status) => status?.selected)
                    ?.map((status) => status?.name)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {statusFilter?.map((status) => (
                  <MenuItem
                    className="pd-5"
                    key={status?.name}
                    value={status?.name}
                  >
                    <Checkbox
                      onClick={() => {
                        setSelectedSavedFilter(null);
                        setStatusFilter(
                          statusFilter?.map((value) => {
                            if (value?.name === status?.name) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={status?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={status?.name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {type === "calendar" && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                Department (
                {
                  departmentFilter?.filter((department) => department?.selected)
                    ?.length
                }
                )
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={departmentFilter}
                renderValue={() => {
                  return departmentFilter
                    ?.filter((department) => department?.selected)
                    ?.map((department) => department?.name)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {departmentFilter?.map((department) => (
                  <MenuItem
                    className="pd-5"
                    key={department?._id}
                    value={department?._id}
                  >
                    <Checkbox
                      onClick={() => {
                        setDepartmentFilter(
                          departmentFilter?.map((value) => {
                            if (value?._id === department?._id) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={department?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={department?.name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {type === "calendar" && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                Activity (
                {
                  activityFilter?.filter((activity) => activity?.selected)
                    ?.length
                }
                )
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={activityFilter}
                renderValue={() => {
                  return activityFilter
                    ?.filter((activity) => activity?.selected)
                    ?.map((activity) => activity?.name)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {activityFilter?.map((activity) => (
                  <MenuItem
                    className="pd-5"
                    key={activity?.name}
                    value={activity?.name}
                  >
                    <Checkbox
                      onClick={() => {
                        setActivityFilter(
                          activityFilter?.map((value) => {
                            if (value?.name === activity?.name) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={activity?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={activity?.name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {type === "system" && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                Equipment Category (
                {
                  equipmentCategoryFilter?.filter(
                    (category) => category?.selected
                  )?.length
                }
                )
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={equipmentCategoryFilter}
                renderValue={() => {
                  return equipmentCategoryFilter
                    ?.filter((category) => category?.selected)
                    ?.map((category) => category?.LABEL)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {equipmentCategoryFilter?.map((category) => (
                  <MenuItem
                    className="pd-5"
                    key={category?.LABEL}
                    value={category?.LABEL}
                  >
                    <Checkbox
                      onClick={() => {
                        setEquipmentCategoryFilter(
                          equipmentCategoryFilter?.map((value) => {
                            if (value?.LABEL === category?.LABEL) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={category?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={category?.LABEL}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {type === "system" && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                Equipment Type (
                {equipmentTypeFilter?.filter((type) => type?.selected)?.length})
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={equipmentTypeFilter}
                renderValue={() => {
                  return equipmentTypeFilter
                    ?.filter((type) => type?.selected)
                    ?.map((type) => type?.LABEL)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {equipmentTypeFilter?.map((type) => (
                  <MenuItem
                    className="pd-5"
                    key={type?.VALUE}
                    value={type?.VALUE}
                  >
                    <Checkbox
                      onClick={() => {
                        setEquipmentTypeFilter(
                          equipmentTypeFilter?.map((value) => {
                            if (value?.VALUE === type?.VALUE) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={type?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={type?.LABEL}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {type === "system" && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                Area ({areaFilter?.filter((area) => area?.selected)?.length})
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={areaFilter}
                renderValue={() => {
                  return areaFilter
                    ?.filter((area) => area?.selected)
                    ?.map((area) => area?.label)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {areaFilter?.map((area) => (
                  <MenuItem className="pd-5" key={area?._id} value={area?._id}>
                    <Checkbox
                      onClick={() => {
                        setAreaFilter(
                          areaFilter?.map((value) => {
                            if (value?._id === area?._id) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={area?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={area?.label}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {type === "system" && (
            <Grid item xs={4}>
              <InputLabel className="mb-5">
                System Type (
                {systemTypeFilter?.filter((type) => type?.selected)?.length})
              </InputLabel>
              <Select
                fullWidth
                multiple
                variant="outlined"
                value={systemTypeFilter}
                renderValue={() => {
                  return systemTypeFilter
                    ?.filter((type) => type?.selected)
                    ?.map((type) => type?.LABEL)
                    ?.join(", ");
                }}
                MenuProps={MenuProps}
              >
                {systemTypeFilter?.map((type) => (
                  <MenuItem
                    className="pd-5"
                    key={type?.VALUE}
                    value={type?.VALUE}
                  >
                    <Checkbox
                      onClick={() => {
                        setSystemTypeFilter(
                          systemTypeFilter?.map((value) => {
                            if (value?.VALUE === type?.VALUE) {
                              return { ...value, selected: !value?.selected };
                            } else {
                              return value;
                            }
                          })
                        );
                      }}
                      className="pd-5"
                      size="small"
                      checked={type?.selected}
                    />
                    <ListItemText
                      primaryTypographyProps={listItemStyle}
                      primary={type?.LABEL}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
        </Grid>
        <Grid container justify="flex-end" className="mt-10">
          <Button variant="contained" onClick={handleCancel}>
            CANCEL
          </Button>

          <Button
            className="ml-10"
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleApply}
          >
            APPLY
          </Button>
        </Grid>
      </Box>
    </div>
  );
};
