import React, { useMemo, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import { DetailDialog, NotesDialog } from "./components";
import useStyles from "./styles";
import usePhaseItemLogic from "./helpers";
import {
  // ArrowDropDown,
  Flag,
} from "@material-ui/icons";
import { ReactComponent as ExpandDown } from "icons/arrow_down.svg";
import { ReactComponent as OpenInNew } from "icons/open_in_new.svg";
import { CHECKED_FLAG_COLOR, UNCHECKED_FLAG_COLOR } from "./constants";
import LINKS from "utils/constants/links";
import { Highlighted } from "utils/helpers/hightlightText";
import { useHistory } from "react-router-dom";
// import { OpenInNewRounded } from "@mui/icons-material";
import MovePhaseDialog from "./components/MovePhaseDialog";
import { ALLOWED_ROLES } from "./constants";
import FeatureFlag from "utils/hocs/FeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";

const PhaseItem = ({
  item,
  searchText,
  canDrag = true,
  setLoading,
  phases,
}) => {
  const { isDragging, dragRef, onClick } = usePhaseItemLogic(
    item,
    canDrag,
    setLoading
  );
  const users = useSelector((state) => state.users.results);
  const classes = useStyles({ isDragging });
  const { permissions } = useSelector(({ auth }) => auth.currentUser);
  const isDragAllowed = ALLOWED_ROLES.includes(permissions);
  const [toggledDetail, setToggledDetail] = useState(false);
  const [toggledNotes, setToggledNotes] = useState(false);
  const [openMovePhase, setOpenMovePhase] = useState(false);
  const history = useHistory();

  const handleFlagClick = (e) => {
    e.stopPropagation();
    setToggledNotes(true);
  };

  const handleNotesClose = (e) => {
    setToggledNotes(false);
  };

  const handleDetailClose = (e) => {
    setToggledDetail(false);
  };

  const noteAvailable = useMemo(
    () => item.mainSystem.deliverables.some((d) => d.notes.length),
    [item]
  );
  const noteChecked = useMemo(
    () =>
      item.mainSystem.deliverables.length &&
      item.mainSystem.deliverables.every(
        (d) => d.notes.length && d.notes.every((n) => n.status)
      ),
    [item]
  );

  return (
    <>
      <Card ref={dragRef} className={classes.container}>
        {item.resource && <CardHeader className={classes.header} />}
        <CardContent className={classes.content}>
          <Grid
            container
            column={12}
            alignItems="center"
            justify="space-between"
          >
            {users.find((u) => u._id === item.resource)?.name && (
              <Grid item xs={2} className="avatar">
                <Tooltip
                  title={users.find((u) => u._id === item.resource)?.name}
                >
                  <Avatar
                    className="pointer"
                    onClick={() =>
                      history.push(
                        LINKS.EDIT_USER.HREF.replace(":id", item.resource)
                      )
                    }
                    alt={users.find((u) => u._id === item.resource)?.name}
                  >
                    {users
                      .find((u) => u._id === item.resource)
                      ?.name.replace(/[^A-Z]/g, "")}
                  </Avatar>
                </Tooltip>
              </Grid>
            )}
            {!users.find((u) => u._id === item.resource)?.name && (
              <Grid item xs={2} className="avatar">
                <Avatar alt="Lucy Lavender" src="" />
              </Grid>
            )}

            {noteAvailable && (
              <Grid item xs={1}>
                <IconButton
                  className={classes.noteFlag}
                  onClick={handleFlagClick}
                  style={{
                    color: noteChecked
                      ? CHECKED_FLAG_COLOR
                      : UNCHECKED_FLAG_COLOR,
                  }}
                >
                  <Flag />
                </IconButton>
              </Grid>
            )}

            <Grid
              item
              xs={noteAvailable ? 7 : 8}
              className="txt-md"
              onClick={() => setToggledDetail(true)}
            >
              <Highlighted text={item.name} highlight={searchText} />
            </Grid>
            <Grid item xs={2} className="d-jsb-r ">
              {isDragAllowed && (
                <IconButton
                  className="pointer pd-0"
                  size="small"
                  onClick={() => {
                    if (isDragAllowed) {
                      setOpenMovePhase(true);
                    }
                  }}
                >
                  {/* <ArrowDropDown style={{ fontSize: "20px" }} /> */}
                  <ExpandDown className="icons_xsmall" />
                </IconButton>
              )}
              <FeatureFlag feature={FEATURE_FLAGS.Systems}>
                <IconButton
                  className="pointer pd-0"
                  size="small"
                  onClick={onClick}
                >
                  {/* <OpenInNewRounded style={{ fontSize: "12px" }} /> */}
                  <OpenInNew className="icons_xsmall icons_space_small" />
                </IconButton>
              </FeatureFlag>
            </Grid>
          </Grid>
          <div className="d-jsb-c">
            <div className="progress-base wd-100">
              <div
                className="progress-primary"
                style={{
                  width:
                    Math.round(item.mainSystem.calculated?.overallStatus) + "%",
                }}
              ></div>
            </div>
            <div className="txt-sm ml-5">
              {Math.round(item.mainSystem.calculated?.overallStatus)}%
            </div>
          </div>
          <div className="txt-sm">
            <Highlighted text={item.equipmentNumber} highlight={searchText} />
          </div>

          {/* <div>
            <Box
              display="flex"
              color={noteChecked ? CHECKED_FLAG_COLOR : UNCHECKED_FLAG_COLOR}
            >
              {noteAvailable && (
                <IconButton
                  className={classes.noteFlag}
                  onClick={handleFlagClick}
                  color="inherit"
                >
                  <Flag color="inherit" />
                </IconButton>
              )}
              <Typography color="textPrimary">
                <Highlighted text={item.name} highlight={searchText} />
              </Typography>
            </Box>
            <Typography color="textPrimary">
              {Math.round(item.mainSystem.status)}% {item.equipmentNumber}
            </Typography>
          </div>
          <ColorButton colour="lightGreen" onClick={onClick}>
            Detail
          </ColorButton> */}
        </CardContent>
      </Card>
      <NotesDialog
        open={toggledNotes}
        deliverables={item.mainSystem.deliverables}
        users={users}
        onClose={handleNotesClose}
      />
      <DetailDialog
        open={toggledDetail}
        onClose={handleDetailClose}
        metaSystem={item}
      />
      <MovePhaseDialog
        open={openMovePhase}
        onClose={() => {
          setOpenMovePhase(false);
        }}
        phases={phases}
        phase={item}
      />
    </>
  );
};

export default PhaseItem;
