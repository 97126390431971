import React, { memo, useEffect } from "react";
import LINKS from "utils/constants/links";
import PageHeader from "parts/PageHeader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import SystemGantt from "./components/SystemGantt/SystemGantt";
import { getProjects } from "redux/actions/projects";

const Scheduler = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);
  const project = projects.results.find((item) => item._id === projectId);
  useEffect(() => {
    dispatch(getProjects({ filter: { _id: projectId } }));
  }, [dispatch, projectId]);
  const NAV_LINKS = [
    LINKS.PROJECT_MANAGEMENT,
    LINKS.PROJECTS,
    {
      HREF: LINKS.EDIT_PROJECT.HREF.replace(":id", projectId),
      TITLE: project?.name || "Not Found",
    },
  ];
  return (
    <>
      <PageHeader title={`Scheduler`} links={NAV_LINKS} />
      <SystemGantt />
    </>
  );
};

export default memo(Scheduler);
