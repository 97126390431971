import React, { useState, memo, useEffect } from "react";
import styled from "styled-components/macro";
import {
  NavLink,
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import {
  Collapse,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  Typography,
} from "@material-ui/core";
import _ from "lodash";

import SidebarHeader from "./SidebarHeader";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { sidebarRoutes as routes } from "utils/constants/routes";
import "vendor/perfect-scrollbar.css";
import useAllowedRoutes from "utils/hooks/useAllowedRoutes";
import { ArrowBack } from "@material-ui/icons";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SideNavContext } from "routes";
import { getProjectRoutes } from "./helper";
import useUserPermissions from "utils/hooks/useUserPermission";
import FeatureFlag from "utils/hocs/FeatureFlag";
import useFeatureFlag from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import LINKS from "utils/constants/links";
import { ENVIRONMENT } from "config";
import { getModulePreferences } from "redux/actions/modulePreferenceActions";
import { setActiveProject } from "redux/actions/projects";
import { getProjects } from "services/api-project";
import TYPES from "utils/constants/action-types";
import packageJson from "../../../../package.json";
const drawerWidth = 268;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  boxShadow: "0 0 14px 0 rgb(53 64 82 / 20%)",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "60px",
  boxShadow: "0 0 14px 0 rgb(53 64 82 / 20%)",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    // eslint-disable-next-line
    "& .MuiDrawer-paper": {
      width: drawerWidth,
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Items = styled.div``;

const SidebarSection = styled(Typography)`
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const Sidebar = ({ location }) => {
  const [projectLink, setProjectLink] = useState(LINKS.PROJECTS);
  const allowedSidebarRoutes = useAllowedRoutes(routes);
  const { open, setOpen } = useContext(SideNavContext);
  const projectPages = useLocation().pathname.includes("/projects/edit/");
  const projects = useSelector((state) => state.projects);
  const { projectId, id } = useParams();
  const dispatch = useDispatch();
  const _id = projectId || id;
  const { isAdmin } = useUserPermissions();
  const accessedFeatures = useSelector(
    (state) => state?.modulePreference?.accessibleFeature
  );
  const { permissions, ...userInfo } = useSelector(
    ({ auth }) => auth.currentUser
  );
  const { activeProject } = useSelector(({ projects }) => projects);
  const project = projects.results.find((item) => item._id === _id);
  const systemSnapshotFeatureFlag = useFeatureFlag(
    FEATURE_FLAGS.SystemSnapshot
  );
  const deliverableSnapshotFeatureFlag = useFeatureFlag(
    FEATURE_FLAGS.DeliverableSnapshot
  );
  const deliverableMatrixFeatureFlag = useFeatureFlag(
    FEATURE_FLAGS.DeliverableMatrix
  );
  const projectRoutes = getProjectRoutes(
    _id,
    isAdmin,
    systemSnapshotFeatureFlag,
    deliverableSnapshotFeatureFlag,
    deliverableMatrixFeatureFlag,
    accessedFeatures,
    permissions
  );
  const history = useHistory();

  useEffect(() => {
    if (ENVIRONMENT?.toLowerCase() === "qms") {
      const link = project?.type === "QMS" ? LINKS.OVERVIEW : LINKS.DELIVERY;
      setProjectLink(link);
    }
  }, [project]);

  const qmsProjData = useSelector((state) => state.projects.qmsProject);
  useEffect(() => {
    if (
      ENVIRONMENT?.toLowerCase() === "qms" &&
      !projectPages &&
      qmsProjData._id === undefined
    ) {
      getProjects({
        filter: {
          type: "QMS",
        },
      }).then((response) => {
        dispatch({ type: TYPES.SET_QMS_PROJECT, payload: response.data[0] });
      });
    }
    // eslint-disable-next-line
  }, []);

  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;
    let _routes = {};

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";
      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const initOpenProjectRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname.split("/");
    let _routes = {};
    const componentEndPoints = [
      "snapshot",
      "system-snapshot",
      "document-snapshot",
      "matrix",
      "phase-snapshot",
    ];
    projectRoutes.forEach((route, index) => {
      const isActive = componentEndPoints.some((endPoint) =>
        pathName.includes(endPoint)
      );
      // const isActive =
      //   pathName.includes("snapshot") ||
      //   pathName.includes("system-snapshot") ||
      //   pathName.includes("matrix") ||
      //   pathName.includes("phase-snapshot");
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";
      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());
  const [openRoutesProject, setOpenRoutesProject] = useState(() =>
    initOpenProjectRoutes()
  );
  const onDrawerToggle = (type = true) => {
    if (open) {
      setOpenRoutes(() => initOpenRoutes());
      setOpenRoutesProject(() => initOpenProjectRoutes());
    } else {
      if (!type) {
        setOpenRoutes(_.mapValues(openRoutes, () => false));
        setOpenRoutesProject(_.mapValues(openRoutesProject, () => false));
      }
    }
    setOpen(!open);
  };

  const toggle = (index) => {
    if (!open) {
      onDrawerToggle(false);
    }

    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    Object.keys(openRoutesProject).forEach(
      (item) =>
        openRoutesProject[index] ||
        setOpenRoutesProject((openRoutesProject) =>
          Object.assign({}, openRoutesProject, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );

    setOpenRoutesProject((openRoutesProject) =>
      Object.assign({}, openRoutesProject, {
        [index]: !openRoutesProject[index],
      })
    );
  };

  useEffect(() => {
    if (projectId) {
      dispatch(setActiveProject(projectId));
    }
  }, [projectId, dispatch]);

  useEffect(() => {
    if (userInfo._id && projectId && activeProject !== projectId) {
      dispatch(getModulePreferences(userInfo._id, projectId));
    }
  }, [userInfo._id, activeProject, projectId, dispatch]);

  const linkChecker = (currentPath, pathId, categoryPath) => {
    // console.log("categoryPath >>>>> ", categoryPath);
    // console.log("currentPath >>>>> ", currentPath);
    // console.log("pathId >>>>> ", pathId);
    if (categoryPath === currentPath) {
      return true;
    }
    if (currentPath?.toLowerCase().includes(pathId?.toLowerCase())) {
      return true;
    }
    if (
      currentPath?.toLowerCase().includes(categoryPath?.toLowerCase()) &&
      pathId !== "Overview"
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="nav-color">
      <Drawer variant="permanent" open={open}>
        <SidebarHeader open={open} onDrawerToggle={onDrawerToggle} />
        <Divider />
        <List
          disablePadding
          className="nav-color"
          style={{ display: "flex", flex: "1", flexDirection: "column" }}
        >
          <Items>
            {!projectPages &&
              allowedSidebarRoutes
                .filter(
                  (category) =>
                    category?.roles?.includes(permissions) || !category.roles
                )
                .map((category, index) => (
                  <React.Fragment key={index}>
                    {/* {console.log("category >>>>>>>> ", category)} */}
                    {/* {category.header && open && (
                    <SidebarSection>{category.header}</SidebarSection>
                  )} */}
                    {category.component ? (
                      <SidebarCategory
                        isCollapsable={false}
                        name={category.id}
                        to={category.path}
                        activeClassName="active"
                        component={NavLink}
                        icon={category.icon}
                        isActive={linkChecker(
                          location.pathname,
                          category.pathId || category.id,
                          category.path
                        )}
                        button
                        badge={category.badge}
                        open={open}
                      />
                    ) : (
                      <React.Fragment key={index}>
                        <SidebarCategory
                          activeClassName="active"
                          isActive={
                            !open &&
                            linkChecker(
                              location.pathname,
                              category.pathId || category.id,
                              category.path
                            )
                          }
                          isOpen={!openRoutes[index]}
                          isCollapsable={true}
                          name={category.id}
                          icon={category.icon}
                          button={true}
                          open={open}
                          // active={!open && openRoutes[index]}
                          onClick={() => toggle(index)}
                        />
                        <Collapse
                          in={openRoutes[index] && open}
                          timeout="auto"
                          unmountOnExit
                        >
                          {category?.children.map(
                            (route, index) =>
                              !route?.isNotSlide && (
                                <SidebarLink
                                  key={index}
                                  name={route.name}
                                  to={
                                    route.name.toUpperCase() === "QMS"
                                      ? LINKS.EDIT_PROJECT.HREF.replace(
                                          ":id",
                                          qmsProjData?._id
                                        )
                                      : route.path
                                  }
                                  isActive={linkChecker(
                                    location.pathname,
                                    category.pathId || category.id,
                                    route.path
                                  )}
                                  icon={route.icon}
                                  badge={route.badge}
                                />
                              )
                          )}
                        </Collapse>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
            {projectPages && (
              <div>
                {open && (
                  <SidebarSection
                    style={{
                      padding: "10px 16px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                    className="d-jsb-cen-imp"
                  >
                    <IconButton
                      size="small"
                      className="mr-10"
                      style={{ display: "inline-block" }}
                      onClick={() => {
                        history.push(projectLink.HREF);
                      }}
                    >
                      <ArrowBack />
                    </IconButton>
                    <span className="side-nav-text"> {project?.name}</span>
                  </SidebarSection>
                )}
                {projectRoutes.map((category, index) => (
                  <React.Fragment key={index}>
                    <FeatureFlag feature={category.feature}>
                      {category.component ? (
                        <SidebarCategory
                          isCollapsable={false}
                          name={category.id}
                          to={category.path}
                          activeClassName="active"
                          component={NavLink}
                          icon={category.icon}
                          isActive={linkChecker(
                            location.pathname,
                            category.pathId || category.id,
                            category.path
                          )}
                          button
                          badge={category.badge}
                          open={open}
                        />
                      ) : (
                        <>
                          <SidebarCategory
                            activeClassName="active"
                            isActive={linkChecker(
                              location.pathname,
                              category.pathId || category.id,
                              category.path
                            )}
                            isOpen={!openRoutesProject[index]}
                            isCollapsable={true}
                            name={category.id}
                            icon={category.icon}
                            button={true}
                            open={open}
                            active={!open && openRoutesProject[index]}
                            onClick={() => toggle(index)}
                          />
                          <Collapse
                            in={openRoutesProject[index] && open}
                            timeout="auto"
                            unmountOnExit
                          >
                            {category.children.map(
                              (route, index) =>
                                !route?.isNotSlide && (
                                  <FeatureFlag feature={route.feature}>
                                    <SidebarLink
                                      key={index}
                                      name={route.id}
                                      to={route.path}
                                      isActive={linkChecker(
                                        location.pathname,
                                        category.pathId || category.id,
                                        route.path
                                      )}
                                      icon={route.icon}
                                      badge={route.badge}
                                    />
                                  </FeatureFlag>
                                )
                            )}
                          </Collapse>
                        </>
                      )}
                    </FeatureFlag>
                  </React.Fragment>
                ))}
              </div>
            )}
          </Items>
        </List>
        <Divider />
        {open && (
          <div className="ml-20 mb-5 nav-color txt-grey">
            Version {packageJson.version}
          </div>
        )}
        {/* <SidebarFooter open={open} /> */}
      </Drawer>
    </div>
  );
};

export default memo(withRouter(Sidebar));
